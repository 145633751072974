import { useMutation, useQueryClient, type QueryKey } from "@tanstack/react-query"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

const useFreeTimeOffSlot = ({
  onSettled,
  onSuccess,
  queryKeyToInvalidate,
}: {
  onSuccess?: () => void
  onSettled?: () => void
  queryKeyToInvalidate: QueryKey
}) => {
  const queryClient = useQueryClient()
  const { operationRequest } = useClient()

  const updateFn = async ({ slotId }: { slotId: string }) => {
    return operationRequest({
      endpoint: "Slot",
      id: slotId,
      method: "POST",
      operation: "free",
    })
  }

  const { mutateAsync: freeTimeOffSlot, isPending } = useMutation({
    mutationFn: updateFn,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKeyToInvalidate })
      displayNotificationSuccess("Practitioner time off hours updated successfully!")
      onSuccess?.()
    },
    onSettled,
  })

  return { freeTimeOffSlot, isPending }
}

export { useFreeTimeOffSlot }
