import type { Reference } from "fhir"
import { useFormikContext } from "formik"
import { AutoComplete } from "primereact/autocomplete"
import { type FC, useEffect, useState } from "react"

import { GenericFieldArray, MentionTextAreaField, ReferenceDropdownField } from "commons"
import { useOrganizationContext } from "organization"

import { useAppointmentParticipants } from "../hooks"
import type { AppointmentFormData } from "../types"

const AppointmentParticipantsSection: FC<Props> = ({ hideExtraParticipantFields }) => {
  const {
    values: { healthcareService, selectedSlot, devices, room, override },
    setFieldValue,
  } = useFormikContext<AppointmentFormData>()
  const { devicesRefs, roomsRefs, isLoading } = useAppointmentParticipants({
    appointmentType: healthcareService,
    startDate: selectedSlot?.start,
    endDate: selectedSlot?.end,
    enabled: !override,
  })
  const { roomsRefs: overrideRooms, devicesRefs: overrideDivices } = useOrganizationContext()
  const [filteredDevices, setFilteredDevices] = useState<Reference[]>()
  const [filter, setFilter] = useState("")
  const fieldsDisabled = !selectedSlot && !override

  const filterDevices = () => {
    setFilteredDevices(
      devicesRefs.filter((dev) => dev?.display?.includes(filter) && !devices.some(({ id }) => id === dev?.id)),
    )
  }

  useEffect(() => {
    if (!isLoading) {
      setFilteredDevices(devicesRefs)
      setFieldValue(
        "devices",
        !devices.length ? devicesRefs : devices.filter((dev) => devicesRefs.some(({ id }) => id === dev.id)),
      )
      setFieldValue(
        "room",
        !room && roomsRefs.length === 1 ? roomsRefs[0] : roomsRefs.find(({ id }) => id === room?.id),
      )
    }
  }, [isLoading])

  const NO_DEV_FOUND = "No devices found"

  return (
    <fieldset
      className="flex flex-col gap-2 disabled:pointer-events-none disabled:opacity-40"
      disabled={fieldsDisabled}
    >
      {!hideExtraParticipantFields && (
        <>
          <ReferenceDropdownField
            field="room"
            label="Room"
            options={override ? overrideRooms : roomsRefs}
            className="w-full"
            disabled={fieldsDisabled || (roomsRefs.length === 0 && !override)}
            loading={isLoading}
            validation={(value) => !!roomsRefs.length && !value && "Room is required"}
          />
          <GenericFieldArray
            field="devices"
            label="Devices"
            itemModelBuilder={(item: Reference) => ({ leftData: [{ lineItems: [{ value: item.display }] }] })}
            addButtonVisible={false}
            emptyMessageVisible={false}
            className="field !mb-2 flex flex-col"
            labelClassName="text-sm mb-2"
            containerClassName="ml-3"
            itemClassName="!py-2"
            readOnly
          >
            {({ push }) => (
              <AutoComplete
                className="p-inputtext-sm mb-3"
                placeholder="Add new device"
                suggestions={override ? overrideDivices : filteredDevices?.length ? filteredDevices : [NO_DEV_FOUND]}
                itemTemplate={(item) => item?.display ?? NO_DEV_FOUND}
                onChange={(e) => {
                  if (typeof e.value === "string") {
                    if (e.value === NO_DEV_FOUND) return
                    setFilter(e.value)
                  } else {
                    push(e.value)
                    setFilter("")
                  }
                }}
                disabled
                completeMethod={filterDevices}
                value={filter}
                dropdown
              />
            )}
          </GenericFieldArray>
        </>
      )}
      <MentionTextAreaField field="comment" label="Notes" rows={10} />
    </fieldset>
  )
}

type Props = {
  hideExtraParticipantFields?: boolean
  isEditing?: boolean
}

export { AppointmentParticipantsSection }
