import { faCalendarDays, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Chip } from "primereact/chip"
import { format } from "date-fns/format"

import { formatsByTypes } from "data"
import { AvatarListItem } from "commons"

const AgendaSlotItem = ({ start, status, type, description, color, onReleaseSlot }: Props) => {
  const head = (
    <>
      <span title="Patient">{description}</span>
      {status && (
        <span title="Status">
          <Chip label={status} className="custom-chip ml-2" />
        </span>
      )}
    </>
  )

  const details = (
    <>
      <span className="flex items-center">
        <FontAwesomeIcon icon={faCalendarDays} className="mr-1 text-gray-400" />
        <span title="Start">{start ? format(start, formatsByTypes.LONG_DATETIME) : "No date"}</span>
      </span>
      {type && (
        <span title="Type" className="pl-2 font-bold capitalize" style={{ color }}>
          {type}
        </span>
      )}
    </>
  )

  return (
    <AvatarListItem
      header={head}
      subHeader={details}
      actionItem={{
        label: "Release slot",
        icon: <FontAwesomeIcon icon={faTrashCan} size="sm" />,
        disabled: status === "cancelled",
        command: onReleaseSlot,
      }}
    />
  )
}

type Props = {
  status: string
  description: string
  type: string
  patientAvatar?: string
  start?: Date
  end?: Date
  color?: string
  onReleaseSlot?: () => void
}

export { AgendaSlotItem }
