import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $insertNodes } from "lexical"
import { $generateNodesFromDOM } from "@lexical/html"
import { type FC } from "react"
import { useMountEffect } from "primereact/hooks"

const TemplatePlugin: FC<TemplatePluginProps> = ({ template }) => {
  const [editor] = useLexicalComposerContext()

  useMountEffect(() => {
    editor.update(() => {
      const parser = new DOMParser()
      const dom = parser.parseFromString(template, "text/html")

      const nodes = $generateNodesFromDOM(editor, dom)

      $insertNodes(nodes)
    })
  })

  return null
}

type TemplatePluginProps = {
  template: string
}

export { TemplatePlugin }
