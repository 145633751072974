import { type Reference, type Task, asReference } from "fhir"
import { Sidebar } from "primereact/sidebar"
import { useCallback, useMemo, useReducer, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useMountEffect } from "primereact/hooks"

import { FiltersContextProvider, FormContainer, SkeletonLoader, ValueSetIds } from "commons"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { SYSTEM_VALUES } from "system-values"
import { type ValueSetItem, useValueSet } from "value-set"
import { usePractitioner } from "practitioner/hooks"

import { useCreateTask, useUpdateTask } from "../hooks"
import { TaskDetailView } from "./TaskDetailView"
import { TaskForm } from "./TaskForm"
import { TaskList } from "./TaskList"
import { getInitialValues, sanitize, taskValidationSchema } from "./validations"
import type { FilterProps } from "../types"

const TaskView = () => {
  const [searchParams] = useSearchParams()
  const { currentOrganizationId, currentOrganization, loggedInPractitionerRole } = useOrganizationContext()
  const { codes: taskCodes } = useValueSet({ valueSetId: ValueSetIds.TASK_CODE })

  const { organizationPractitionerRefs, organizationPractitionersStaffRef } = useOrganizationPractitioners({
    organizationId: currentOrganizationId,
  })
  const [initialRender, setInitialRender] = useState(true)

  const assignedTo = searchParams.get("assignedTo") ?? undefined
  const initialSearchText = searchParams.get("searchText")

  const { practitionerRef, isLoading: isLoadingPractitioner } = usePractitioner(assignedTo, initialRender)

  const initialFilters = parseParamsToFilters(searchParams, practitionerRef)

  const practitioners = useMemo(() => {
    const seen = new Set<string>()
    return [...(organizationPractitionerRefs ?? []), ...(organizationPractitionersStaffRef ?? [])].filter(
      (p) => !seen.has(p?.id as string) && seen.add(p?.id as string),
    )
  }, [organizationPractitionerRefs, organizationPractitionersStaffRef])

  //TODO: remove fallback when organizational is added to VS
  const defaultTaskCode = useMemo(
    () =>
      taskCodes?.find((tCode) => tCode.code === "organizational") ?? {
        system: SYSTEM_VALUES.TASK_CODE,
        code: "organizational",
        display: "Organizational",
      },
    [taskCodes],
  )

  const { showSidePanel, initialValues, isNew, add, edit, reset } = useReducerState(
    asReference(currentOrganization),
    defaultTaskCode,
    asReference(loggedInPractitionerRole ?? {}),
  )

  const closeForm = () => {
    reset()
  }
  useMountEffect(() => setInitialRender(false))

  const { createTask } = useCreateTask(closeForm)
  const { updateTask } = useUpdateTask({ hideForm: closeForm })

  if (isLoadingPractitioner)
    return (
      <>
        <SkeletonLoader loaderType="one-line" repeats={1} />
        <SkeletonLoader loaderType="form-two-cols" repeats={1} />
      </>
    )

  const customSidebarTitlebar = (
    <>
      <span className="w-full bg-white">
        <h6 className="font-semibold">Task</h6>
        <p className="text-sm text-slate-400">Create or edit tasks for practitioners</p>
      </span>
    </>
  )

  const onSubmit = (task: Task) => {
    isNew ? createTask(sanitize(task)) : updateTask(sanitize(task))
  }

  return (
    <>
      <FiltersContextProvider
        initialFilters={initialFilters}
        initialSearchText={initialSearchText as string}
        filtersKeysValueMap={{
          assignedTo: (val) => (val as Reference | undefined)?.id,
        }}
        setUrlFilters
      >
        {!searchParams.get("id") ? (
          <TaskList
            organizationId={currentOrganizationId}
            practitioners={practitioners}
            onAdd={add}
            onEditTask={edit}
          />
        ) : (
          <TaskDetailView />
        )}
      </FiltersContextProvider>
      <Sidebar
        visible={showSidePanel}
        position="right"
        style={{ minWidth: "30%" }}
        header={customSidebarTitlebar}
        onHide={closeForm}
        className="sidebar-form"
      >
        <div className="relative h-full">
          <FormContainer
            initialValue={initialValues}
            onSubmit={onSubmit}
            onCancel={closeForm}
            validationSchema={taskValidationSchema}
          >
            <TaskForm practitioners={organizationPractitionerRefs} />
          </FormContainer>
        </div>
      </Sidebar>
    </>
  )
}

const initialState = (defaultTask: Task) =>
  ({
    initialValues: defaultTask,
    showSidePanel: false,
  }) as State

const reducer = (state: State, { type, payload }: { type: "reset" | "add" | "edit"; payload: Task | string }) => {
  switch (type) {
    case "reset":
      return { ...state, showSidePanel: false, isNew: false, initialValues: payload as Task, confirmDeleteItem: "" }
    case "add":
      return { ...state, showSidePanel: true, panelView: "form", isNew: true, initialValues: payload as Task }
    case "edit":
      return { ...state, showSidePanel: true, panelView: "form", isNew: false, initialValues: payload as Task }
    default:
      return state
  }
}
const useReducerState = (organization: Reference, defaultTaskCode: ValueSetItem, practitionerRole?: Reference) => {
  const defaultTask = useMemo(
    () => getInitialValues(organization, defaultTaskCode, practitionerRole),
    [organization, practitionerRole, defaultTaskCode],
  )
  const state = useMemo(() => initialState(defaultTask), [defaultTask])
  const [{ initialValues, showSidePanel, isNew }, dispatch] = useReducer(reducer, state)

  const reset = useCallback(() => {
    dispatch({ type: "reset", payload: defaultTask })
  }, [defaultTask])

  const add = useCallback(() => {
    dispatch({ type: "add", payload: defaultTask })
  }, [defaultTask])

  const edit = (selectedTask: Task) => {
    dispatch({ type: "edit", payload: selectedTask })
  }

  return { initialValues, isNew, reset, add, edit, showSidePanel }
}

type State = {
  initialValues: Task
  showSidePanel: boolean
  isNew?: boolean
}
const DEFAULT_FILTERS: FilterProps = {
  status: undefined,
  code: undefined,
  assignedTo: undefined,
  performerType: undefined,
  id: undefined,
}

export { TaskView }

const parseParamsToFilters = (searchParams: URLSearchParams, practitionerRef?: Reference): FilterProps => ({
  id: searchParams.get("id") ?? DEFAULT_FILTERS.id,
  status: searchParams.get("status")?.split(",") ?? DEFAULT_FILTERS.status,
  code: searchParams.get("code")?.split(",") ?? DEFAULT_FILTERS.code,
  performerType: searchParams.get("performerType")?.split(",") ?? DEFAULT_FILTERS.performerType,
  assignedTo: searchParams.get("assignedTo")
    ? practitionerRef ?? ({ id: searchParams.get("assignedTo"), resourceType: "Practitioner" } as Reference)
    : DEFAULT_FILTERS.assignedTo,
})
