import { humanNameAsString, type Patient, type ResourceObject } from "fhir"
import { Fragment, useMemo, useRef, useState, type FC } from "react"
import { classNames } from "primereact/utils"
import { faEnvelopeOpenText } from "@fortawesome/pro-regular-svg-icons"
import { Button } from "primereact/button"
import { isAfter, formatDistance } from "date-fns"
import type { UseMutateFunction } from "@tanstack/react-query"

import { Badge, ConfirmDialog, EmptyMessage, SkeletonLoader, type CustomError } from "commons"
import { getBadgeColor } from "utils"

import { usePatientInvitation } from "../hooks"

const PatientInviteContainer: FC<Props> = ({
  patient,
  className,
  hasB2CUser,
  emailUpdates,
  isInviting,
  invitePatient,
  disabled,
}) => {
  const { invitationsByDate, isLoading: isLoadingInvitation } = usePatientInvitation(patient?.id as string, true)
  const blockInvite = useRef(false)
  const patientId = patient.id as string
  const patientName = humanNameAsString(patient.name?.[0])

  const [showConfirm, setShowConfirm] = useState(false)

  const { isUpdating, newEmail, initialEmail, updateEmailAndInvite } = emailUpdates

  const isSameAsLastInvitationEmail = useMemo(
    () => newEmail === invitationsByDate[0]?.items?.[0]?.email,
    [newEmail, invitationsByDate?.[0]?.items],
  )

  const hasChangedEmail = useMemo(() => initialEmail?.localeCompare(newEmail as string) !== 0, [initialEmail, newEmail])

  const handleInvite = () => {
    if (hasChangedEmail) {
      updateEmailAndInvite(() => {
        blockInvite.current = true
        setTimeout(() => (blockInvite.current = false), 30000)
      })
    } else {
      invitePatient({ patientId, patientNameOrEmail: patientName })
      blockInvite.current = true
      setTimeout(() => (blockInvite.current = false), 30000)
    }
  }

  return (
    <div className={classNames("flex flex-col overflow-hidden rounded-lg border border-gray-200", className)}>
      <h5 className="p-3 font-medium text-gray-700">Invitation history</h5>
      <div className="flex flex-1 grow flex-col gap-3 overflow-y-auto p-3">
        {isLoadingInvitation ? (
          <SkeletonLoader repeats={4} loaderType="plain-text" />
        ) : !invitationsByDate.length ? (
          <EmptyMessage
            message={hasB2CUser ? "Already invited" : "Not invited"}
            icon={faEnvelopeOpenText}
            subMessage={
              hasB2CUser
                ? "Sorry, no records of the invitations where found"
                : "This patient has not received an invitation"
            }
          />
        ) : (
          <>
            {invitationsByDate.map(({ date, items }) => (
              <Fragment key={date}>
                <span className="flex w-full items-center text-sm text-nowrap text-gray-700">
                  {date}
                  <hr className="ml-1 w-full bg-gray-200" />
                </span>
                <ul className="flex list-none flex-col gap-y-3 divide-y divide-gray-200">
                  {items.map(({ id, status, email, expires }, index) => (
                    <li
                      key={`${id}_${index}`}
                      className="inline-flex w-full items-center justify-between pb-3 text-sm last:pb-0"
                    >
                      <div className="flex flex-col">
                        <span title="Email" className="truncate text-gray-500">
                          {email ?? patientName}
                        </span>
                        {!!expires && isAfter(expires, new Date()) && (
                          <span className="text-xs text-gray-400">
                            expires in {formatDistance(new Date(expires), new Date())}
                          </span>
                        )}
                      </div>

                      <Badge
                        {...getBadgeColor(["active", "in-progress", "completed"].includes(status) ? "pending" : status)}
                      />
                    </li>
                  ))}
                </ul>
              </Fragment>
            ))}
          </>
        )}
      </div>

      {(!hasB2CUser || !!invitationsByDate.length) && (
        <div className="flex flex-shrink-0 flex-col items-end gap-3 border-t border-gray-200 p-3">
          {!hasB2CUser ? (
            <>
              <small className="text-gray-400">*Invitations will be sent to current email address</small>
              <Button
                label={isSameAsLastInvitationEmail ? "Resend invitation" : "Send invitation"}
                disabled={
                  disabled || !newEmail || isInviting || blockInvite.current || isLoadingInvitation || !!isUpdating
                }
                tooltip={
                  blockInvite.current
                    ? "Please allow some time while the invitation is sent"
                    : "Patient needs to have a valid email to send an invitation."
                }
                tooltipOptions={{
                  disabled: !!newEmail && !disabled && !blockInvite.current,
                  position: "left",
                  showOnDisabled: true,
                }}
                loading={isInviting}
                className="border-gray-200 bg-white px-3 py-2 text-sm text-gray-700 ring-gray-300 hover:bg-slate-100"
                onClick={() => (hasChangedEmail ? setShowConfirm(true) : handleInvite())}
                type="button"
              />
            </>
          ) : (
            <small className="w-full text-gray-400">This patient is already invited</small>
          )}
        </div>
      )}
      <ConfirmDialog
        confirmText={`The email will be updated to ${newEmail} and will be used to send a new invitation. Do you want to proceed?`}
        actionName="Invite"
        rejectAction="Cancel"
        onConfirm={handleInvite}
        hideDialog={() => setShowConfirm(false)}
        visible={showConfirm}
        className="xl:max-w-1/3"
      />
    </div>
  )
}

type Props = {
  patient: Patient
  hasB2CUser?: boolean
  className?: string
  emailUpdates: {
    initialEmail?: string
    newEmail?: string
    isUpdating?: boolean
    updateEmailAndInvite(callback?: () => void): void
  }
  invitePatient: UseMutateFunction<
    ResourceObject,
    CustomError,
    { patientId: string; patientNameOrEmail: string },
    unknown
  >
  isInviting?: boolean
  disabled?: boolean
}

export { PatientInviteContainer }
