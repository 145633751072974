import { useQuery } from "@tanstack/react-query"
import {
  type Appointment,
  type Device,
  type HealthcareService,
  type Location,
  type Patient,
  type Practitioner,
  getResources,
  getResourcesByTypeAsIndex,
  isDevice,
  isHealthcareService,
  isLocation,
  isPatient,
  isPractitioner,
} from "fhir"

import { useClient } from "api"
import { formatsByTypes } from "data"
import { formatDate } from "utils"
import type { CalendarAppointment } from "commons/types"

import { practitionerAptsQueryKeys } from "../query-keys"

const usePractitionerAppointments = ({
  currentOrganizationId,
  patientId,
  practitionerId,
  appointmentType,
  start,
  end,
}: {
  currentOrganizationId: string
  patientId?: string[]
  practitionerId?: string[]
  start?: Date
  end?: Date
  appointmentType?: string[]
}) => {
  const { search } = useClient()
  const queryKey = practitionerAptsQueryKeys.list(currentOrganizationId, {
    start,
    end,
    patientId,
    practitionerId,
    appointmentType,
  })

  const {
    isSuccess,
    data: appointments,
    isLoading,
  } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        status: "booked",
        _include: "actor",
        ...(patientId ? { patient: patientId.join(",") } : {}),
        ...(practitionerId ? { practitioner: practitionerId.join(",") } : {}),
        ...(appointmentType?.length
          ? { actor: appointmentType.map((type) => `HealthcareService/${type}`).join(",") }
          : {}),
        _sort: "-date",
        "actor:Patient.organization": currentOrganizationId,
      })

      start && filters.append("date", `ge${formatDate(start, formatsByTypes.ISO_8601_DATE)}`)
      end && filters.append("date", `le${formatDate(end, formatsByTypes.ISO_8601_DATE)}`)

      const bundle = await search({ endpoint: "Appointment", filters, signal })
      const appointments = getResources<Appointment>(bundle, "Appointment")
      const practitioners = getResourcesByTypeAsIndex<Practitioner>(bundle, "Practitioner")
      const patients = getResourcesByTypeAsIndex<Patient>(bundle, "Patient")
      const locations = getResourcesByTypeAsIndex<Location>(bundle, "Location")
      const healthcareServices = getResourcesByTypeAsIndex<HealthcareService>(bundle, "HealthcareService")
      const indexedDevices = getResourcesByTypeAsIndex<Device>(bundle, "Device")

      return (
        appointments?.map<CalendarAppointment>((appointment) => {
          const { practitioner, patient, location, healthcareService, devices } = appointment.participant.reduce<{
            practitioner?: Practitioner
            patient?: Patient
            location?: Location
            healthcareService?: HealthcareService
            devices?: Device[]
          }>((prev, { actor }) => {
            if (isPractitioner(actor) && actor.id) return { ...prev, practitioner: practitioners?.[actor.id] }
            if (isPatient(actor) && actor.id) return { ...prev, patient: patients?.[actor.id] }
            if (isLocation(actor) && actor.id) return { ...prev, location: locations?.[actor.id] }
            if (isHealthcareService(actor) && actor.id)
              return { ...prev, healthcareService: healthcareServices?.[actor.id] }
            if (isDevice(actor) && actor.id)
              return { ...prev, devices: [...(prev.devices ?? []), indexedDevices?.[actor.id]] }
            return prev
          }, {})

          return {
            appointment,
            practitioner,
            patient,
            location,
            healthcareService,
            devices,
          }
        }) ?? []
      )
    },
    meta: { context: { queryKey, patientId } },
  })

  return { appointments, isLoading, isSuccess, queryKey }
}

export { usePractitionerAppointments }
