import { faFilterSlash } from "@fortawesome/pro-regular-svg-icons"
import { faFilter } from "@fortawesome/pro-solid-svg-icons"
import type { Coding, Composition, Reference } from "fhir"
import { type FormikProps, type FormikValues, Form, Formik } from "formik"
import type { CalendarProps } from "primereact/calendar"
import type { DropdownChangeEvent } from "primereact/dropdown"
import { OverlayPanel } from "primereact/overlaypanel"
import { classNames } from "primereact/utils"
import { Fragment, useRef } from "react"

import { AutoCompletePatientField, DateField, DropdownField, InputField, MultiSelectField } from "../forms"
import { Button } from "./Buttons"
import { SearchInput } from "./SearchInput"

import "./SearchWithFilters.css"

const SearchWithFilters = <T extends FormikValues>({
  isLoading,
  initialFilters,
  onBeforeFilter,
  onFilter,
  onTextFilter,
  filtersData,
  placeholder = "Search",
  className,
  buttonClassName = "w-12",
  showClearButton = true,
  autoFocus,
}: SearchWithFiltersProps<T>) => {
  const overlayFilter = useRef<OverlayPanel>(null)

  const filterNone = !initialFilters || Object.values(initialFilters).every((f) => f === undefined || f === "")
  const filter = (filters?: SearchTextProps & T) => {
    overlayFilter?.current?.hide()
    onFilter?.(filters)
  }

  return (
    <>
      <div className={classNames("flex", className)}>
        <SearchInput
          className="flex-1"
          search={(searchText) => {
            onBeforeFilter?.()
            initialFilters ? onFilter?.({ ...initialFilters, searchText }) : onTextFilter?.(searchText)
          }}
          isLoading={isLoading}
          placeholder={placeholder}
          autoFocus={autoFocus}
        />
        {filtersData && initialFilters && (
          <>
            <Button
              icon={faFilter}
              buttonStyle="default"
              className={classNames(
                "ml-3 justify-center",
                filterNone ? "text-slate-400" : "text-primary",
                buttonClassName,
              )}
              iconClassName="w-3.5 h-3.5"
              onClick={(e) => overlayFilter?.current?.toggle(e)}
            />
            <OverlayPanel
              ref={overlayFilter}
              showCloseIcon={false}
              dismissable
              id="overlay_panel"
              className="form-panel"
              breakpoints={{ "1330px": "25vw", "1024px": "45vw", "960px": "75vw", "640px": "90vw" }}
              style={{ width: "25vw" }}
            >
              <Formik initialValues={initialFilters} onSubmit={(values) => filter(values)} enableReinitialize>
                {({ isSubmitting }: FormikProps<T>) => (
                  <Form className="form-search-with-filters flex h-full w-full flex-col gap-2 bg-white">
                    <>
                      {filtersData.map((filterProp, index) => (
                        <Fragment key={index}>
                          {filterProp.type === "multiselect" ? (
                            <MultiSelectField
                              label={filterProp.label}
                              field={filterProp.field}
                              options={filterProp.data}
                              optionLabel={filterProp.optionLabel ?? "display"}
                              optionValue={filterProp.optionValue ?? "code"}
                              horizontal
                              showClear
                              inputClassName="slashed text-sm"
                            />
                          ) : filterProp.type === "select" ? (
                            <DropdownField
                              label={filterProp.label}
                              field={filterProp.field}
                              options={filterProp.data}
                              optionLabel={filterProp.optionLabel ?? "display"}
                              optionValue={filterProp.optionValue ?? "id"}
                              horizontal
                              showClear
                              inputClassName="slashed text-sm"
                              handleChange={filterProp.handleChange}
                            />
                          ) : filterProp.type === "date" ? (
                            <DateField
                              label={filterProp.label}
                              field={filterProp.field}
                              minDate={filterProp.minDate}
                              maxDate={filterProp.maxDate}
                              selectionMode={filterProp.dateSelectionMode}
                              readOnlyInput
                              horizontal
                              inputClassName="slashed text-sm"
                            />
                          ) : filterProp.type === "patient" ? (
                            <AutoCompletePatientField
                              label={filterProp.label}
                              field={filterProp.field}
                              horizontal
                              inputClassName="slashed text-sm"
                            />
                          ) : (
                            filterProp.type === "text" && (
                              <InputField
                                label={filterProp.label}
                                field={filterProp.field}
                                type={filterProp.textType}
                                inputClassName="slashed text-sm"
                                clearable
                                blockScapeChar
                                horizontal
                                truncate={false}
                                onClear={() => onFilter?.({ ...initialFilters, [filterProp.field]: "" } as T)}
                              />
                            )
                          )}
                        </Fragment>
                      ))}
                      <div className="flex shrink-0 justify-end px-4 py-4">
                        {showClearButton && (
                          <Button
                            label="Clear"
                            buttonStyle="default"
                            size="lg"
                            className="mr-3"
                            icon={faFilterSlash}
                            disabled={isSubmitting || filterNone}
                            onClick={() => filter(undefined)}
                          />
                        )}
                        <Button type="submit" label="Search" size="lg" loading={isSubmitting} />
                      </div>
                    </>
                  </Form>
                )}
              </Formik>
            </OverlayPanel>
          </>
        )}
      </div>
    </>
  )
}

export type SearchWithFiltersProps<T> = {
  autoFocus?: boolean
  filtersData?: FilterProps[]
  initialFilters?: T
  isLoading: boolean
  placeholder?: string
  className?: string
  buttonClassName?: string
  showClearButton?: boolean
  onBeforeFilter?(): void
  onFilter?(filters?: SearchTextProps & T): void
  onTextFilter?(searchText?: string): void
}

type SearchTextProps = {
  searchText?: string
}

type FilterProps =
  | {
      label: string
      field: string
      type: "patient"
    }
  | {
      label: string
      field: string
      type: "date"
      minDate?: Date
      maxDate?: Date
      dateSelectionMode?: CalendarProps["selectionMode"]
    }
  | {
      label: string
      field: string
      data: Reference[] | Coding[] | Composition[]
      optionLabel?: string
      optionValue?: string
      type: "select" | "multiselect"
      handleChange?(e: DropdownChangeEvent): void
    }
  | {
      label: string
      field: string
      optionLabel?: string
      optionValue?: string
      type: "text"
      textType?: string
    }

export { SearchWithFilters }
