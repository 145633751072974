import { useQuery } from "@tanstack/react-query"
import { formatDate } from "date-fns"
import {
  type Bundle,
  type Parameters,
  type Slot,
  getResources,
  type Appointment,
  getResourcesByTypeAsIndex,
  type HealthcareService,
  type Device,
  type Patient,
  type Practitioner,
  type Location,
  isDevice,
  isHealthcareService,
  isLocation,
  isPatient,
  isPractitioner,
} from "fhir"

import { useClient } from "api"
import { formatsByTypes } from "data"
import { useOrganizationContext } from "organization"
import type { CalendarAppointment } from "commons/types"

import { settingsQueryKeys } from "../../../query-keys"
import type { PractitionerCalendarEvent } from "../types"

const useGetPractitionerCalendar = ({
  practitionerId,
  startDate,
  endDate,
}: {
  practitionerId?: string
  startDate?: Date
  endDate?: Date
}) => {
  const { operationRequest } = useClient()
  const { currentOrganizationId } = useOrganizationContext()
  const queryKey = settingsQueryKeys.practitionerCalendar({
    organizationId: currentOrganizationId,
    practitioner: practitionerId,
    start: startDate,
    end: endDate,
  })

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const start = formatDate(startDate!, formatsByTypes.ISO_8601_DATETIME)
      const end = formatDate(endDate!, formatsByTypes.ISO_8601_DATETIME)

      const parameters: Parameters = {
        resourceType: "Parameters",
        parameter: [
          {
            name: "start",
            value: {
              dateTime: start,
            },
          },
          {
            name: "end",
            value: {
              dateTime: end,
            },
          },
        ],
      }

      const bundle = await operationRequest<Bundle>({
        endpoint: "Practitioner",
        id: practitionerId,
        method: "POST",
        operation: "get-calendar",
        parameters,
        signal,
      })

      const slots = getResources<Slot>(bundle, "Slot")
      const appointments = getResources<Appointment>(bundle, "Appointment")
      const practitioners = getResourcesByTypeAsIndex<Practitioner>(bundle, "Practitioner")
      const patients = getResourcesByTypeAsIndex<Patient>(bundle, "Patient")
      const locations = getResourcesByTypeAsIndex<Location>(bundle, "Location")
      const healthcareServices = getResourcesByTypeAsIndex<HealthcareService>(bundle, "HealthcareService")
      const indexedDevices = getResourcesByTypeAsIndex<Device>(bundle, "Device")

      return {
        appointments:
          appointments?.map<CalendarAppointment>((appointment) => {
            const { practitioner, patient, location, healthcareService, devices } = appointment.participant.reduce<{
              practitioner?: Practitioner
              patient?: Patient
              location?: Location
              healthcareService?: HealthcareService
              devices?: Device[]
            }>((prev, { actor }) => {
              if (isPractitioner(actor) && actor.id) return { ...prev, practitioner: practitioners?.[actor.id] }
              if (isPatient(actor) && actor.id) return { ...prev, patient: patients?.[actor.id] }
              if (isLocation(actor) && actor.id) return { ...prev, location: locations?.[actor.id] }
              if (isHealthcareService(actor) && actor.id)
                return { ...prev, healthcareService: healthcareServices?.[actor.id] }
              if (isDevice(actor) && actor.id)
                return { ...prev, devices: [...(prev.devices ?? []), indexedDevices?.[actor.id]] }
              return prev
            }, {})

            return {
              appointment,
              practitioner,
              patient,
              location,
              healthcareService,
              devices,
            }
          }) ?? [],
        slots: slots.filter(({ status }) => status === "busy-unavailable"),
      } as PractitionerCalendarEvent
    },
    enabled: !!practitionerId && !!startDate && !!endDate,
    meta: { context: { queryKey } },
  })

  return { events: data ?? { appointments: [], slots: [] }, isLoading, queryKey }
}

export { useGetPractitionerCalendar }
