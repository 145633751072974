import type { Reference, Task } from "fhir"
import { useId } from "react"

import {
  Button,
  InfiniteScroll,
  SkeletonLoader,
  StackedListContainer,
  useFiltersContext,
  ValueSetIds,
  ViewContainerWithFilters,
} from "commons"
import { practitionerRolesCodes, taskStatusCodes } from "data"
import { useValueSet } from "value-set"

import { usePatchTask, useTasks } from "../hooks"
import { tasksQueryKeys } from "../query-keys"
import { TaskEmptyState } from "./TaskEmptyState"
import { taskModelBuilder } from "./taskModelBuilder"
import type { FilterProps } from "../types"

const TaskList = ({ organizationId, practitioners, onAdd, onEditTask }: Props) => {
  const loaderKey = useId()
  const { codes: taskCodes } = useValueSet({ valueSetId: ValueSetIds.TASK_CODE })

  const {
    searchText,
    filters: { id, assignedTo, code, performerType, status },
    onClearFilters,
    onFilter,
    onSearch,
  } = useFiltersContext<FilterProps>()

  const { tasks, isLoading, isFetchingNextPage, count, total, hasNextPage, fetchNextPage } = useTasks(
    organizationId,
    searchText,
    code?.join(","),
    assignedTo?.id,
    status?.join(","),
    performerType?.join(","),
  )

  const { updateTask, isUpdating } = usePatchTask({
    successMessage: "Task completed successfully",
    queryKeysToInvalidate: [tasksQueryKeys.all],
  })

  const goToTaskDetails = (taskId: string) => {
    onFilter({ assignedTo, code, performerType, status, id: taskId })
  }

  const onCompleteTask = (taskId: string) => updateTask({ id: taskId, status: "completed" })

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <ViewContainerWithFilters
      isLoading={isLoading || isFetchingNextPage}
      itemTitle="task"
      itemCount={count}
      itemTotal={total}
      actionButtons={<Button label="Create New Task" onClick={onAdd} />}
      initialFilters={{
        id,
        code,
        assignedTo,
        performerType,
        status,
      }}
      initialSearchText={searchText}
      filtersData={[
        {
          label: "Code",
          field: "code",
          data: taskCodes ?? [],
          type: "multiselect",
        },
        {
          label: "Assigned to",
          field: "assignedTo",
          data: practitioners,
          type: "practitionerReference",
        },
        {
          label: "Assign to role",
          field: "performerType",
          data: practitionerRolesCodes,
          type: "multiselect",
        },
        {
          label: "Status",
          field: "status",
          data: taskStatusCodes,
          type: "multiselect",
        },
      ]}
      onTextFilter={(searchText) => onSearch(searchText)}
      onFilter={(filters) => onFilter(filters ?? {})}
      onClear={onClearFilters}
    >
      {isLoading ? (
        loader()
      ) : !tasks?.length ? (
        <TaskEmptyState onAdd={onAdd} />
      ) : (
        <div className="flex grow flex-col overflow-auto">
          <InfiniteScroll
            loadMore={() => {
              fetchNextPage?.()
            }}
            hasMore={hasNextPage}
            loader={loader()}
            initialLoad={false}
          >
            <StackedListContainer
              data={tasks}
              itemModelBuilder={(item) =>
                taskModelBuilder({
                  task: item,
                  onShowTask: () => goToTaskDetails(item.id as string),
                  onEditTask: () => onEditTask(item),
                  onCompleteTask: () => onCompleteTask(item.id as string),
                  isCompletingTask: isUpdating,
                })
              }
            />
          </InfiniteScroll>
        </div>
      )}
    </ViewContainerWithFilters>
  )
}

type Props = {
  organizationId: string
  practitioners: Reference[]
  onAdd(): void
  onEditTask(task: Task): void
}

export { TaskList }
