import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { useEffect, type FC, type PropsWithChildren } from "react"
import { Tooltip } from "primereact/tooltip"
import { formatDate } from "date-fns"

import { ModulesId, NumberField } from "commons"
import { vitalSignCodes, vitalSignUnits } from "data"
import { useAppModuleContext } from "internals"
import { calculateBMI } from "utils"

import type { ColData, VitalsFormType } from "../types"
import { getVitalName } from "../utils"
import { VitalChart } from "./VitalChart"

const VitalsForm: FC<Props> = ({ vitals, isTableEmpty, showChart }) => {
  const { appModules } = useAppModuleContext()

  return (
    <div
      className={classNames("p-fluid relative grid min-w-full flex-1 grow", {
        "w-max": !showChart,
      })}
    >
      <div className="sticky -top-1 left-0 z-[5] h-9 w-48 bg-white" />
      {isTableEmpty && (
        <div className="absolute top-[50%] flex w-full flex-col justify-center pl-[25%] text-center">
          <FontAwesomeIcon icon={appModules[ModulesId.VITALS].getIcon()} size="2x" className="text-slate-400" />
          <h3 className="mt-2 text-sm font-semibold text-slate-700">No vitals found</h3>
          <p className="mt-2 text-sm text-slate-500">Get started by creating new vitals</p>
        </div>
      )}
      {Object.keys(vitalSignCodes).flatMap(
        (key, i) =>
          key !== "bloodPressure" && (
            <VitalItem
              key={key}
              vital={key as keyof ColData}
              label={getVitalName(key as keyof ColData)}
              field={key as keyof VitalsFormType}
              disabled={key === "bmi"}
              helpText={key === "bmi" ? "weight*703 / [height in inches]ˆ2" : undefined}
              bordered={!isTableEmpty}
            >
              {showChart ? (
                <VitalChart
                  vitalName={key as keyof ColData}
                  vitalRecords={vitals?.[key as keyof ColData]}
                  className="min-h-60 transition-all ease-linear"
                />
              ) : (
                vitals?.[key as keyof ColData]?.map(({ date, value }, index) => (
                  <div
                    key={`${date}_${index}`}
                    className="group relative flex w-28 flex-col items-center justify-center p-2 pt-6 text-gray-500 odd:bg-[#FBF9FA]"
                  >
                    {i === 0 && (
                      <span className="absolute -top-10 right-0 left-0 rounded-t-2xl p-2 pt-3 text-center text-sm whitespace-nowrap group-odd:bg-[#FBF9FA]">
                        {formatDate(new Date(date as string), "MM/dd/yyyy")}
                      </span>
                    )}
                    <span className="text-sm text-gray-800">{value?.value ? `${value.value} ${value.unit}` : " "}</span>
                  </div>
                ))
              )}
            </VitalItem>
          ),
      )}
    </div>
  )
}

const VitalItem: FC<PropsWithChildren<VitalItemType>> = ({
  label,
  field,
  vital,
  className,
  disabled,
  helpText,
  children,
  bordered,
}) => {
  const { values, setFieldValue } = useFormikContext<VitalsFormType>()

  useEffect(() => {
    if (values.height && values.weight) {
      setFieldValue("bmi", calculateBMI(values.weight, values.height))
    } else {
      setFieldValue("bmi", 0)
    }
  }, [values])

  return (
    <div className={classNames("relative grid w-full grid-flow-col-dense", className)}>
      <div className="sticky left-0 z-10 col-span-1 flex h-full w-fit items-center bg-white pt-6">
        <div
          className={classNames(
            "relative flex h-fit w-48 flex-row items-center self-center rounded-lg border-2 border-slate-200 p-2 md:p-3 lg:p-4",
            { "border-slate-500": !!values[field] },
          )}
        >
          <div className="p-inputgroup relative flex-1">
            <NumberField
              label={label}
              className="w-full"
              labelClassName={!values[field] ? "text-gray-400/80" : undefined}
              field={field}
              placeholder="0.00"
              showButtons={false}
              minFractionDigits={2}
              maxFractionDigits={2}
              disabled={disabled}
              inputClassName="pr-10"
            />
            <span className="p-inputgroup-addon absolute right-0 bottom-[29%] h-8 border-none bg-transparent text-xs">
              {vitalSignUnits[vital as keyof typeof vitalSignUnits]?.toUpperCase()}
            </span>
          </div>
          {helpText && (
            <div className="absolute top-4 right-5 text-gray-400">
              <FontAwesomeIcon icon={faInfoCircle} className={`text-primary vital-help-text-${field}`} />
              <Tooltip
                className="m-0 p-0 text-xs"
                target={`.vital-help-text-${field}`}
                position="top"
                event="hover"
                autoHide={false}
              >
                <span>{helpText}</span>
              </Tooltip>
            </div>
          )}
        </div>
      </div>

      <div
        className={classNames("col-span-4 ml-3 grid h-full grid-flow-col gap-3 scroll-smooth", {
          "border-b border-[#E5E7EB]": bordered,
        })}
      >
        {children}
      </div>
    </div>
  )
}

type VitalItemType = {
  label: string
  field: keyof VitalsFormType
  vital: keyof ColData
  className?: string
  disabled?: boolean
  helpText?: string
  bordered?: boolean
}

type Props = {
  vitals?: ColData
  isTableEmpty?: boolean
  showChart?: boolean
}

export { VitalsForm }
