import type { PractitionerRole, PractitionerRoleAvailableTimeArray } from "fhir"

import { dayOfWeek } from "data"

import type { OperationHours } from "../../types"

export const getInitialValues = (practRole: PractitionerRole) => {
  const initData = { regularHours: [] }
  const { regularHours } =
    practRole?.availableTime?.reduce<OperationHours>((acc, hop) => {
      if (hop.daysOfWeek) {
        const days = hop.daysOfWeek
          ?.map((day) => dayOfWeek.findIndex((dw) => dw.code === day))
          ?.sort()
          ?.join("|") as string
        const dayHOP = acc.regularHours.find((d) => d.days === days)
        if (dayHOP) dayHOP.hours.push(hop)
        else acc.regularHours.push({ days, dayCodes: hop.daysOfWeek ?? [], hours: [hop] })
      }

      return acc
    }, initData) ?? initData

  return { regularHours }
}

export const sanitize = (practRole: PractitionerRole, data: OperationHours) => {
  const regularHours = data.regularHours.reduce<PractitionerRoleAvailableTimeArray[]>((acc, day) => {
    if (day.dayCodes.length) {
      const data = day.hours.map((hour) => ({
        daysOfWeek: day.dayCodes,
        availableStartTime: hour.availableStartTime,
        availableEndTime: hour.availableEndTime,
      }))

      return [...acc, ...data]
    }

    return acc
  }, [])

  return { ...practRole, availableTime: regularHours }
}
