import { UserProfileMenu } from "commons"
import { OrganizationSelection } from "organization"

import bg from "assets/bg.webp"

const Home: React.FC = () => {
  document.title = "Electronic Health Record"
  const link = document.querySelector<HTMLLinkElement>("link[rel~='icon']")
  if (link) link.href = "/favicon.ico"
  document.body.removeAttribute("style")

  return (
    <div className="flex h-full w-full items-center justify-center bg-white">
      <img alt="bg" src={bg} className="h-full w-full object-cover" />
      <div className="absolute top-5 right-5 flex h-auto w-auto cursor-pointer items-center rounded-full shadow-md">
        <UserProfileMenu />
      </div>
      <div className="absolute flex min-w-[25%] flex-col justify-center rounded-xl bg-white shadow-xs shadow-gray-600 xl:min-w-[30%]">
        <div className="flex h-16 items-center justify-center rounded-t-xl bg-gray-50 font-bold">
          Organization selection
        </div>
        <div className="px-20 pt-10 pb-16">
          <div className="mt-8 w-full xl:mt-14">
            <OrganizationSelection />
          </div>
        </div>
        <div className="mb-3 flex justify-center">
          <a
            title="Policy & Terms"
            href="https://www.chartedhealth.com/156-2/"
            rel="noreferrer"
            target="_blank"
            className="font-light text-blue-400 hover:text-blue-500/90 hover:underline"
          >
            Privacy Policy & Terms of usage
          </a>
        </div>
      </div>
    </div>
  )
}

export { Home }
