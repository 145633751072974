import { type SyntheticEvent, forwardRef, useEffect, useId, useState } from "react"
import { Menu } from "primereact/menu"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faSearch } from "@fortawesome/pro-light-svg-icons"
import type { Patient } from "fhir"

import { GroupedList, InfiniteScroll, SearchInput, SkeletonLoader } from "commons"
import { usePatients } from "patients"

import { ContactListItem } from "./ContactListItem"
import { useUnreadMessages } from "../hooks"
import type { PatientChat } from "../types"

const NewChatMenu = forwardRef<Menu, Props>(function NewChatMenu({ showChat, initialSearchText, onToggleMenu }, ref) {
  const [searchText, setSearchText] = useState<string | undefined>(initialSearchText)
  const { patientGroups, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = usePatients(searchText)
  const [selectedPatient, setSelectedPatient] = useState<Patient>()
  const { unreadList } = useUnreadMessages(selectedPatient?.id)

  const search = (value?: string) => {
    setSearchText(value)
  }

  const handleContactItemClick = (patient: Patient) => {
    setSelectedPatient(patient)
  }

  useEffect(() => {
    if (selectedPatient) {
      showChat({ ...unreadList?.[0], sender: selectedPatient })
      setSearchText(undefined)
      setSelectedPatient(undefined)
    }
  }, [unreadList, selectedPatient])

  useEffect(() => {
    setSearchText(initialSearchText)
  }, [initialSearchText])

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="list" />

  return (
    <>
      <span
        className="flex cursor-pointer items-center justify-center overflow-hidden text-2xl font-bold text-slate-400"
        aria-controls="popup_menu"
        aria-haspopup
        title="New chat"
        onClick={onToggleMenu}
      >
        <FontAwesomeIcon icon={faEdit} size="xs" />
      </span>
      <Menu
        model={[
          {
            template: (
              <div className="w-96 rounded-lg border border-gray-200 bg-white p-4 shadow-xs">
                <div className="flex flex-col gap-4">
                  <h3 className="text-base font-semibold">New Chat</h3>
                  <SearchInput isLoading={isLoading || isFetchingNextPage} search={search} initialValue={searchText} />
                  <div className="h-4/5 max-h-[60vh] grow overflow-y-auto">
                    {isLoading ? (
                      loader()
                    ) : (
                      <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
                        <GroupedList
                          className="grow"
                          groups={patientGroups}
                          renderItem={(patient) => (
                            <ContactListItem
                              key={patient.id}
                              onClick={(event) => {
                                handleContactItemClick(patient)
                                onToggleMenu(event)
                              }}
                              patient={patient}
                            />
                          )}
                          renderEmptyState={() => (
                            <div className="flex flex-col items-center justify-center pt-10">
                              <FontAwesomeIcon icon={faSearch} size="2x" className="text-slate-500" />
                              <p className="pt-3 text-slate-400">No results found</p>
                            </div>
                          )}
                        />
                      </InfiniteScroll>
                    )}
                  </div>
                </div>
              </div>
            ),
          },
        ]}
        popup
        ref={ref}
        id="popup_menu"
        style={{ fontSize: "small" }}
      />
    </>
  )
})

type Props = {
  showChat(chat: PatientChat): void
  onToggleMenu: (event: SyntheticEvent<Element, Event>) => void
  initialSearchText?: string
}

export { NewChatMenu }
