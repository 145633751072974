import type { Duration } from "fhir"
import { type FC, useCallback } from "react"

import { ModulesId } from "commons"
import type { ConfigurationItem } from "commons/procedures"
import { useAppModuleContext } from "internals"
import { useDeleteMedicationRequest, useUpdateMedicationRequestsDispenseInterval } from "medication-requests"
import { useDeleteProcedure } from "procedures"
import { strCapitalize } from "utils"
import { REFILL_MODES, oneTimeDispenseIntervalOption } from "commons/meds"

import { useCPOERequestsContext } from "../hooks"
import { ACTION_GROUP_CODES } from "../types"
import { CheckoutItemsSection } from "./CheckoutItemsSection"

const OrdersList: FC<Props> = ({ readonly }) => {
  const { isModuleActive } = useAppModuleContext()
  const { actions, productPrices, selectedRequests, updateRequests, setIsProcessingActions } = useCPOERequestsContext()

  const { updateMRDispenseInterval } = useUpdateMedicationRequestsDispenseInterval(() => setIsProcessingActions(false))
  const { deleteMedicationRequest, isDeleting: isDeletingMr } = useDeleteMedicationRequest((_, error, reqId) => {
    if (!error) handleDeleteRequest(reqId)
  })

  const { deleteProcedure, isDeleting: isDeletingProcedure } = useDeleteProcedure((_, error, { procedure }) => {
    if (!error) handleDeleteRequest(procedure.id)
    setIsProcessingActions(false)
  })

  const handleDeleteRequest = useCallback(
    (id?: string) => {
      const actionIndex = actions.findIndex(
        ({ resource, laboratoryData }) => resource.resource?.id === id || laboratoryData?.carePlanId === id,
      )

      if (actionIndex >= 0) updateRequests(actions.toSpliced(actionIndex, 1))
    },
    [actions],
  )

  const onChangeRequestStatus = useCallback(
    (value: string, id?: string) => {
      let actionIndex = actions.findIndex(({ resource }) => resource.resource?.id === id)
      actionIndex = actionIndex < 0 ? 0 : actionIndex

      const newAction = { ...actions[actionIndex] }

      newAction.resource.code = [
        {
          coding: [
            {
              ...newAction.resource.code?.[0].coding?.[0],
              code: value,
              display: strCapitalize(value),
            },
          ],
        },
        ...(newAction.resource.code?.slice(1) ?? []),
      ]

      updateRequests(actions.toSpliced(actionIndex, 1, newAction))
    },
    [actions],
  )

  const onChangeMedRequest = useCallback(
    (field: string, value: boolean | Duration, id?: string) => {
      let actionIndex = actions.findIndex(({ resource }) => resource.resource?.id === id)
      actionIndex = actionIndex < 0 ? 0 : actionIndex

      const newAction = { ...actions[actionIndex] }

      if (field === "dispenseInterval") {
        if (newAction.medicationData?.dispenseRequest) {
          newAction.medicationData.dispenseRequest.dispenseInterval = value as Duration

          if (newAction.resource.resource?.id) {
            const isOneTime =
              (value as Duration).value === oneTimeDispenseIntervalOption?.value?.value &&
              (value as Duration).code === oneTimeDispenseIntervalOption?.value?.code
            const refills = newAction.medicationData.dispenseRequest?.numberOfRepeatsAllowed

            if (isOneTime) {
              newAction.medicationData.dispenseRequest = {
                ...newAction.medicationData.dispenseRequest,
                numberOfRepeatsAllowed: 0,
                refillMode: REFILL_MODES["one-time"],
              }
            } else {
              newAction.medicationData.dispenseRequest = {
                ...newAction.medicationData.dispenseRequest,
                numberOfRepeatsAllowed: refills ? undefined : 1,
                refillMode: REFILL_MODES["auto-order"],
              }
            }
            updateMRDispenseInterval([
              { mrId: newAction.resource.resource.id, dispenseRequest: newAction.medicationData?.dispenseRequest },
            ])
            setIsProcessingActions(true)
          }
        }
      }

      if (field === "status") {
        const status = value === true ? "activate" : "draft"
        newAction.resource.code = [
          {
            coding: [
              {
                ...newAction.resource.code?.[0].coding?.[0],
                code: status,
                display: strCapitalize(status),
              },
            ],
          },
          ...(newAction.resource.code?.slice(1) ?? []),
        ]
      }

      updateRequests(actions.toSpliced(actionIndex, 1, newAction))
    },
    [actions],
  )

  return (
    <div className="mt-4 flex flex-col space-y-5">
      {isModuleActive(ModulesId.MEDICATIONR) && (
        <CheckoutItemsSection
          requests={readonly ? selectedRequests : actions}
          label="Nutraceuticals"
          sectionRequestsType={ACTION_GROUP_CODES.NUTRA}
          onChangeMed={onChangeMedRequest}
          onChangeRequestStatus={() => ({})}
          editableRequests
          onDeleteRequest={(reqId) => deleteMedicationRequest(reqId)}
          isDeleting={isDeletingMr}
          readonly={readonly}
          productPrices={productPrices}
        />
      )}
      {isModuleActive(ModulesId.EPRESCRIBE) && (
        <CheckoutItemsSection
          requests={readonly ? selectedRequests : actions}
          label="Pharmaceuticals"
          sectionRequestsType={ACTION_GROUP_CODES.PHARMA}
          onChangeMed={onChangeMedRequest}
          onChangeRequestStatus={() => ({})}
          editableRequests
          onDeleteRequest={(reqId) => deleteMedicationRequest(reqId)}
          isDeleting={isDeletingMr}
          readonly={readonly}
          productPrices={productPrices}
        />
      )}
      {isModuleActive(ModulesId.PROCEDURES) && (
        <CheckoutItemsSection
          requests={readonly ? selectedRequests : actions}
          label="Procedures"
          sectionRequestsType={ACTION_GROUP_CODES.PROCEDURE}
          onChangeMed={onChangeMedRequest}
          onChangeRequestStatus={onChangeRequestStatus}
          editableRequests
          onDeleteRequest={(_, item) => {
            if (item?.procedureData) {
              const confItems = item.procedureData.medicationAdministrations.reduce((acc, ma, index) => {
                return [
                  ...acc,
                  { medicationAdministration: ma, medicationRequest: item.procedureData?.medicationRequests?.[index] },
                ]
              }, Array<ConfigurationItem>())

              deleteProcedure({
                procedure: item.procedureData.procedure,
                deletedMedications: [],
                configurationItem: confItems,
              })
              setIsProcessingActions(true)
            }
          }}
          isDeleting={isDeletingProcedure}
          readonly={readonly}
        />
      )}
      {isModuleActive(ModulesId.LABSORDER) && (
        <CheckoutItemsSection
          requests={readonly ? selectedRequests : actions}
          label="Laboratories"
          sectionRequestsType={ACTION_GROUP_CODES.LAB}
          onChangeMed={onChangeMedRequest}
          onChangeRequestStatus={onChangeRequestStatus}
          editableRequests
          readonly={readonly}
        />
      )}
    </div>
  )
}

type Props = {
  readonly?: boolean
}

export { OrdersList }
