import type { Invoice } from "fhir"
import { Chip } from "primereact/chip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarDays } from "@fortawesome/pro-solid-svg-icons"
import { faScannerGun } from "@fortawesome/pro-regular-svg-icons"
import { classNames } from "primereact/utils"

import { formatDate, getMoneyCurrencyAlt } from "utils"
import { ListItem } from "commons"
import { formatsByTypes, invoiceStatusCodes } from "data"
import { SYSTEM_VALUES } from "system-values"

const LaboratoryOrderDetailsInvoice = ({ invoice, className, onClick }: Props) => {
  const invoiceDate = invoice.date && formatDate(new Date(invoice.date), formatsByTypes.LONG_DATE)

  const itemsCount = invoice.lineItem?.filter((item) => item.priceComponent?.[0]?.type === "base").length

  const price = invoice?.totalGross?.value
    ? getMoneyCurrencyAlt(invoice?.totalGross.currency) + invoice.totalGross.value.toFixed(2)
    : "$0.00"

  const head = (
    <p className="truncate font-medium" title="Invoice number">
      {`#${invoice.identifier?.find(({ system }) => system === SYSTEM_VALUES.INVOICE_INDENTIFIER)?.value ?? "Unspecified number"}`}
    </p>
  )

  const customChip = (
    <span title="Status">
      <Chip
        label={invoiceStatusCodes.find((status) => status.code === invoice.status)?.display}
        className={classNames("custom-chip ml-2", {
          "is-danger": invoice.status === "cancelled",
          "is-warning": invoice.status === "entered-in-error",
          "is-success": invoice.status === "balanced",
          "is-info": invoice.status === "issued",
          "is-secondary": invoice.status === "draft",
        })}
      />
    </span>
  )

  const details = (
    <>
      {invoice.date && (
        <span title="Issued date" className="flex items-center">
          <FontAwesomeIcon icon={faCalendarDays} className="mr-1.5 text-gray-400" />
          {invoiceDate}
        </span>
      )}
      <span title="Total Net" className="pl-2">
        {price}
      </span>
      <span title="Items" className="flex items-center pl-2">
        <FontAwesomeIcon icon={faScannerGun} className="mr-1.5 text-gray-400" />
        {itemsCount}
      </span>
    </>
  )

  return (
    <ListItem
      header={head}
      subHeader={details}
      chip={customChip}
      externalAction="Pay Invoice"
      onContentClick={onClick}
      className={className}
    />
  )
}

type Props = {
  invoice: Invoice
  className?: string
  showMenu?: boolean
  onClick?(): void
}

export { LaboratoryOrderDetailsInvoice }
