import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { isCarePlan } from "fhir"
import type { FC } from "react"

import { ModulesId } from "commons"
import { useChartContext } from "chart-view"
import { getStatus } from "commons/labs"

import { WrapUpSection } from "./WrapUpSection"
import { OrderItem } from "./OrderItem"
import { useEncounterContext } from "../../hooks"
import { ObservationItem } from "./ObservationItem"
import { encounterQueryKeys } from "../../query-keys"

const LabResultsNotesSection: FC<Props> = ({ patientId, encounterId, icon }) => {
  const { showModule } = useChartContext()

  const goToOrderDetails = (order: string) => {
    showModule({ module: ModulesId.LABSORDER, moduleParams: { order } })
  }

  const { encounterData, totalLabResultsWithNotes: total, planRefs } = useEncounterContext()

  return (
    <WrapUpSection
      sectionTitle="Lab results remarks"
      isEmpty={!total}
      icon={icon}
      emptyMesage="No remarks on results during this encounter"
      className="w-full gap-6 self-end pb-2"
    >
      {[...(encounterData?.labResultsWithNotes ?? [])].map(({ order: sr, name, items: observations }) => {
        const isDraft = sr?.status === "draft"
        const cp = planRefs?.[sr?.basedOn?.find(isCarePlan)?.id as string]

        return (
          <OrderItem
            key={sr?.id}
            requester={sr?.requester}
            status={getStatus(sr)?.display}
            title={name}
            date={sr?.authoredOn ? new Date(sr.authoredOn) : undefined}
            seeOrderDetails={!isDraft ? () => goToOrderDetails(sr?.id as string) : undefined}
            className="first:mt-6"
            badge={
              cp?.display
                ? {
                    text: cp.display,
                    colorStyle: "gray",
                    title: cp.display,
                    className: "truncate max-w-24 !block",
                  }
                : undefined
            }
          >
            {observations.map((observation) => (
              <ObservationItem
                key={observation.id}
                observation={observation}
                className="border-t border-gray-300 pt-3 first:border-none first:pt-0"
                queriesToInvalidate={encounterQueryKeys.labResultsNotes(patientId, encounterId)}
                encounter={{ id: encounterId, resourceType: "Encounter" }}
              />
            ))}
          </OrderItem>
        )
      })}
    </WrapUpSection>
  )
}

type Props = {
  encounterId: string
  patientId: string
  icon: IconDefinition
}

export { LabResultsNotesSection }
