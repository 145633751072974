import { Message } from "primereact/message"

import { useCPOERequestsContext } from "../hooks"

const ProcessingTimesBanner = () => {
  const {
    activeRequestsInfo: { hasNutraRequest, hasRXRequest },
  } = useCPOERequestsContext()

  if (!hasRXRequest && !hasNutraRequest) {
    return null
  }

  return (
    <div className="sticky top-0 py-2 z-20 bg-white">
      <Message
        className="w-full"
        severity="info"
        text={
          <div className="flex flex-col gap-1">
            {hasNutraRequest && (
              <p>
                Nutraceutical orders processed by 11:30 EST will ship Same Day (M-F) otherwise orders will ship the
                following business day.
              </p>
            )}
            {hasRXRequest && (
              <p>
                Current pharmacy processing times may vary. Please expect your prescription to ship in 3-5 business
                days.
              </p>
            )}
          </div>
        }
        pt={{ root: { className: "justify-start" } }}
      />
    </div>
  )
}

export { ProcessingTimesBanner }
