import { faSearch, faTrashCan } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Observation, type Reference, codeableConceptAsString } from "fhir"
import { ErrorMessage, useFormikContext } from "formik"
import { type FC, useEffect, useMemo } from "react"

import {
  type StackedListItemProps,
  AddFieldArrayItemButton,
  ConfirmDialog,
  DialogFormContainer,
  MenuStyles,
  StackedListContainer,
  useCrudReducer,
} from "commons"
import { formatsByTypes } from "data"
import { formatDate } from "utils"

import { type ReplacementProps, useReplaceFormContext } from "commons/hooks"
import { classNames } from "primereact/utils"
import type { Laboratory } from "../types"
import { LabResultForm } from "./LabResultForm"
import { getObservationInitialValues } from "./validations"

const LabResults: FC<Props> = ({ patient, encounter }) => {
  const {
    values: { results, dr },
    errors: { results: fError },
    touched: { results: fTouched },
    initialValues,
    setFieldValue,
  } = useFormikContext<Laboratory>()

  const labResults = useMemo(() => results || initialValues.results || [], [results])

  const { showSlide, initialValue, add, reset, deleteIndex, setDeleteIndex } = useCrudReducer({
    defaultEntity: { newResult: getObservationInitialValues(patient, dr.performer?.[0], encounter) },
  })

  const addnewResult = async ({ newResult }: { newResult: Observation }) => {
    const newResults = [...labResults, newResult]

    await setFieldValue("results", newResults)
    reset()
  }

  const deleteResult = async (deleteIndex: number) => {
    const newResults = labResults.toSpliced(deleteIndex, 1)

    await setFieldValue("results", newResults)
  }

  const dialogFormProps = useMemo(() => {
    return {
      title: "Observation",
      showForm: showSlide,
      useFormik: true,
      initialValue,
      saveLabel: initialValue.newResult?.id ? "Save" : "Add",
      children: <LabResultForm fieldPath="newResult" />,
      onCancel: reset,
      onSubmit: (data: { newResult: Observation }) => addnewResult(data),
    } as ReplacementProps<{ newResult: Observation }>
  }, [showSlide])

  const dialogContext = useReplaceFormContext<{ newResult: Observation }>()

  useEffect(() => {
    if (dialogContext) {
      dialogContext.setReplacementContent?.(dialogFormProps)
    }
  }, [dialogFormProps])

  return (
    <>
      <AddFieldArrayItemButton className="px-2 py-4" onClick={add} />
      {labResults.length > 0 ? (
        <StackedListContainer
          itemsClassName="px-2 py-4"
          data={labResults}
          itemModelBuilder={(item: Observation, itemIndex) => resultModel(item, () => setDeleteIndex(itemIndex))}
        />
      ) : (
        <div
          className={classNames("my-2 flex w-full flex-col items-center justify-center py-5", {
            "rounded-md border border-red-600": fError && fTouched,
          })}
        >
          <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
          <p className="pt-1 text-xs text-slate-500">No results added yet</p>
        </div>
      )}
      <ErrorMessage name="results">
        {(msg) =>
          typeof msg === "string" && (
            <small id="errorMessage.results" className="p-error">
              {msg}
            </small>
          )
        }
      </ErrorMessage>
      {!dialogContext && <DialogFormContainer {...dialogFormProps} />}
      <ConfirmDialog
        confirmText="Are you sure you want to remove this result?"
        actionName="Remove"
        visible={deleteIndex !== undefined}
        onConfirm={() => deleteResult(deleteIndex as number)}
        hideDialog={() => setDeleteIndex(undefined)}
      />
    </>
  )
}

const resultModel = (observation: Observation, onDelete: () => void): StackedListItemProps => {
  const { code, performer, issued, interpretation, value } = observation
  return {
    leftData: [
      {
        lineItems: [
          { name: "Code", value: codeableConceptAsString(code) },
          ...(value?.Quantity?.value && value.Quantity.unit
            ? [{ name: "Value", value: `${value.Quantity.value}${value.Quantity.unit}` }]
            : []),
          ...(performer?.[0]?.display ? [{ name: "Performer", value: performer[0].display }] : []),
        ],
      },
      {
        lineItems: [
          ...(issued
            ? [
                {
                  name: "Issued at",
                  value: formatDate(new Date(issued), formatsByTypes.LONG_DATETIME),
                },
              ]
            : []),
          ...(interpretation
            ? [
                {
                  name: "Interpretation",
                  value: `${codeableConceptAsString(interpretation[0])}${
                    interpretation.length > 1 ? ` ${interpretation.length - 1}+` : ""
                  }`,
                },
              ]
            : []),
        ],
      },
    ],
    menu: [
      {
        label: "Delete",
        icon: <FontAwesomeIcon icon={faTrashCan} size="1x" className="mr-1" />,
        command: onDelete,
      },
    ],
    menuStyle: MenuStyles.ActionItems,
  }
}

type Props = {
  patient: Reference
  encounter?: Reference
}

export { LabResults }
