import { faLayerGroup } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { SettingItemArray } from "fhir"
import { Dropdown } from "primereact/dropdown"
import { type FC, useMemo } from "react"

import { ValueSetIds } from "commons"
import { useValueSet } from "value-set"

const ConfigurationAlgorithmSetting: FC<Props> = ({ setting, isDisabled, onChange }) => {
  const { codes, isLoading } = useValueSet({
    valueSetId: ValueSetIds.MC_MAPPING_GROUP,
  })

  const configAlgorithmOptions = useMemo(
    () =>
      codes?.map(({ code, display }) => ({
        label: display,
        value: code,
      })),
    [codes],
  )

  return (
    <div className="mt-4 flex flex-col border-b border-gray-200 last:border-b-0">
      <div className="flex flex-1 items-center">
        <FontAwesomeIcon icon={faLayerGroup} size="2x" className="w-8 text-gray-400" />
        <span className="ml-2 capitalize">
          {setting.code?.display?.toLocaleLowerCase().replace("enabled", "") || "Unspecified"}
        </span>
      </div>
      <div className="mr-10 ml-10 flex items-center justify-between border-b border-gray-200 py-4 last:border-b-0">
        <div className="flex flex-col">
          <span className="text-gray-700">Output</span>
          <span className="text-sm text-gray-500">Output for Nutraceutical recommendations in Metabolic Code</span>
        </div>
        <Dropdown
          id={setting.code?.code}
          options={configAlgorithmOptions}
          optionLabel="label"
          optionValue="value"
          value={setting.value?.string}
          onChange={(e) =>
            onChange(setting.code?.code as string, {
              ...setting,
              value: { string: e.value },
            })
          }
          className="p-inputtext-sm small-trigger min-w-52 self-start"
          disabled={isDisabled}
          loading={isLoading}
        />
      </div>
    </div>
  )
}

type Props = {
  setting: SettingItemArray
  isDisabled: boolean
  onChange(code: string, item: SettingItemArray): void
}

export { ConfigurationAlgorithmSetting }
