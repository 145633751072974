import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faUserPlus } from "@fortawesome/pro-regular-svg-icons"

import { Button } from "commons"

const PatientEmptyState = ({ onAdd }: Props) => (
  <div className="flex h-full w-full items-center justify-center">
    <div className="flex flex-col items-center">
      <FontAwesomeIcon icon={faUserPlus} size="3x" className="mx-auto h-12 w-12 text-gray-400" />

      <h3 className="mt-2 text-sm font-semibold text-gray-900">No patients</h3>
      <p className="mt-1 text-sm text-gray-500">Get started by creating a new patient.</p>
      <Button
        label="New Patient"
        icon={faPlus}
        className="button-primary p-button-sm mt-6"
        onClick={onAdd}
        disabled={!onAdd}
      />
    </div>
  </div>
)

type Props = {
  onAdd?(): void
}

export { PatientEmptyState }
