import { classNames } from "primereact/utils"
import type { DetailedHTMLProps, InputHTMLAttributes } from "react"

const Input = ({ type = "text", className, ...props }: Props) => (
  <input
    type={type}
    className={classNames(
      "block w-full rounded-md border border-solid border-gray-300 text-sm placeholder:text-slate-400",
      className,
    )}
    {...props}
  />
)

type Props = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export { Input }
