import type { PlanDefinition, Reference } from "fhir"
import { useFormikContext } from "formik"
import pluralize from "pluralize"
import { useCallback, useEffect, useMemo, useState } from "react"

import { BILLING_TYPES_CODES, DEFAULT_BLOOD_DRAWN_PANELS_LIST, billingTypes } from "data"
import { useOrganizationContext } from "organization"

import { AddFieldArrayItemButton } from "../../../components/Buttons"
import { ConfirmDialog } from "../../../components/ConfirmDialog"
import { StackedListContainer } from "../../../components/StackedListContainer"
import { type FormFieldBaseProps, FormField } from "../../../forms/FormField"

import { useBloodDrawnTests, useLoadProductsPrice } from "../../../hooks"
import type { LaboratoryTest } from "../../../types"
import { usePlanDefinitionTests } from "../../hooks"
import type { ComboDefinition, PanelItemDisplay, PlanData } from "../../types"
import { getPDCanonical, getQuestionnaireCanonicalsFromPanels, panelModelBuilder } from "../../utils"
import { ExtraPanelList } from "./ExtraPanelList"
import { ExtraPanelSelection } from "./ExtraPanelSelection"

const ExtraPanelField = ({ plan, containerClassName, ...formFielfProps }: Props) => {
  const { currentOrganizationId, isExemptLabPayment, labsWithSuppliedPhlebotomistEnabled } = useOrganizationContext()

  const [panelItems, setPanelItems] = useState<PanelItemDisplay[]>([])
  const [panelsToDel, setPanelsToDel] = useState<{ panels: PanelItemDisplay[]; autoDelete?: boolean }>({ panels: [] })
  const [showSlide, setShowSlide] = useState(false)

  const {
    values: { billingType, panels, combo, extraPlanDefinition, performer, bloodDrawnInOffice, bloodDrawnMode },
    setFieldValue,
    getFieldMeta,
  } = useFormikContext<PlanData>()

  const isInsurance = useMemo(() => billingType === BILLING_TYPES_CODES.INSURANCE, [billingType])
  const includePhlebotomistFee = useMemo(
    () => labsWithSuppliedPhlebotomistEnabled.has(performer?.id as string),
    [performer?.id],
  )

  const selectedCombo = useMemo(() => plan?.combos.find((c) => c.canonical === combo), [combo, plan])
  const initialTests = useMemo(
    () => panels?.filter((p) => !selectedCombo?.canonicalPanels.includes(p) && p !== selectedCombo?.canonical) ?? [],
    [panels, selectedCombo],
  )

  const { bloodDrawnTests, isLoading: isLoadingBDTests } = useBloodDrawnTests(
    currentOrganizationId,
    DEFAULT_BLOOD_DRAWN_PANELS_LIST,
  )
  const { labTests, isLoading } = usePlanDefinitionTests(currentOrganizationId, initialTests)

  const isDBIOPanelActive = useCallback(
    (pIdOrCode: string) =>
      (!bloodDrawnInOffice || includePhlebotomistFee) &&
      !!(
        bloodDrawnTests &&
        performer?.id &&
        bloodDrawnTests[performer.id]?.some(
          ({ planDefinition }) =>
            planDefinition.id === pIdOrCode || planDefinition.identifier?.some(({ value }) => pIdOrCode === value),
        )
      ),
    [bloodDrawnTests, performer, bloodDrawnInOffice, includePhlebotomistFee],
  )

  const onUpdatePanels = useCallback(
    ({
      newPanels,
      deletedPanels,
      deleteAll,
    }: {
      newPanels: LaboratoryTest[]
      deletedPanels: { id: string; index: number }[]
      deleteAll?: boolean
    }) => {
      let panelsAfterDelete = deleteAll
        ? []
        : panels?.filter((pCanonical) => !deletedPanels.some(({ id }) => id === pCanonical))
      const testCanonicalsToAdd = [] as string[]
      const { panelsToAdd, pDefinitions } = newPanels.reduce(
        (acc, test) => {
          const canonical = `${test.planDefinition.url}|${test.planDefinition.version}`
          testCanonicalsToAdd.push(canonical)

          return {
            panelsToAdd: [
              ...acc.panelsToAdd,
              {
                id: canonical,
                display: test.display,
                price: test.price,
                planDefinition: test.planDefinition,
                isCombo: false,
                questionnaires: test.questionnaires,
              } as PanelItemDisplay,
            ],
            pDefinitions: [...acc.pDefinitions, test.planDefinition],
          }
        },
        { panelsToAdd: Array<PanelItemDisplay>(), pDefinitions: Array<PlanDefinition>() },
      )
      // Add a track of PD from extra panels added. This PDs aren't in original CP
      // Allows to get the identifiers fom this extra panels and craft the panel codes
      setFieldValue("extraPlanDefinition", [...(extraPlanDefinition ?? []), ...pDefinitions])

      const isComboToDelete =
        !!selectedCombo?.canonical && deletedPanels.some(({ id }) => id === selectedCombo.canonical)

      if (isComboToDelete || deleteAll) {
        // Handle unselect combo
        setFieldValue("combo", undefined)
        // Remove all panels related to unselected combo
        panelsAfterDelete = panelsAfterDelete?.filter((p) => !selectedCombo?.canonicalPanels.includes(p))
      }
      setFieldValue("panels", [...(panelsAfterDelete ?? []), ...testCanonicalsToAdd])
      setPanelItems((pItems) => {
        const updatedPanels = [
          ...pItems.filter(
            ({ planDefinition }) => !deletedPanels.some(({ id }) => id === getPDCanonical(planDefinition)),
          ),
          ...panelsToAdd,
        ]

        setFieldValue(
          "questionnairesCanonicals",
          getQuestionnaireCanonicalsFromPanels([
            ...updatedPanels.flatMap(({ planDefinition }) => planDefinition),
            ...(!isComboToDelete ? selectedCombo?.panels ?? [] : []),
          ]),
        )

        return updatedPanels
      })
      setShowSlide(false)
    },
    [panels, selectedCombo, extraPlanDefinition],
  )

  const [prevPerformer, setPrevPerformer] = useState(performer?.id)

  const performerHasChanged = useMemo(() => prevPerformer !== performer?.id, [performer?.id, prevPerformer])

  const getNewBDPanels = (performerId: string, bloodDrawnMode?: string) =>
    bloodDrawnTests?.[performerId]?.reduce((prev, labTest) => {
      // Match panel fee with blood draw mode fees. Avoid adding panels that don't match the blood draw mode. EX: AM Walk-In when AM Mobile Phleb.
      if (!!bloodDrawnMode && !labTest.planDefinition.identifier?.some(({ value }) => value === bloodDrawnMode))
        return prev

      return [...prev, labTest]
    }, Array<LaboratoryTest>()) ?? []

  useEffect(() => {
    if (!performer?.id) {
      onUpdatePanels({ newPanels: [], deletedPanels: [], deleteAll: true })
    } else if (performerHasChanged) {
      onUpdatePanels({
        newPanels: [...(!bloodDrawnInOffice || includePhlebotomistFee ? getNewBDPanels(performer.id) : [])],
        deletedPanels: [],
        deleteAll: true,
      })
      setPrevPerformer(performer.id)
      setFieldValue("bloodDrawnMode", undefined)
    }
  }, [performer?.id, includePhlebotomistFee, performerHasChanged])

  useEffect(() => {
    if (!performerHasChanged) {
      if (!bloodDrawnInOffice || includePhlebotomistFee) {
        if (bloodDrawnTests && performer?.id && !isLoadingBDTests) {
          // Match panel fee with blood draw mode fees. Avoid adding panels that don't match the blood draw mode. EX: AM Walk-In when AM Mobile Phleb.
          const testToAdd = getNewBDPanels(performer.id, bloodDrawnMode)
          const toDel = panelItems
            .filter(({ planDefinition }) =>
              planDefinition.identifier?.some(({ value }) => DEFAULT_BLOOD_DRAWN_PANELS_LIST.includes(value as string)),
            )
            .map(({ planDefinition }, index) => {
              return { id: getPDCanonical(planDefinition), index }
            })

          if (testToAdd.length || toDel.length) onUpdatePanels({ newPanels: testToAdd, deletedPanels: toDel })
        }
      } else if (bloodDrawnTests && performer?.id) {
        const canonicalPanelsToDelete =
          bloodDrawnTests[performer?.id]?.map(({ planDefinition }, index) => {
            return {
              id: getPDCanonical(planDefinition),
              index,
            }
          }) ?? []
        if (canonicalPanelsToDelete.length) onUpdatePanels({ newPanels: [], deletedPanels: canonicalPanelsToDelete })
      }
    }
  }, [
    bloodDrawnInOffice,
    bloodDrawnTests,
    isLoadingBDTests,
    bloodDrawnMode,
    includePhlebotomistFee,
    performerHasChanged,
  ])

  useEffect(() => {
    const initialPanelItems = labTests?.map((test) => {
      return {
        display: test.display,
        price: test.price,
        isCombo: false,
        planDefinition: test.planDefinition,
      } as PanelItemDisplay
    })

    const comboPanel = selectedCombo && {
      display: selectedCombo.definition.title ?? selectedCombo.definition.name ?? "",
      price: selectedCombo.price,
      isCombo: true,
      info: `${selectedCombo.panels.length} panels`,
      planDefinition: selectedCombo.definition,
      panels: selectedCombo.panels,
    }

    setPanelItems((items) => {
      const itemKeys = new Set<string>(items.flatMap(({ planDefinition }) => getPDCanonical(planDefinition)))

      const panelsNotInCombo = [
        ...items.filter(
          (i) =>
            !i.isCombo &&
            panels?.includes(getPDCanonical(i.planDefinition)) &&
            getPDCanonical(i.planDefinition) !== selectedCombo?.canonical,
        ),
        ...(initialPanelItems?.filter(
          ({ planDefinition }) =>
            !itemKeys.has(getPDCanonical(planDefinition)) && panels?.includes(getPDCanonical(planDefinition)),
        ) ?? []),
      ]

      setFieldValue(
        "questionnairesCanonicals",
        getQuestionnaireCanonicalsFromPanels([
          ...panelsNotInCombo.flatMap(({ planDefinition }) => planDefinition),
          ...(comboPanel?.panels ?? []),
          ...(comboPanel ? [comboPanel.planDefinition] : []),
        ]),
      )

      return [...panelsNotInCombo, ...(comboPanel ? [comboPanel] : [])]
    })
  }, [selectedCombo, initialTests, labTests])

  const { mapProductsPrice } = useLoadProductsPrice(
    currentOrganizationId,
    (billingType ?? BILLING_TYPES_CODES.BILL_PATIENT) as BILLING_TYPES_CODES,
    "panels",
    (data) => {
      setPanelItems((items) => {
        const indexedData = (data as PanelItemDisplay[]).reduce(
          (acc, dataItem) => ({
            ...acc,
            [getPDCanonical(dataItem.planDefinition)]: dataItem,
          }),
          {} as Record<string, PanelItemDisplay>,
        )

        return items.flatMap((item) => {
          const updatedItem = indexedData[getPDCanonical(item.planDefinition)]
          return updatedItem ?? item
        })
      })
    },
  )

  useEffect(() => {
    if (!isLoading) {
      if (!isExemptLabPayment) mapProductsPrice({ products: panelItems })
      if (billingType !== BILLING_TYPES_CODES.INSURANCE && !isExemptLabPayment)
        setPanelsToDel({
          panels: panelItems.filter(
            (pd) =>
              !pd.price &&
              !pd.planDefinition.identifier?.some(({ value }) =>
                DEFAULT_BLOOD_DRAWN_PANELS_LIST.includes(value as string),
              ),
          ),
          autoDelete: true,
        })
    }
  }, [billingType, isLoading])

  const cancelRemovePanels = () => {
    if (panelsToDel.autoDelete) setFieldValue("billingType", "insurance")
    const pervPerformer = selectedCombo?.performer ?? plan?.performer
    if (performer?.id !== pervPerformer?.id && !panelsToDel.autoDelete) {
      setFieldValue("performer", pervPerformer)
    }
    setPanelsToDel({ panels: [] })
  }

  const removePanels = (toDelete: PanelItemDisplay[]) => {
    let updatedPanels = panels?.filter(
      (panel) => !toDelete.some((p) => `${p.planDefinition.url}|${p.planDefinition.version}` === panel),
    )

    if (toDelete.some((item) => item.isCombo)) {
      // Handle unselect combo
      setFieldValue("combo", undefined)
      // Remove all panels related to unselected combo
      updatedPanels = updatedPanels?.filter((p) => !selectedCombo?.canonicalPanels.includes(p))
    }
    setFieldValue("panels", updatedPanels)
    setPanelItems((items) => {
      const panelsAfterRemove = items.filter((i) => !toDelete.includes(i))

      setFieldValue(
        "questionnairesCanonicals",
        getQuestionnaireCanonicalsFromPanels([...panelsAfterRemove.flatMap(({ planDefinition }) => planDefinition)]),
      )
      return panelsAfterRemove
    })
    setPanelsToDel({ panels: [] })
  }

  const { touched, error } = getFieldMeta("panels")

  return (
    <>
      <FormField field="panels" labelAlign="items-start" showInvalidState {...formFielfProps}>
        {showSlide && (
          <ExtraPanelSelection
            extraPanels={panelItems}
            onHide={() => setShowSlide(false)}
            onSave={onUpdatePanels}
            selectedCombo={selectedCombo}
            selectedPerformer={performer}
            billingType={billingType as BILLING_TYPES_CODES}
            isReadonlyItem={isDBIOPanelActive}
          />
        )}
        <AddFieldArrayItemButton
          label="Select Additional Testing"
          onClick={() => setShowSlide(true)}
          className="px-3 pt-2 pb-4"
          iconClassName="h-8 w-8"
          disabled={!performer}
        />
        <ExtraPanelList
          panelItems={panelItems}
          onDelete={(item) => removePanels([item])}
          isLoading={isLoading}
          error={touched ? !!error : undefined}
          modelBuilder={panelModelBuilder}
          showPrice={!isExemptLabPayment}
          isReadonlyItem={isDBIOPanelActive}
          isInsurance={isInsurance}
          className={containerClassName}
        />
      </FormField>

      <ConfirmDialog
        headerTitle="Confirm panel deletion"
        actionName="Remove"
        visible={!!panelsToDel.panels.length}
        onConfirm={() => removePanels(panelsToDel.panels)}
        hideDialog={cancelRemovePanels}
        waitToFinish
        confirmElement={
          <div className="flex flex-col gap-2">
            {panelsToDel.autoDelete ? (
              <p className="font-semibold">
                As you specified{" "}
                <strong>
                  billing type as {billingTypes.find(({ code }) => code === billingType)?.display ?? billingType}
                </strong>
                , the following {pluralize("panel", panelsToDel.panels.length)} will be removed:
              </p>
            ) : (
              <p className="font-semibold">
                The following {pluralize("panel", panelsToDel.panels.length)} will be removed:
              </p>
            )}
            <StackedListContainer
              data={panelsToDel.panels}
              itemModelBuilder={(item) => panelModelBuilder({ item, readOnly: true, showPrice: !isExemptLabPayment })}
            />
          </div>
        }
      />
    </>
  )
}

type Props = {
  plan?: { combos: ComboDefinition[]; performer?: Reference }
  containerClassName?: string
} & Omit<FormFieldBaseProps, "field">

export { ExtraPanelField }
