import type { SettingItemArray } from "fhir"
import { ColorPicker } from "primereact/colorpicker"
import { Formik } from "formik"
import { hsl } from "color-convert"

import { AzureImage, FileUploaderField } from "commons"
import { azureContainer, MAX_FILE_SIZE } from "data"

import "./ThemeSettings.css"

const ThemeSettings = ({ settings, readOnly }: Props) => {
  const iconlogoUrl = settings.find((s) => s.code?.code === "icon-logo")?.value?.uri
  const banner = settings.find((s) => s.code?.code === "banner")?.value?.uri
  const primaryColor = settings.find((s) => s.code?.code === "primary-color")?.value?.string
  const secondaryColor = settings.find((s) => s.code?.code === "secondary-color")?.value?.string
  const photoBgColor = settings.find((s) => s.code?.code === "photo-bg-color")?.value?.string

  const hslToHex = (hslColor: string) => {
    if (new RegExp(/^\d+deg \d+% \d+%$/g).test(hslColor)) {
      const values = hslColor
        .replace("%", "")
        .split(" ")
        .map((v) => parseInt(v))
      return hsl.hex([values[0], values[1], values[2]])
    }
    return ""
  }

  const imageSetting = (label: string, field: string, url: string, classNames: string) => (
    <div className="flex gap-2 border-b border-gray-200 p-3">
      <div className="flex w-60 items-center">
        <span className="text-2xl font-semibold text-slate-400">{label}</span>
      </div>
      <div className="flex items-center">
        {!readOnly && (
          <FileUploaderField
            field={field}
            azureContainer={azureContainer.branding}
            maxFileSize={MAX_FILE_SIZE}
            accept="image/*"
            className="mr-6"
          />
        )}
        {url && <AzureImage url={url} imageClassName={classNames} loadingClassName={classNames} />}
      </div>
    </div>
  )

  const colorSetting = (label: string, color: string) => (
    <div className="flex gap-2 border-b border-gray-200 p-3">
      <div className="flex w-60 items-center">
        <span className="text-2xl font-semibold text-slate-400">{label}</span>
      </div>
      <div className="flex items-center">
        {!readOnly ? (
          <ColorPicker className="flex w-40 justify-center" format="hex" value={color} />
        ) : (
          <div className="h-8 w-8 rounded-md border" style={{ backgroundColor: `#${color}` }}></div>
        )}
      </div>
    </div>
  )

  return (
    <Formik initialValues={{}} onSubmit={() => new Promise(() => ({}))}>
      <div className="flex flex-col p-2">
        {imageSetting("Icon logo", "iconLogo", iconlogoUrl as string, "w-12")}
        {imageSetting("Banner", "banner", banner as string, "w-96")}
        {colorSetting("Primary color", hslToHex(primaryColor as string))}
        {colorSetting("Secondary color", hslToHex(secondaryColor as string))}
        {colorSetting("Photo bg-color", hslToHex(photoBgColor as string))}
      </div>
    </Formik>
  )
}

type Props = {
  settings: SettingItemArray[]
  readOnly: boolean
}

export { ThemeSettings }
