import { type FieldProps, ErrorMessage, Field } from "formik"
import { InputNumber } from "primereact/inputnumber"
import { classNames } from "primereact/utils"
import type { FC } from "react"
import type { AnyObject } from "yup/lib/object"

const NumberField: FC<Props> = ({
  field,
  label,
  className,
  min,
  max,
  minFractionDigits,
  maxFractionDigits,
  placeholder,
  prefix,
  suffix,
  showButtons = true,
  mode = "decimal",
  floatLabel,
  horizontal,
  validation,
  disabled,
  inputClassName,
  labelClassName,
  onChange: onValueChage,
  subMessage,
  allowEmpty = true,
}) => (
  <Field name={field} validate={validation}>
    {({ field: { name, value, onChange }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => (
      <div
        className={classNames(
          "field relative space-y-2",
          horizontal && "inline-flex justify-between",
          floatLabel && "float-label relative mt-1 block",
          !horizontal && !floatLabel && "flex flex-col",
          className,
        )}
      >
        {label && !floatLabel && (
          <label
            htmlFor={name}
            className={classNames(
              "text-sm font-medium text-gray-700",
              { "mt-2 mr-3 mb-0": horizontal },
              labelClassName,
            )}
          >
            {label}
          </label>
        )}
        <div className="flex flex-col">
          <InputNumber
            inputId={name}
            name={name}
            value={value}
            onValueChange={onChange}
            onChange={(e) => {
              setFieldValue(name, e.value)
              onValueChage?.(e.value)
            }}
            mode={mode}
            currency={mode === "currency" ? "USD" : undefined}
            placeholder={placeholder}
            showButtons={showButtons}
            min={min}
            max={max}
            allowEmpty={allowEmpty}
            maxFractionDigits={maxFractionDigits}
            minFractionDigits={minFractionDigits}
            prefix={prefix}
            suffix={suffix}
            useGrouping={false}
            disabled={disabled}
            inputClassName={inputClassName}
            className={classNames("p-inputtext-sm h-10", {
              "p-invalid": touched && error,
              horizontal: horizontal,
            })}
          />
          {label && floatLabel && (
            <label
              htmlFor={name}
              className={classNames(
                "absolute top-2 left-3 text-sm text-gray-400 transition-all ease-in-out",
                labelClassName,
              )}
            >
              {label}
            </label>
          )}
          {subMessage && <span className="text-xs text-gray-700">&#42;{subMessage}</span>}
          <div className="p-error field-error-spacing flex items-start">
            {touched && error && <ErrorMessage name={field}>{(msg) => <small>{msg}</small>}</ErrorMessage>}
          </div>
        </div>
      </div>
    )}
  </Field>
)

type Props = {
  field: string
  label?: string
  className?: string
  inputClassName?: string
  labelClassName?: string
  min?: number
  max?: number
  minFractionDigits?: number
  maxFractionDigits?: number
  showButtons?: boolean
  placeholder?: string
  mode?: "decimal" | "currency"
  prefix?: string
  suffix?: string
  horizontal?: boolean
  floatLabel?: boolean
  disabled?: boolean
  subMessage?: string
  allowEmpty?: boolean
  validation?(value: string): void
  onChange?(value: AnyObject | number | null | undefined): void
}

export { NumberField }
