import { Message } from "primereact/message"
import { type FC, useMemo } from "react"

import { MedicationOrderDetailHeader } from "commons/meds"
import { InvoicePreviewSection, getSummaryParameter } from "orders"
import { getMoneyCurrencyAlt } from "utils"

import { useMrOrderContext } from "../../hooks"
import { MedicationOrderEditFooter } from "./MedicationOrderEditFooter"

const MedicationOrderEditPreview: FC = () => {
  const { invoiceData, serviceRequest } = useMrOrderContext()

  const invoiceTotal = useMemo(() => invoiceData?.invoice.totalGross, [invoiceData?.invoice.totalGross])

  const { creditNote, debitNote } = useMemo(() => {
    const creditNote = getSummaryParameter(invoiceData?.summary ?? {}, "credit-note") as number | undefined
    const debitNote = getSummaryParameter(invoiceData?.summary ?? {}, "debit-note") as number | undefined

    return { creditNote, debitNote }
  }, [invoiceData?.summary])

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <div className="flex h-full flex-col space-y-4">
        <MedicationOrderDetailHeader
          hideEditedOrderDetails
          hideNavigateAction
          serviceRequest={serviceRequest}
          invoices={invoiceData?.invoice && [invoiceData.invoice]}
          hiddenActions
          headerText={<span className="text-lg font-medium text-primary">Preview</span>}
        />
        <div className="flex grow flex-col space-y-4 overflow-y-auto px-6">
          <InvoicePreviewSection invoiceData={invoiceData} showProductsSection summaryAsCard />
          <Message
            text={
              (!invoiceTotal?.value
                ? "No changes reflected on the price"
                : `The total amount of ${getMoneyCurrencyAlt(invoiceTotal?.currency)}${(debitNote || (creditNote ?? 0) * -1).toFixed(2)} will be ${debitNote ? "charged" : "refunded"} for this order`) +
              ". Please, proceed to finish your order"
            }
            severity="info"
          />
        </div>
        <MedicationOrderEditFooter />
      </div>
    </div>
  )
}

export { MedicationOrderEditPreview }
