import { faEye, faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type MedicationKnowledge, codeableConceptAsString, isMedicationKnowledge } from "fhir"
import { type FieldProps, ErrorMessage, Field, useField, useFormikContext } from "formik"
import { type AutoCompleteSelectEvent, AutoComplete } from "primereact/autocomplete"
import { classNames } from "primereact/utils"
import { type FC, type HTMLAttributes, useDeferredValue, useEffect, useRef, useState } from "react"

import type { MEDICATION_CATALOG } from "data"
import { useOrganizationContext } from "organization"
import { SYSTEM_VALUES } from "system-values"

import { useMedicationKnowledge } from "../hooks"
import { getMKDisplayText } from "../utils"
import "./AutocompleteMedicationField.css"
import { MedicationKnowledgeDetails } from "./MedicationKnowledgeDetails"

//const addAsWriteInMessage = "Add as write-in"
const loadingMessage = "Loading..."

const AutocompleteMedicationField: FC<Props> = ({
  field,
  label,
  className,
  readonly,
  disabled,
  allowedMedicationCodes,
  category,
  appendTo = "self",
  panelClassName = "w-full",
  onChange,
  excludeCodes,
  validate = (val: MedicationKnowledge) => (!val?.code?.coding?.[0]?.code ? `${label} is required` : undefined),
}) => {
  const [filter, setFilter] = useState<string>("")
  const deferredFilter = useDeferredValue(filter)
  const { currentOrganizationId } = useOrganizationContext()
  const { medicationKnowledge, isLoading } = useMedicationKnowledge({
    filter: deferredFilter,
    category,
    medCodes: allowedMedicationCodes?.join(","),
    hasCIDInOrg: currentOrganizationId,
    excludedCodes: excludeCodes?.join(", "),
  })

  const [suggestionList, setSuggestionList] = useState<(MedicationKnowledge | string)[]>([loadingMessage])
  const [selectedMK, setSelectedMK] = useState<MedicationKnowledge>()
  const [{ value: fieldValue }] = useField(field)
  const isMKSelected = isMedicationKnowledge(fieldValue)
  const { setFieldValue } = useFormikContext<MedicationKnowledge>()
  const autoCompleteRef = useRef(null)

  const itemTemplate = (item: MedicationKnowledge | string) => {
    if (!isMedicationKnowledge(item)) return <div className="text-sm">{item}</div>

    const { code, manufacturer, doseForm, packaging, ingredient, intendedRoute, amount } = item
    const lfID = code?.coding?.find((c) => c.system === SYSTEM_VALUES.LIFEFILE_MEDICATION)?.code
    const ndc = code?.coding?.find((c) => c.system === SYSTEM_VALUES.MEDICATION_NDC)?.code
    const route = intendedRoute?.[0]?.coding?.[0]?.display?.replace(/use|route/, "")?.trim()

    return (
      <div className="relative flex w-full items-center justify-between gap-x-2">
        <div className="flex flex-1 flex-col gap-1 overflow-hidden">
          <div className="flex items-baseline gap-2 truncate">
            <span className="inline font-semibold">{codeableConceptAsString(code)}</span>
            {ingredient?.length === 1 && ingredient[0]?.strength?.numerator?.unit && (
              <span title={ingredient[0].strength.numerator.unit} className="min-w-0 truncate">
                {ingredient[0].strength.numerator.unit}
              </span>
            )}
          </div>
          <div className="flex flex-wrap gap-1 divide-x divide-gray-200 text-sm font-light text-gray-400">
            {manufacturer?.display && (
              <span className="pl-1 first:pl-0">{`Pharmacy: ${manufacturer.display.replace("Farmakeio", "FarmaKeio")}`}</span>
            )}
            {doseForm?.coding?.[0]?.display && (
              <span className="inline-flex space-x-1 pl-1 first:pl-0">
                {`Drug Form: ${doseForm?.coding?.[0]?.display}`}
              </span>
            )}
            {amount?.value && amount?.unit && (
              <span className="inline-flex space-x-1 pl-1 first:pl-0">
                {`Amount: ${amount?.value} ${amount?.unit}`}
              </span>
            )}
            {packaging?.type?.coding?.[0]?.display && (
              <span className="pl-1 first:pl-0">Pkg: {packaging.type.coding[0].display}</span>
            )}
            {lfID && <span className="pl-1 first:pl-0">ID: {lfID}</span>}
            {ndc && <span className="pl-1 first:pl-0">NDC: {ndc}</span>}
            {route && <span className="pl-1 first:pl-0">{route}</span>}
          </div>
        </div>
        <PreviewButton onClick={() => setSelectedMK(item)} />
      </div>
    )
  }

  const handleSelect = (e: AutoCompleteSelectEvent) => {
    if (isMedicationKnowledge(e.value)) {
      const value = { ...e.value, textDisplayedInField: getMKDisplayText(e.value) }
      setFieldValue(field, value)
    } else {
      setFieldValue(field, null)
    }

    onChange?.(e.value)
  }

  useEffect(() => {
    if (autoCompleteRef.current) {
      const inputElement: HTMLInputElement = autoCompleteRef.current
      inputElement.setAttribute("spellcheck", "false")
    }
  }, [])

  useEffect(() => {
    if (isLoading) {
      setSuggestionList([loadingMessage])
      return
    }

    if (!medicationKnowledge.length) {
      setSuggestionList([])
      return
    }

    setSuggestionList(medicationKnowledge)
  }, [medicationKnowledge, isLoading, suggestionList.length])

  return (
    <>
      <Field name={field} validate={validate}>
        {({ field: { name, value }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => {
          return (
            <div className={classNames("field relative flex flex-col", className)}>
              {label && (
                <label htmlFor={name} className="mb-2 text-sm font-medium text-gray-700">
                  {label}
                </label>
              )}
              <AutoComplete
                id={name}
                name={name}
                aria-autocomplete="none"
                autoComplete="false"
                inputRef={autoCompleteRef}
                field="textDisplayedInField"
                className={classNames("mk-autocomplete p-autocomplete-dd", "p-inputtext-sm", {
                  "p-invalid": touched && error,
                })}
                disabled={disabled}
                readOnly={readonly}
                suggestions={/*suggestionList.length ? suggestionList : [addAsWriteInMessage]*/ suggestionList}
                emptyMessage="No medications found"
                showEmptyMessage
                delay={400}
                itemTemplate={itemTemplate}
                dropdown={!disabled}
                dropdownMode="current"
                dropdownIcon={<FontAwesomeIcon icon={faPlus} className="bg-white" />}
                value={value}
                completeMethod={(e) => {
                  setFilter(e.query.split("-")[0].trim())
                  setSuggestionList((list) => [...list])
                }}
                onChange={(e) => {
                  setFieldValue(name, e.value)
                }}
                onSelect={handleSelect}
                appendTo={appendTo}
                panelClassName={panelClassName}
              />
              {isMKSelected && (
                <PreviewButton
                  id="autocomplete-med-button"
                  onClick={() => setSelectedMK(value)}
                  className={classNames("absolute top-1/2 cursor-pointer bg-white py-0.5 text-slate-500", {
                    "right-4": disabled,
                    "right-10": !disabled,
                  })}
                />
              )}
              <div className="p-error field-error-spacing flex items-start">
                <ErrorMessage name={name}>{(msg) => <small>{msg}</small>}</ErrorMessage>
              </div>
            </div>
          )
        }}
      </Field>
      <MedicationKnowledgeDetails
        selectedMK={selectedMK}
        onHide={() => setSelectedMK(undefined)}
        showImgFallback={false}
      />
    </>
  )
}

const PreviewButton: FC<HTMLAttributes<SVGSVGElement>> = ({ onClick, className }) => (
  <FontAwesomeIcon
    className={className}
    icon={faEye}
    title="Preview"
    onClick={(e) => {
      e.stopPropagation()
      onClick?.(e)
    }}
  />
)

type Props = {
  field: string
  label?: string
  className?: string
  readonly?: boolean
  disabled?: boolean
  allowedMedicationCodes?: string[]
  onChange?(value: MedicationKnowledge | undefined): void
  category?: MEDICATION_CATALOG
  appendTo?: "self" | HTMLElement | undefined | null
  panelClassName?: string
  excludeCodes?: string[]
  validate?(mk?: MedicationKnowledge): Promise<string | undefined>
}

export { AutocompleteMedicationField }
