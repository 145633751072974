import {
  type Appointment,
  type Location,
  type Reference,
  asReference,
  isDevice,
  isHealthcareService,
  isLocation,
  isPatient,
  isPractitioner,
} from "fhir"
import * as Yup from "yup"

import type { AppointmentFormData } from "../types"
import { getSlotInitialValue } from "../utils"

const INITIAL_VALUES: Appointment = {
  status: "booked",
  start: undefined,
  end: undefined,
  description: "",
  participant: [],
  appointmentType: undefined,
  minutesDuration: 30,
  resourceType: "Appointment",
}

const initialValues = (appointment: Appointment = INITIAL_VALUES, location?: Location): AppointmentFormData => {
  const patient = findActor(appointment, isPatient)
  const pract = findActor(appointment, isPractitioner)
  const room = findActor(appointment, (actor) => isLocation(actor) && actor.id !== location?.id)
  const healthcareService = findActor(appointment, isHealthcareService)
  const devices = filterActor(appointment, isDevice)

  return {
    ...appointment,
    patient,
    practitioner: pract,
    room,
    healthcareService,
    devices,
    location: asReference(location as Location),
    selectedSlot: getSlotInitialValue(appointment.start, appointment.end),
  }
}

const findActor = (appointment: Appointment, condition: (actor: Reference) => boolean) =>
  appointment.participant.find(({ actor }) => condition(actor as Reference))?.actor

const filterActor = (appointment: Appointment, condition: (actor: Reference) => boolean) =>
  appointment.participant
    .filter(({ actor }) => condition(actor as Reference))
    ?.map(({ actor }) => actor as Reference) ?? []

const appointmentValidationSchema = Yup.object().shape({
  minutesDuration: Yup.number().required("Must specify appointment duration").min(5, "Minimum duration of 5 minutes"),
  selectedSlot: Yup.object().required("Must select a time"),
  patient: Yup.object().test("test-patient", "Patient is required", (value) => !!value?.id),
  practitioner: Yup.object().test("test-practitioner", "Practitioner is required", (value) => !!value?.id),
})

const sanitize = ({
  location,
  healthcareService,
  patient,
  practitioner,
  room,
  devices,
  ...appointment
}: AppointmentFormData): Appointment => {
  if (appointment.comment === "") delete appointment.comment

  delete appointment.selectedSlot
  delete appointment.questionnaires
  delete appointment.override

  return {
    ...appointment,
    participant: [
      { actor: healthcareService, status: "tentative" },
      { actor: patient, status: "tentative" },
      { actor: practitioner, status: "tentative" },
      { actor: location, status: "tentative" },
      ...(room ? [{ actor: room, status: "tentative" }] : []),
      ...devices.map((device) => ({ actor: device, status: "tentative" })),
    ],
  }
}

export { INITIAL_VALUES, appointmentValidationSchema, initialValues, sanitize }
