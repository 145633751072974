import { classNames } from "primereact/utils"

const Badge = ({ title, text, colorStyle = "gray", size = "md", className }: BadgeProps) => {
  const colorVariants = {
    gray: "bg-gray-50 text-gray-600",
    red: "bg-red-50 text-red-600",
    yellow: "bg-yellow-50 text-yellow-600",
    green: "bg-green-50 text-green-600",
    blue: "bg-blue-50 text-blue-600",
    indigo: "bg-indigo-50 text-indigo-600",
    purple: "bg-purple-50 text-purple-600",
    pink: "bg-pink-50 text-pink-600",
    orange: "bg-orange-50 text-orange-600",
  }
  return (
    <span
      title={title}
      className={classNames(
        `items-center rounded-full py-0.5 font-medium ${colorVariants[colorStyle]}`,
        { "px-2.5 text-xs": size === "md" },
        { "px-3 text-sm": size === "lg" },
        className,
      )}
    >
      {text}
    </span>
  )
}

export type BadgeProps = {
  title?: string
  className?: string
  text: string
  colorStyle?: "gray" | "red" | "yellow" | "green" | "blue" | "indigo" | "purple" | "pink" | "orange"
  size?: "md" | "lg"
}

export { Badge }
