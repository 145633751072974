import { type MedicationKnowledge, codeableConceptAsString } from "fhir"
import type { FC } from "react"

const MedicationItemDetail: FC<Props> = ({ mk }) => {
  return (
    <li className="flex flex-col whitespace-nowrap">
      <span className="max-w-xs overflow-hidden text-sm font-normal text-ellipsis whitespace-nowrap text-slate-500">
        {codeableConceptAsString(mk.code)}
      </span>
      {mk.manufacturer?.display && <span className="text-sm font-light text-gray-400">{mk.manufacturer?.display}</span>}
      {mk.packaging && (
        <span className="text-sm font-light text-gray-400">{`Packaging: ${mk.packaging?.quantity?.value ?? "unspecified"} ${mk.packaging?.quantity?.unit ?? "unspecified"}`}</span>
      )}
    </li>
  )
}

type Props = { mk: MedicationKnowledge }

export { MedicationItemDetail }
