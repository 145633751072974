import { type FC, useState } from "react"
import type { Practitioner } from "fhir"

import { PractitionersList } from "../PractitionersList"
import { PractitionerCalendarView } from "./PractitionerCalendarView"

const PractitionersCalendarContainer: FC = () => {
  const [selectedPractitioner, setSelectedPractitioner] = useState<Practitioner | undefined>(undefined)

  if (selectedPractitioner) {
    return (
      <PractitionerCalendarView
        practitioner={selectedPractitioner}
        handleGoBack={() => setSelectedPractitioner(undefined)}
      />
    )
  }

  return <PractitionersList onPractitionerSelected={setSelectedPractitioner} />
}

export { PractitionersCalendarContainer }
