import { useChartContext } from "chart-view"
import { KnowledgeListContainer, ModulesId, SearchWithStatus } from "commons"
import { useMedicationRequestDataBind, useMedicationRequests, useMedReducerState, useMrOrders } from "commons/meds"
import { medicationRequestStatus } from "data"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"
import { useOrganizationContext } from "organization"

import { prescriptionItemModel } from "../prescriptions/prescriptionItemModel"

const EPrescribeKnowledge: React.FC = () => {
  const { hasCreditCard } = useOrganizationContext()
  const { patientId } = usePatientContext()
  const { showModule } = useChartContext()

  const { statusFilter, searchFilter, updateFilters, updateSearchText } = useMedReducerState({
    statusFilter: ["active"],
  })

  const { medicationRequests, medicationKnowledges, isLoading } = useMedicationRequests({
    patientId,
    category: "medication",
    statusFilter,
    searchText: searchFilter,
  })
  const { serviceRequests, isLoading: isLoadingSR } = useMrOrders(
    patientId,
    medicationRequests?.map((med) => med.id as string),
  )
  const { appModules } = useAppModuleContext()

  const { medicationRequestData } = useMedicationRequestDataBind({
    medicationRequests,
    medicationKnowledges,
    serviceRequests,
  })

  const showOrder = (orderId: string) =>
    showModule({ module: ModulesId.EPRESCRIBE, moduleParams: { subview: "orders", order: orderId } })

  return (
    <div className="mt-1 flex flex-1 flex-col overflow-hidden">
      <SearchWithStatus
        className="mx-5 my-3"
        placeholder="Search products"
        options={medicationRequestStatus}
        selectedItems={statusFilter}
        onStatusCheck={updateFilters}
        onSearch={(filter) => updateSearchText(filter ?? "")}
      />
      <KnowledgeListContainer
        data={medicationRequestData}
        dataTitle="Products"
        iconDataNotFound={appModules[ModulesId.MEDICATIONR].getIcon()}
        itemModelBuilder={(item) =>
          prescriptionItemModel(
            {
              mrData: item,
              showAsExternalAction: true,
              externalAction: [
                {
                  label: "Show order",
                  command: item.serviceRequest?.id ? () => showOrder(item.serviceRequest?.id as string) : undefined,
                },
              ],
              hideOrganizationPrice: !hasCreditCard,
            } /*() => showOrder(item.serviceRequest?.id as string)*/,
          )
        }
        isLoading={isLoading || isLoadingSR}
      />
    </div>
  )
}

export { EPrescribeKnowledge }
