import { format, parseISO } from "date-fns"
import { codeableConceptAsString, type Reference } from "fhir"
import { classNames } from "primereact/utils"

import { formatsByTypes } from "data"
import { getMoneyCurrencyAlt } from "utils"

import type { LabPanelDetailsComponent } from "../types"
import { ResultDetails } from "./ResultDetails"

const LabPanelDetails = ({ panel, hidePrice, orderId, openEncounter }: Props) => {
  const results = panel.results
  const hasFinalResults = results?.length && results?.every(({ status }) => status === "final")

  return (
    <div className="flex flex-col rounded-lg border p-4">
      <div className="flex flex-row items-baseline justify-between">
        <div className="flex flex-col">
          <div className="flex flex-row items-baseline gap-2">
            <span
              className={classNames("h-2 w-2 rounded-full", hasFinalResults ? "bg-green-500" : "bg-yellow-500")}
            ></span>
            <span className="text-sm font-medium text-wrap break-words whitespace-normal text-primary">
              {codeableConceptAsString(panel.code ?? panel.key)}
            </span>
          </div>
          <div className="flex gap-8 text-sm text-gray-500">
            {!!panel.collected && (
              <span>Collected: {format(parseISO(panel.collected), formatsByTypes.SHORT_DATETIME)}</span>
            )}
            {!!panel.received && (
              <span>Received: {format(parseISO(panel.received), formatsByTypes.SHORT_DATETIME)}</span>
            )}
          </div>
        </div>
        <div className="flex flex-col text-right text-sm text-gray-500">
          {!!panel.tests && <span>Tests: {panel.tests}</span>}
          {!hidePrice && panel?.price?.value !== undefined && (
            <span>{`${getMoneyCurrencyAlt(panel?.price?.currency)}${panel.price.value.toFixed(2)}`}</span>
          )}
        </div>
      </div>
      {!!panel.totalResults && (
        <div className="mt-4 flex flex-col gap-5 divide-y divide-gray-200 border-t border-gray-200 pt-4">
          {results?.map((observation) => (
            <ResultDetails
              key={observation.key}
              labPanelResult={observation}
              orderId={orderId}
              openEncounter={openEncounter}
            />
          ))}
        </div>
      )}
    </div>
  )
}

type Props = {
  orderId: string
  panel: LabPanelDetailsComponent
  hidePrice?: boolean
  openEncounter?: Reference
}

export { LabPanelDetails }
