import { type Coding, codeableConceptAsString } from "fhir"
import { classNames } from "primereact/utils"
import { useState } from "react"

import { type BodyZones, type ConfigurationItem, BodySites } from "commons/procedures"

import { MedicationItem } from "./MedicationItem"

const ItemDetails = ({ items, isMassage, isBotox }: Props) => {
  const [medIndex, setMedIndex] = useState<number>(0)
  const [bodySiteClass, setBodySiteClass] = useState("opacity-100")
  const [bodyZone, setBodyZone] = useState<Coding>(items[medIndex]?.zone as Coding)

  const getBodyPoints = (medIndex: number) =>
    !isMassage && !isBotox
      ? items[medIndex]?.bodySite?.coding?.reduce<string[]>((acc, coding) => [...acc, coding.code as string], [])
      : items[medIndex]?.bodySite?.coding?.[0]?.code?.split("|")

  const [bodySite, setBodySite] = useState<string[] | undefined>(getBodyPoints(medIndex))

  const changeCurrentMed = (medIndex: number) => {
    setBodySiteClass("opacity-20")
    setMedIndex(medIndex)
    setTimeout(() => {
      setBodySiteClass("opacity-100")
      setBodySite(getBodyPoints(medIndex))
      setBodyZone(items[medIndex]?.zone as Coding)
    }, 300)
  }

  return (
    <div className="relative flex h-full flex-1 flex-row gap-5 py-3 pr-3">
      <div className="flex flex-col gap-4">
        {items.map((item, index) => (
          <MedicationItem
            key={index}
            item={item}
            isMassage={isMassage}
            isBotox={isBotox}
            title={
              isMassage
                ? `Massage ${index + 1}`
                : codeableConceptAsString(item?.medicationRequest?.medication?.CodeableConcept)
            }
            expanded={medIndex === index}
            onClick={medIndex !== index ? () => changeCurrentMed(index) : undefined}
          />
        ))}
      </div>
      <div
        className={classNames(
          "sticky top-0 flex flex-1 items-center justify-center pb-3 transition-opacity duration-300 focus:shadow-none",
          bodySiteClass,
        )}
      >
        <BodySites
          isPellectSelection={!isMassage && !isBotox}
          bodyZone={bodyZone?.code as BodyZones}
          selectedPoint={bodySite}
          showPointOrder={isBotox}
        />
      </div>
    </div>
  )
}

type Props = {
  items: ConfigurationItem[]
  isMassage: boolean
  isBotox: boolean
}

export { ItemDetails }
