import { useMutation, useQueryClient, type QueryKey } from "@tanstack/react-query"
import type { Parameters, ParametersParameterArrayArray, Reference } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import type { TimeOffHours } from "../types"

const useSetTimeOff = ({
  practitioner,
  onSettled,
  onSuccess,
  queryKeyToInvalidate,
}: {
  practitioner: Reference
  onSuccess?: () => void
  onSettled?: () => void
  queryKeyToInvalidate: QueryKey
}) => {
  const queryClient = useQueryClient()
  const { operationRequest } = useClient()

  const updateFn = async ({ notAvailable }: { notAvailable: TimeOffHours }) => {
    const hoursParam = notAvailable.hours.reduce((acc, timeOff) => {
      const start = timeOff.availableStartTime
      const end = timeOff.availableEndTime

      return start && end
        ? [
            ...acc,
            {
              name: "time",
              part: [
                { name: "start-time", value: { time: start } },
                { name: "end-time", value: { time: end } },
              ],
            },
          ]
        : acc
    }, [] as ParametersParameterArrayArray[])

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        { name: "start-date", value: { date: notAvailable.during?.start } },
        { name: "end-date", value: { date: notAvailable.during?.end } },
        { name: "all-day", value: { boolean: notAvailable.allDay ?? false } },
        { name: "description", value: { string: notAvailable.description } },
        { name: "actor", value: { Reference: practitioner } },
        ...(!notAvailable.allDay ? [...hoursParam] : []),
      ],
    }

    return operationRequest({
      endpoint: "Schedule",
      method: "POST",
      operation: "block",
      parameters,
    })
  }

  const { mutateAsync: addTimeOff, isPending } = useMutation({
    mutationFn: updateFn,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKeyToInvalidate })
      displayNotificationSuccess("Practitioner time off hours updated successfully!")
      // datadogLogs.logger.info(`Practice Hours on ${managingOrganization?.display} updated successfully!`)
      onSuccess?.()
    },
    onSettled,
  })

  return { addTimeOff, isPending }
}

export { useSetTimeOff }
