import type { QueryKey } from "@tanstack/react-query"
import type { Annotation, Reference } from "fhir"
import { classNames } from "primereact/utils"

import { InlineNoteContainer } from "./InlineNoteContainer"

const Notes = ({
  notes,
  className,
  label = "Notes",
  editProps,
  forceSingleNote,
}: {
  notes?: Annotation[]
  className?: string
  editProps?: EditProps
  label?: string
  forceSingleNote?: boolean
}) => {
  const itemNotes = notes?.filter(({ text }) => !!text)
  return (
    <>
      <div className={classNames("relative mb-2 flex flex-col text-sm", className)}>
        <span className="mb-2 inline-flex w-full items-center font-medium text-gray-400">
          {label}
          <hr className="ml-1 flex-1" />
        </span>

        {editProps ? (
          <InlineNoteContainer
            orderNote={itemNotes}
            parentId={editProps.parentId}
            context={editProps.context}
            queriesToInvalidate={editProps.queriesToInvalidate}
            contentClassName={className}
            inputRows={3}
            buttonClassName="absolute -top-1 right-0 z-10 bg-white"
            notShowFullDataNotFound={editProps.notShowFullDataNotFound}
            encounter={editProps.encounter}
            forceSingleNote={forceSingleNote}
          />
        ) : (
          itemNotes?.map(({ text }, index) => <p key={index}>{text}</p>)
        )}
      </div>
    </>
  )
}

type EditProps = {
  parentId: string
  context: NotesUseContext
  queriesToInvalidate: QueryKey
  notShowFullDataNotFound?: boolean
  encounter?: Reference
}

const NotesUseContext = {
  NUTRA: "MedicationRequest",
  RX: "MedicationRequest",
  PROCEDURE: "Procedure",
  ORDER: "ServiceRequest",
  OBSERVATION: "Observation",
} as const

type NotesUseContext = (typeof NotesUseContext)[keyof typeof NotesUseContext]

export { Notes, type EditProps, NotesUseContext }
