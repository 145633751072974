import { faMars, faVenus, faVenusMars } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { codeableConceptAsString } from "fhir"
import pluralize from "pluralize"
import { classNames } from "primereact/utils"
import { type FC, useMemo, useState } from "react"

import { COMBO_PROMO_CODE } from "data"
import { getMoneyCurrencyAlt } from "utils"

import { CardSelectionItem } from "../../components/CardSelectionItem"
import type { LaboratoryComboTest } from "../../types"
import { LabComboDetails } from "./ComboDetails"
import { LabComboTitle } from "./LabComboTitle"
import { PlanDefinitionDetails } from "./PlanDefinitionDetails"

const ComboItem: FC<Props> = ({
  combo,
  isInsuranceOrder,
  hidePrice,
  selected,
  selectCombo,
  className,
  detailMode = "modal",
  disabled,
  selectionFeedback = "none",
}) => {
  const [showDetails, setShowDetails] = useState(false)
  const promo = combo.combo?.topic?.find((topic) => topic.coding?.[0]?.code === COMBO_PROMO_CODE)?.coding?.[0]
  const isPromoCombo = !!promo
  const gender = combo.combo.useContext?.find(({ code }) => code.code === "gender")?.value?.CodeableConcept

  const tests = useMemo(
    () => combo.laboratoryTests.flatMap(({ planDefinition }) => ({ definition: planDefinition })),
    [combo.laboratoryTests],
  )

  const renderComboDetail = (
    <>
      <span title={codeableConceptAsString(gender)} className="text-sm text-gray-400">
        <FontAwesomeIcon
          icon={gender ? (gender?.coding?.[0]?.code === "male" ? faMars : faVenus) : faVenusMars}
          className="mr-1 text-xs"
        />
      </span>
      <span title="Tests" className="text-sm text-gray-400">
        {combo.laboratoryTests?.length} {pluralize("test", combo.laboratoryTests?.length ?? 0)}
      </span>
    </>
  )

  if (isInsuranceOrder && isPromoCombo) return null

  return (
    <>
      <div className={classNames("relative", className)}>
        {isPromoCombo && (
          <div className="absolute top-0 left-0 h-16 w-16">
            <div className="absolute top-2 -left-7 z-10 flex h-5 w-24 -rotate-45 transform items-center justify-center bg-red-500 bg-gradient-to-r from-red-400 to-red-600 py-1 text-center text-sm text-white shadow-lg">
              {promo.display}
            </div>
          </div>
        )}
        <CardSelectionItem
          mainText={combo.combo.title as string}
          mainData={<LabComboTitle combo={combo.combo} />}
          extraDetails={
            detailMode !== "accordion" ? <div className="flex space-x-1">{renderComboDetail}</div> : undefined
          }
          rightData={
            !hidePrice ? (
              <span
                className={classNames({ "line-through": isInsuranceOrder })}
              >{`${getMoneyCurrencyAlt(combo.price?.currency)} ${(combo?.price?.value ?? 0).toFixed(2)}`}</span>
            ) : undefined
          }
          onSelect={() => selectCombo(combo)}
          selected={selected}
          showSelectedMark={selectionFeedback === "icon"}
          onShowDetails={detailMode === "modal" ? () => setShowDetails(true) : undefined}
          clickableArea="card"
          footer={
            detailMode === "accordion" ? (
              <PlanDefinitionDetails
                panels={tests}
                customHeader={() => <div className="flex w-full space-x-1 pr-6 text-sm">{renderComboDetail}</div>}
              />
            ) : undefined
          }
          disabled={disabled}
          showCheckMark={selectionFeedback === "check"}
        />
      </div>

      {showDetails && (
        <LabComboDetails
          definition={combo.combo}
          onHide={() => setShowDetails(false)}
          price={combo.price}
          tests={combo.laboratoryTests}
          showPrice={!hidePrice}
          styledPrice={classNames({ "line-through": isInsuranceOrder })}
        />
      )}
    </>
  )
}

type Props = {
  combo: LaboratoryComboTest
  isInsuranceOrder?: boolean
  hidePrice?: boolean
  className?: string
  selected: boolean
  selectCombo(combo: LaboratoryComboTest): void
  detailMode?: "accordion" | "modal"
  selectionFeedback?: "check" | "icon" | "none"
  disabled?: boolean
}

export { ComboItem }
