import { Dialog } from "primereact/dialog"
import { InputTextarea } from "primereact/inputtextarea"
import { useState, type FC } from "react"

import { Button } from "commons"

const MedicationOrdersResubmitConfirmation: FC<Props> = ({ orderId, onHide, onResubmit, visible, isResubmitting }) => {
  const [resubmitReason, setResubmitReason] = useState<string>("")

  return (
    <Dialog
      closable={true}
      header="Resubmit order"
      visible={visible}
      draggable={false}
      dismissableMask={false}
      style={{ width: "35vw" }}
      pt={{ content: { className: "!pb-2" } }}
      onHide={() => {
        setResubmitReason("")
        onHide()
      }}
      footer={
        <div className="mt-2">
          <Button
            label="Resubmit"
            className="button-primary"
            onClick={() => onResubmit({ orderId, resubmitReason: resubmitReason as string })}
            size="xl"
            loading={isResubmitting}
          />
        </div>
      }
    >
      <label>Please provide a reason (optional)</label>
      <InputTextarea
        className="mt-2 w-full resize-none"
        rows={3}
        value={resubmitReason}
        onChange={(e) => setResubmitReason(e.target.value)}
      />
    </Dialog>
  )
}

type Props = {
  orderId: string
  onHide(): void
  onResubmit({ orderId, resubmitReason }: { orderId: string; resubmitReason: string }): void
  visible: boolean
  isResubmitting: boolean
}

export { MedicationOrdersResubmitConfirmation }
