import { codeableConceptAsString } from "fhir"
import { useFormikContext } from "formik"
import { Checkbox } from "primereact/checkbox"
import { classNames } from "primereact/utils"
import { useMemo } from "react"

import { InputField, NumberField } from "commons"
import { commonsDispenseInterval } from "commons/meds"
import { MED_FEE_TYPE, MEDICATION_PRODUCT_TYPE } from "data"
import { sumPrice } from "utils"

import { MEDICATIONS_SECTION_TYPE, type MedItem } from "../../types"

const MedicationForm = ({ medType, isEditing }: Props) => {
  const effectiveBasePrice =
    medType === MEDICATIONS_SECTION_TYPE.MEDICATIONS_RX_SECTION ? "practicePrice" : "patientPrice"
  const {
    setFieldValue,
    values: { fee, feeType, [effectiveBasePrice]: basePrice, referencePrices, mk },
  } = useFormikContext<MedItem>()

  const packaging = mk?.packaging?.type?.coding?.[0]?.display
  const doseForm = codeableConceptAsString(mk.doseForm)

  const isPharmaceutical = mk?.productType?.[0]?.coding?.[0]?.code === MEDICATION_PRODUCT_TYPE.RX
  const feeByFrequencyFieldVisible = useMemo(() => feeType === MED_FEE_TYPE.ByFrequency, [feeType])

  const renderFeeByFrequencyField = ({ start = 0, len }: { start?: number; len?: number }) =>
    commonsDispenseInterval.slice(start, len).map(({ label }, index) => (
      <div className="relative" key={label}>
        <NumberField
          field={`fee[${index + start}].value`}
          min={0}
          mode="currency"
          allowEmpty={false}
          validation={(value) =>
            value !== undefined && value !== null && value.toString() !== "0" ? false : "Fee is required"
          }
          showButtons={false}
        />
        <span className="absolute inset-y-0 top-[0.625rem] right-2 text-xs text-gray-500">{label}</span>
      </div>
    ))

  const initializeFeeByFrequency = () => {
    const initialFees = commonsDispenseInterval.map((frequency) => ({
      value: 0,
      duration: frequency.value,
    }))
    setFieldValue("fee", initialFees)
    setFieldValue("feeType", MED_FEE_TYPE.ByFrequency)
  }

  const initializeFixedFee = () => {
    setFieldValue("fee", [{ value: 0 }])
    setFieldValue("feeType", MED_FEE_TYPE.Fixed)
  }

  const handleFeeTypeChange = (checked: boolean) => {
    if (checked) {
      initializeFeeByFrequency()
    } else {
      initializeFixedFee()
    }
  }

  return (
    <div className="pt-1.5">
      <div className="p-fluid grid gap-4">
        <InputField field="display" /* label="Medication" */ placeholder="Add a name" disabled={isEditing} />
      </div>
      {!!referencePrices.length && (
        <div className="flex flex-col gap-1 space-y-1 pt-2 pb-4 text-sm">
          <span className="pb-1 font-semibold">{`${packaging} Price:`}</span>
          <div className="flex flex-wrap items-center space-x-2 divide-x-2 divide-gray-200">
            {referencePrices.map(({ qty, price }) => (
              <div key={qty} className="pr-2">
                {[`${qty} ${doseForm}`, `$${price?.toFixed(2)}`].join(" - ")}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="mt-4 flex flex-1 flex-col justify-center *:not-last:pb-3">
        <div className="mb-2 flex items-center justify-between pt-2 text-sm">
          <span className="font-semibold">Subtotal</span>
          <p className="font-semibold">${basePrice?.toFixed(2)}</p>
        </div>
        <div className="flex flex-1 flex-col justify-center divide-y divide-gray-200 *:not-last:pb-3">
          <div className="flex flex-col pb-1">
            <div className={classNames("grid grid-cols-2 gap-x-4 gap-y-1", { "items-end": isPharmaceutical })}>
              <div className="flex flex-col">
                <span className="text-sm font-semibold">{feeByFrequencyFieldVisible ? "Fee by frequency" : "Fee"}</span>
                {isPharmaceutical && (
                  <div className="flex items-center">
                    <Checkbox
                      inputId="enableFeeByFrequency"
                      onChange={(e) => handleFeeTypeChange(e.checked as boolean)}
                      checked={feeByFrequencyFieldVisible}
                    />
                    <label htmlFor="enableFeeByFrequency" className="ml-1 cursor-pointer text-sm">
                      Set fee by frequency
                    </label>
                  </div>
                )}
              </div>
              {feeByFrequencyFieldVisible ? (
                renderFeeByFrequencyField({ len: 1 })
              ) : (
                <NumberField
                  min={0}
                  field="fee[0].value"
                  mode="currency"
                  className="p-inputtext-sm p-fluid w-full"
                  allowEmpty={false}
                  showButtons={false}
                />
              )}
            </div>
            {feeByFrequencyFieldVisible && (
              <div className="grid grid-cols-2 gap-x-4 gap-y-1 pt-4">{renderFeeByFrequencyField({ start: 1 })}</div>
            )}
          </div>
          {!feeByFrequencyFieldVisible && (
            <div className="flex justify-between pt-2">
              <span className="text-sm font-semibold">Total</span>
              <span className="text-sm font-semibold">
                ${sumPrice(basePrice ?? 0, fee[0].value ?? 0).sum.toFixed(2)}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

type Props = {
  isEditing?: boolean
  isLoadingData?: boolean
  medType: MEDICATIONS_SECTION_TYPE
}

export { MedicationForm }
