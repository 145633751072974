import {
  type MedicationKnowledge,
  type MedicationKnowledgeAdministrationGuidelinesArray,
  isMedicationKnowledge,
} from "fhir"
import { type FieldProps, ErrorMessage, Field, useFormikContext } from "formik"
import { type AutoCompleteSelectEvent, AutoComplete } from "primereact/autocomplete"
import { classNames } from "primereact/utils"
import { type FC, useDeferredValue, useMemo, useRef, useState } from "react"

import { strCapitalize } from "utils"
import { isInjectableMK, oneTimeDispenseIntervalOption } from "commons/meds"

import { usePrescriptionSigContext, useSigSuggestions } from "../../hooks"
import type { MedicationRequestFormData } from "../../types"
import { serializeDosage } from "../../utils/formatters"
import {
  calculateDosageDispenseInterval,
  getDosageInitialValues,
  getGuidelineLabel,
  getMedicationKnowledgeSku,
} from "../../utils/transformers"

const AutocompleteSigField: FC<Props> = ({ field, index, label, className, readonly, disabled, validation }) => {
  const {
    values: { medicationField, dosages: prevDosages },
    setFieldValue,
  } = useFormikContext<MedicationRequestFormData>()
  const inputValue = prevDosages?.[index]?.instructionText
  const deferredInputValue = useDeferredValue(inputValue)
  const [suggestionList, setSuggestionList] = useState<string[]>([])
  const [suffix, setSuffix] = useState<string>()
  const ref = useRef<AutoComplete>(null)

  const { medRecommendedDosage, getAdministrationGuideline } = usePrescriptionSigContext()
  const mkSku = isMedicationKnowledge(medicationField) ? getMedicationKnowledgeSku(medicationField) : undefined
  const hasPrevDosages = !!prevDosages?.[0]?.instructionText

  const dosentHaveDefaultDosage = medRecommendedDosage === undefined

  const { refetch } = useSigSuggestions({
    input: deferredInputValue ?? "",
    sku: mkSku,
    seq: index,
  })

  const guidelinesOptions: GuidelineOption[] = useMemo(() => {
    if (isMedicationKnowledge(medicationField)) {
      const defGuideline = getAdministrationGuideline?.(medicationField, medRecommendedDosage)

      return [...(defGuideline ? [defGuideline] : []), ...(medicationField?.administrationGuidelines ?? [])].map(
        (guideline) => ({
          label: getGuidelineLabel(guideline),
          guideline,
        }),
      )
    }

    return []
  }, [medicationField])

  const guideLinesSuggestions = guidelinesOptions.filter(({ label }) =>
    label.toLowerCase().startsWith(inputValue?.toLowerCase?.() ?? ""),
  )

  const itemTemplate = (option: GuidelineOption | string) => {
    if (typeof option === "string") return option

    const { guideline } = option
    return (
      <div className="flex grow flex-col">
        <div className="flex w-full flex-wrap gap-2">
          {guideline.dosage?.flatMap(({ dosage }) =>
            dosage?.map(({ text }, index) => (
              <p key={index} className="flex flex-wrap break-words whitespace-normal">
                {text ?? ""}
              </p>
            )),
          )}
        </div>
        <div className="text-sm font-semibold text-gray-400 italic">recommended</div>
      </div>
    )
  }

  const handleSelect = (e: AutoCompleteSelectEvent) => {
    if (!guideLinesSuggestions.length || hasPrevDosages) {
      const end = suffix?.length ? -suffix.length : undefined
      const value = `${inputValue?.slice(0, end) ?? ""}${e.value}${dosentHaveDefaultDosage ? " " : ""}`
      setFieldValue(field, strCapitalize(value))

      setTimeout(() => {
        if (dosentHaveDefaultDosage && typeof inputValue === "string") {
          ref.current?.search(e.originalEvent, value, "input")
          ref.current?.show()
        }
      }, 300)
      return
    }

    const { intendedRoute } = medicationField as MedicationKnowledge
    const dosages = [
      ...(prevDosages?.slice(0, -1) ?? []),
      ...((e.value.guideline as MedicationKnowledgeAdministrationGuidelinesArray)?.dosage?.flatMap(
        ({ dosage: instructions }) =>
          instructions.map((dosage) =>
            serializeDosage(
              dosage,
              intendedRoute,
              isMedicationKnowledge(medicationField) ? getDosageInitialValues(medicationField) : undefined,
            ),
          ),
      ) ?? []),
    ]

    const dispenseInterval = calculateDosageDispenseInterval(
      dosages,
      isInjectableMK(medicationField as MedicationKnowledge),
    )
    const isOneTime =
      dispenseInterval?.value === oneTimeDispenseIntervalOption?.value?.value &&
      dispenseInterval?.code === oneTimeDispenseIntervalOption?.value?.code

    setFieldValue("dosages", dosages)
    setFieldValue("dispenseRequest.dispenseInterval", dispenseInterval)
    setFieldValue("dispenseRequest.numberOfRepeatsAllowed", isOneTime ? 0 : 1)
  }

  const searchSuggestions = async () => {
    if (!mkSku) {
      setSuggestionList([])
      return
    }
    const { data } = await refetch()
    setSuggestionList([...(data?.suggestions ?? [])])
    setSuffix(data?.suffix)
  }

  return (
    <Field name={field} validate={validation}>
      {({ field: { name, value }, form: { setFieldValue, setFieldTouched }, meta: { touched, error } }: FieldProps) => (
        <div className={classNames("field relative flex w-full flex-col", className)}>
          {label && (
            <label htmlFor={name} className="mb-2 text-sm font-medium text-gray-700">
              {label}
            </label>
          )}
          <AutoComplete
            ref={ref}
            id={name}
            name={name}
            field={guideLinesSuggestions.length ? "label" : undefined}
            className={classNames("p-inputtext-sm", { "p-invalid": touched && error })}
            panelClassName="max-w-xl"
            disabled={disabled}
            readOnly={readonly}
            suggestions={guideLinesSuggestions.length && !hasPrevDosages ? [...guideLinesSuggestions] : suggestionList}
            delay={400}
            value={value}
            itemTemplate={itemTemplate}
            completeMethod={searchSuggestions}
            onChange={({ value }) => {
              setFieldValue(name, value)
              setFieldTouched(name)
            }}
            onSelect={handleSelect}
            onClick={(e) => {
              if (isMedicationKnowledge(medicationField) && !value) {
                ref.current?.search(e, "", "dropdown")
              }
            }}
          />
          <div className="p-error field-error-spacing flex items-start">
            {touched && error && <ErrorMessage name={name}>{(msg) => <small>{msg}</small>}</ErrorMessage>}
          </div>
        </div>
      )}
    </Field>
  )
}

type Props = {
  field: string
  index: number
  label?: string
  className?: string
  readonly?: boolean
  disabled?: boolean
  validation?: (value: string) => string | undefined
}

type GuidelineOption = {
  label: string
  guideline: MedicationKnowledgeAdministrationGuidelinesArray
}

export { AutocompleteSigField }
