import { Checkbox } from "primereact/checkbox"
import { Dropdown } from "primereact/dropdown"
import { useEffect, useState } from "react"

import { type StackedListItemProps, StackedListItem } from "commons"

const SuggestedMedItem = ({ model, checked, stockQuantity, initialQuantity, onChange }: Props) => {
  const [qty, setQty] = useState(initialQuantity)
  const [checkStatus, setCheckStatus] = useState(checked)

  useEffect(() => {
    setCheckStatus(checked)
  }, [checked])

  return (
    <div className="flex items-center">
      <div
        className={"flex flex-1 items-center"}
        onClick={() => {
          setCheckStatus(!checkStatus)
          onChange(!checkStatus, qty)
        }}
      >
        <Checkbox checked={checkStatus} />
        <StackedListItem modelData={model} />
      </div>
      <Dropdown
        className="p-inputtext-sm mr-1"
        title="Quantity"
        disabled={!checkStatus}
        options={Array.from({ length: stockQuantity }, (_, i) => i + 1)}
        value={qty}
        onChange={(e) => {
          setQty(e.value)
          onChange(checkStatus, e.value)
        }}
      />
    </div>
  )
}

type Props = {
  model: StackedListItemProps
  checked: boolean
  stockQuantity: number
  initialQuantity: number
  onChange(checked: boolean, quantity: number): void
}

export { SuggestedMedItem }
