import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { asReference, type Annotation, type ResourceObject } from "fhir"
import { classNames } from "primereact/utils"
import { Button } from "primereact/button"
import type { UseMutateFunction } from "@tanstack/react-query"

import { useLoginContext } from "security"

import { useCrudReducer } from "../hooks"
import { NoteItem } from "./NoteItem"
import { ConfirmDialog } from "./ConfirmDialog"
import { DialogFormContainer, MentionTextAreaField } from "../forms"
import { noteValidationSchema } from "./validation"
import type { CustomError } from "../types"

const NotesModalContainer = <T extends ResourceObject>({
  resource,
  notes,
  onUpdateResource,
  className,
  renderNoteText,
  confirmDelete,
  textClassName,
  itemClassName,
  isProcessingActions,
  label = "Notes",
}: Props<T>) => {
  const { loggedInPractitioner, loggedInPractitionerId } = useLoginContext()

  const {
    initialValue,
    reset,
    showSlide: showForm,
    editWithIndex: edit,
    editIndex,
    deleteIndex,
    add,
    setDeleteIndex,
  } = useCrudReducer({
    defaultEntity: {
      text: "",
      author: {
        Reference: asReference(loggedInPractitioner),
      },
    } as Annotation,
  })

  const onSubmit = (note: Annotation) => {
    const resourceWithNoteData = { ...resource, note: [...(notes ?? [])] }

    if (editIndex !== undefined) {
      if (note?.text === resourceWithNoteData?.note[editIndex]?.text) {
        reset()
        return
      }
      resourceWithNoteData.note[editIndex] = note
    } else {
      resourceWithNoteData.note.push(note)
    }

    onUpdateResource(resourceWithNoteData, { onSettled: reset })
  }

  const onRemoveNote = (index: number) => {
    const resourceWithNoteData = { ...resource, note: [...(notes ?? [])] }
    resourceWithNoteData.note.splice(index, 1)
    onUpdateResource(resourceWithNoteData, { onSettled: reset })
  }

  return (
    <>
      <div className={classNames("relative flex flex-col text-sm", className)}>
        <ul className="list-inside space-y-3 overflow-y-auto">
          {notes
            ?.filter((note) => !note.author?.Reference?.id)
            .map((note, index) => (
              <li key={index} className={classNames("rounded-lg bg-gray-100", itemClassName)}>
                <p className={textClassName}>{renderNoteText?.(note.text) ?? note.text}</p>
              </li>
            ))}
        </ul>
        <div className="inline-flex w-full items-center font-medium text-gray-500">
          {label}
          <hr className="ml-1 flex-1" />
          <Button
            label="Add note"
            text
            onClick={!isProcessingActions ? add : undefined}
            size="small"
            pt={{ root: { className: "px-2 py-1" }, label: { className: "font-medium" } }}
            icon={<FontAwesomeIcon className="fa-fw" icon={faCirclePlus} />}
            loading={isProcessingActions}
          />
        </div>
        <ul className="list-inside space-y-3 overflow-y-auto">
          {notes?.map((note, index) => {
            if (!note.author?.Reference?.id) return null
            return (
              <li key={`${note.author?.Reference?.id}-${index}`} className="text-sm">
                <NoteItem
                  index={index}
                  note={note}
                  loggedInPractitionerId={loggedInPractitionerId}
                  onEditNote={edit}
                  onRemoveNote={confirmDelete ? setDeleteIndex : onRemoveNote}
                  textClassName={textClassName}
                  className={itemClassName}
                  renderNoteText={renderNoteText}
                  disabled={isProcessingActions}
                />
              </li>
            )
          })}
        </ul>
      </div>

      <DialogFormContainer
        onCancel={reset}
        onSubmit={onSubmit}
        showForm={showForm}
        initialValue={initialValue}
        validationSchema={noteValidationSchema}
        enableReinitialize
        title={editIndex ? "Edit note" : "Add note"}
      >
        <MentionTextAreaField field="text" className="p-1" autoFocus />
      </DialogFormContainer>

      <ConfirmDialog
        hideDialog={() => setDeleteIndex(undefined)}
        actionName="Delete"
        confirmText="Are you sure you want to delete this note?"
        onConfirm={() => onRemoveNote(deleteIndex as number)}
        visible={deleteIndex !== undefined}
      />
    </>
  )
}

type Props<T extends ResourceObject> = {
  label?: string
  className?: string
  textClassName?: string
  itemClassName?: string
  notes?: Annotation[]
  resource: T
  confirmDelete?: boolean
  onUpdateResource: UseMutateFunction<T | ResourceObject, CustomError, Partial<T>, unknown>
  renderNoteText?(text?: string): JSX.Element
  isProcessingActions?: boolean
}

export { NotesModalContainer }
