import { useQuery, useQueryClient } from "@tanstack/react-query"
import type { Parameters } from "fhir"

import { useClient } from "api"

import { ordersQueryKeys } from "../query-keys"

const useCountCpoeOrders = (patientId: string) => {
  const { operationRequest } = useClient()
  const queryKey = ordersQueryKeys.count.withPatientId(patientId)
  const queryClient = useQueryClient()

  const { data, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      const result = await operationRequest<Parameters>({
        endpoint: `Patient/${patientId}/cpoe`,
        method: "POST",
        operation: "count",
      })

      return { ordersCount: result.parameter?.find((param) => param.name === "Total")?.value?.integer ?? 0 }
    },
    meta: { context: { queryKey, patientId } },
    refetchOnWindowFocus: true,
  })

  if (data?.ordersCount === 0) {
    queryClient.cancelQueries({ queryKey: ordersQueryKeys.extrasPrices([]), exact: false, fetchStatus: "fetching" })
    queryClient.cancelQueries({ queryKey: ordersQueryKeys.list(patientId), fetchStatus: "fetching" })
    queryClient.cancelQueries({
      queryKey: ordersQueryKeys.invoicePreview(patientId),
      fetchStatus: "fetching",
      exact: false,
    })
  }

  return {
    ordersCount: data?.ordersCount ?? 0,
    refreshCpoeCount: refetch,
  }
}

export { useCountCpoeOrders }
