import type { MedicationKnowledge, Money, PlanDefinition } from "fhir"
import type { FC } from "react"

import { Accordion } from "../../components/Accordion"
import { MedicationItemDetail } from "../../meds"
import { LabComboTitle } from "./LabComboTitle"
import { classNames } from "primereact/utils"

const PlanDefinitionDetails: FC<Props> = ({
  combos,
  panels,
  nutraceuticals,
  rx,
  customHeader,
  headerClassName,
  contentClassName,
}) => (
  <Accordion
    data={[{ combos, panels, nutraceuticals, rx, headerClassName }]}
    onClick={(e) => e.stopPropagation()}
    headerTemplate={customHeader ?? defaultHeader}
    pt={{
      accordiontab: {
        headerAction: { className: "p-3" },
        headerTitle: { className: "w-[97%] mr-1" },
        content: { className: "pt-0 @container" },
      },
    }}
    contentTemplate={({ combos, panels, rx, nutraceuticals }) => (
      <div className={classNames("flex grow flex-col gap-2", contentClassName)}>
        {!!combos?.length && (
          <>
            <h6 className="text-sm font-medium">Combos</h6>
            {combos?.map((combo) => (
              <div key={combo.definition.id} className="flex flex-col gap-2 pl-2">
                <div className="flex flex-1 items-center justify-between text-sm font-normal whitespace-nowrap text-slate-500">
                  <LabComboTitle combo={combo.definition} />
                  <hr className="ml-3 w-full border-slate-300" />
                </div>
                <div className="row-auto grid grid-flow-row gap-2 pl-2 @xl:grid-cols-2 @2xl:grid-cols-3 @3xl:grid-cols-4">
                  {combo.panels.map((p, index) => (
                    <span key={p.id ?? p.title ?? index} className="text-sm text-slate-400">
                      {p.title}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </>
        )}
        {!!panels?.length && (
          <div className="flex grow flex-col gap-2">
            <h6 className="text-sm font-medium">Panels</h6>
            <ul className="row-auto grid grid-flow-row gap-2 pl-2 @xl:grid-cols-2 @2xl:grid-cols-3 @3xl:grid-cols-4">
              {panels.map((panel) => (
                <li
                  key={panel.definition.id}
                  className="items-center text-sm font-normal whitespace-nowrap text-slate-500"
                >
                  {panel.definition.title}
                </li>
              ))}
            </ul>
          </div>
        )}
        {!!rx?.length && (
          <div className="flex grow flex-col gap-2">
            <h6 className="text-sm font-medium">Rx</h6>
            <ul className="row-auto grid grid-flow-row-dense gap-2 pl-2 @xl:grid-cols-2 @2xl:grid-cols-3 @3xl:grid-cols-4">
              {rx.map((mk) => (
                <MedicationItemDetail key={mk.id} mk={mk} />
              ))}
            </ul>
          </div>
        )}
        {!!nutraceuticals?.length && (
          <div className="flex grow flex-col gap-2">
            <h6 className="text-sm font-medium">Nutraceuticals</h6>
            <ul className="row-auto grid grid-flow-row gap-2 pl-2 @xl:grid-cols-2 @2xl:grid-cols-3 @3xl:grid-cols-4">
              {nutraceuticals.map((mk) => (
                <MedicationItemDetail key={mk.id} mk={mk} />
              ))}
            </ul>
          </div>
        )}
      </div>
    )}
  />
)

const defaultHeader = ({ combos, panels, rx, nutraceuticals, headerClassName }: Data) => (
  <div className={classNames("flex w-full flex-1 justify-between pr-6 text-sm", headerClassName)}>
    {!!combos?.length && <span>Combos: {combos.length}</span>}
    {!!panels?.length && <span>Test panels: {panels.length}</span>}
    {!!nutraceuticals?.length && <span>Nutraceuticals: {nutraceuticals?.length}</span>}
    {!!rx?.length && <span>Rx: {rx?.length}</span>}
    <span>Info</span>
  </div>
)

type Data = {
  combos?: { definition: PlanDefinition; panels: PlanDefinition[]; price?: Money }[]
  panels?: { definition: PlanDefinition }[]
  nutraceuticals?: MedicationKnowledge[]
  rx?: MedicationKnowledge[]
} & { headerClassName?: string }

type Props = Data & {
  customHeader?(data: Data): JSX.Element
  headerClassName?: string
  contentClassName?: string
}
export { PlanDefinitionDetails }
