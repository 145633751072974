import type { Composition } from "fhir"
import { classNames } from "primereact/utils"

import { SearchWithFilters } from "../../components/SearchWithFilters"

const MedicationCatalogListFilters = ({ isLoading, catalogs, catalog, setSelectedCatalog, setSearchFilter }: Props) => (
  <>
    <SearchWithFilters
      className="mt-1"
      buttonClassName={classNames("w-10", { hidden: catalogs.length <= 1 })}
      showClearButton={false}
      isLoading={isLoading}
      placeholder="Search products"
      initialFilters={{ catalog }}
      filtersData={[
        {
          field: "catalog",
          label: "Catalog",
          data: catalogs,
          type: "select",
          optionLabel: "title",
          optionValue: "id",
        },
      ]}
      onFilter={(filters) => {
        setSearchFilter(filters?.searchText)
        setSelectedCatalog(filters?.catalog)
      }}
    />
    <div className="mt-1 flex items-baseline gap-2">
      {catalog ? (
        <>
          <span className="font-semibold">Filtering by:</span>
          <label className="text-center text-sm whitespace-nowrap">
            {getSelectedCatalog(catalog, catalogs)?.title}
          </label>
        </>
      ) : (
        <span>No catalog selected</span>
      )}
    </div>
  </>
)

type Props = {
  isLoading: boolean
  catalogs: Composition[]
  catalog?: string
  setSelectedCatalog(catalogId?: string): void
  setSearchFilter(filter?: string): void
}

const getSelectedCatalog = (id: string, catalogs: Composition[]) => {
  const selectedCatalog = catalogs.filter((catalog: Composition) => catalog.id === id)?.[0]

  return selectedCatalog
}

export { MedicationCatalogListFilters }
