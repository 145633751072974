import { faCalendarDays, faEye, faPlay, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns/parseISO"
import { Chip } from "primereact/chip"
import { useNavigate } from "react-router-dom"
import { isAfter } from "date-fns/isAfter"
import { format } from "date-fns/format"
import { type FC, useState } from "react"
import type { Appointment } from "fhir"

import { EncounterConfig, useOpenEncounter } from "encounter"
import { formatsByTypes } from "data"
import { useOrganizationContext } from "organization"
import { AvatarImage, AvatarListItem } from "commons"

const AgendaAppointmentItem: FC<Props> = ({
  start,
  end,
  status,
  type,
  description,
  patientAvatar,
  patientName,
  appointment,
  color,
  patientId,
  action,
  onUnbook,
}) => {
  const { currentOrganizationId } = useOrganizationContext()
  const navigate = useNavigate()
  const { openEncounterRef } = useOpenEncounter(patientId as string)

  const [startEncounter, setStartEncounter] = useState(false)

  const isFutureOrProximAppt = end ? isAfter(parseISO(end.toString()), new Date()) : false

  const redirectToPatient = () => {
    navigate(`/orgs/${currentOrganizationId}/patients/${patientId}`)
  }

  const handleStartEncounter = () => {
    setStartEncounter(true)
  }

  const dropdownItems = [
    ...(patientId && !openEncounterRef && isFutureOrProximAppt
      ? [
          {
            label: "Start encounter",
            icon: <FontAwesomeIcon icon={faPlay} size="sm" className="mr-2" />,
            command: handleStartEncounter,
          },
        ]
      : []),
    {
      label: "Unbook",
      icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
      disabled: status === "cancelled",
      command: onUnbook,
    },
    {
      label: "View patient",
      icon: <FontAwesomeIcon icon={faEye} size="sm" className="mr-2" />,
      command: () => redirectToPatient(),
    },
  ]

  const head = (
    <>
      <span title="Patient">{patientName}</span>
      {status && (
        <span title="Status">
          <Chip label={status} className="custom-chip ml-2" />
        </span>
      )}
    </>
  )

  const details = (
    <>
      <span className="flex items-center pr-2">
        <FontAwesomeIcon icon={faCalendarDays} className="mr-1 text-gray-400" />
        <span title="Start">{start ? format(start, formatsByTypes.LONG_DATETIME) : "No date"}</span>
      </span>
      {description && (
        <span title="Description" className="pr-2">
          {description}
        </span>
      )}
      {type && (
        <span title="Type" className="pr-2 font-bold capitalize" style={{ color }}>
          {type}
        </span>
      )}
    </>
  )

  return (
    <>
      <AvatarListItem
        avatarImg={<AvatarImage name={patientName} photoUrl={patientAvatar} className="h-10 w-10 rounded-full" />}
        header={head}
        subHeader={details}
        dropdownMenuItems={dropdownItems}
        onContentClick={action}
      />
      <EncounterConfig
        patientId={patientId as string}
        patientRef={{ id: patientId, display: patientName, resourceType: "Patient" }}
        appointment={appointment}
        visible={startEncounter}
        onHide={() => setStartEncounter(false)}
      />
    </>
  )
}

type Props = {
  patientName: string
  status: string
  description?: string
  patientId?: string
  type: string
  patientAvatar?: string
  appointment?: Appointment
  start?: Date
  end?: Date
  color?: string
  action?(): void
  onUnbook?(): void
}

export { AgendaAppointmentItem }
