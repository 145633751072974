import type { SnippetsTypeaheadOption } from "./SnippetsTypeHeadOption"

const SnippetSuggestionItem = ({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number
  isSelected: boolean
  onClick: () => void
  onMouseEnter: () => void
  option: SnippetsTypeaheadOption
}): JSX.Element => {
  let className =
    "cursor-pointer bg-slate-50/50 text-unique-600 hover:bg-secondary-50 py-2 px-3 text-xs flex items-center"
  if (isSelected) {
    className += " bg-slate-200/40"
  }
  return (
    <li
      key={option.key}
      tabIndex={-1}
      className={className}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={"typeahead-item-" + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      <div className="flex w-full items-center justify-between">
        <span className="rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold whitespace-nowrap text-gray-500">
          # {option.metadata.id}
        </span>
        <span className="ml-2 max-w-36 truncate overflow-hidden overflow-ellipsis text-gray-600">
          {option.metadata.value}
        </span>
      </div>
    </li>
  )
}

export { SnippetSuggestionItem }
