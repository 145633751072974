import { useState, type FC } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClock,
  faLocationDot,
  faCalendar,
  faStethoscope,
  faLaptopMedical,
  faUser,
} from "@fortawesome/pro-regular-svg-icons"
import { differenceInMinutes, format, isAfter, parseISO } from "date-fns"
import { asReference, humanNameAsString } from "fhir"

import { Button, Badge } from "commons"
import { getBadgeColor } from "utils"
import { formatsByTypes } from "data"
import { EncounterConfig } from "encounter"
import type { CalendarEventDetails } from "commons/types"

import { StartEncounterAction } from "./StartEncounterAction"

const AppointmentDetails: FC<Props> = ({ calendarEvent: { event, extraInfo }, onUnbook }) => {
  const [startClicked, setStartClicked] = useState(false)

  const { appointment, type, status, color, practitioner, devices, location, patient } = extraInfo

  const locationName = location?.name

  const duration = !!event.start && !!event.end && differenceInMinutes(event.end, event.start)

  const isFutureOrProximAppt = event.end ? isAfter(parseISO(event.end.toString()), new Date()) : false

  const appointmentDevices = devices?.filter(({ deviceName }) => !!deviceName?.[0]?.name)

  const patientId = patient?.id

  return (
    <>
      <div className="flex items-center justify-between border-b border-gray-200 p-4">
        <h2 className="inline-flex items-center text-base font-semibold text-gray-700">
          <div className="mr-2 h-2 w-2 rounded-full" style={{ backgroundColor: color }} />
          {type}
        </h2>
        <Badge {...getBadgeColor(status)} />
      </div>

      <div className="grid grid-cols-2 gap-x-8 gap-y-4 p-6">
        <div className="space-y-2">
          <div className="flex items-center gap-2 text-gray-500">
            <FontAwesomeIcon icon={faCalendar} className="h-3 w-3" />
            Date
          </div>
          <span className="text-sm font-normal text-gray-700">
            {format(event.start as Date, formatsByTypes.SHORT_DATE)}
          </span>
        </div>

        {locationName && (
          <div className="space-y-2">
            <div className="flex items-center gap-2 text-gray-500">
              <FontAwesomeIcon icon={faLocationDot} className="h-3 w-3" />
              Location
            </div>
            <span className="text-sm font-normal text-gray-700">{locationName}</span>
          </div>
        )}

        {practitioner && (
          <div className="space-y-2">
            <div className="flex items-center gap-2 text-gray-500">
              <FontAwesomeIcon icon={faStethoscope} className="h-3 w-3" />
              Practitioner
            </div>
            <span className="text-sm font-normal text-gray-700">{humanNameAsString(practitioner?.name?.[0])}</span>
          </div>
        )}

        {patient && (
          <div className="space-y-2">
            <div className="flex items-center gap-2 text-gray-500">
              <FontAwesomeIcon icon={faUser} className="h-3 w-3" />
              Patient
            </div>
            <span className="text-sm font-normal text-gray-700">{humanNameAsString(patient?.name?.[0])}</span>
          </div>
        )}

        {duration && (
          <div className="space-y-2">
            <div className="flex items-center gap-2 text-gray-500">
              <FontAwesomeIcon icon={faClock} className="h-3 w-3" />
              Time
            </div>
            <span>
              <div className="text-sm font-normal text-gray-700">
                {format(event.start as Date, formatsByTypes.SHORT_TIME)} -{" "}
                {format(event.end as Date, formatsByTypes.TIME)}
              </div>
              <div className="text-gray-500">{duration} minutes</div>
            </span>
          </div>
        )}

        {!!appointmentDevices?.length && (
          <div className="space-y-2">
            <div className="flex items-center gap-2 text-gray-500">
              <FontAwesomeIcon icon={faLaptopMedical} className="h-3 w-3" />
              Devices
            </div>
            <span className="space-y-2">
              {appointmentDevices.map(({ id, deviceName }, index) => (
                <div key={id ?? index} className="flex items-center gap-2">
                  <span className="text-sm font-normal text-gray-700">{deviceName?.[0]?.name}</span>
                </div>
              ))}
            </span>
          </div>
        )}
      </div>

      <div className="flex items-center justify-end space-x-3 rounded-b-lg border-t border-gray-200 bg-white p-4">
        {!!patientId && isFutureOrProximAppt && (
          <StartEncounterAction
            label="Start encounter"
            className="text-primary"
            patientId={patientId}
            command={() => setStartClicked(true)}
          />
        )}
        {onUnbook && (
          <Button buttonStyle="text" label="Unbook" className="text-slate-400" size="sm" onClick={onUnbook} />
        )}
      </div>

      {!!patientId && !!appointment && (
        <EncounterConfig
          visible={startClicked}
          patientId={patientId}
          appointment={appointment}
          patientRef={asReference(patient)}
          onHide={() => setStartClicked(false)}
        />
      )}
    </>
  )
}

type Props = {
  calendarEvent: CalendarEventDetails
  onUnbook?: () => void
}

export { AppointmentDetails }
