import { useMutation } from "@tanstack/react-query"
import { type List, type ListEntryArray, type Parameters } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

import { getOnDemandValidationResponseFromParameters } from "../utils"
import type { RefillOnDemandValidationResponse } from "../types"

const useValidateRefillOnDemand = (
  onSuccess?: (_: { validationResponse: RefillOnDemandValidationResponse; medicationRequests: string[] }) => void,
) => {
  const { operationRequest } = useClient()

  const validate = async ({ medicationRequests }: Props) => {
    if (!medicationRequests.length) return

    const list: List = {
      resourceType: "List",
      mode: "working",
      status: "current",
      entry: medicationRequests.reduce((acc, mrId) => {
        return [...acc, { item: { id: mrId, resourceType: "MedicationRequest" } }]
      }, [] as ListEntryArray[]),
    }

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "items",
          resource: list,
        },
      ],
    }

    return operationRequest<Parameters>({
      endpoint: "MedicationRequest",
      method: "POST",
      operation: "meds-validate-refill-on-demand",
      parameters,
    })
  }

  const { mutate: validateRefillOnDemand, isPending } = useMutation({
    mutationFn: validate,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (parameters, { medicationRequests }) => {
      const validationResponse = getOnDemandValidationResponseFromParameters(parameters!)
      onSuccess?.({ validationResponse, medicationRequests })
    },
  })

  return { validateRefillOnDemand, isValidating: isPending }
}

type Props = {
  medicationRequests: string[]
}

export { useValidateRefillOnDemand }
