import { useCallback, type FC } from "react"

import { DialogFormContainer } from "commons"
import { useOrganizationContext } from "organization"

import { getInitialValues, sanitize } from "./validations"
import { RegularHours } from "./RegularHours"
import { useUpdatePractitionerRole } from "../../hooks"
import type { OperationHours } from "../../types"

const AvailabilityFormModal: FC<Props> = ({ onHide, visible }) => {
  const { loggedInPractitionerRole } = useOrganizationContext()
  const { updatePractitionerRole } = useUpdatePractitionerRole(onHide)

  const onSubmit = useCallback(
    (data: OperationHours) => updatePractitionerRole(sanitize(loggedInPractitionerRole, data)),
    [loggedInPractitionerRole],
  )

  return (
    <DialogFormContainer
      title="Available Hours"
      showForm={!!visible}
      initialValue={getInitialValues(loggedInPractitionerRole)}
      useFormik
      onSubmit={onSubmit}
      onCancel={onHide}
      saveLabel="Save"
    >
      <RegularHours />
    </DialogFormContainer>
  )
}

type Props = {
  visible?: boolean
  onHide(): void
}

export { AvailabilityFormModal }
