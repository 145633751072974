import { useQuery } from "@tanstack/react-query"
import { type Address, type Bundle, type ChargeItemDefinition, type List, type Parameters, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { cidSort, isPoBoxAddress } from "utils"
import { OVERNIGHT_FEE_CODES } from "commons/meds"

import { ordersQueryKeys } from "../query-keys"

const useExtrasPrices = (
  itemList: { resourceType: string; id: string }[],
  validateShippingAddresses?: { nutraAddress?: Address[]; medsAddress?: Address[] },
  enabled: boolean = true,
) => {
  const { operationRequest } = useClient()

  const queryKey = ordersQueryKeys.extrasPrices(itemList)

  const { data, isLoading, isPending } = useQuery({
    queryKey,
    queryFn: async () => {
      const list: List = {
        resourceType: "List",
        mode: "Working",
        status: "Current",
        entry: [],
      }
      itemList?.forEach(({ resourceType, id }) => {
        list.entry?.push({
          item: {
            resourceType,
            id,
          },
        })
      })

      const parameters: Parameters = {
        resourceType: "Parameters",
        parameter: [
          {
            name: "items",
            resource: list,
          },
        ],
      }
      const bundle = await operationRequest({
        endpoint: `ChargeItemDefinition`,
        method: "POST",
        operation: "extras-prices",
        parameters,
      })
      const result = bundle as Parameters

      let medsDiscounts: ChargeItemDefinition[] | undefined
      let nutraDiscounts: ChargeItemDefinition[] | undefined
      let labsDiscounts: ChargeItemDefinition[] | undefined
      let nutraShippingMethods: ChargeItemDefinition[] | undefined
      let medsShippingMethods: ChargeItemDefinition[] | undefined

      result.parameter?.forEach(({ resource, name }) => {
        if (name === "discounts-medication") {
          medsDiscounts = (getResources(resource as Bundle) as ChargeItemDefinition[]).sort((a, b) =>
            (a.propertyGroup?.[0].priceComponent?.[0].factor ?? 0) <
            (b.propertyGroup?.[0].priceComponent?.[0].factor ?? 0)
              ? -1
              : 1,
          )
        } else if (name === "discounts-nutraceutical") {
          nutraDiscounts = (getResources(resource as Bundle) as ChargeItemDefinition[]).sort((a, b) =>
            (a.propertyGroup?.[0].priceComponent?.[0].factor ?? 0) <
            (b.propertyGroup?.[0].priceComponent?.[0].factor ?? 0)
              ? -1
              : 1,
          )
        } else if (name === "discounts-lab") {
          labsDiscounts = (getResources(resource as Bundle) as ChargeItemDefinition[]).sort((a, b) =>
            (a.propertyGroup?.[0].priceComponent?.[0].factor ?? 0) <
            (b.propertyGroup?.[0].priceComponent?.[0].factor ?? 0)
              ? -1
              : 1,
          )
        } else if (name.includes("shipping-methods-nutraceutical")) {
          nutraShippingMethods = cidSort([...(getResources(resource as Bundle) as ChargeItemDefinition[])])
        } else if (["shipping-methods-medication", "services-medication"].includes(name)) {
          medsShippingMethods = cidSort([...(getResources(resource as Bundle) as ChargeItemDefinition[])])
        }
      })

      return { nutraShippingMethods, medsShippingMethods, nutraDiscounts, medsDiscounts, labsDiscounts }
    },
    enabled: !!itemList.length && enabled,
    refetchOnWindowFocus: false,
    meta: { context: { queryKey } },
  })

  const { nutraShippingMethods, medsShippingMethods } = useMemo(() => {
    const { nutraAddress, medsAddress } = validateShippingAddresses ?? {}
    let nutraShippingMethods = data?.nutraShippingMethods
    let medsShippingMethods = data?.medsShippingMethods

    if (nutraAddress?.some((add) => isPoBoxAddress(add))) {
      nutraShippingMethods = nutraShippingMethods?.filter(({ code }) =>
        code?.coding?.every(({ code }) => !OVERNIGHT_FEE_CODES.includes(code as string)),
      )
    }

    if (medsAddress?.some((add) => isPoBoxAddress(add))) {
      medsShippingMethods = medsShippingMethods?.filter(({ code }) =>
        code?.coding?.every(({ code }) => !OVERNIGHT_FEE_CODES.includes(code as string)),
      )
    }

    return { nutraShippingMethods, medsShippingMethods }
  }, [data, validateShippingAddresses])

  return {
    nutraShippingMethods,
    medsShippingMethods,
    medsDiscounts: data?.medsDiscounts,
    nutraDiscounts: data?.nutraDiscounts,
    labsDiscounts: data?.labsDiscounts,
    isLoadingExtrasPrices: isLoading,
    isPending,
  }
}

export { useExtrasPrices }
