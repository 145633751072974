import type { CodeableConcept, Reference } from "fhir"
import * as Yup from "yup"

import type { AllergyFormData } from "../types"

const getInitialValues = (patientRef: Reference, encounter?: Reference): AllergyFormData => ({
  name: "",
  code: { coding: undefined },
  clinicalStatus: undefined,
  type: undefined,
  category: undefined,
  criticality: undefined,
  recordedDate: new Date().toISOString(),
  lastOccurrence: new Date().toISOString(),
  onset: { dateTime: new Date().toISOString() },
  // recorder: asReference(practitioner as Practitioner) ?? undefined,
  patient: patientRef,
  note: [{ text: "" }],
  reaction: [
    {
      manifestation: [{ text: undefined }],
    },
  ],
  encounter,
  resourceType: "AllergyIntolerance",
})

const allergyValidationSchema = Yup.object().shape({
  name: Yup.string().test(
    "test-codeable-concept",
    "Either this or Code is required",
    (value: string | undefined, context) => {
      return Boolean(value || context.parent.code.coding)
    },
  ),
  code: Yup.object().test(
    "test-codeable-concept",
    "Either this or Name is required",
    (value: CodeableConcept, context) => {
      return Boolean(value.coding || context.parent.name)
    },
  ),
})

const sanitize = ({ ...allergy }: AllergyFormData) => {
  if (!allergy.note?.[0]?.text) delete allergy.note
  if (!allergy.encounter) delete allergy.encounter

  allergy.lastOccurrence = new Date(allergy.lastOccurrence as string).toISOString()
  allergy.onset = { dateTime: new Date(allergy.onset?.dateTime as string).toISOString() }

  if (allergy.code && allergy.name) {
    allergy.code.text = allergy.name
  }

  delete allergy.name

  return allergy
}

export { allergyValidationSchema, getInitialValues, sanitize }
