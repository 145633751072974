import { faTrashCan } from "@fortawesome/pro-regular-svg-icons"
import { type Duration, isCarePlan, isPatient } from "fhir"
import { Checkbox } from "primereact/checkbox"
import { Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { useMemo, useState } from "react"

import { Button, CardListItem } from "commons"
import {
  type DispenseIntervalOption,
  allDispenseInterval,
  RefillModeCodes,
  onDemandDispenseIntervalOption,
} from "commons/meds"
import { useOrganizationContext } from "organization"
import { getMoneyCurrencyAlt, strCapitalize, unitToDays } from "utils"

import type { CpoeRequest } from "../types"
import "./OrderListItem.css"

const OrderListItem = ({ medRequest, onChange, editable, isDeleting, readonly, onDelete }: Props) => {
  const { isExemptPaymentPellet } = useOrganizationContext()
  const dispenseTemplate = (option: DispenseIntervalOption) => `${strCapitalize(option?.label)}`
  const {
    medicationRequest,
    manufacturer,
    doseForm,
    packaging,
    isDfo,
    dispenseRequest,
    isProcedure,
    isMedication,
    maxDaysSupplyLimit,
  } = medRequest.medicationData ?? {}

  const planBased = medicationRequest?.basedOn?.some(isCarePlan) ?? false
  const isOnDemand = medicationRequest?.dispenseRequest?.refillMode?.coding?.[0]?.code === RefillModeCodes.ON_DEMAND
  const refills = medicationRequest?.dispenseRequest?.numberOfRepeatsAllowed

  const effectiveDispenseIntervals = useMemo(
    () =>
      isMedication
        ? allDispenseInterval.filter((dispenseIntervalOption) => {
            const specifiedDurationInDays =
              (dispenseIntervalOption?.value?.value as number) * unitToDays(dispenseIntervalOption?.value?.code)

            return (
              specifiedDurationInDays <= (maxDaysSupplyLimit || 0) &&
              specifiedDurationInDays * (dispenseRequest?.numberOfRepeatsAllowed || 1) <= (maxDaysSupplyLimit || 0)
            )
          })
        : allDispenseInterval,
    [dispenseRequest, isMedication, maxDaysSupplyLimit],
  )

  const [deletePressed, setDeletePressed] = useState(false)

  const head = (
    <div className="flex h-fit w-full items-center justify-between gap-1">
      <div className="flex flex-1 items-center gap-2">
        {editable && !readonly && (
          <Checkbox
            name="status"
            checked={medRequest.resource.code?.[0].coding?.[0].code === "activate"}
            onChange={(e) => onChange?.(e.target.name, !!e.checked)}
          />
        )}
        <div className="flex flex-1 flex-col">
          <div className="line-clamp-2 flex font-semibold text-wrap text-ellipsis text-gray-600">
            {medRequest.display}
          </div>
          <div className="line-clamp-1 space-x-1 divide-x divide-gray-200 text-sm text-ellipsis text-gray-400">
            {!!manufacturer && <span title="Manufacturer">{manufacturer}</span>}
            {!!doseForm && (
              <span title="Drug Form" className="pl-1 first:pl-0">
                {doseForm}
              </span>
            )}
            {!!packaging && (
              <span title="Packaging" className="pl-1 first:pl-0">
                Pkg: {packaging}
              </span>
            )}
            {!!isDfo && (
              <span title="Dispense from office" className="pl-1 first:pl-0">
                DFO
              </span>
            )}
          </div>

          {isPatient(medicationRequest?.recorder) && (
            <span className="truncate text-xs text-orange-700">Requested by patient</span>
          )}
        </div>
      </div>
      <div className="flex max-w-[50%] min-w-[35%] items-center justify-end gap-3">
        <div className="flex flex-wrap-reverse items-center justify-end gap-3">
          {!isDfo && (
            <div
              className={classNames("flex flex-col items-start text-gray-400", { "mr-6": isOnDemand && editable })}
              title={editable && !readonly && !isOnDemand ? "Click to change dispense interval" : "Dispense"}
            >
              <Dropdown
                name="dispenseInterval"
                options={isOnDemand ? [onDemandDispenseIntervalOption] : effectiveDispenseIntervals}
                optionLabel="label"
                optionValue="value"
                className="p-inputtext-sm order"
                valueTemplate={dispenseTemplate}
                value={isOnDemand ? onDemandDispenseIntervalOption.value : dispenseRequest?.dispenseInterval}
                disabled={!editable || readonly || isOnDemand}
                onChange={(e) => onChange?.(e.target.name, e.target.value)}
              />
              {!!refills && <span className="-mt-1 px-[0.65625rem] text-gray-500">{`Refills:  ${refills}`}</span>}
            </div>
          )}
          <span className="flex-1 text-right font-bold text-gray-600">
            {getMoneyCurrencyAlt(medRequest?.unitPrice?.currency)}
            {(isExemptPaymentPellet && isProcedure ? 0 : medRequest?.unitPrice?.value ?? 0).toFixed(2)}
          </span>
        </div>
        <div className={classNames("flex items-center justify-end", { "min-w-9": !readonly })}>
          {!!onDelete && !planBased && !readonly && (
            <Button
              icon={faTrashCan}
              loading={isDeleting && deletePressed}
              buttonStyle="text"
              onClick={() => {
                setDeletePressed(true)
                onDelete(medRequest.resource.resource?.id as string)
              }}
              title="Delete"
            />
          )}
        </div>
      </div>
    </div>
  )

  return <CardListItem className="order-item !border-none px-2" contentHeader={head} />
}

type Props = {
  medRequest: CpoeRequest
  onChange?(field: string, value: boolean | Duration): void
  editable: boolean
  onDelete?(itemId: string): void
  isDeleting?: boolean
  readonly?: boolean
}

export { OrderListItem }
