import { type FieldProps } from "formik"
import type { FC } from "react"
import { Dropdown, type DropdownProps } from "primereact/dropdown"
import type { Reference } from "fhir"
import { classNames } from "primereact/utils"

import { FormField } from "./FormField"

const PractitionerReferenceDropdownField: FC<Props> = ({ field, options, label }) => {
  return <ReferenceDropdownField label={label} field={field} options={options} />
}

const ReferenceDropdownField: FC<Props> = ({
  field,
  options,
  label,
  className,
  horizontal,
  inputClassName,
  optionLabel = "display",
  readonly,
  disabled,
  filterBy = "display",
  allowClear = false,
  isLoading,
  validation,
  ...dropdownProps
}) => (
  <FormField field={field} validation={validation} label={label} horizontal={horizontal} className={className}>
    {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
      <Dropdown
        id={name}
        name={name}
        optionLabel={optionLabel}
        options={options}
        filterBy={filterBy}
        resetFilterOnHide={true}
        onChange={onChange}
        value={value}
        showClear={allowClear && !isLoading}
        readOnly={readonly}
        disabled={disabled}
        dataKey="id"
        className={classNames(
          "p-inputtext-sm",
          {
            "p-invalid": touched && error,
            horizontal,
          },
          inputClassName,
        )}
        loading={isLoading}
        {...dropdownProps}
      />
    )}
  </FormField>
)

type Props = {
  field: string
  options?: Reference[]
  label?: string
  className?: string
  filterBy?: string
  horizontal?: boolean
  inputClassName?: string
  optionLabel?: string
  readonly?: boolean
  disabled?: boolean
  allowClear?: boolean
  isLoading?: boolean
  validation?(value: Reference): void
} & DropdownProps

export { PractitionerReferenceDropdownField }
