import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faSearch } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { ResourceObject } from "fhir"
import type { FormikValues } from "formik"
import pluralize from "pluralize"

import { SkeletonLoader } from "./SkeletonLoader"
import { StackedListContainer } from "./StackedListContainer"
import type { StackedListItemProps } from "./StackedListItem"

const KnowledgeListContainer = <T extends ResourceObject | FormikValues>({
  dataTitle,
  data,
  iconDataNotFound = faSearch,
  itemModelBuilder,
  isLoading,
  customEmptyMessage,
}: Props<T>) => (
  <>
    {isLoading ? (
      <SkeletonLoader repeats={4} loaderType="two-lines" />
    ) : (
      <div className="flex flex-1 flex-col overflow-y-auto bg-white">
        {data && (data.length ?? 0) > 0 ? (
          <StackedListContainer data={data} className="px-5" itemModelBuilder={itemModelBuilder} />
        ) : (
          <div className="m-auto text-center">
            <FontAwesomeIcon icon={iconDataNotFound} size="2x" className="text-slate-400" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              {customEmptyMessage ?? `No ${pluralize(dataTitle?.toLowerCase() ?? "item", 2)} found`}
            </h3>
          </div>
        )}
      </div>
    )}
  </>
)

type Props<T> = {
  dataTitle?: string
  data: T[] | undefined
  itemModelBuilder(item: T, itemIndex: number): StackedListItemProps
  iconDataNotFound?: IconDefinition
  isLoading?: boolean
  customEmptyMessage?: string
}

export { KnowledgeListContainer }
