import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { type Invoice, isCarePlan, type MedicationKnowledge, type MedicationRequest, type Reference } from "fhir"
import { classNames } from "primereact/utils"
import { type FC, Fragment, useState } from "react"

import { useChartContext } from "chart-view"
import { ModulesId, Notes, NotesUseContext, StackedListItem, type EditProps } from "commons"
import {
  MedicationKnowledgeDetails,
  type MedicationRequestData,
  medsQueryKeys,
  useMedicationRequests,
  useMrOrdersResources,
} from "commons/meds"
import { prescriptionItemModel, useMedicationRequestDataBind } from "eprescribe"
import { SYSTEM_VALUES } from "system-values"

import { useEncounterContext } from "../../hooks"
import { OrderItem } from "./OrderItem"
import { WrapUpSection } from "./WrapUpSection"

const MedsSection: FC<Props> = ({ patientId, encounterId, icon }) => {
  const { showModule } = useChartContext()

  const [previewItem, setPreviewItem] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()

  const { mrOrderData, isLoading, total } = useMrOrdersResources({
    patientId,
    subcategory: "medication-order",
    encounter: encounterId,
    statusFilter: ["active", "completed"],
  })

  const {
    medicationRequests: draftMedicationsRequests = [],
    medicationKnowledges: draftMedicationKnowledges = {},
    medicationDispenses: draftMedicationsDispenses = [],
    isLoading: isLoadingDraftMedications,
    total: totalDraftMedications,
  } = useMedicationRequests({
    patientId: patientId as string,
    category: "medication",
    statusFilter: ["draft"],
    perPage: 100,
    page: 1,
    encounter: encounterId,
  })

  const { planRefs } = useEncounterContext()

  const goToOrderDetails = (order: string) => {
    showModule({
      module: ModulesId.EPRESCRIBE,
      moduleParams: { order },
    })
  }

  const { medicationRequestData } = useMedicationRequestDataBind({
    medicationRequests: [...draftMedicationsRequests],
    medicationKnowledges: { ...draftMedicationKnowledges },
    medicationDispenses: [...draftMedicationsDispenses],
  })

  const renderDetails = ({
    className,
    medicationRequestData,
    editProps,
  }: {
    medicationRequestData?: MedicationRequestData[]
    className?: string
    editProps?: Omit<EditProps, "parentId" | "context">
  }) => (
    <>
      {!!medicationRequestData?.length && (
        <div className={classNames("mb-2 flex flex-col text-sm", className)}>
          {medicationRequestData.map((item) => {
            const cp = planRefs?.[item.medicationRequestInfo.basedOn?.find(isCarePlan)?.id as string]
            const mr = item.medicationRequestInfo
            const notes = mr?.note

            return (
              <Fragment key={item.medicationRequestInfo.id}>
                <StackedListItem
                  className="py-4 !pl-0"
                  modelData={{
                    ...prescriptionItemModel({
                      mrData: item,
                      preview: ({ medicationKnowledge, medicationRequestInfo }) =>
                        setPreviewItem({ mk: medicationKnowledge, mr: medicationRequestInfo }),
                    }),
                    ...(cp
                      ? {
                          badge: {
                            text: cp.display ?? "Plan based",
                            colorStyle: "gray",
                            title: cp.display,
                            className: "truncate max-w-24 !block",
                          },
                        }
                      : { badge: undefined }),
                  }}
                />
                <Notes
                  notes={notes}
                  editProps={{ ...(editProps as EditProps), parentId: mr?.id as string, context: NotesUseContext.RX }}
                  className={className}
                />
              </Fragment>
            )
          })}
        </div>
      )}
    </>
  )

  const renderOrderItem = ({
    id,
    title,
    date,
    requester,
    status,
    invoice,
    medicationRequestData,
    editProps,
    showOrderDetails,
    customData,
  }: {
    id?: string
    title: string
    date?: Date
    requester?: Reference
    status?: string
    invoice?: Invoice
    medicationRequestData: MedicationRequestData[]
    editProps?: Omit<EditProps, "parentId" | "context">
    showOrderDetails?: boolean
    customData?: {
      label: string
      value: string
    }[]
  }) => (
    <OrderItem
      key={id}
      requester={requester}
      status={status}
      invoice={invoice}
      title={title}
      date={date}
      seeOrderDetails={showOrderDetails ? () => id && goToOrderDetails(id) : undefined}
      showOrderDetails={showOrderDetails}
      className="first:mt-6"
      customData={customData}
    >
      {renderDetails({
        medicationRequestData,
        className: "text-sm pr-2",
        editProps,
      })}
    </OrderItem>
  )

  return (
    <WrapUpSection
      sectionTitle="Pharmaceuticals"
      isLoading={isLoading || isLoadingDraftMedications}
      isEmpty={!total && !totalDraftMedications}
      icon={icon}
      emptyMesage="No pharmaceutical's orders placed during this encounter"
      className="w-full gap-6 self-end pb-2"
    >
      {draftMedicationsRequests?.map(({ id, authoredOn }, index) =>
        renderOrderItem({
          id,
          title: index === 0 ? "Pending for checkout" : "",
          date: authoredOn ? new Date(authoredOn) : undefined,
          medicationRequestData: medicationRequestData.filter(
            ({ medicationRequestInfo }) => medicationRequestInfo?.id === id,
          ),
          editProps: id
            ? {
                queriesToInvalidate: medsQueryKeys.medicationRequestList(
                  patientId,
                  "medication",
                  ["draft"],
                  undefined,
                  100,
                  1,
                  encounterId,
                ),
                notShowFullDataNotFound: true,
              }
            : undefined,
          showOrderDetails: false,
        }),
      )}

      {mrOrderData?.map(({ serviceRequest: sr, invoices, medications }) => {
        const lifeFileId = sr?.identifier?.find(({ system }) => system === SYSTEM_VALUES.LIFEFILE_MEDICATION_ORDER)
        const medOrderIdentifier = sr?.identifier?.find(({ system }) => system === SYSTEM_VALUES.MEDICATION_ORDER)

        return renderOrderItem({
          id: sr?.id,
          requester: sr?.requester,
          status: sr?.status,
          invoice: invoices?.[0],
          title: medOrderIdentifier?.value ?? "Unspecified number",
          date: sr?.authoredOn ? new Date(sr.authoredOn) : undefined,
          medicationRequestData: medications,
          editProps: sr?.id
            ? {
                queriesToInvalidate: medsQueryKeys.ordersResources(
                  patientId,
                  "medication-order",
                  ["active", "completed"],
                  undefined,
                  encounterId,
                ),
                notShowFullDataNotFound: true,
              }
            : undefined,
          customData: lifeFileId?.value ? [{ label: "LifeFile ID", value: lifeFileId.value }] : undefined,
          showOrderDetails: true,
        })
      })}
      <MedicationKnowledgeDetails
        selectedMK={previewItem?.mk}
        onHide={() => setPreviewItem(undefined)}
        mr={previewItem?.mr}
        showImgFallback={false}
      />
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounterId: string
  icon: IconDefinition
}

export { MedsSection }
