import { faExternalLink } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "primereact/button"
import type { FC, PropsWithChildren } from "react"
import type { AnyObject } from "yup/lib/types"

import { StackedListContainer } from "../../../../components/StackedListContainer"
import type { StackedListItemProps } from "../../../../components/StackedListItem"

const IntakeDataSection = <T extends AnyObject>({
  title,
  className,
  isLinkVisible,
  data,
  children,
  modelBuilder,
  onLinkClicked,
}: PropsWithChildren<Props<T>>) => {
  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <h2 className="text-gray-900">{title}</h2>
        {!!onLinkClicked && <ExternalLinkButton onClick={onLinkClicked} isVisible={isLinkVisible} />}
      </div>
      {children}
      <StackedListContainer data={data} itemModelBuilder={(item) => modelBuilder(item)} />
    </div>
  )
}

const ExternalLinkButton: FC<ExternalLinkProps> = ({ onClick, isVisible }) =>
  isVisible && (
    <Button
      className="close-left-panel p-button-text"
      icon={<FontAwesomeIcon className="fa-fw" icon={faExternalLink} />}
      onClick={onClick}
    />
  )

type ExternalLinkProps = {
  onClick: () => void
  isVisible?: boolean
}

type Props<T> = {
  title: string
  className?: string
  data: T[]
  onLinkClicked?(): void
  isLinkVisible: boolean
  modelBuilder(item: T): StackedListItemProps
}

export { IntakeDataSection }
