import { type Device, asReference } from "fhir"

import { DataContainerSlideoverForm, StackedListContainer, useCrudReducer } from "commons"
import { DeviceForm, deviceModel, getDeviceInitialValues, useCreateUpdateDevice } from "commons/readers"
import { useOrganizationContext } from "organization"

import { useDeleteDevice } from "../hooks"

const Devices = () => {
  const { devices, currentOrganization } = useOrganizationContext()
  const { showSlide, initialValue, deleteIndex, add, edit, setDeleteIndex, reset } = useCrudReducer({
    defaultEntity: getDeviceInitialValues(asReference(currentOrganization)),
  })

  const { createOrUpdateDevice } = useCreateUpdateDevice({ onSuccess: reset })
  const { removeDevice, isDeleting } = useDeleteDevice()

  const onSubmit = (device: Device) => createOrUpdateDevice(device)

  return (
    <DataContainerSlideoverForm
      formTitle="Device"
      height="calc(100% - 8rem)"
      showSlide={showSlide}
      hasData={devices.length > 0}
      onButtonAddClick={add}
      formInitialValue={initialValue}
      form={<DeviceForm />}
      onSubmit={onSubmit}
      onCancel={reset}
      deleteSupport={{
        showDelete: !!deleteIndex,
        isDeleting,
        onConfirmDelete: () => removeDevice(deleteIndex as string),
        onCancelDelete: () => setDeleteIndex(undefined),
      }}
    >
      <div className="h-full overflow-auto bg-white">
        <StackedListContainer
          data={devices}
          itemPadding
          itemModelBuilder={(item) =>
            deviceModel({
              device: item,
              onEdit: () => edit(item),
              onDelete: () => setDeleteIndex(item.id as string),
            })
          }
        />
      </div>
    </DataContainerSlideoverForm>
  )
}

export { Devices }
