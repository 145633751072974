import {
  faCalendarClock,
  faCalendarPen,
  faCheck,
  faClockRotateLeft,
  faInfoCircle,
  faPencil,
  faStop,
  faTrashCan,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns"
import {
  type MedicationRequestMedication,
  type ResourceObject,
  codeableConceptAsString,
  isCarePlan,
  type MedicationKnowledge,
} from "fhir"
import pluralize from "pluralize"

import { type StackedListItemProps, MenuStyles } from "commons"
import { getMRDispenseInfo, type MedicationRequestData } from "commons/meds"
import { formatsByTypes } from "data"
import { SYSTEM_VALUES } from "system-values"
import { getBadgeColor, getDateLabel, getMRStatus, getMoneyCurrencyAlt, strCapitalize } from "utils"

import type { MedicationRequestFormData, MedicationRequestInfo } from "../../types"
import {
  getDispenseFrequency,
  getMedicationFormData,
  isOnDemandMRData,
  isOneTimeMRData,
} from "../../utils/transformers"

export const prescriptionItemModel = ({
  mrData,
  edit,
  externalAction,
  discard,
  stop,
  complete,
  reschedule,
  showDetails,
  preview,
  showStatusBadge = true,
  showAsExternalAction = false,
  loading,
  hiddenPrices,
  renew,
  allowEdit,
  restore,
  hideOrganizationPrice = false,
  refillOn,
}: {
  mrData: MedicationRequestData
  edit?: (mrData: MedicationRequestFormData) => void
  discard?: (mrId: string) => void
  stop?: (mrId: string) => void
  complete?: (mrId: string) => void
  reschedule?: (mrId: string) => void
  refillOn?: (mrId: string) => void
  preview?: (mrData: MedicationRequestData) => void
  showDetails?: (mr: MedicationRequestInfo) => void
  renew?: (mr: MedicationRequestInfo, mk?: MedicationKnowledge) => void
  externalAction?: StackedListItemProps["menu"]
  showStatusBadge?: boolean
  showAsExternalAction?: boolean
  loading?: boolean
  hiddenPrices?: boolean
  allowEdit?: boolean
  restore?: (mrId: string) => void
  hideOrganizationPrice?: boolean
}): StackedListItemProps => {
  const { medicationRequestInfo, medicationDispense, medicationKnowledge, patientPrice, practicePrice } = mrData

  const prescriptionsStatus = getMRStatus(medicationRequestInfo)

  const planBased = medicationRequestInfo?.basedOn?.some(isCarePlan) ?? false
  const disabledAction = planBased || prescriptionsStatus !== "draft"
  const isStopped = prescriptionsStatus === "stopped"
  const isCompleted = prescriptionsStatus === "completed"
  const enableRenew = !planBased && (isCompleted || isStopped) && medicationKnowledge?.status === "active"
  const enabledRefillOn = prescriptionsStatus === "active" && isOnDemandMRData(mrData)

  const isWriteIn = medicationRequestInfo.medication?.Reference?.localRef !== undefined
  const medication =
    medicationRequestInfo?.medication?.Reference?.localRef && medicationRequestInfo?.contained
      ? (medicationRequestInfo?.contained?.find(
          (resource) => (resource as ResourceObject)?.id === medicationRequestInfo?.medication?.Reference?.localRef,
        ) as MedicationRequestMedication)
      : (medicationRequestInfo?.medication as MedicationRequestMedication)
  const lfID = medicationKnowledge?.code?.coding?.find((c) => c.system === SYSTEM_VALUES.LIFEFILE_MEDICATION)?.code
  const ndc = medicationKnowledge?.code?.coding?.find((c) => c.system === SYSTEM_VALUES.MEDICATION_NDC)?.code

  const dispenseRequest = medicationRequestInfo?.dispenseRequest
  const { refills, refillsLeft, nextRefill /* , isDfo */ } = getMRDispenseInfo(
    medicationRequestInfo.status,
    dispenseRequest,
    medicationDispense,
  )
  const dispense = getDispenseFrequency(dispenseRequest?.dispenseInterval)?.label
  const createdAt = medicationRequestInfo.meta?.createdAt
  const refillMode =
    medicationRequestInfo.dispenseRequest?.refillMode &&
    codeableConceptAsString(medicationRequestInfo.dispenseRequest?.refillMode)
  const isOneTimeRefillMode = isOneTimeMRData(mrData)

  return {
    leftData: [
      {
        lineItems: [
          {
            component: (
              <div className="flex items-baseline gap-2">
                <span
                  className={preview && "cursor-pointer"}
                  onClick={(e) => {
                    e.stopPropagation()
                    preview?.(mrData)
                  }}
                >
                  {medicationKnowledge
                    ? strCapitalize(codeableConceptAsString(medicationKnowledge?.code) as string)
                    : strCapitalize(codeableConceptAsString(medication?.CodeableConcept)) ?? "Unspecified"}
                </span>
                {medicationKnowledge?.ingredient?.length === 1 &&
                  medicationKnowledge?.ingredient[0]?.strength?.numerator?.unit && (
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap text-gray-500">
                      {medicationKnowledge?.ingredient[0].strength.numerator.unit}
                    </span>
                  )}
              </div>
            ),
          },
        ],
      },
      {
        withVerticalDivider: true,
        lineItems: [
          ...(medicationKnowledge?.manufacturer?.display
            ? [
                {
                  name: "Manufacturer",
                  value: `Pharmacy: ${medicationKnowledge.manufacturer.display.replace("Farmakeio", "FarmaKeio")}`,
                },
              ]
            : []),
          ...(medicationKnowledge?.doseForm?.coding?.[0]?.display
            ? [
                {
                  name: "Form",
                  value: `Drug Form: ${medicationKnowledge?.doseForm?.coding?.[0]?.display}`,
                },
              ]
            : []),
          ...(medicationKnowledge?.amount?.value && medicationKnowledge?.amount?.unit
            ? [
                {
                  name: "Amount",
                  value: `Amount: ${medicationKnowledge?.amount?.value} ${medicationKnowledge?.amount?.unit}`,
                },
              ]
            : []),
          ...(medicationKnowledge?.packaging?.type?.coding?.[0]?.display
            ? [
                {
                  name: "Packaging",
                  value: `Pkg: ${medicationKnowledge?.packaging?.type?.coding?.[0]?.display}`,
                },
              ]
            : []),
          ...(lfID
            ? [
                {
                  name: "ID",
                  value: `ID: ${lfID}`,
                },
              ]
            : []),
          ...(ndc
            ? [
                {
                  name: "NDC",
                  value: `NDC: ${ndc}`,
                },
              ]
            : []),
          ...(isWriteIn
            ? [
                {
                  name: "Type",
                  value: "Write-in",
                },
              ]
            : []),
        ],
      },
      {
        withVerticalDivider: true,
        lineItems: [
          {
            name: "Quantity",
            value: `Quantity: ${dispenseRequest?.quantity?.value} ${
              dispenseRequest?.quantity?.unit ?? pluralize("unit", dispenseRequest?.quantity?.value)
            }`,
          },
          ...(dispense
            ? [
                {
                  name: "Dispense",
                  value: `Dispense: ${dispense}`,
                },
              ]
            : []),
          {
            name: "Refills",
            value: `Refills: ${refills} ${pluralize("refill", refills)}`,
          },
          ...(nextRefill
            ? [
                {
                  name: "Next Refill",
                  value: `Next Refill: after ${getDateLabel(nextRefill, formatsByTypes.LONG_DATE)}`,
                },
              ]
            : []),
          ...(createdAt
            ? [
                {
                  name: "Created",
                  value: `Created: ${format(createdAt, formatsByTypes.LONG_DATE)}`,
                },
              ]
            : []),
        ],
      },
      {
        withVerticalDivider: true,
        lineItems: [
          ...(!hiddenPrices && !hideOrganizationPrice && practicePrice
            ? [
                {
                  name: "Practice Price",
                  value: `Practice Price: ${getMoneyCurrencyAlt(practicePrice?.currency)}${practicePrice?.value?.toFixed(2)}`,
                },
              ]
            : []),
          ...(!hiddenPrices && patientPrice
            ? [
                {
                  name: hideOrganizationPrice ? "Price" : "Patient Price",
                  value: `${hideOrganizationPrice ? "Price" : "Patient Price"}: ${getMoneyCurrencyAlt(patientPrice?.currency)}${patientPrice?.value?.toFixed(2)}`,
                },
              ]
            : []),
        ],
      },
    ],
    onClick: showDetails ? () => showDetails(medicationRequestInfo) : undefined,
    ...(refillMode
      ? {
          leadingRightElement: (
            <div className="text-xs shrink-0 flex flex-col gap-2" title="Refill Mode">
              <span>{refillMode}</span>
              {!isOneTimeRefillMode && <span className="text-gray-500">{`Refills left:  ${refillsLeft}`}</span>}
            </div>
          ),
        }
      : {}),
    badge: showStatusBadge ? getBadgeColor(prescriptionsStatus) : undefined,
    menuStyle: showAsExternalAction ? MenuStyles.ExternalAction : undefined,
    isLoading: loading,
    menu:
      renew || edit || discard || stop || complete || restore || externalAction?.length
        ? [
            ...(externalAction ?? []),
            ...(showDetails
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faInfoCircle} />,
                    label: "Show details",
                    command: () => showDetails(medicationRequestInfo),
                  },
                ]
              : []),
            ...(renew
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faClockRotateLeft} />,
                    label: "Reorder",
                    command: () => renew(medicationRequestInfo, medicationKnowledge),
                    disabled: !enableRenew,
                  },
                ]
              : []),
            ...(refillOn
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faCalendarClock} />,
                    label: "Refill-On",
                    command: () => refillOn(medicationRequestInfo.id ?? ""),
                    disabled: !enabledRefillOn,
                  },
                ]
              : []),
            ...(edit
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faPencil} />,
                    label: "Edit",
                    command: () => edit(getMedicationFormData(mrData)),
                    disabled: !allowEdit && disabledAction,
                  },
                ]
              : []),
            ...(stop
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faStop} />,
                    label: "Stop",
                    command: () => stop(medicationRequestInfo.id ?? ""),
                    disabled: isStopped || planBased || isCompleted || prescriptionsStatus === "draft",
                  },
                ]
              : []),
            ...(complete
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faCheck} />,
                    label: "Complete",
                    command: () => complete(medicationRequestInfo.id ?? ""),
                    disabled: isCompleted || planBased || isStopped || prescriptionsStatus === "draft",
                  },
                ]
              : []),
            ...(discard
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faTrashCan} />,
                    label: "Discard",
                    command: () => discard(medicationRequestInfo.id ?? ""),
                    disabled: !allowEdit && disabledAction,
                  },
                ]
              : []),
            ...(reschedule
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faCalendarPen} />,
                    label: "Reschedule",
                    command: () => reschedule(medicationRequestInfo.id ?? ""),
                    disabled: !["on-hold", "active"].includes(prescriptionsStatus),
                  },
                ]
              : []),
            ...(restore
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faClockRotateLeft} />,
                    label: "Restore",
                    command: () => restore(medicationRequestInfo.id ?? ""),
                    disabled: !allowEdit && disabledAction,
                  },
                ]
              : []),
          ]
        : undefined,
  }
}
