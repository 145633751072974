import { type FC, useCallback } from "react"
import type { Account, Device } from "fhir"

import { useReaders } from "commons/readers"
import { useOrganizationContext } from "organization"
import { CardSelectionItem, SkeletonLoader } from "commons"

const OrderReadersContainer: FC<Props> = ({ readerAccount, updateReader }) => {
  const { currentOrganizationId, currentOrganizationReaderAccounts, cardReadersEnabled } = useOrganizationContext()
  const {
    readers,
    locationsByReader,
    isLoading: isLoadingReaders,
  } = useReaders(currentOrganizationId, undefined, cardReadersEnabled)

  const getReaderAccount = useCallback(
    (readerDevice: Device) =>
      currentOrganizationReaderAccounts?.find(({ subject }) => subject?.[0]?.id === readerDevice.id),
    [currentOrganizationReaderAccounts],
  )

  const getReaderSubtext = (readerId: string) => {
    const readerLocation = locationsByReader?.[readerId]?.name
    return [...(readerLocation ? [readerLocation] : [])]
  }

  if (isLoadingReaders) return <SkeletonLoader repeats={1} loaderType="two-lines" />
  if (!readers?.length || !cardReadersEnabled) return null

  return (
    <>
      <h4 className="flex items-center justify-between text-sm font-medium text-gray-700">
        Readers
        <hr className="ml-3 flex-1 border" />
      </h4>
      <div className="mt-5 flex flex-wrap gap-3 pr-2 pb-3 pl-6 text-sm">
        {readers?.map((device, index) => (
          <CardSelectionItem
            key={device.id ?? index}
            onSelect={() => updateReader(getReaderAccount(device))}
            mainText={device.deviceName?.[0]?.name as string}
            subTexts={getReaderSubtext(device.id ?? "")}
            clickableArea="card"
            showSelectedMark
            headerClassName="justify-between w-full"
            selected={device.id === readerAccount?.subject?.[0]?.id}
            className="min-w-60"
          />
        ))}
      </div>
    </>
  )
}

type Props = {
  readerAccount?: Account
  updateReader(account: Account | undefined): void
}

export { OrderReadersContainer }
