import type { FC } from "react"
import { Message } from "primereact/message"

import type { MedicationRequestFormData } from "../../types"
import { getMKMaxDaysSupply } from "../../utils/transformers"
import { exceedsDaysSupplyLimit } from "../../utils/validations"

const PrescriptionSupplyLimitWarningMessage: FC<Props> = ({ medicationRequestFormData }) => {
  const maxDaysSupply = getMKMaxDaysSupply(medicationRequestFormData.medicationField)?.value
  const exceedsLimit = exceedsDaysSupplyLimit(medicationRequestFormData)

  if (!exceedsLimit) return null

  return (
    <Message
      className="w-full justify-start text-sm"
      severity="warn"
      text={`Prescriptions are limited to a ${maxDaysSupply} Days Supply. If this script is for more than ${maxDaysSupply} days it will be rejected by the pharmacy as invalid.`}
    />
  )
}

type Props = {
  medicationRequestFormData: MedicationRequestFormData
}

export { PrescriptionSupplyLimitWarningMessage }
