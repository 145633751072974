import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { asReference, type Coverage, type Reference } from "fhir"
import { type FieldProps, useFormikContext } from "formik"
import { Dialog } from "primereact/dialog"
import { type FC, type PropsWithChildren, useCallback, useState } from "react"

import {
  CoverageDetail,
  CoverageForm,
  coverageModelBuilder,
  coverageValidationSchema,
  getCoverageInitialValues,
  sanitizeCoverage,
  useCoverages,
  useCreateCoverage,
} from "coverage"
import type { CoverageData } from "coverage/types"
import { usePatientContext } from "patients"

import { Button } from "../../components/Buttons"
import { ReplaceFormProvider } from "../../context"
import { DialogFormContainer } from "../DialogFormContainer"
import { FormField, type FormFieldProps } from "../FormField"
import { StackedListItem } from "commons/components/StackedListItem"

enum OperationType {
  "Details",
  "Edit",
}

const MAX_INSURANCE_ALLOWED = 3

const InsuranceField: FC<PropsWithChildren<Props>> = ({
  field,
  showEdit = false,
  horizontal,
  validation,
  className,
  labelClassName,
  children,
  setInsuranceBilling,
  ...formFieldProps
}) => {
  const { patientId } = usePatientContext()
  const { coverages, isLoading } = useCoverages(patientId)
  const [itemOperation, setItemOperation] = useState<{ item: Coverage; operation: OperationType } | undefined>(
    undefined,
  )
  const [showAdd, setShowAdd] = useState(false)

  const handleHideOperation = () => {
    setItemOperation(undefined)
    setShowAdd(false)
  }

  const { setFieldValue, getFieldMeta } = useFormikContext()

  const handleAutoselectInsurance = useCallback(
    (newInsurance: Coverage) => {
      if (newInsurance.id) {
        const currentInsurances = (getFieldMeta(field).value as Reference[]) ?? []
        if (currentInsurances.length < MAX_INSURANCE_ALLOWED)
          setFieldValue(field, [...currentInsurances, asReference(newInsurance)]).finally(() => {
            if (!currentInsurances.length) setInsuranceBilling(true)
          })
      }
    },
    [getFieldMeta(field).value],
  )

  const { createCoverage } = useCreateCoverage(handleHideOperation, handleAutoselectInsurance)

  const hanldeFormSubmit = (coverage: CoverageData) => {
    createCoverage(sanitizeCoverage(coverage))
  }

  return (
    <>
      <FormField
        field={field}
        validation={validation}
        horizontal={horizontal}
        className={className}
        labelClassName={labelClassName}
        {...formFieldProps}
      >
        {({ field: { name, value }, form: { setFieldValue } }: FieldProps) => (
          <>
            <div className="inline-flex w-full items-center justify-between">
              {children}
              <Button
                buttonStyle="default"
                icon={faPlus}
                title="Add new insurance"
                onClick={() => {
                  setShowAdd(true)
                }}
                loading={isLoading}
              />
            </div>
            <div className="text-n mt-3 grid w-full grid-flow-row-dense gap-x-1 overflow-hidden border-t-2 @3xl:grid-cols-2">
              {!coverages?.length ? (
                <span className="mt-3 text-sm text-gray-400">No insurance policies available</span>
              ) : (
                coverages?.map((coverage, index) => {
                  if (!Array.isArray(value) && value !== undefined) return
                  const isChecked =
                    (value as Reference[] | undefined)?.some(({ id }) => Boolean(id) && id === coverage.id) ?? false
                  const disabledByMaximumAllowed = (value as Reference[] | undefined)?.length === MAX_INSURANCE_ALLOWED
                  const onCheck = (checked: boolean) => {
                    const effectiveCoverages =
                      (value as Reference[] | undefined)?.filter(({ id }) => Boolean(id) && id !== coverage.id) ?? []

                    setFieldValue(name, [
                      ...effectiveCoverages,
                      ...(checked && coverage.id ? [asReference(coverage)] : []),
                    ]).finally(() => {
                      if (effectiveCoverages.length === 0) setInsuranceBilling(checked)
                    })
                  }
                  return (
                    <StackedListItem
                      key={`${coverage.id}_${index}`}
                      checked={isChecked}
                      modelData={coverageModelBuilder({
                        coverage,
                        onlyAddressDetail: true,
                        hideBadge: true,
                        onEdit: showEdit
                          ? () => setItemOperation({ item: coverage, operation: OperationType.Edit })
                          : undefined,
                        lineItemClassname: "first:font-medium first:!text-black",
                      })}
                      checkable
                      onCheck={onCheck}
                      disabled={isLoading || (disabledByMaximumAllowed && !isChecked)}
                    />
                  )
                })
              )}
            </div>
          </>
        )}
      </FormField>

      {itemOperation?.operation === OperationType.Details ? (
        <Dialog
          header="Coverage Details"
          visible
          closable={true}
          draggable={false}
          resizable={false}
          onHide={handleHideOperation}
          footer={
            <div className="mt-2">
              <Button label="Close" buttonStyle="default" size="xl" className="m-0" onClick={handleHideOperation} />
            </div>
          }
        >
          <CoverageDetail coverage={itemOperation.item} />
        </Dialog>
      ) : (
        (itemOperation?.operation === OperationType.Edit || showAdd) && (
          <ReplaceFormProvider>
            <DialogFormContainer
              title={showAdd ? "Add Coverage Information" : "Edit Coverage Information"}
              onSubmit={hanldeFormSubmit}
              initialValue={showAdd ? getCoverageInitialValues(patientId) : itemOperation?.item}
              showForm
              useFormik
              onCancel={handleHideOperation}
              validationSchema={coverageValidationSchema}
            >
              <CoverageForm />
            </DialogFormContainer>
          </ReplaceFormProvider>
        )
      )}
    </>
  )
}

type Props = {
  field: string
  validation?(value: Reference[] | undefined): string | undefined
  showEdit?: boolean
  horizontal?: boolean
  labelClassName?: string
  setInsuranceBilling(isInsurance: boolean): void
} & Omit<FormFieldProps, "children" | "label">

export { InsuranceField }
