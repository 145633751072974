import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"

import { ModulesId } from "../../../Module"
import { AppointmentHistoryContainer } from "./AppointmentHistoryContainer"
import { SelectedCPDetails } from "./SelectedCPDetails"

const PlanDetails = () => {
  const chartContext = useChartContext()
  const [searchParams] = useSearchParams()

  const planId = searchParams.get("planId") as string

  const [selectedPlanId, setSelectedPlanId] = useState(planId)

  useEffect(() => {
    chartContext.setSearchData({ showSearch: false })
  }, [])

  const showOrder = (srId: string) => {
    chartContext.showModule({ module: ModulesId.LABSORDER, moduleParams: { order: srId } })
  }

  const showProcedureCalculator = (procedureId?: string) => {
    chartContext.showModule({
      module: ModulesId.PROCEDURES,
      keepOrigin: true,
      ...(procedureId ? { moduleParams: { procedureId } } : {}),
    })
  }

  const showAssessment = (mcPlanId: string) => {
    chartContext.showModule({ module: ModulesId.MC, moduleParams: { mcPlanId } })
  }

  return (
    <div className="relative flex flex-1 overflow-hidden">
      <SelectedCPDetails {...{ selectedPlanId, showOrder, showProcedureCalculator, showAssessment }} />
      <AppointmentHistoryContainer {...{ planId, selectedPlanId, setSelectedPlanId }} />
    </div>
  )
}

export { PlanDetails }
