import { faEllipsisVertical, faExternalLink, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Menu } from "primereact/menu"
import type { MenuItem } from "primereact/menuitem"
import { classNames } from "primereact/utils"
import { useRef } from "react"

const ListItem = ({
  rowImg,
  chip,
  header,
  subHeader,
  dropdownMenuItems,
  actionItem,
  actionsClassName,
  onContentClick,
  externalAction,
  itemPadding = false,
  rowHover = true,
  isLoading = false,
  className,
}: Props) => {
  const menu = useRef<Menu>(null)

  // Preference order dropdown, action, external-link
  const RenderLinks = () => {
    if (isLoading) {
      return <FontAwesomeIcon icon={faSpinner} spin className="mr-1 text-slate-400" size="sm" />
    }

    if (dropdownMenuItems) {
      return (
        <div>
          <span
            className="mr-2 flex h-8 w-8 cursor-pointer items-center justify-center overflow-hidden rounded-full text-2xl font-bold text-slate-400 hover:bg-slate-100"
            aria-controls="popup_menu"
            aria-haspopup
            onClick={(event) => {
              menu.current && menu.current.toggle(event)
            }}
          >
            <FontAwesomeIcon icon={faEllipsisVertical} size="sm" />
          </span>
          <Menu model={dropdownMenuItems} popup ref={menu} id="popup_menu" style={{ fontSize: "small" }} />
        </div>
      )
    }

    if (actionItem) {
      return (
        <span
          title={actionItem.label}
          className={classNames(
            "item-actions mr-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full font-semibold text-slate-400 hover:bg-slate-100",
            actionsClassName,
          )}
          onClick={(e) => !actionItem.disabled && actionItem.command?.({ originalEvent: e, item: actionItem })}
        >
          {actionItem.icon ?? actionItem.label}
        </span>
      )
    }

    if (externalAction) {
      return (
        <span
          className="mr-2 inline-flex h-1/2 cursor-pointer items-center justify-center text-sm text-slate-400"
          title={externalAction}
          onClick={onContentClick}
        >
          <FontAwesomeIcon icon={faExternalLink} />
        </span>
      )
    }

    return <></>
  }

  return (
    <li className={className}>
      <span className={classNames("block", { "hover:bg-gray-50": rowHover })}>
        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
          <div
            className={classNames("flex w-full items-center py-4", {
              "cursor-pointer": onContentClick,
              "px-4": itemPadding,
            })}
            onClick={onContentClick}
          >
            <div className="flex">
              {rowImg && <div className="shrink-0">{rowImg}</div>}
              <div className={classNames("truncate", { "px-3": rowHover })}>
                <div className="flex text-sm">{header}</div>
                {subHeader && (
                  <div className="mt-2 flex flex-wrap items-center divide-x divide-gray-400 text-xs text-gray-500 *:pr-2">
                    {subHeader}
                  </div>
                )}
              </div>
            </div>
          </div>
          {chip && <div className="m-2 flex justify-end">{chip} </div>}
          <RenderLinks />
        </div>
      </span>
    </li>
  )
}

type Props = {
  header: string | JSX.Element
  rowImg?: JSX.Element
  chip?: JSX.Element
  subHeader?: string | JSX.Element
  dropdownMenuItems?: MenuItem[]
  actionItem?: MenuItem
  onContentClick?(): void
  actionsClassName?: string
  externalAction?: string
  itemPadding?: boolean
  rowHover?: boolean
  isLoading?: boolean
  className?: string
}

export { ListItem }
