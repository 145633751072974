import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { PractitionerRole } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { organizationQueryKeys } from "organization"
import { displayNotificationSuccess } from "utils"

const useUpdatePractitionerRole = (onSuccess?: () => void, onSettled?: () => void) => {
  const { update } = useClient()
  const queryClient = useQueryClient()

  const updateFn = async (practitionerRole: PractitionerRole) =>
    update("PractitionerRole", practitionerRole.id as string, practitionerRole)

  const { mutateAsync: updatePractitionerRole, isPending } = useMutation({
    mutationFn: updateFn,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { organization, practitioner }) => {
      organizationQueryKeys.practitionerRole(practitioner?.id as string, organization?.id as string)
      await queryClient.refetchQueries({
        queryKey: organizationQueryKeys.practitionerRole(practitioner?.id as string, organization?.id as string),
      })
      displayNotificationSuccess("Practitioner availability hours updated successfully!")
      // datadogLogs.logger.info(`Practice Hours on ${managingOrganization?.display} updated successfully!`)
      onSuccess?.()
    },
    onSettled,
  })

  return { updatePractitionerRole, isUpdating: isPending }
}

export { useUpdatePractitionerRole }
