import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faImageSlash } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Image } from "primereact/image"
import { ProgressSpinner } from "primereact/progressspinner"
import { classNames } from "primereact/utils"
import { type FC, type ReactNode, useEffect, useRef, useState } from "react"

import { useGetSignedUrl } from "../hooks"
import "./AzureImage.css"

const SignedAzureImage: FC<Props> = ({
  url,
  alt,
  className,
  imageClassName,
  loadingClassName,
  fallbackIconNode,
  fallbackIcon = faImageSlash,
  fallbackIconClass,
  showPreview,
  onImageLoaded,
}) => {
  const { signedUrl, isLoading, error } = useGetSignedUrl(url)
  const [showFallback, setShowFallback] = useState(false)
  const [isDownloaded, setIsDownloaded] = useState(false)
  const imgRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    if (isLoading) {
      setIsDownloaded(false)
      setShowFallback(false)
      imgRef.current?.classList.remove("loaded")
    } else if (error) {
      setIsDownloaded(true)
      setShowFallback(true)
    }
  }, [isLoading, error])

  const imageLoaded = () => {
    setIsDownloaded(true)
    imgRef.current?.classList.add("loaded")
    if (signedUrl) onImageLoaded?.(signedUrl)
  }

  return showPreview ? (
    <Image src={signedUrl} alt={alt} className={className} preview={showPreview} imageClassName={imageClassName} />
  ) : (
    <div className={classNames("img-container flex h-full flex-col", { relative: isLoading || !isDownloaded })}>
      {(isLoading || !isDownloaded) && (
        <ProgressSpinner
          animationDuration="1s"
          className={classNames("absolute w-full", loadingClassName ?? imageClassName ?? className)}
        />
      )}
      <div
        className={classNames("flex h-full items-center justify-center", {
          hidden: !showFallback,
        })}
      >
        {fallbackIconNode ?? <FontAwesomeIcon icon={fallbackIcon} className={fallbackIconClass} />}
      </div>
      <img
        ref={imgRef}
        src={signedUrl}
        loading="lazy"
        alt={alt}
        onLoad={imageLoaded}
        className={classNames({ hidden: isDownloaded && showFallback }, imageClassName)}
        onError={() => {
          if (!isLoading) {
            setIsDownloaded(true)
            setShowFallback(true)
          }
        }}
      />
    </div>
  )
}

type Props = {
  url: string
  alt?: string
  className?: string
  imageClassName?: string
  loadingClassName?: string
  fallbackIconNode?: ReactNode
  fallbackIcon?: IconDefinition
  fallbackIconClass?: string
  showPreview?: boolean
  onImageLoaded?(resolvedUrl: string): void
}

export { SignedAzureImage }
