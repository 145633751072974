import type { SizeProp } from "@fortawesome/fontawesome-svg-core"
import { faSpinner } from "@fortawesome/pro-regular-svg-icons"
import {
  type IconDefinition,
  faCheck,
  faClockRotateLeft,
  faPlayCircle,
  faStop,
  faTrashCan,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns"
import { type MedicationKnowledge, isPatient } from "fhir"
import type { MenuItem } from "primereact/menuitem"
import { classNames } from "primereact/utils"

import { Badge, type BadgeProps, DropdownMenu } from "commons"
import {
  type MedicationRequestData,
  getMRDispenseInfo,
  MedicationKnowledgeImage,
  MedicationRequestItem,
} from "commons/meds"
import { formatsByTypes } from "data"
import { getBadgeColor, getMRStatus, getMoneyCurrencyAlt } from "utils"

import { useChartContext } from "chart-view"
import type { MRActions } from "../../types"

const DEFAULT_IMAGE_CLASS = "w-full"

const MedicationRequestListItem = ({
  medicationData,
  medicationRequestActions,
  isLoading,
  showActions = true,
  onSelectMK,
  imageClassName = DEFAULT_IMAGE_CLASS,
  extraActionDefinition,
  showDispenseInfo = true,
  showPrice = true,
  showStatus = true,
  shouldResume,
  itemClassName,
  priceClassName = "font-semibold text-lg",
  badge,
  notShowDivider = false,
}: Props) => {
  const { hasGP } = useChartContext()

  const getMRAllowedActions = (medicationData: MedicationRequestData) => {
    const status = getMRStatus(medicationData.medicationRequestInfo)

    const planBased = medicationData.medicationRequestInfo?.basedOn?.some((r) => r.resourceType === "CarePlan") ?? false
    const isMKActive = medicationData.medicationKnowledge?.status === "active"
    const enableRenew = !planBased && ["completed", "stopped"].includes(status) && hasGP && isMKActive
    const enableComplete = !planBased && status === "active"
    const enableStop = !planBased && (status === "active" || status === "on-hold") && hasGP

    const enableDiscard = !planBased && status === "draft"

    const mrActionsItems = shouldResume
      ? [
          {
            label: "Resume",
            icon: <FontAwesomeIcon icon={faPlayCircle} size="sm" className="mr-2" />,
            command: () => medicationRequestActions?.resume?.(medicationData.medicationRequestInfo.id as string),
            disabled: !medicationData.medicationRequestInfo.doNotPerform,
            visible: !!medicationRequestActions?.resume,
          },
        ]
      : ([
          {
            label: "Reorder",
            icon: <FontAwesomeIcon icon={faClockRotateLeft} size="sm" className="mr-2" />,
            command: () => {
              medicationRequestActions?.renew?.(medicationData.medicationRequestInfo)
            },
            disabled: !enableRenew,
            visible: !!medicationRequestActions?.renew,
          },
          {
            label: "Complete",
            icon: <FontAwesomeIcon icon={faCheck} size="sm" className="mr-2" />,
            command: () => {
              medicationRequestActions?.complete?.(medicationData.medicationRequestInfo.id as string)
            },
            disabled: !enableComplete,
            visible: !!medicationRequestActions?.complete,
          },
          {
            label: "Stop",
            icon: <FontAwesomeIcon icon={faStop} size="sm" className="mr-2" />,
            command: () => {
              medicationRequestActions?.stop?.(medicationData.medicationRequestInfo.id as string)
            },
            disabled: !enableStop,
            visible: !!medicationRequestActions?.stop,
          },
          {
            label: "Stop & Renew",
            icon: <FontAwesomeIcon icon={faClockRotateLeft} size="sm" className="mr-2" />,
            command: () => {
              medicationRequestActions?.stopAndRenew?.(medicationData.medicationRequestInfo.id as string)
            },
            disabled: !enableStop,
            visible: !!medicationRequestActions?.stopAndRenew,
          },
          {
            label: "Discard",
            icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
            command: () => medicationRequestActions?.discard?.(medicationData.medicationRequestInfo.id as string),
            disabled: !enableDiscard,
            visible: !!medicationRequestActions?.discard,
          },
        ] as MenuItem[])

    return mrActionsItems.filter(({ visible }) => visible)
  }

  const dropdownMenuItems = medicationRequestActions && getMRAllowedActions(medicationData)

  const { refills, dispensed, isDfo, startDispense } = getMRDispenseInfo(
    medicationData.medicationRequestInfo.status,
    medicationData.medicationRequestInfo.dispenseRequest,
    medicationData.medicationDispense,
  )
  const status = getMRStatus(medicationData.medicationRequestInfo)

  return (
    <div
      className={classNames("w-full border-b border-slate-300 p-3 last-of-type:border-b-0 xl:p-5", {
        "!border-b-0": notShowDivider,
      })}
    >
      <div
        className={classNames(
          "product-list-item flex shrink-0 grow-0 flex-row items-baseline gap-2 lg:gap-4",
          itemClassName,
        )}
      >
        <span
          className={classNames(
            "my-auto min-w-[6rem]",
            imageClassName !== DEFAULT_IMAGE_CLASS ? imageClassName : "w-48 lg:w-52",
            medicationData.medicationKnowledge && "cursor-pointer",
          )}
          onClick={() => onSelectMK?.(medicationData.medicationKnowledge)}
        >
          <MedicationKnowledgeImage
            drugCharacteristic={medicationData.medicationKnowledge?.drugCharacteristic}
            className={imageClassName}
          />
        </span>
        <MedicationRequestItem
          medicationRequest={medicationData.medicationRequestInfo}
          medicationKnowledge={medicationData.medicationKnowledge ?? {}}
          dosageInstructions={medicationData.medicationRequestInfo.dosageInstruction}
          amount={
            medicationData.medicationRequestInfo.dispenseRequest?.quantity?.value ??
            medicationData.medicationRequestInfo.dispenseRequest?.initialFill?.quantity?.value ??
            1
          }
          showPackagingType
          className="ml-2 xl:ml-3"
          onClick={() => onSelectMK?.(medicationData.medicationKnowledge)}
        />

        <div className="product-list-action flex min-w-fit flex-col items-end gap-2">
          {showActions && (
            <>
              {isLoading ? (
                <span className="flex h-8 items-center">
                  <FontAwesomeIcon icon={faSpinner} spin size="lg" />
                </span>
              ) : (
                <>
                  {!!dropdownMenuItems?.length && <DropdownMenu dropdownMenuItems={dropdownMenuItems} />}
                  {!!extraActionDefinition && (
                    <FontAwesomeIcon
                      onClick={() => !extraActionDefinition.disabled && extraActionDefinition.command()}
                      icon={extraActionDefinition.icon}
                      size={extraActionDefinition.iconSize}
                      className={classNames(
                        { "cursor-pointer": !extraActionDefinition.disabled },
                        extraActionDefinition.className,
                      )}
                    />
                  )}
                </>
              )}
            </>
          )}

          {(showStatus || badge) && (
            <span title="Status">{badge ? <Badge {...badge} /> : <Badge {...getBadgeColor(status)} />}</span>
          )}
          {showPrice && medicationData.productPrice?.value && (
            <span title="Price" className={priceClassName}>
              {getMoneyCurrencyAlt(medicationData.productPrice.currency)}
              {medicationData.productPrice.value.toFixed(2)}
            </span>
          )}
          {showDispenseInfo && (
            <>
              {medicationData.medicationRequestInfo.status !== "draft" && refills >= 1 && dispensed <= refills ? (
                <span title="Dispensed" className="text-sm">{`Dispense ${dispensed} of ${refills}`}</span>
              ) : (
                <span title="Dispensed" className="text-sm">
                  {isDfo
                    ? `Dispensed from office${startDispense ? `: ${startDispense}` : ""}`
                    : `Dispensed ${dispensed}${startDispense ? ` since ${startDispense}` : ""}`}
                </span>
              )}
            </>
          )}

          {(medicationData.medicationRequestInfo.status === "active" ||
            medicationData.medicationRequestInfo.status === "on-hold") &&
            medicationData.medicationRequestInfo.dispenseRequest?.nextRefillDate && (
              <span title="Next Refill" className="text-sm">
                {`Next refill: after ${format(
                  new Date(medicationData.medicationRequestInfo.dispenseRequest.nextRefillDate),
                  formatsByTypes.LONG_DATE,
                )}`}
              </span>
            )}
          {isPatient(medicationData.medicationRequestInfo.requester) && (
            <span className="truncate text-xs text-gray-500">Requested by patient</span>
          )}
        </div>
      </div>
    </div>
  )
}

type Props = {
  medicationData: MedicationRequestData
  medicationRequestActions?: MRActions
  isLoading?: boolean
  showActions?: boolean
  onSelectMK?(mk?: MedicationKnowledge): void
  imageClassName?: string
  extraActionDefinition?: {
    icon: IconDefinition
    command(): void
    iconSize: SizeProp
    className?: string
    disabled?: boolean
  }
  showDispenseInfo?: boolean
  showStatus?: boolean
  showPrice?: boolean
  shouldResume?: boolean
  itemClassName?: string
  priceClassName?: string
  badge?: BadgeProps
  notShowDivider?: boolean
}

export { MedicationRequestListItem }
