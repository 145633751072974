import { classNames } from "primereact/utils"
import type { Observation } from "fhir"

import { Badge } from "commons"

import type { TriadData } from "../../types"
import { triads } from "../../data"

const TriadItem = ({ triadItem }: Props) => (
  <div className="flex rounded-lg p-2">
    <span
      className={classNames(
        "flex h-8 w-8 items-center justify-center rounded-full border text-xs",
        triads[triadItem.id].borderColor,
      )}
    >
      {triadItem.triad.value?.Quantity?.value}
    </span>
    <img src={triads[triadItem.id].img} alt="" className="w-24" />
    <div className="mr-4 ml-14 flex flex-1 flex-col space-y-0.5">
      <div className="grid grid-cols-3">
        <div className="col-start-2 flex w-72 items-center gap-1 place-self-center">
          <span className="flex-1 px-2 text-center text-[0.65rem] font-semibold uppercase">Symptoms</span>
          <span className="flex-1 px-2 text-center text-[0.65rem] font-semibold uppercase">Labs</span>
          <span className="flex-1 px-2 text-center text-[0.65rem] font-semibold uppercase">Total</span>
        </div>
      </div>
      {triadItem.organs?.map((o) => <TriadDataItem key={o.id} obs={o.organ} />)}
    </div>
  </div>
)

const TriadDataItem = ({ obs }: { obs: Observation }) => {
  const symptValue = obs.component?.find((c) => c.code?.coding?.[0]?.code === "survey")?.value?.Quantity?.value
  const labValue = obs.component?.find((c) => c.code?.coding?.[0]?.code === "labs")?.value?.Quantity?.value
  const risk = obs.interpretation?.[0]?.text

  return (
    <div className="grid grid-cols-3 rounded-md bg-gray-100">
      <span className="p-1 px-2 font-medium">{obs.code.text}</span>
      <div className="flex w-72 items-center gap-1 place-self-center">
        <span className="flex-1 rounded-lg bg-white px-2 text-center font-medium" title="Symptom score">
          {symptValue}
        </span>
        <span className="flex-1 rounded-lg bg-white px-2 text-center font-medium" title="Lab score">
          {labValue}
        </span>
        <span className="flex-1 rounded-lg bg-white px-2 text-center font-medium" title="Total">
          {obs.value?.Quantity?.value}
        </span>
      </div>
      <div className="text-right">
        <Badge
          className="my-2 mr-4"
          text={`${risk} risk`}
          colorStyle={risk === "Low" ? "green" : risk === "High" ? "red" : "yellow"}
        />
      </div>
    </div>
  )
}

type Props = {
  triadItem: TriadData
}

export { TriadItem }
