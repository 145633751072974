import { type DatesSetArg, type EventContentArg, createPlugin } from "@fullcalendar/core"
import type { ViewProps } from "@fullcalendar/core/internal"
import { asReference, type Appointment, type Practitioner, type Slot } from "fhir"
import { classNames } from "primereact/utils"
import type { CalendarMonthChangeEvent } from "primereact/calendar"
import { type FC, createRef, useMemo, useReducer, useState } from "react"
import type FullCalendar from "@fullcalendar/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons"
import { isSameDay } from "date-fns/isSameDay"

import { getMonthDateBounds, useUnbookAppointment } from "appointments"
import { Button, ConfirmDialog } from "commons"
import type { MonthBounds } from "appointments/types"
import { type CalendarEventExtraInfo, EventType } from "commons/types"
import { AppointmentCalendar } from "calendar"

import { AgendaView } from "../agenda/AgendaView"
import { PractitionerFullCalendar } from "./PractitionerFullCalendar"
import { useFreeTimeOffSlot, useGetPractitionerCalendar } from "../../hooks"
import { getCalendarDateFromDatesSet, getEvents } from "../../utils"
import { EventDetailsContainer } from "./EventDetailsContainer"
import { AvailabilityFormModal } from "../availability/AvailabilityFormModal"
import { TimeOffFormModal } from "../timeOff/TimeOffFormModal"
import type { TimeOffHours } from "../../types"

const currentDate = new Date()

const PractitionerCalendarView: FC<Props> = ({ practitioner, handleGoBack }) => {
  const calendarRef = createRef<FullCalendar>()

  const [datesSet, setDatesSet] = useState<DatesSetArg>()

  const [selectedMonthBounds] = useState<MonthBounds>(
    getMonthDateBounds({ month: currentDate.getMonth(), year: currentDate.getFullYear(), ignoreCurrentDay: true }),
  )

  const calendarDate = getCalendarDateFromDatesSet(datesSet)

  const { events, isLoading, queryKey } = useGetPractitionerCalendar({
    practitionerId: practitioner.id,
    startDate: selectedMonthBounds.start,
    endDate: selectedMonthBounds.end,
  })

  const calendarEvents = useMemo(() => getEvents(events), [events])

  const {
    setAvailability,
    unbookItem,
    reset,
    confirmUnbookItem,
    showAvailabilityForm,
    showTimeOffForm,
    requestTimeOff,
    confirmFreeSlot,
    releaseSlot,
  } = useReducerState()

  const { unbookAppointment, isUnbooking } = useUnbookAppointment({ onSettled: reset, queryKeyToInvalidate: queryKey })
  const onUnbook = (appointment: Appointment) => unbookAppointment(appointment.id)

  const { freeTimeOffSlot, isPending: isReleasingSlot } = useFreeTimeOffSlot({
    queryKeyToInvalidate: queryKey,
    onSettled: reset,
  })
  const onReleaseSlot = (slot: Slot) => freeTimeOffSlot({ slotId: slot.id as string })

  const handleCalendarMonthChange = ({ month, year }: CalendarMonthChangeEvent) => {
    const { start } = getMonthDateBounds({ month, year })
    calendarRef.current?.getApi().gotoDate(start)
    calendarRef.current?.getApi().changeView("dayGridMonth")
  }

  const handleDateSelection = (date?: Date) => {
    if (date) {
      calendarRef.current?.getApi().gotoDate(date)
      calendarRef.current?.getApi().changeView("agenda")
    }
  }

  const getDateEvents = (date: Date) =>
    calendarEvents.filter(
      (ev) =>
        (ev.start && isSameDay(new Date(ev.start.toString()), date)) ||
        (ev.end && isSameDay(new Date(ev.end.toString()), date)),
    )

  // passes props to AgendaPlugin
  class MorePropsToView {
    transform(viewProps: ViewProps) {
      return {
        ...viewProps,
        unbook: unbookItem,
        release: releaseSlot,
      }
    }
  }

  const AgendaPlugin = useMemo(
    () =>
      createPlugin({
        name: "AgendaView",
        views: {
          agenda: (props: ViewProps) => <AgendaView {...props} />,
        },
        viewPropsTransformers: [MorePropsToView],
      }),
    [MorePropsToView],
  )

  const loadingOverlay = (
    <div className="absolute z-10 h-full w-full pt-10 pb-2">
      <div className="m-auto flex h-full w-full items-center justify-center rounded-md bg-gray-300/70 backdrop-blur-sm">
        <span className="text-center text-white">
          <FontAwesomeIcon icon={faCircleNotch} className="mr-1 h-5 w-5" spin />
          <span>Loading events...</span>
        </span>
      </div>
    </div>
  )

  const renderEventContent = (eventInfo: EventContentArg) => {
    const appointment = eventInfo.event._def.extendedProps.appointment as Appointment | undefined
    const color = eventInfo.event._def.extendedProps.color
    const eventType = eventInfo.event._def.extendedProps.eventType
    const weekViewVariant = eventInfo.view.type === "timeGridWeek"
    const slot = eventInfo.event._def.extendedProps.slot as Slot | undefined

    const isSlotEvent = eventType === EventType.SLOT
    const isAllDay: boolean | undefined = eventInfo.event._def.extendedProps.allDay

    return (
      <div
        className={classNames("group relative flex flex-1 justify-between rounded-sm", {
          "items-center": weekViewVariant,
          "!bg-white": !weekViewVariant,
          "border border-red-500": isSlotEvent && !weekViewVariant,
          "hover:bg-gray-200": isSlotEvent,
          "min-h-[12dvh] items-start": isAllDay,
        })}
        id={`appt_${eventInfo.event.id}`}
      >
        <div
          className={classNames(
            "grid items-center gap-1",
            weekViewVariant ? "grid-flow-row-dense" : "grid-flow-col-dense",
          )}
        >
          {!weekViewVariant && !isSlotEvent && (
            <div className="col-span-1 h-1.5 w-1.5 rounded-full border-[3px]" style={{ borderColor: color }} />
          )}
          <p className={classNames("col-span-3 truncate text-xs", weekViewVariant ? "text-white" : "text-gray-600")}>
            {eventInfo.event.title}
          </p>
          <span className={classNames("col-span-1 text-xs", weekViewVariant ? "text-white" : "self-end text-gray-400")}>
            {eventInfo.timeText}
          </span>
        </div>

        <EventDetailsContainer
          calendarEvent={{
            event: eventInfo.event,
            extraInfo: eventInfo.event._def.extendedProps as CalendarEventExtraInfo,
          }}
          onUnbook={appointment ? () => unbookItem(appointment) : undefined}
          onReleaseSlot={isSlotEvent && slot?.id ? () => releaseSlot(slot) : undefined}
        />
      </div>
    )
  }

  return (
    <div className="flex h-full bg-white">
      <div className={classNames("relative flex-1")}>
        {isLoading && loadingOverlay}
        <PractitionerFullCalendar
          practitioner={practitioner}
          calendarRef={calendarRef}
          AgendaPlugin={AgendaPlugin}
          events={calendarEvents}
          renderEvent={renderEventContent}
          handleUpdateDateRange={(dates) =>
            setDatesSet((datesSet) => (dates?.startStr !== datesSet?.startStr ? dates : datesSet))
          }
          handleGoBack={handleGoBack}
        />
      </div>
      <div className="flex w-2/6 flex-none flex-col pt-2 pl-3 md:w-[35%] 2xl:w-1/4">
        <div className="relative flex">
          {isLoading && loadingOverlay}
          <AppointmentCalendar
            currentDate={calendarDate}
            selectDate={handleDateSelection}
            currentDateEvents={getDateEvents}
            onMonthChange={handleCalendarMonthChange}
          />
        </div>

        <div className="flex gap-3 p-2">
          <Button label="Set availability" className="w-full" onClick={setAvailability} />
          <Button label="New Day Off" buttonStyle="default" className="w-full" onClick={() => requestTimeOff(true)} />
        </div>

        <AvailabilityFormModal visible={showAvailabilityForm} onHide={reset} />
        <TimeOffFormModal
          visible={showTimeOffForm}
          currentCalendarQuery={queryKey}
          practitioner={asReference(practitioner)}
          onHide={reset}
        />

        <ConfirmDialog
          confirmText="Are you sure you want to unbook this appointment"
          actionName="Unbook"
          visible={confirmUnbookItem !== undefined}
          onConfirm={() => onUnbook(confirmUnbookItem as Appointment)}
          hideDialog={reset}
          waitToFinish
          isLoading={isUnbooking}
        />

        <ConfirmDialog
          confirmText="Are you sure you want to release this time off slot"
          actionName="Release"
          visible={confirmFreeSlot !== undefined}
          onConfirm={() => onReleaseSlot(confirmFreeSlot as Slot)}
          hideDialog={reset}
          waitToFinish
          isLoading={isReleasingSlot}
        />
      </div>
    </div>
  )
}

type Props = {
  practitioner: Practitioner
  handleGoBack(): void
}

const initialState: State = {
  showAvailabilityForm: false,
  selectedDate: new Date(),
  confirmUnbookItem: undefined,
  agendaDate: undefined,
  showTimeOffForm: false,
  confirmFreeSlot: undefined,
}

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "reset" | "setAvailability" | "unbook" | "selectDate" | "timeOff" | "freeTimeOff"
    payload?: Date | string | boolean | TimeOffHours | Appointment | Slot
  },
) => {
  switch (type) {
    case "reset":
      return {
        ...initialState,
        selectedDate: state.selectedDate,
      }
    case "setAvailability":
      return { ...state, showAvailabilityForm: true, agendaDate: undefined, confirmUnbookItem: undefined }
    case "unbook":
      return { ...state, confirmFreeSlot: undefined, confirmUnbookItem: payload as Appointment }
    case "selectDate":
      return {
        ...state,
        selectedDate: payload as Date,
        confirmUnbookItem: undefined,
        agendaDate: payload as Date,
      }
    case "timeOff":
      return {
        ...state,
        showTimeOffForm: payload as boolean,
        showAvailabilityForm: false,
        agendaDate: undefined,
        confirmUnbookItem: undefined,
      }
    case "freeTimeOff":
      return { ...state, confirmUnbookItem: undefined, confirmFreeSlot: payload as Slot }

    default:
      return state
  }
}

const useReducerState = () => {
  const [{ showAvailabilityForm, agendaDate, confirmUnbookItem, showTimeOffForm, confirmFreeSlot }, dispatch] =
    useReducer(reducer, initialState)

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const setAvailability = () => {
    dispatch({ type: "setAvailability" })
  }

  const unbookItem = (appointment: Appointment) => {
    dispatch({ type: "unbook", payload: appointment })
  }

  const selectDate = (selectedDate: Date) => {
    dispatch({ type: "selectDate", payload: selectedDate })
  }

  const requestTimeOff = (payload: boolean) => {
    dispatch({ type: "timeOff", payload })
  }

  const releaseSlot = (payload: Slot) => {
    dispatch({ type: "freeTimeOff", payload })
  }

  return {
    showAvailabilityForm,
    confirmUnbookItem,
    reset,
    setAvailability,
    selectDate,
    unbookItem,
    agendaDate,
    showTimeOffForm,
    requestTimeOff,
    confirmFreeSlot,
    releaseSlot,
  }
}

type State = {
  showAvailabilityForm: boolean
  selectedDate: Date
  confirmUnbookItem?: Appointment
  agendaDate?: Date
  showTimeOffForm: boolean
  confirmFreeSlot?: Slot
}

export { PractitionerCalendarView }
