import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { Bundle, Parameters } from "fhir"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { patientsQueryKeys } from "patients/query-keys"
import { displayNotificationSuccess } from "utils"

import type { CustomError } from "../types"

const useUpdateUserEmail = (
  patientId?: string,
  onSuccess?: (data: Bundle, vars: MutationVars) => void,
  onSettled?: (data: Bundle | undefined, error: CustomError | null) => void,
) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const { mutate: updateEmail, isPending } = useMutation({
    mutationFn: async ({ resourceId, resourceType, email }: MutationVars) => {
      const parameters = {
        resourceType: "Parameters",
        parameter: [{ name: "new-email", value: { string: email } }],
      } as Parameters

      return operationRequest<Bundle>({
        endpoint: `${resourceType}`,
        method: "POST",
        operation: "edit-email",
        parameters,
        id: resourceId,
      })
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (data, vars) => {
      displayNotificationSuccess("Email updated successfully!")
      // datadogLogs.logger.info(`Email updated successfully!`, { data })
      if (vars.resourceType === "Patient")
        await queryClient.refetchQueries({
          queryKey: patientsQueryKeys.details(patientId),
        })
      onSuccess?.(data, vars)
    },
    onSettled: (data, error) => {
      onSettled?.(data, error)
    },
  })

  return { updateEmail, isPending }
}

type MutationVars = {
  resourceId: string
  resourceType: "Patient" | "Practitioner"
  email: string
}

export { useUpdateUserEmail }
