import { format } from "date-fns"
import { type MedicationDispense, type Organization, codeableConceptAsString, humanNameAsString } from "fhir"
import pluralize from "pluralize"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { classNames } from "primereact/utils"

import { formatsByTypes } from "data"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { SYSTEM_VALUES } from "system-values"
import { getStringAddress } from "utils"

import { allDispenseInterval } from "../data"
import type { MedicationRequestInfo } from "../types"
import { getMRDispenseInfo } from "../utils"

const MedicationRequestDetails = ({
  medicationRequest,
  medicationDispense,
  containerClassName,
  titleClassName = "font-medium",
  textClassName = "mb-3 p-1 text-slate-400",
  showAsList,
}: Props) => {
  const { currentOrganizationId } = useOrganizationContext()
  const { organizationPractitionersInfo } = useOrganizationPractitioners({ organizationId: currentOrganizationId })
  const { status, dispenseRequest, requester, note, dosageInstruction, medication } = medicationRequest ?? {}
  const mostRecentMD = medicationDispense?.at(0)
  const pharmacyStatus =
    mostRecentMD?.statusReason?.CodeableConcept && codeableConceptAsString(mostRecentMD?.statusReason?.CodeableConcept)
  const trackingCode = mostRecentMD?.identifier?.find(
    ({ system }) => system === SYSTEM_VALUES.MEDICATION_DISPENSE_TRACKING_CODE,
  )?.value
  const dispensePerformerLocation = (
    mostRecentMD?.contained?.find(
      (resource) => (resource as Organization).id === mostRecentMD?.performer?.[0]?.actor?.localRef,
    ) as Organization
  )?.name

  const NOTES_LABEL = "Notes"
  const notes = note?.filter(({ text }) => Boolean(text))

  const dispenseValue = `${dispenseRequest?.dispenseInterval?.value} ${dispenseRequest?.dispenseInterval?.unit}`
  const dispenseOption = allDispenseInterval.find(({ value: { value, unit } }) => `${value} ${unit}` === dispenseValue)

  const prescriber = organizationPractitionersInfo.find(
    ({ practitionerRole }) => requester?.id === practitionerRole?.id,
  )?.practitioner

  const { refills, refillsLeft, dispensed, isDfo, startDispense } = getMRDispenseInfo(
    status as string,
    dispenseRequest,
    medicationDispense,
  )

  const mrData = [
    { label: "Medication", value: codeableConceptAsString(medication?.CodeableConcept) },
    ...(dispenseRequest?.quantity?.value
      ? [
          {
            label: "Quantity",
            value: `${dispenseRequest?.quantity?.value} ${dispenseRequest?.quantity?.unit ?? pluralize("unit", dispenseRequest?.quantity?.value)}`,
          },
        ]
      : []),
    ...(dispenseOption
      ? [
          {
            label: "Dispense",
            value: dispenseOption.label,
          },
        ]
      : []),
    ...(refillsLeft
      ? [
          {
            label: "Refills left",
            value: `${refillsLeft}`,
          },
        ]
      : [
          {
            label: "Dispensed",
            value: isDfo
              ? `From office${startDispense ? `: ${startDispense}` : ""}`
              : `${dispensed}${startDispense ? ` since ${startDispense}` : ""}`,
          },
        ]),
    {
      label: "Refills",
      value: `${dispenseRequest?.numberOfRepeatsAllowed ?? 0} ${pluralize("refill", dispenseRequest?.numberOfRepeatsAllowed ?? 0)}`,
    },
    ...(dispenseRequest?.nextRefillDate && (dispenseRequest?.numberOfRepeatsAllowed ?? 0) > 0 && status !== "draft"
      ? [
          {
            label: "Next Refill",
            value: `after ${format(new Date(dispenseRequest?.nextRefillDate), formatsByTypes.LONG_DATE)}`,
          },
        ]
      : []),
    ...(prescriber
      ? [
          {
            label: "Prescriber",
            value: humanNameAsString(prescriber.name?.[0]),
          },
        ]
      : []),
    ...(dispenseRequest?.performer?.display
      ? [
          {
            label: "Pharmacy",
            value: dispenseRequest?.performer?.display,
          },
        ]
      : []),
    ...(dispensePerformerLocation
      ? [
          {
            label: "Pharmacy Location",
            value: dispensePerformerLocation,
          },
        ]
      : []),
    ...(pharmacyStatus
      ? [
          {
            label: "Pharmacy Status",
            value: pharmacyStatus,
          },
        ]
      : []),
    ...(pharmacyStatus === "Shipped" && trackingCode
      ? [
          {
            label: "Tracking Number",
            value: trackingCode,
          },
        ]
      : []),
    ...(dispenseRequest?.shippingAddress
      ? [
          {
            label: "Shipping Address",
            value: getStringAddress(dispenseRequest?.shippingAddress),
          },
        ]
      : []),
    ...(notes?.length
      ? [
          {
            label: NOTES_LABEL,
            element: (
              <ul className="flex flex-col gap-4">
                {notes.map(({ text }, index) => (
                  <li key={index} title="Notes">
                    <pre className="font-sans text-sm text-wrap">{text}</pre>
                  </li>
                ))}
              </ul>
            ),
          },
        ]
      : []),
  ]
  const bodyTemplate = ({ label, value, element }: { label: string; value: string; element?: JSX.Element }) => {
    if (label === NOTES_LABEL) {
      return element
    }

    return value
  }

  if (!showAsList)
    return (
      <DataTable value={mrData} showHeaders={false}>
        <Column
          field="label"
          className="px-0 py-4 align-baseline text-sm font-semibold text-gray-600"
          style={{ width: "130px" }}
        />
        <Column field="value" className="py-4 text-sm text-black" body={bodyTemplate} />
      </DataTable>
    )

  return (
    <div className={classNames("flex flex-col", containerClassName)}>
      <div className="flex flex-col">
        <h6 className={titleClassName}>Medication</h6>
        <span>{codeableConceptAsString(medication?.CodeableConcept)}</span>
      </div>
      {!!dosageInstruction?.length && (
        <div className="flex flex-col">
          <h6 className={titleClassName}>Sig</h6>
          <div className={classNames("grid grid-flow-row-dense grid-cols-2 gap-x-6 @md:grid-cols-3", textClassName)}>
            {dosageInstruction?.flatMap(({ text }, index) => <p key={`${index}-${text}`}>{text ?? ""}</p>)}
          </div>
        </div>
      )}
      {dispenseRequest?.quantity?.value && (
        <>
          <div className={titleClassName}>Quantity</div>
          <div className={textClassName}>
            {`${dispenseRequest?.quantity?.value} ${pluralize(
              dispenseRequest?.quantity?.unit ?? "unit",
              dispenseRequest?.quantity?.value,
            )}`}
          </div>
        </>
      )}
      {dispenseOption && (
        <>
          <div className={titleClassName}>Dispense</div>
          <div className={textClassName}>{dispenseOption.label}</div>
        </>
      )}
      {refills >= 1 && dispensed <= refills ? (
        <>
          <div className={titleClassName}>Refills left</div>
          <div
            className={textClassName}
          >{`${refills - (dispensed > 1 ? dispensed - 1 : 0) /* -1 initial fill */}`}</div>
        </>
      ) : (
        <>
          <div className={titleClassName}>Dispensed</div>
          <div className={textClassName}>
            {isDfo
              ? `From office${startDispense ? `: ${startDispense}` : ""}`
              : `${dispensed}${startDispense ? ` since ${startDispense}` : ""}`}
          </div>
        </>
      )}
      <div className={titleClassName}>Refills</div>
      <div className={textClassName}>{dispenseRequest?.numberOfRepeatsAllowed ?? 0} refills</div>
      {dispenseRequest?.nextRefillDate && (dispenseRequest?.numberOfRepeatsAllowed ?? 0) > 0 && (
        <>
          <div className={titleClassName}>Next Refill</div>
          <div className={textClassName}>{`after ${format(
            new Date(dispenseRequest?.nextRefillDate),
            formatsByTypes.LONG_DATE,
          )}`}</div>
        </>
      )}
      {prescriber && (
        <>
          <div className={titleClassName}>Prescriber</div>
          <div className={textClassName}>{humanNameAsString(prescriber.name?.[0])}</div>
        </>
      )}
      {dispenseRequest?.performer?.display && (
        <>
          <div className={titleClassName}>Pharmacy</div>
          <div className={textClassName}>{dispenseRequest?.performer?.display}</div>
        </>
      )}
      {dispenseRequest?.shippingAddress && (
        <>
          <div className={titleClassName}>Shipping Address</div>
          <div className={textClassName}>{getStringAddress(dispenseRequest?.shippingAddress)}</div>
        </>
      )}
      {!!notes?.length && (
        <div className="flex flex-col gap-2">
          <div className={titleClassName}>Notes</div>
          <ul className="flex flex-col gap-2">
            {notes.map(({ text }, index) => (
              <li key={index} title="Notes" className="rounded-lg bg-gray-50 p-4">
                <pre className="font-sans text-sm text-wrap">{text}</pre>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

type Props = {
  medicationRequest?: MedicationRequestInfo
  medicationDispense?: MedicationDispense[]
  titleClassName?: string
  textClassName?: string
  containerClassName?: string
  showAsList?: boolean
}

export { MedicationRequestDetails }
