import { type IconDefinition, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { ResourceObject } from "fhir"
import { type FieldArrayRenderProps, type FormikValues, ErrorMessage, FieldArray } from "formik"
import pluralize from "pluralize"
import { classNames } from "primereact/utils"
import { type ReactNode, useState } from "react"

import { type StackedListItemProps, ConfirmDialog, MenuStyles, SkeletonLoader, StackedListItem } from "commons"

const ListFieldArray = <T extends ResourceObject | FormikValues>({
  field,
  emptyDataMessage,
  itemModelBuilder,
  confirmDeleteItemText,
  onRemoveItem,
  children,
  askDeleteConfirmation = true,
  className,
  disabled,
  isLoading,
  hideEmptyMessage,
  icon,
  itemLabel = "item",
}: Props<T>) => {
  const [deleteIndex, setDeleteIndex] = useState<number>()

  const modelBuilder = (item: T, index: number, onDelete: (index: number) => void, editionDisabled?: boolean) => {
    const model = itemModelBuilder(item, index)
    model.menu = !editionDisabled
      ? [
          ...(model.menu ? model.menu : []),
          ...(!model.readOnlyItem
            ? [
                {
                  label: "Delete",
                  icon: (
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      size="sm"
                      className={classNames({ "mr-1": model.menu?.length })}
                    />
                  ),
                  command: () => onDelete(index),
                },
              ]
            : []),
        ]
      : undefined

    if (model.menu?.length === 1) model.menuStyle = MenuStyles.ActionItems

    return model
  }

  return (
    <FieldArray name={field}>
      {(props: FieldArrayRenderProps) => {
        const {
          remove,
          name,
          form: { getFieldMeta },
        } = props

        const fieldValue = getFieldMeta<T[] | undefined>(field).value
        const fError = getFieldMeta(field).error
        const fTouched = getFieldMeta(field).touched

        const removeItem = (index: number) => {
          Array.isArray(fieldValue) && fieldValue[index] && onRemoveItem?.(fieldValue[index], props)
          remove(index)
        }

        return (
          <div className={className}>
            {askDeleteConfirmation && (
              <ConfirmDialog
                confirmText={
                  confirmDeleteItemText ?? `Are you sure you want to delete this ${itemLabel.toLowerCase()}?`
                }
                visible={deleteIndex !== undefined}
                hideDialog={() => setDeleteIndex(undefined)}
                actionName="Delete"
                onConfirm={() => {
                  removeItem(deleteIndex as number)
                }}
              />
            )}
            {children?.(props)}
            {isLoading ? (
              <SkeletonLoader loaderType="two-lines" repeats={2} />
            ) : fieldValue && Array.isArray(fieldValue) && fieldValue.length > 0 ? (
              <ul className="@container min-h-max grow divide-y divide-gray-200 overflow-auto px-3 py-3">
                {fieldValue.map((item: T, index: number) => (
                  <div key={index} className={classNames({ "pb-4": fError?.[index] !== undefined })}>
                    <StackedListItem
                      modelData={modelBuilder(
                        item,
                        index,
                        (i) => (askDeleteConfirmation ? setDeleteIndex(i) : removeItem(i)),
                        disabled,
                      )}
                      className={classNames("py-4", { "pb-0": fError?.[index] !== undefined })}
                    />
                    <ErrorMessage name={`${name}[${index}]`}>
                      {(msg) => typeof msg === "string" && <small className="p-error">{msg}</small>}
                    </ErrorMessage>
                  </div>
                ))}
              </ul>
            ) : (
              !hideEmptyMessage && (
                <div
                  className={classNames("flex min-h-max w-full flex-1 flex-col items-center justify-center py-8", {
                    "mt-0.5 rounded-md border border-red-600": fError && fTouched,
                  })}
                >
                  {icon && <FontAwesomeIcon icon={icon} size="2xl" className="mb-2 text-slate-500" />}
                  <p className="text-xs text-slate-500">
                    {emptyDataMessage ?? `No ${pluralize(itemLabel.toLowerCase(), 2)} added yet`}
                  </p>
                </div>
              )
            )}
            <ErrorMessage name={name}>
              {(msg) =>
                typeof msg === "string" && (
                  <small id={`errorMessage.${field}`} className="p-error">
                    {msg}
                  </small>
                )
              }
            </ErrorMessage>
          </div>
        )
      }}
    </FieldArray>
  )
}

type Props<T> = {
  field: string
  itemLabel?: string
  itemModelBuilder(item: T, itemIndex: number): ListItemProps
  confirmDeleteItemText?: string
  children?(props: FieldArrayRenderProps): ReactNode
  onRemoveItem?(item: T, props: FieldArrayRenderProps): void
  className?: string
  askDeleteConfirmation?: boolean
  hideEmptyMessage?: boolean
  emptyDataMessage?: string
  disabled?: boolean
  isLoading?: boolean
  icon?: IconDefinition
}

type ListItemProps = StackedListItemProps & {
  readOnlyItem?: boolean
}

export { ListFieldArray }
