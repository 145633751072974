import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Quantity, isMedicationKnowledge } from "fhir"
import { type FieldProps, ErrorMessage, Field, getIn, useFormikContext } from "formik"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { type FC, useMemo } from "react"

import { ValueSetIds } from "commons"
import { useValueSet } from "value-set"

import { durationCodeOptions, prescriptionFrequencies, routeOptions } from "../../data"
import type { MedicationDosage, MedicationRequestFormData } from "../../types"
import { AutocompleteSigField } from "./AutocompleteSigField"

const PrescriptionSigFields: FC<Props> = ({ index, fieldPath, onRemove, isStructured, removeDisabled }) => (
  <div className="flex flex-1 flex-col gap-2">
    <div className="p-fluid group inline-flex flex-1 items-center justify-between gap-x-2">
      {isStructured ? (
        <div className="grid w-full grid-cols-4 gap-x-6 pb-3">
          <DoseQuantity fieldPath={fieldPath} />
          <TreatmentFrequency fieldPath={fieldPath} />
          <TreatmentRoute fieldPath={fieldPath} />
          <PrescriptionDuration fieldPath={fieldPath} />
        </div>
      ) : (
        <AutocompleteSigField
          index={index}
          field={`${fieldPath}.instructionText`}
          validation={(value: string) => (!value ? "This field is required" : undefined)}
        />
      )}
      {!removeDisabled && (
        <div className="flex-end-0 mt-1.5 self-start pr-1">
          <button
            className={classNames(
              "flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-50 p-1 hover:bg-gray-100",
            )}
            onClick={(e) => {
              e.preventDefault()
              onRemove?.()
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="text-lg" />
          </button>
        </div>
      )}
    </div>
  </div>
)

const DoseQuantity: FC<StructuredFieldProps> = ({ fieldPath }) => {
  const { isSubmitting, errors } = useFormikContext<{ [key: string]: MedicationDosage }>()
  const doseQuantityError = getIn(errors, `${fieldPath}.doseQuantity`)
  const { codes: quantityUnits, isLoading } = useValueSet({
    valueSetId: ValueSetIds.DOSE_QTY_UNIT,
  })
  const medicationUnitOptions = useMemo(
    () =>
      quantityUnits?.map(({ code, display, system }) => ({
        label: display,
        value: {
          code,
          unit: display,
          system,
        },
      })),
    [quantityUnits],
  )

  return (
    <div className="flex w-full flex-col">
      <div className="p-inputgroup relative flex">
        <Field
          title="Units"
          name={`${fieldPath}.doseQuantity`}
          validate={(value: string) =>
            /^(\d*(\.\d+)?)(\s*-\s*(\d*(\.\d+)?))?$/.test(value) ? undefined : "Use a range like 1-9"
          }
        >
          {({
            field: { name, value, onChange },
            meta: { touched, error },
          }: FieldProps<string, MedicationRequestFormData>) => {
            return (
              <InputText
                type="text"
                id={name}
                name={name}
                onChange={onChange}
                value={value}
                className={classNames("p-inputtext-sm max-w-min min-w-[3.2rem] text-center", {
                  "p-invalid": touched && error,
                })}
                disabled={isSubmitting}
              />
            )
          }}
        </Field>
        <ErrorMessage name={`${fieldPath}.doseQuantity`}>
          {(msg) => <small className="p-error absolute -bottom-5">{msg}</small>}
        </ErrorMessage>
        <Field
          name={`${fieldPath}.medicationQuantity`}
          validate={(value: Quantity) => (doseQuantityError || value ? undefined : "Unit is required")}
        >
          {({
            field: { name, value, onChange },
            meta: { touched, error },
            form: { values },
          }: FieldProps<Quantity, MedicationRequestFormData>) => (
            <Dropdown
              options={medicationUnitOptions}
              optionLabel="label"
              optionValue="value"
              id={name}
              name={name}
              value={value}
              onChange={onChange}
              className={classNames("p-inputtext-sm small-trigger min-w-[5rem]", {
                "p-invalid": touched && error,
              })}
              disabled={
                isSubmitting ||
                (isMedicationKnowledge(values.medicationField) &&
                  !!values.medicationField.administrationGuidelines?.length)
              }
              loading={isLoading}
            />
          )}
        </Field>
        {!doseQuantityError && (
          <ErrorMessage name={`${fieldPath}.medicationQuantity`}>
            {(msg) => <small className="p-error absolute -bottom-5">{msg}</small>}
          </ErrorMessage>
        )}
      </div>
    </div>
  )
}

const TreatmentFrequency: FC<StructuredFieldProps> = ({ fieldPath }) => {
  const { isSubmitting } = useFormikContext()

  return (
    <div className="p-inputgroup relative flex">
      <Field
        name={`${fieldPath}.treatmentFrequency`}
        validate={(value: string) => (!value ? "Frequency is required" : undefined)}
      >
        {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
          <Dropdown
            options={prescriptionFrequencies}
            optionLabel="label"
            optionValue="value"
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            className={classNames("p-inputtext-sm small-trigger w-full min-w-[9rem]", {
              "p-invalid": touched && error,
            })}
            disabled={isSubmitting}
          />
        )}
      </Field>
      <ErrorMessage name={`${fieldPath}.treatmentFrequency`}>
        {(msg) => <small className="p-error absolute -bottom-5">{msg}</small>}
      </ErrorMessage>
    </div>
  )
}

const TreatmentRoute: FC<StructuredFieldProps> = ({ fieldPath }) => {
  const { isSubmitting } = useFormikContext()

  return (
    <div className="p-inputgroup relative flex">
      <Field
        name={`${fieldPath}.treatmentRoute`}
        validate={(value: string) => (!value ? "Route is required" : undefined)}
      >
        {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
          <Dropdown
            options={routeOptions}
            optionLabel="label"
            optionValue="value"
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            className={classNames("p-inputtext-sm small-trigger w-full min-w-[9rem]", {
              "p-invalid": touched && error,
            })}
            disabled={isSubmitting}
          />
        )}
      </Field>
      <ErrorMessage name={`${fieldPath}.treatmentRoute`}>
        {(msg) => <small className="p-error absolute -bottom-5 left-0">{msg}</small>}
      </ErrorMessage>
    </div>
  )
}

const PrescriptionDuration: FC<StructuredFieldProps> = ({ fieldPath }) => {
  const { isSubmitting, errors } = useFormikContext<{ [key: string]: MedicationDosage }>()
  const prescriptionDurationError = getIn(errors, `${fieldPath}.prescriptionDuration`)

  return (
    <div className="p-inputgroup relative flex">
      <Field
        name={`${fieldPath}.prescriptionDuration`}
        validate={(value: string) => (/^\d+$/.test(value) ? undefined : "Duration is invalid.")}
      >
        {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
          <InputText
            type="text"
            id={name}
            name={name}
            onChange={onChange}
            value={value}
            className={classNames("p-inputtext-sm max-w-min min-w-[3.2rem] text-center", {
              "p-invalid": touched && error,
            })}
            disabled={isSubmitting}
          />
        )}
      </Field>
      <ErrorMessage name={`${fieldPath}.prescriptionDuration`}>
        {(msg) => <small className="p-error absolute -bottom-5">{msg}</small>}
      </ErrorMessage>
      <Field
        name={`${fieldPath}.prescriptionDurationUnit`}
        validate={(value: string) => (prescriptionDurationError || value ? undefined : "Duration unit is required")}
      >
        {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
          <Dropdown
            options={durationCodeOptions.slice(3)}
            optionLabel="label"
            optionValue="value"
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            className={classNames("p-inputtext-sm small-trigger min-w-[5rem]", {
              "p-invalid": touched && error,
            })}
            disabled={isSubmitting}
          />
        )}
      </Field>
      {!prescriptionDurationError && (
        <ErrorMessage name={`${fieldPath}.prescriptionDurationUnit`}>
          {(msg) => <small className="p-error absolute -bottom-5">{msg}</small>}
        </ErrorMessage>
      )}
    </div>
  )
}

type Props = {
  index: number
  fieldPath: string
  isStructured?: boolean
  removeDisabled?: boolean
  onRemove?(): void
}

type StructuredFieldProps = {
  fieldPath: string
  showLabels?: boolean
}

export { PrescriptionSigFields }
