import { type PropsWithChildren, useCallback, useEffect, useMemo, useReducer } from "react"
import type { AnyObject } from "yup/lib/types"
import { useSearchParams } from "react-router-dom"

import { FiltersContext, type InitialProps } from "../hooks"

const FiltersContextProvider = <T extends AnyObject>({
  children,
  initialFilters,
  initialSearchText,
  filtersKeysValueMap,
  filtersKeysNameMap,
  isLoadingData,
  setUrlFilters = false,
}: PropsWithChildren<InitialProps<T>>) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { filter, reset, hardReset, searchWithText, onShowOverlay, showOverlay, filters, searchText } =
    useFiltersReducer<T>({
      defaultEntity: initialFilters,
      searchText: initialSearchText,
    })

  const hasActiveFilters = useMemo(
    () => Object.values(filters).some((value) => (Array.isArray(value) ? !!value.length : !!value)),
    [filters],
  )

  const onReset = useCallback(
    (clearAll?: boolean) => {
      if (setUrlFilters) {
        Object.keys(filters).forEach((key) => searchParams.delete(filtersKeysNameMap?.[key] ?? key))
        setSearchParams(searchParams)
      }
      if (clearAll) hardReset()
      else reset()
    },
    [searchParams],
  )

  useEffect(() => {
    if (setUrlFilters && !isLoadingData) {
      const params = new URLSearchParams({ ...searchParams })
      Object.entries({ ...filters, searchText }).forEach(([key, val]) => {
        const paramValue = filtersKeysValueMap?.[key] ? filtersKeysValueMap?.[key]?.(val) : val

        if (paramValue) params.append(filtersKeysNameMap?.[key] ?? key, paramValue)
        else params.delete(key)
      })

      setSearchParams(params)
    }
  }, [filters, searchText, searchParams])

  return (
    <FiltersContext.Provider
      value={{
        searchText,
        showOverlay,
        filters,
        initialFilters,
        initialSearchText,
        onFilter: filter,
        onSearch: searchWithText,
        onClearFilters: onReset,
        onClearAll: () => onReset(true),
        hasActiveFilters,
        onShowOverlay,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}

const useFiltersReducer = <T extends AnyObject>({ defaultEntity, searchText }: Props<T>) => {
  const initState = {
    filters: defaultEntity,
    showOverlay: false,
    searchText: searchText,
  } as BasicFiltersState<T>

  const reducer = (state: BasicFiltersState<T>, { type, payload }: ExtraReducerState<T>): BasicFiltersState<T> => {
    switch (type) {
      case "hardReset":
        return initState
      case "reset":
        return { ...state, showOverlay: false, filters: defaultEntity as T }
      case "filter":
        return { ...state, showOverlay: false, filters: payload as T }
      case "searchWithText":
        return { ...state, searchText: payload as string | undefined }
      case "showOverlay":
        return { ...state, showOverlay: payload as boolean }
      default:
        return { ...state }
    }
  }

  const [state, dispatch] = useReducer(reducer, initState)

  const reset = () => dispatch({ type: "reset" })

  const hardReset = () => dispatch({ type: "hardReset" })

  const filter = (value: T) => {
    dispatch({ type: "filter", payload: value })
  }

  const searchWithText = (value: string | undefined) => {
    dispatch({ type: "searchWithText", payload: value })
  }

  const onShowOverlay = (value: boolean) => {
    dispatch({ type: "showOverlay", payload: value })
  }

  return {
    filters: state.filters,
    showOverlay: state.showOverlay,
    searchText: state.searchText,
    state,
    reset,
    filter,
    hardReset,
    dispatch,
    searchWithText,
    onShowOverlay,
  }
}

export type ExtraReducerState<T> = {
  type: string
  payload?: number | string | boolean | T
}

export type BasicFiltersState<T> = {
  filters: T
  showOverlay: boolean
  searchText: string | undefined
}

type Props<T> = {
  defaultEntity: T
  searchText: string | undefined
}

export { FiltersContextProvider }
