import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { formatDate, isValid } from "date-fns"
import { parseISO } from "date-fns/parseISO"
import { codeableConceptAsString, isCarePlan, type Coding, type Medication, type Reference } from "fhir"
import pluralize from "pluralize"
import { classNames } from "primereact/utils"
import { type FC } from "react"

import { useChartContext } from "chart-view"
import { ModulesId, Notes, NotesUseContext, StackedListItem } from "commons"
import type { ConfigurationItem, ProcedureData } from "commons/procedures"
import { formatsByTypes } from "data"
import { proceduresItemModel, proceduresQueryKeys } from "procedures"
import { SYSTEM_VALUES } from "system-values"
import { getCodingBySystem } from "utils"

import { useEncounterContext } from "../../hooks"
import { WrapUpSection } from "./WrapUpSection"

const ProceduresSection: FC<Props> = ({ patientId, encounter, icon }) => {
  const { showModule } = useChartContext()
  const { encounterData, totalProcedures: total, planRefs } = useEncounterContext()

  const goToDetails = (procedureId: string) => {
    showModule({ module: ModulesId.PROCEDURES, moduleParams: { procedureId } })
  }

  return (
    <WrapUpSection
      isEmpty={!total}
      icon={icon}
      emptyMesage="No procedures activated during this encounter"
      className="flex flex-row pb-2"
      inlineContent
      sectionTitle="Procedures"
    >
      <div className="w-full divide-y divide-gray-200">
        {encounterData?.procedures?.map((procedureData, index) => (
          <ProcedureItem
            key={procedureData.procedure.id ?? index}
            procedureData={procedureData}
            patientId={patientId}
            encounter={encounter}
            goToDetails={goToDetails}
            planRefs={planRefs}
          />
        ))}
      </div>
    </WrapUpSection>
  )
}

const ProcedureItem: FC<{
  procedureData: ProcedureData
  patientId: string
  encounter: string
  goToDetails: (procedureId: string) => void
  planRefs: Record<string, Reference> | undefined
}> = ({ procedureData, patientId, encounter, goToDetails, planRefs }) => {
  const itemNotes = procedureData.procedure.note?.filter(({ text }) => !!text)
  const cp = planRefs?.[procedureData.procedure.basedOn?.find(isCarePlan)?.id as string]

  const editProps = procedureData.procedure.id
    ? {
        parentId: procedureData.procedure.id,
        context: NotesUseContext.PROCEDURE,
        queriesToInvalidate: proceduresQueryKeys.list(patientId, undefined, undefined, encounter),
        notShowFullDataNotFound: true,
      }
    : undefined

  return (
    <section>
      <StackedListItem
        className="py-4 !pl-0"
        modelData={{
          ...proceduresItemModel({
            procedureData,
            onShowDetails: () => goToDetails(procedureData.procedure.id as string),
          }),
          ...(cp
            ? {
                badge: {
                  text: cp.display ?? "Plan based",
                  colorStyle: "gray",
                  title: cp.display,
                  className: "truncate max-w-24 !block",
                },
              }
            : { badge: undefined }),
        }}
      />
      <ProcedureDetails procedureData={procedureData} />
      <Notes notes={itemNotes} editProps={editProps} forceSingleNote />
    </section>
  )
}

const ProcedureDetails: FC<{
  procedureData: ProcedureData
}> = ({ procedureData }) => {
  const isMassage = procedureData.procedure?.code?.coding?.some(
    (cc) => cc.system === SYSTEM_VALUES.PROCEDURE_TYPE && cc.code === "massage-procedure",
  )
  const isBotox = procedureData.procedure?.code?.coding?.some(
    (cc) => cc.system === SYSTEM_VALUES.PROCEDURE_TYPE && cc.code === "botox-procedure",
  )

  return (
    <div className="relative mb-2 flex flex-col text-sm">
      <span className="mb-2 inline-flex w-full items-center font-medium text-gray-400">
        {isMassage ? "Massages" : "Medications"}
        <hr className="ml-1 flex-1" />
      </span>
      <ProcedureConfigurationDetails cis={procedureData.configurationItem} isBotox={isBotox} isMassage={isMassage} />
    </div>
  )
}

const ProcedureConfigurationDetails: FC<{
  cis: ConfigurationItem[]
  isBotox?: boolean
  isMassage?: boolean
}> = ({ cis, isBotox, isMassage }) => {
  return (
    <div className="flex w-full flex-col gap-4">
      {cis.map((item, index) => (
        <ProcedureConfigurationItem key={index} item={item} index={index} isBotox={isBotox} isMassage={isMassage} />
      ))}
    </div>
  )
}

const ProcedureConfigurationItem: FC<{
  item: ConfigurationItem
  index: number
  isBotox?: boolean
  isMassage?: boolean
}> = ({ item, index, isBotox, isMassage }) => {
  const bodySites = item?.bodySite?.coding?.[0]?.code?.split("|") ?? []

  const title = isMassage
    ? `Massage ${index + 1}`
    : codeableConceptAsString(item?.medicationRequest?.medication?.CodeableConcept)

  return (
    <div className="flex flex-col gap-2">
      {!isMassage ? (
        <MedicationDetails item={item} title={title} isBotox={isBotox} />
      ) : (
        <MassageDetails item={item} bodySites={bodySites} title={title} />
      )}
    </div>
  )
}

const MedicationDetails: FC<{
  item: ConfigurationItem
  title: string
  isBotox?: boolean
}> = ({ item, title, isBotox }) => {
  const dosages =
    item?.bodySite?.coding
      ?.find(({ system }) => system === SYSTEM_VALUES.PROCEDURE_CONFIGURATION_DOSAGE)
      ?.code?.split("|") ?? []
  const dosagesBatch =
    getCodingBySystem(item?.bodySite, SYSTEM_VALUES.PROCEDURE_CONFIGURATION_BATCH)?.code?.split("|") ?? []
  const selectedBatches = dosagesBatch.map((doseBatch) => item.batches?.find((b) => b.index === doseBatch)?.label ?? "")

  return (
    <>
      {item.medicationAdministration?.contained?.map((_, batchIndex) => {
        const expDate = parseISO(
          (item.medicationAdministration?.contained?.[batchIndex] as Medication)?.batch?.expirationDate ?? "",
        )
        return (
          <div key={batchIndex} className="flex w-full flex-col gap-1">
            <span className="mb-2 inline-flex w-full items-center font-medium text-gray-400">
              {title}
              <hr className="ml-1 flex-1" />
            </span>
            <div className="relative flex flex-col gap-1 rounded-md px-2 py-1">
              <BatchDetails item={item} batchIndex={batchIndex} expDate={expDate} isBotox={isBotox} />
            </div>
          </div>
        )
      })}
      {isBotox && (
        <div className="flex flex-col gap-2 p-2">
          <AdministrationSite site={codeableConceptAsString(item.bodySite)} className="mt-2" />
          <div className="grid grow grid-cols-1 gap-5 p-1 lg:grid-cols-2">
            {dosages.map((dose, index) => (
              <div key={`${dose}_${index}`} className="grid w-fit grid-cols-3 items-center">
                <span className="text-gray-900">Point {index + 1}:</span>
                <span className="text-gray-700">{dose}</span>
                <span className="text-gray-700">{selectedBatches[index]}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

const BatchDetails: FC<{
  item: ConfigurationItem
  batchIndex: number
  expDate: Date
  isBotox?: boolean
}> = ({ item, batchIndex, expDate, isBotox }) => {
  return (
    <>
      <div className="flex gap-2">
        <span className="text-gray-900">Batch number:</span>
        <span className="text-gray-700">{batchIndex + 1}</span>
      </div>
      <div className="flex gap-2">
        <span className="text-gray-900">Lot number:</span>
        <span className="text-gray-700">
          {(item.medicationAdministration?.contained?.[batchIndex] as Medication)?.batch?.lotNumber}
        </span>
      </div>
      {isValid(expDate) && (
        <div className="flex gap-2">
          <span className="text-gray-900">Expiration date:</span>
          <span className="text-gray-700">{formatDate(expDate, formatsByTypes.LONG_DATE)}</span>
        </div>
      )}
      <div className="flex gap-2">
        <span className="text-gray-900">Quantity:</span>
        <span className="text-gray-700">
          {(item.medicationAdministration?.contained?.[batchIndex] as Medication)?.amount?.numerator?.value}
        </span>
      </div>
      {!isBotox && (
        <AdministrationSite
          site={codeableConceptAsString({ coding: [item.bodySite?.coding?.[batchIndex] as Coding] })}
        />
      )}
    </>
  )
}

const MassageDetails: FC<{
  item: ConfigurationItem
  bodySites: string[]
  title: string
}> = ({ item, bodySites, title }) => {
  return (
    <>
      <span className="mb-2 inline-flex w-full items-center font-medium text-gray-400">
        {title}
        <hr className="ml-1 flex-1" />
      </span>
      <div className="flex gap-2">
        <span className="text-gray-900">Zone:</span>
        <span className="text-gray-700">{item.zone?.display}</span>
      </div>
      <div className="flex gap-2">
        <span className="text-gray-700">{`${bodySites.length} selected ${pluralize("point", bodySites.length)}`}</span>
      </div>
    </>
  )
}

const AdministrationSite: FC<{ site: string; className?: string }> = ({ site, className }) => (
  <div className={classNames("flex flex-col gap-2", className)}>
    <div className="flex gap-2">
      <span className="text-gray-900">Administration site: </span>
      <span className="text-gray-700">{site}</span>
    </div>
  </div>
)

type Props = {
  patientId: string
  encounter: string
  icon: IconDefinition
}

export { ProceduresSection }
