import { faSearch } from "@fortawesome/pro-light-svg-icons"
import { faAddressBook, faFileInvoiceDollar, faIdCard } from "@fortawesome/pro-regular-svg-icons"
import { faPills } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { MenuItemCommandEvent } from "primereact/menuitem"
import { useId } from "react"
import { useNavigate } from "react-router-dom"

import {
  Button,
  GroupedList,
  InfiniteScroll,
  SkeletonLoader,
  useFiltersContext,
  ViewContainerWithFilters,
} from "commons"
import { ModulesId } from "commons/Module"
import { useAppModuleContext } from "internals"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { genders } from "data"

import { useExportPatients, usePatients } from "../hooks"
import type { FilterProps } from "../types"
import { PatientEmptyState } from "./PatientEmptyState"
import { PatientListItem } from "./PatientListItem"

const PatientList = ({ showForm }: Props) => {
  const navigate = useNavigate()
  const { currentOrganizationId } = useOrganizationContext()

  const { isModuleActive } = useAppModuleContext()
  const {
    searchText,
    filters: { gender, practitioner },
    onClearFilters,
    onFilter,
    onSearch,
  } = useFiltersContext<FilterProps>()

  const { patientGroups, isLoading, isFetchingNextPage, count, total, hasNextPage, fetchNextPage } = usePatients(
    searchText,
    gender,
    practitioner,
  )

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="list" />

  const { organizationPractitionerRefs } = useOrganizationPractitioners({ organizationId: currentOrganizationId })
  const { exportPatients, isExporting } = useExportPatients()

  const navigateAndStopPropagation = (path: string) => (e: MenuItemCommandEvent) => {
    e.originalEvent.stopPropagation()
    navigate(path)
  }

  return (
    <ViewContainerWithFilters
      isLoading={isLoading || isFetchingNextPage}
      itemTitle="patient"
      itemCount={count}
      itemTotal={total}
      actionButtons={
        <div className="flex gap-2">
          <Button label="Create New Patient" onClick={showForm} disabled={organizationPractitionerRefs?.length === 0} />
          <Button
            label="Export Patients"
            buttonStyle="default"
            onClick={() => exportPatients()}
            disabled={organizationPractitionerRefs?.length === 0}
            loading={isExporting}
          />
        </div>
      }
      initialFilters={{
        gender: gender,
        practitioner: practitioner,
      }}
      initialSearchText={searchText}
      filtersData={[
        {
          label: "Biological Sex",
          field: "gender",
          data: genders,
          type: "select",
          optionLabel: "label",
          optionValue: "code",
        },
        {
          label: "General practitioner",
          field: "practitioner",
          data: organizationPractitionerRefs,
          type: "practitionerReference",
        },
      ]}
      onTextFilter={(searchText) => onSearch(searchText)}
      onFilter={(filters) => onFilter(filters ?? {})}
      onClear={onClearFilters}
    >
      {isLoading ? (
        loader()
      ) : !patientGroups?.length ? (
        <PatientEmptyState onAdd={organizationPractitionerRefs?.length ? showForm : undefined} />
      ) : (
        <div className="flex grow flex-col overflow-auto">
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
            <GroupedList
              className="grow"
              groups={patientGroups}
              renderItem={(patient) => (
                <PatientListItem
                  patient={patient}
                  onClick={() =>
                    navigate(`/orgs/${currentOrganizationId}/patients/${patient.id}?kp=${ModulesId.PATIENT}`)
                  }
                  dropdownItems={[
                    {
                      label: "View Patient",
                      icon: <FontAwesomeIcon icon={faIdCard} size="sm" className="mr-2" />,
                      command: navigateAndStopPropagation(`/orgs/${currentOrganizationId}/patients/${patient.id}`),
                    },
                    {
                      label: "View Contact Information",
                      icon: <FontAwesomeIcon icon={faAddressBook} size="sm" className="mr-2" />,
                      command: navigateAndStopPropagation(
                        `/orgs/${currentOrganizationId}/patients/${patient.id}?view=${ModulesId.PATIENT}&subview=contact`,
                      ),
                    },
                    {
                      label: "View Patient Invoices",
                      icon: <FontAwesomeIcon icon={faFileInvoiceDollar} size="sm" className="mr-2" />,
                      command: navigateAndStopPropagation(
                        `/orgs/${currentOrganizationId}/patients/${patient.id}?view=${ModulesId.INVOICE}`,
                      ),
                    },
                    ...(isModuleActive(ModulesId.MEDICATIONR)
                      ? [
                          {
                            label: "Nutraceuticals",
                            icon: <FontAwesomeIcon icon={faPills} size="sm" className="mr-2" />,
                            command: navigateAndStopPropagation(
                              `/orgs/${currentOrganizationId}/patients/${patient.id}?view=${ModulesId.MEDICATIONR}`,
                            ),
                          },
                        ]
                      : []),
                  ]}
                />
              )}
              renderEmptyState={() => (
                <div className="flex flex-col items-center justify-center pt-10">
                  <FontAwesomeIcon icon={faSearch} size="2x" className="text-slate-500" />
                  <p className="pt-3 text-slate-400">No results found, please change filters and try again</p>
                </div>
              )}
            />
          </InfiniteScroll>
        </div>
      )}
    </ViewContainerWithFilters>
  )
}

type Props = {
  showForm(): void
}

export { PatientList }
