import { faCalendarDays, faClock, faLayerGroup, faUser } from "@fortawesome/pro-solid-svg-icons"
import { type Appointment, isPatient } from "fhir"

import type { StackedListItemProps } from "commons"
import { formatsByTypes } from "data"
import { formatDate, getAppointmentType } from "utils"

const appointmentModelBuilder = (appt: Appointment): StackedListItemProps => {
  const patient = appt.participant.find(({ actor }) => isPatient(actor))
  const apptType = getAppointmentType(appt, appt.description)

  return {
    leftData: [
      {
        lineItems: [
          {
            name: "Start",
            value: appt.start ? formatDate(new Date(appt.start.toString()), formatsByTypes.LONG_DATE) : "No date",
            icon: faCalendarDays,
          },
          {
            name: "Duration in minutes",
            value: appt.minutesDuration?.toString() ?? "No duration",
            icon: faClock,
          },
          {
            name: "Patient",
            value: patient?.actor?.display ?? "",
            icon: faUser,
          },
          ...(apptType
            ? [
                {
                  name: "Type",
                  value: apptType,
                  icon: faLayerGroup,
                },
              ]
            : []),
        ],
      },
    ],
    badge: { text: appt.status },
  }
}

export { appointmentModelBuilder }
