import {
  AutocompleteCodeableConceptField,
  InputField,
  SwitchField,
  NumberField,
  GenderField,
  ValueSetIds,
  MentionTextAreaField,
} from "commons"

import { FamilyHistoryConditions } from "./FamilyHistoryConditions"

const FamilyHistoryForm = () => (
  <div className="p-fluid relative mb-3 grid gap-4">
    <div className="flex justify-between space-x-6">
      <InputField field="name" label="Name" className="flex-1" />
      <SwitchField field="deceased['boolean']" label="Deceased" />
    </div>

    <div className="flex justify-between space-x-6">
      <AutocompleteCodeableConceptField
        field="relationship"
        label="Relationship"
        valueSetId={ValueSetIds.FAMILY_MEMBER}
        className="w-2/3"
      />
      <NumberField field="age.Age.value" label="Age" className="w-1/3" min={1} />
    </div>
    <GenderField field="sex" label="Biological Sex" className="w-full" useSexCodes />

    <MentionTextAreaField field="note[0].text" label="Note" rows={4} />
    <FamilyHistoryConditions />
  </div>
)

export { FamilyHistoryForm }
