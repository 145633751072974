import type { DatesSetArg, EventContentArg, EventInput, PluginDef } from "@fullcalendar/core/index.js"
import FullCalendar from "@fullcalendar/react"
import { type FC, type RefObject } from "react"
import DayGridPlugin from "@fullcalendar/daygrid"
import TimeGridPlugin from "@fullcalendar/timegrid"
import { humanNameAsString, type Practitioner } from "fhir"

import "./PractitionerFullCalendar.css"

const PractitionerFullCalendar: FC<Props> = ({
  practitioner,
  calendarRef,
  events,
  AgendaPlugin,
  handleUpdateDateRange,
  renderEvent,
  handleGoBack,
}) => {
  const practitionerName = humanNameAsString(practitioner.name?.[0])

  return (
    <FullCalendar
      ref={calendarRef}
      plugins={[DayGridPlugin, TimeGridPlugin, AgendaPlugin]}
      initialView="dayGridMonth"
      events={events}
      eventTimeFormat={{
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      }}
      customButtons={{
        customTitle: {
          text: `${practitionerName}'s Calendar`,
        },
        goBack: {
          icon: "chevron-left",
          click: handleGoBack,
        },
      }}
      headerToolbar={{
        left: "goBack,customTitle",
        right: "prev,today,next dayGridMonth timeGridWeek agenda",
      }}
      buttonText={{ month: "Month", week: "Week", agenda: "Agenda" }}
      height="100%"
      datesSet={handleUpdateDateRange}
      eventContent={renderEvent}
    />
  )
}

type Props = {
  practitioner: Practitioner
  calendarRef: RefObject<FullCalendar>
  AgendaPlugin: PluginDef
  events: EventInput[]
  renderEvent(eventInfo: EventContentArg): JSX.Element
  onShowFilters?(): void
  handleUpdateDateRange(_: DatesSetArg): void
  handleGoBack(): void
}

export { PractitionerFullCalendar }
