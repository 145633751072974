import { codeableConceptAsString } from "fhir"
import pluralize from "pluralize"
import type { FC } from "react"

import { CardSelectionItem } from "commons"

import type { MedicationRequestFormData } from "../../types"
import { getDispenseFrequency } from "../../utils/transformers"

const PrescriptionSelectionItem: FC<Props> = ({ className, selected, item, disabled, edit }) => {
  const title = codeableConceptAsString(item.medication?.CodeableConcept ?? item.medicationKnowledge?.code)
  const ingredient = item.medicationKnowledge?.ingredient?.[0]?.strength?.numerator?.unit
  const dispense = getDispenseFrequency(item.dispenseRequest?.dispenseInterval)
  const packaging =
    item.medicationKnowledge?.packaging?.type?.coding?.[0] &&
    `${item.medicationKnowledge?.packaging?.type?.coding?.[0]?.display}`

  const dosages = item.dosageInstruction?.flatMap(({ text }) => text ?? "")
  const refillsCount = item?.dispenseRequest?.numberOfRepeatsAllowed ?? 0

  return (
    <CardSelectionItem
      className={className}
      selected={selected}
      mainText={title}
      onSelect={() => {
        edit?.(item)
      }}
      clickableArea="card"
      showSelectedMark={true}
      extraDetails={
        <div className="relative mt-1 flex w-full flex-wrap gap-x-3 text-sm text-gray-300">
          {!!ingredient && <span title="Content">{ingredient}</span>}
          {!!item.medicationKnowledge?.doseForm?.coding?.[0]?.display && (
            <span title="Dose Form" className="inline-flex space-x-1">
              <p>{item.medicationKnowledge?.packaging?.quantity?.value}</p>
              <p>{item.medicationKnowledge?.doseForm?.coding?.[0]?.display}</p>
            </span>
          )}
          {!!packaging && <span title="Packaging">Pkg: {packaging}</span>}
          {!!item.dispenseRequest && (
            <>
              {!!item.dispenseRequest.quantity?.value && (
                <span title="Dispense">
                  {item.dispenseRequest.quantity?.value} {item.dispenseRequest.quantity?.unit} - {dispense?.label}
                </span>
              )}
              {!!refillsCount && <span title="Refill">{`${refillsCount} ${pluralize("refill", refillsCount)}`}</span>}

              {!!item.dispenseRequest.performer?.display && (
                <span title="Pharmacy">{item.dispenseRequest.performer.display}</span>
              )}
            </>
          )}
          {!item.dispenseRequest?.performer && !!item.medicationKnowledge?.manufacturer?.display && (
            <span title="Manufacturer">{item.medicationKnowledge?.manufacturer?.display}</span>
          )}

          <span title={`Sig: ${dosages?.join(" ")}`} className="relative flex overflow-hidden">
            <span className="line-clamp-1 gap-x-1 text-ellipsis">
              {dosages?.flatMap((text, index) => <p key={`${index}-${text}`}>{text}</p>)}
            </span>
          </span>
        </div>
      }
      disabled={disabled}
    />
  )
}

type Props = {
  className?: string
  selected?: boolean
  item: MedicationRequestFormData
  disabled?: boolean
  edit?(item: MedicationRequestFormData): void
}

export { PrescriptionSelectionItem }
