import type { Coding, Identifier, Reference } from "fhir"
import { useFormikContext } from "formik"

import { CodeableConceptField, InputField, PeriodField, ReferenceDropdownField } from "commons"
import { IDENTIFIER_CODE } from "data"

const IdentifierForm = ({ fieldPath, types, assigners }: Props) => {
  const {
    values: { type },
  } = useFormikContext<Identifier>()

  const template = (code: Coding) => (
    <div className="flex flex-col">
      <div>{`${code.display} (${code.code?.toUpperCase()})`}</div>
      <div className="text-sm text-gray-400">{code.system}</div>
    </div>
  )

  return (
    <div className="p-fluid relative w-full space-y-5">
      <InputField field={fieldPath ? `${fieldPath}.value` : "value"} label="Value" className="flex-1" />
      {types?.length ? (
        <CodeableConceptField
          codes={types}
          field={fieldPath ? `${fieldPath}.type` : "type"}
          label="Type"
          className="flex-1"
          itemTemplate={template}
        />
      ) : (
        <InputField field={fieldPath ? `${fieldPath}.system` : "system"} label="System" className="flex-1" />
      )}
      {type?.coding?.[0]?.code === IDENTIFIER_CODE.AN && (
        <ReferenceDropdownField
          field={fieldPath ? `${fieldPath}.assigner` : "assigner"}
          label="Assigner"
          options={assigners}
        />
      )}

      <PeriodField
        field={fieldPath ? `${fieldPath}.period` : "period"}
        stringFormatType="ISO_8601_DATE"
        className="flex-1"
      />
    </div>
  )
}

type Props = {
  fieldPath?: string
  types?: Coding[]
  assigners?: Reference[]
}
export { IdentifierForm }
