import type { FC } from "react"
import type { Reference } from "fhir"

import { DateField, FileUploaderField, InputField, MentionTextAreaField } from "commons"
import { azureContainer, MAX_FILE_SIZE } from "data"

import { LabResults } from "./LabResults"
import "./LabsForm.css"

const LabsForm: FC<Props> = ({ patient, encounter }) => (
  <div className="p-fluid relative mb-3 grid gap-4">
    <InputField field="dr.performer[0].display" label="Performer" />
    <DateField
      field="dr.effective.dateTime"
      label="Effective"
      stringFormatType="ISO_8601_DATETIME"
      showTime
      className="grow"
    />
    <FileUploaderField
      field="dr.presentedForm[0]"
      azureContainer={azureContainer.drs}
      label="Health report"
      maxFileSize={MAX_FILE_SIZE}
      className="button-primary"
    />
    <MentionTextAreaField field="dr.conclusion" label="Conclusion" rows={5} />
    <LabResults patient={patient} encounter={encounter} />
  </div>
)
type Props = { patient: Reference; encounter?: Reference }

export { LabsForm }
