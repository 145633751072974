import { faClock } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isPractitioner, type Quantity } from "fhir"
import { useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { type FC, useEffect, useMemo, useState } from "react"
import { differenceInMinutes } from "date-fns"

import { AutoCompletePatientField, ReferenceDropdownField } from "commons"
import { useOrganizationContext } from "organization"

import { telemedicineServiceCC } from "../data"
import { useScheduleAppointmentContext } from "../hooks"
import type { AppointmentFormData } from "../types"
import { AppointmentCalendar } from "./AppointmentCalendar"
import { roundTime } from "../utils"

const AppointmentCalendarSection: FC<Props> = ({ hidePatientField, isEditing }) => {
  const { updateSelectedDate, updateSelectedPatientGender } = useScheduleAppointmentContext()
  const { healthcareServicesRefs, healthcareServices } = useOrganizationContext()
  const {
    values: { healthcareService, override, selectedSlot },
    setFieldValue,
  } = useFormikContext<AppointmentFormData>()
  const selectedHealthcareService = useMemo(
    () => healthcareServices.find(({ id }) => id === healthcareService?.id),
    [healthcareServices, healthcareService],
  )
  const practitionersRefs = useMemo(
    () => selectedHealthcareService?.participant?.filter(isPractitioner) ?? [],
    [selectedHealthcareService],
  )
  const [duration, setDuration] = useState(selectedHealthcareService?.duration)

  useEffect(() => {
    if (override) {
      setDuration({
        value: differenceInMinutes(roundTime(selectedSlot?.end), roundTime(selectedSlot?.start)),
        unit: "minutes",
      } as Quantity)
    } else {
      setDuration(selectedHealthcareService?.duration)
    }
    if (duration?.value) setFieldValue("minutesDuration", duration?.value)
  }, [duration, override])

  useEffect(() => {
    const isTelemedicineAppointment = selectedHealthcareService?.characteristic?.some(
      ({ coding }) => coding?.[0]?.code === "telemedicine",
    )
    setFieldValue("virtualService", isTelemedicineAppointment ? [telemedicineServiceCC] : undefined)
    setDuration(selectedHealthcareService?.duration)
    setFieldValue("override", false)
  }, [selectedHealthcareService])

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col">
        <ReferenceDropdownField
          field="healthcareService"
          label="Appointment Type"
          options={healthcareServicesRefs}
          className="w-full"
          showFilter={false}
          validation={(val) => (!val?.id ? "Appointment type is required" : undefined)}
          handleChange={() => updateSelectedDate(undefined)}
        />
        <div
          className={classNames("-mt-3 flex h-4 items-center gap-1 text-sm text-gray-400", {
            hidden: !duration,
          })}
        >
          <FontAwesomeIcon icon={faClock} size="sm" />
          {duration?.value} {duration?.unit} session
        </div>
      </div>
      {!hidePatientField && (
        <AutoCompletePatientField
          field="patient"
          label="Patient"
          isReadonly={isEditing}
          withPatientDetails
          onSelect={(patient) => {
            updateSelectedPatientGender(patient?.gender)
          }}
        />
      )}
      <ReferenceDropdownField
        field="practitioner"
        label="Practitioner"
        options={practitionersRefs}
        className="w-full"
        isClearable
        disabled={!selectedHealthcareService}
        handleChange={() => updateSelectedDate(undefined)}
      />
      <AppointmentCalendar />
    </div>
  )
}

type Props = {
  hidePatientField?: boolean
  isEditing?: boolean
}

export { AppointmentCalendarSection }
