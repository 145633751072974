import { format } from "date-fns"
import { codeableConceptAsString } from "fhir"
import pluralize from "pluralize"

import { useChartContext } from "chart-view"
import { type StackedListItemProps, KnowledgeListContainer, MenuStyles, ModulesId, SearchWithStatus } from "commons"
import {
  type MedicationRequestData,
  useMedicationRequestDataBind,
  useMedicationRequests,
  useMedReducerState,
  useMrOrders,
} from "commons/meds"
import { formatsByTypes, medicationRequestStatus } from "data"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

const MedicationKnowledge: React.FC = () => {
  const { patientId } = usePatientContext()
  const { showModule } = useChartContext()

  const { statusFilter, searchFilter, updateFilters, updateSearchText } = useMedReducerState({
    statusFilter: ["active"],
  })

  const { medicationRequests, medicationKnowledges, isLoading } = useMedicationRequests({
    patientId,
    category: "nutraceutical",
    statusFilter,
    searchText: searchFilter,
  })
  const { serviceRequests, isLoading: isLoadingSR } = useMrOrders(
    patientId,
    medicationRequests?.map((med) => med.id as string),
  )
  const { appModules } = useAppModuleContext()

  const { medicationRequestData } = useMedicationRequestDataBind({
    medicationRequests,
    medicationKnowledges,
    serviceRequests,
  })

  const showOrder = (orderId: string) =>
    showModule({ module: ModulesId.MEDICATIONR, moduleParams: { subview: "orders", order: orderId } })

  return (
    <div className="mt-1 flex flex-1 flex-col overflow-hidden">
      <SearchWithStatus
        className="mx-5 my-3"
        placeholder="Search products"
        options={medicationRequestStatus}
        selectedItems={statusFilter}
        onStatusCheck={updateFilters}
        onSearch={(filter) => updateSearchText(filter ?? "")}
      />
      <KnowledgeListContainer
        data={medicationRequestData}
        dataTitle="Products"
        iconDataNotFound={appModules[ModulesId.MEDICATIONR].getIcon()}
        itemModelBuilder={(item) =>
          medicationModel(
            item,
            item.serviceRequest?.id ? () => showOrder(item.serviceRequest?.id as string) : undefined,
          )
        }
        isLoading={isLoading || isLoadingSR}
      />
    </div>
  )
}

const medicationModel = (med: MedicationRequestData, showOrder?: () => void): StackedListItemProps => {
  const packing = med.medicationKnowledge?.packaging?.type
  const amount =
    med.medicationRequestInfo.dispenseRequest?.quantity?.value ??
    med.medicationRequestInfo.dispenseRequest?.initialFill?.quantity?.value ??
    1
  const date = med.serviceRequest?.occurrence?.dateTime ?? med.medicationRequestInfo.authoredOn
  return {
    leftData: [
      {
        lineItems: [
          { name: "Medication", value: codeableConceptAsString(med.medicationRequestInfo.medication?.CodeableConcept) },
        ],
      },
      {
        lineItems: [
          ...(date ? [{ value: format(new Date(date), formatsByTypes.SHORT_DATE) }] : []),
          ...(packing ? [{ value: `(${amount} ${pluralize(codeableConceptAsString(packing), amount)})` }] : []),
        ],
      },
    ],
    menuStyle: MenuStyles.ExternalAction,
    menu: [
      ...(showOrder
        ? [
            {
              label: "Show order",
              command: showOrder,
            },
          ]
        : []),
    ],
  }
}

export { MedicationKnowledge }
