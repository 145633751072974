import { faMars, faMarsAndVenus, faVenus } from "@fortawesome/pro-regular-svg-icons"
import { getFirstEmail, humanNameAsString } from "fhir"
import type { MenuItem } from "primereact/menuitem"

import { AvatarImage } from "commons/components/AvatarImage"
import type { StackedListItemProps } from "commons/components/StackedListItem"
import { type PractitionerInfo, MenuStyles } from "commons/types"
import { calculateAge } from "utils"

const getPractitionerModel = ({ practitionerInfo, menu }: Props): StackedListItemProps => {
  const { practitioner } = practitionerInfo

  return {
    image: (
      <AvatarImage
        photoUrl={practitioner.photo?.[0]?.url}
        name={humanNameAsString(practitioner.name?.[0])}
        className="h-10 w-10 rounded-full"
      />
    ),
    leftData: [
      {
        lineItems: [{ name: "Name", value: humanNameAsString(practitioner.name?.[0]) }],
      },
      {
        lineItems: [
          ...(practitioner.birthDate ? [{ name: "Age", value: calculateAge(practitioner.birthDate) }] : []),
          ...(practitioner.gender
            ? [
                {
                  name: practitioner.gender,
                  icon:
                    practitioner.gender === "male"
                      ? faMars
                      : practitioner.gender === "female"
                        ? faVenus
                        : faMarsAndVenus,
                },
              ]
            : []),
          { name: "Email", value: getFirstEmail(practitioner.telecom) },
        ],
      },
    ],
    menu: menu?.(practitionerInfo),
    menuStyle: menu && menu.length > 1 ? MenuStyles.Dropdown : MenuStyles.ActionItems,
  }
}

type Props = {
  practitionerInfo: PractitionerInfo
  menu?: (practInfo: PractitionerInfo) => MenuItem[]
}

export { getPractitionerModel }
