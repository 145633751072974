import { type IconDefinition } from "@fortawesome/pro-light-svg-icons"

import { WrapUpSection } from "./WrapUpSection"
import type { IntakeData } from "../../types"
import { ObservationItem } from "./ObservationItem"

const IntakeLabSection = ({ labs, total, icon, hideSpacer }: Props) => (
  <WrapUpSection
    subSectionTitle="Imported Labs"
    isEmpty={!total}
    icon={icon}
    emptyMesage="No labs imported during this encounter"
    className="flex w-full flex-row text-sm"
    inlineContent={!hideSpacer}
  >
    <div className="flex w-full flex-col gap-6 divide-y divide-dashed divide-gray-200 pr-7">
      {labs?.map((observation) => <ObservationItem key={observation.id} observation={observation} className="pt-6" />)}
    </div>
  </WrapUpSection>
)

type Props = {
  total?: number
  icon: IconDefinition
  hideSpacer?: boolean
} & Pick<IntakeData, "labs">

export { IntakeLabSection }
