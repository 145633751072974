import type { Composition, Reference } from "fhir"
import { useEffect, useMemo, type FC } from "react"
import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import type { QueryKey } from "@tanstack/react-query"
// eslint-disable-next-line import/no-named-as-default
import DOMPurify from "dompurify"

import { useCrudReducer, DataContainerForm, EditorField } from "commons"
import { useOrganizationContext } from "organization"

import { useCreatePatientComposition, useUpdatePatientComposition } from "../hooks"
import { Assessment_Default_Template, getAssessmentInitialValues, sanitize } from "./validations"
import { AssessmentNoteType, CompositionSectionCode } from "../types"

const AssessmentNote: FC<Props> = ({
  assessment,
  patientRef,
  encounterRef,
  queriesToInvalidate,
  className,
  contentClassName,
  buttonClassName,
  type = AssessmentNoteType.ASSESSMENT,
}) => {
  const { loggedInPractitionerRoleRef } = useOrganizationContext()

  const assessmentInitialValue =
    assessment ?? getAssessmentInitialValues(loggedInPractitionerRoleRef, patientRef, encounterRef, true)

  const {
    initialValue,
    reset,
    showSlide: showForm,
    edit,
    updateInitialValue,
  } = useCrudReducer({
    defaultEntity: assessmentInitialValue,
  })

  const sectionCode =
    type === AssessmentNoteType.ASSESSMENT ? CompositionSectionCode.ASSESSMENT : CompositionSectionCode.PLAN
  let noteIndex = initialValue.section?.findIndex(({ code }) => code?.coding?.[0]?.code === sectionCode) ?? 0
  noteIndex = noteIndex === -1 ? initialValue.section?.length ?? 0 : noteIndex

  const assessmentId = initialValue.id
  const assessmentNote = initialValue.section?.[noteIndex]?.text?.div
  const emptyNote = useMemo(
    () => !assessmentNote || assessmentNote?.localeCompare(Assessment_Default_Template) === 0,
    [assessmentNote],
  )

  const { createAssessment } = useCreatePatientComposition(reset, queriesToInvalidate)
  const { updateAssessment } = useUpdatePatientComposition(reset, queriesToInvalidate)

  const onSubmit = (data: Composition) => {
    if (data.section?.[0]?.text?.div === assessmentNote) {
      reset()
    } else {
      if (!data.id) createAssessment(sanitize(data))
      else updateAssessment(sanitize(data))
    }
  }

  useEffect(() => {
    updateInitialValue(assessmentInitialValue)
  }, [assessmentInitialValue])

  return (
    <DataContainerForm
      form={
        <EditorField
          field={`section[${noteIndex}].text.div`}
          className="py-1"
          inputClassName="pb-5 h-full min-h-96"
          autoFocus
        />
      }
      formInitialValue={{ ...initialValue, id: assessmentId }}
      onSubmit={onSubmit}
      onCancel={reset}
      enableReinitialize
      disableSave={false}
      showForm={showForm}
      hasData={!emptyNote}
      itemTitle={type}
      itemTitleNotFound={type}
      cancelButtonLabel="Cancel"
      containerClassName={className}
      onButtonAddClick={() => edit(initialValue)}
      subMessageDataNotFound=""
      messageDataNotFound={`No ${type} note found`}
      customAddButtonText={`Add ${type} note`}
      customAddButton={<></>}
      emptyAddButtonStyle="outlined"
      saveButtonStyle="outlined"
      innerContainerClassName="pr-3 space-y-6 pb-6"
    >
      {assessmentNote && (
        <div className={classNames("inline-flex w-full items-start space-x-3", contentClassName)}>
          <div
            className="editor-note w-full text-sm whitespace-pre-line"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(assessmentNote, {
                ALLOWED_ATTR: ["class", "style", "src", "href"],
              }),
            }}
          />
          <FontAwesomeIcon
            icon={faEdit}
            className={classNames(
              "mt-1 cursor-pointer text-lg text-gray-400 hover:text-primary-hover",
              buttonClassName,
            )}
            onClick={() => edit(initialValue)}
          />
        </div>
      )}
    </DataContainerForm>
  )
}

type Props = {
  patientRef: Reference
  assessment?: Composition
  encounterRef?: Reference
  className?: string
  contentClassName?: string
  queriesToInvalidate: QueryKey
  buttonClassName?: string
  type?: AssessmentNoteType
}

export { AssessmentNote }
