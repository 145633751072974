import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatDistance } from "date-fns"
import { getFirstEmail, getFirstPhone, humanNameAsString } from "fhir"
import { Button } from "primereact/button"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"

import { AvatarImage, KpListItem, ModulesId } from "commons"
import { useCoverages } from "coverage"
import { useAppModuleContext } from "internals"
import { useOrganizationContext } from "organization"
import { usePatientInvitation } from "patients"
import { SYSTEM_VALUES } from "system-values"
import { getStringAddressByType } from "utils"

import type { PatientWithLinkedUser } from "../types"

const PatientInfo = ({ patient, closePanel }: Props) => {
  const navigate = useNavigate()
  const { coveragesList, isLoading: isInsuranceLoading } = useCoverages(patient?.id as string)
  const hasB2CIdentifier = patient.linkedUser?.identifier?.some(({ system }) => system === SYSTEM_VALUES.B2C)
  const { invitation, isLoading } = usePatientInvitation(patient?.id as string)
  const { currentOrganizationId } = useOrganizationContext()
  const { isModuleActive } = useAppModuleContext()
  const { address, telecom, generalPractitioner } = patient ?? {}
  const homeAddress = getStringAddressByType({ address })

  const invitationStatus = () => {
    if (hasB2CIdentifier) return "Active"
    if (invitation) {
      switch (invitation.status) {
        case "active":
          return "Sending invitation email"
        case "completed":
          return `Invitation pending to accept. The link expires in ${formatDistance(
            new Date(invitation.occurrence?.dateTime as string),
            new Date(),
          )}.`
      }
    }
    return invitation?.status === "revoked" ? "Error sending email" : "Not invited"
  }

  const navigateToPatientInformationView = useCallback(
    (view?: string, subview?: string) => {
      let url = `/orgs/${currentOrganizationId as string}/patients/${patient?.id as string}?kp=${
        ModulesId.PATIENT
      }&view=${view || ModulesId.PATIENT}`
      if (subview) {
        url += `&subview=${subview}`
      }
      navigate(url)
    },
    [navigate, patient?.id, currentOrganizationId],
  )

  return (
    <div className="flex h-full flex-col justify-start overflow-hidden border-t border-gray-200 px-4 py-5 first:border-none sm:p-0">
      <div className="flex justify-end p-1">
        <Button
          className="p-button p-component p-button-text p-button-icon-only rounded-full text-sm"
          title="Close panel"
          icon={<FontAwesomeIcon icon={faTimes} />}
          onClick={() => closePanel()}
        />
      </div>
      <div className="mb-5 flex w-full flex-col">
        <AvatarImage
          name={humanNameAsString(patient?.name?.[0])}
          photoUrl={patient?.photo?.[0]?.url}
          className="m-6 h-auto w-auto self-center rounded-full md:max-h-32 md:max-w-32 xl:max-h-44 xl:max-w-44"
        />
        <h2 className="self-center text-lg font-medium text-gray-800">{humanNameAsString(patient?.name?.[0])}</h2>
      </div>
      <h3 className="sticky top-0 flex min-w-full bg-white text-sm font-medium text-gray-800">Patient information</h3>
      <dl className="flex w-full grow flex-col overflow-x-hidden overflow-y-auto pr-1.5 text-xs sm:divide-y sm:divide-gray-200">
        <KpListItem
          content={generalPractitioner?.[0].display ?? "Unspecified practitioner"}
          label="Practitioner"
          onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.PATIENT, "basic-info")}
          externalActionLabel="Edit practitioner"
        />
        <KpListItem
          content={homeAddress}
          label="Address"
          onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.PATIENT, "address")}
          externalActionLabel="Edit address"
        />
        <KpListItem
          content={getFirstEmail(telecom)}
          label="Email"
          onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.PATIENT, "telecom")}
          externalActionLabel="Edit email"
        />
        <KpListItem
          content={getFirstPhone(telecom)}
          label="Phone"
          onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.PATIENT, "telecom")}
          externalActionLabel="Edit phone"
        />
        <KpListItem
          content={invitationStatus()}
          label="Status"
          isLoading={isLoading}
          onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.PATIENT, "status")}
          externalActionLabel="View status"
        />
        <KpListItem
          content={isInsuranceLoading ? "" : coveragesList ? coveragesList : "Unspecified insurance"}
          label="Insurance"
          isLoading={isInsuranceLoading}
          onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.PATIENT, "insurance")}
          externalActionLabel="View insurance"
        />
        <KpListItem
          content={""}
          label="Nutraceuticals Orders"
          onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.MEDICATIONR)}
          externalActionLabel="View medications"
        />

        {isModuleActive(ModulesId.EPRESCRIBE) && (
          <KpListItem
            content={""}
            label="Rx Orders"
            onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.EPRESCRIBE)}
            externalActionLabel="View eprescribe"
          />
        )}
        {isModuleActive(ModulesId.LABSORDER) && (
          <KpListItem
            content={""}
            label="Labs Orders"
            onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.LABSORDER)}
            externalActionLabel="View labs"
          />
        )}
      </dl>
    </div>
  )
}

type Props = {
  closePanel: () => void
  patient: PatientWithLinkedUser
  patientId: string
}

export { PatientInfo }
