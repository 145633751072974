import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"

import { type VitalColData, getVitalName } from "vitals"
import { vitalSignCodes } from "data"

import { WrapUpSection } from "./WrapUpSection"

const VitalsSection = ({ vitals, total, icon, hideSpacer }: Props) => (
  <WrapUpSection
    subSectionTitle="Vitals"
    isEmpty={!total}
    icon={icon}
    emptyMesage="No vitals gathered in this encounter"
    className="flex w-full flex-row text-sm"
    inlineContent={!hideSpacer}
  >
    <div className="flex w-full flex-col gap-2 divide-y divide-dashed divide-gray-200">
      {Object.keys(vitalSignCodes).flatMap((key, index) => {
        const vitalValue = vitals?.[key as keyof VitalColData]
        const vitalQty = vitalValue?.[0]?.value

        return (
          vitalQty?.value && (
            <div className="flex w-full justify-between self-end py-2 pr-7 text-sm" key={`${key}_${index}`}>
              <span>{getVitalName(key as keyof VitalColData)}</span>
              <span className="text-gray-400">
                {vitalQty?.value} {vitalQty?.unit}
              </span>
            </div>
          )
        )
      })}
    </div>
  </WrapUpSection>
)

type Props = {
  vitals?: VitalColData
  total?: number
  icon: IconDefinition
  hideSpacer?: boolean
}

export { VitalsSection }
