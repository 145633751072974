import type { Slot } from "fhir"
import { isBefore, isEqual, isSameDay, parseISO } from "date-fns"
import { toZonedTime } from "date-fns-tz"
import { type FieldProps, useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { Calendar } from "primereact/calendar"
import { type FC, useEffect, useMemo } from "react"

import { CheckBoxField, FormField } from "commons"
import { formatsByTypes } from "data"
import { formatDate } from "utils"

import { useFindAvailableSlots, useScheduleAppointmentContext } from "../hooks"
import type { AppointmentFormData } from "../types"
import { roundTime } from "../utils"

const AppointmentSlotsSection: FC = () => {
  const {
    selectedMonthBounds: { start: startDate, end: endDate },
    selectedDate,
  } = useScheduleAppointmentContext()
  const {
    values: { patient, practitioner, selectedSlot, healthcareService, override },
    setFieldValue,
  } = useFormikContext<AppointmentFormData>()

  const { slots, isLoading } = useFindAvailableSlots({
    healthcareService,
    startDate,
    endDate,
    patient,
    practitioner,
  })

  const currentDaySlots = useMemo(
    () => (selectedDate ? slots.filter(({ start }) => isSameDay(start, selectedDate)) : []),
    [selectedDate, slots],
  )
  const newSlot = (start?: Date, end?: Date) => {
    return {
      start: start ?? selectedSlot?.start,
      end: end ?? selectedSlot?.end,
      resourceType: "Slot",
    } as Slot
  }

  const overrideTimeError = (slot?: Partial<Slot>) => {
    const error = isBefore(roundTime(slot?.end), roundTime(slot?.start))
    return error
  }
  useEffect(() => {
    setFieldValue("start", selectedSlot?.start && toZonedTime(new Date(selectedSlot?.start), "UTC").toISOString())
    setFieldValue("end", selectedSlot?.end && toZonedTime(new Date(selectedSlot?.end), "UTC").toISOString())
  }, [selectedSlot])

  useEffect(() => {
    setFieldValue("start", override ? roundTime() : undefined)
    setFieldValue("end", override ? roundTime() : undefined)
  }, [override])

  return (
    <fieldset className="disabled:pointer-events-none disabled:opacity-40" disabled={!selectedDate}>
      <FormField
        field="selectedSlot"
        label={
          <div className="flex w-full justify-between">
            <p>Pick time</p>
            <CheckBoxField
              field="override"
              label="Override"
              onCheck={(check) => setFieldValue("selectedSlot", check ? newSlot(roundTime(), roundTime()) : undefined)}
            />
          </div>
        }
        labelClassName="w-full"
      >
        {({ field: { name, value }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) =>
          override ? (
            <div>
              <div className="flex gap-4">
                <Calendar
                  value={selectedSlot?.start ?? roundTime()}
                  onChange={(e) => setFieldValue("selectedSlot", newSlot(roundTime(e.value ?? undefined)))}
                  timeOnly
                  stepMinute={15}
                  maxDate={selectedSlot?.end ?? roundTime()}
                />
                <Calendar
                  value={selectedSlot?.end ?? roundTime()}
                  onChange={(e) => setFieldValue("selectedSlot", newSlot(undefined, roundTime(e.value ?? undefined)))}
                  timeOnly
                  stepMinute={15}
                  minDate={selectedSlot?.start ?? roundTime()}
                />
              </div>
              {overrideTimeError(selectedSlot) && (
                <div className="p-error mt-1 flex h-2 items-start">
                  <small className="p-error">{"Start time must be before end time"}</small>
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="grid grid-cols-2 gap-4">
                {currentDaySlots.length
                  ? currentDaySlots.map((slot, index) => (
                      <span
                        key={`slot-${index}`}
                        className={classNames(
                          "p-component p-inputtext p-inputtext-sm h-[38.75px] cursor-pointer justify-center",
                          {
                            "bg-light-primary border-primary font-semibold text-primary":
                              value?.start && isEqual(value.start, slot.start),
                            "p-invalid border": touched && error,
                          },
                        )}
                        role="button"
                        onClick={() => {
                          setFieldValue(name, slot)
                        }}
                      >
                        {formatDate(
                          toZonedTime(parseISO(new Date(slot.start).toISOString()), "UTC"),
                          formatsByTypes.TIME,
                        )}
                      </span>
                    ))
                  : !selectedDate &&
                    Array.from({ length: 14 }).map((_, index) => (
                      <span
                        key={`skeleton-${index}`}
                        className={classNames(
                          "p-component p-inputtext p-inputtext-sm pointer-events-none h-[38.75px]",
                          {
                            "animate-pulse border-none bg-gray-300": isLoading,
                            "p-invalid border": touched && error,
                          },
                        )}
                      />
                    ))}
              </div>
              {selectedDate && !currentDaySlots.length && (
                <div className="mt-4 flex flex-col items-center">
                  <span className="text-sm text-gray-400">{"No appoiment times available."}</span>
                  <span className="text-sm text-gray-400">{"Select the override option to obtain a new time."}</span>
                </div>
              )}
            </div>
          )
        }
      </FormField>
    </fieldset>
  )
}

export { AppointmentSlotsSection }
