import { faSquareArrowDown } from "@fortawesome/pro-solid-svg-icons"
import type { Observation } from "fhir"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"

import { Button, Slideover } from "commons"
import { openLinkInNewTab } from "utils"

import { useMCReportUrl } from "../../hooks"
import type { TriadData } from "../../types"
import { TriadItem } from "./TriadItem"
import { VitalityDetails, VitalityItem } from "./VitalityDetails"

const Overview = ({ vitalityIndex, vitality, triadsData }: Props) => {
  const [showVitality, setShowVitality] = useState(false)
  const { getReportUrl, isLoadingUrl } = useMCReportUrl(openLinkInNewTab)
  const [searchParams] = useSearchParams()

  const downloadResult = (carePlanId: string) => {
    getReportUrl({ carePlanId })
  }

  return (
    <div className="flex flex-col gap-4 overflow-y-auto py-4">
      <>
        <div className="mr-6 ml-48 flex justify-end">
          <Button
            onClick={() => downloadResult(searchParams.get("mcPlanId") as string)}
            label="Download report"
            disabled={isLoadingUrl}
            loading={isLoadingUrl}
            icon={faSquareArrowDown}
          />
        </div>
        {!!vitalityIndex && (
          <div className="mr-6 ml-48">
            <VitalityItem vitalityItem={vitalityIndex} className="w-full" />
          </div>
        )}
        {/* <div className="grid grid-cols-3 text-xs font-medium">
        <div className="flex col-start-2 w-60 ml-11 -mb-6 justify-between">
          <span className="flex-1 text-center">Symptoms</span>
          <span className="flex-1 text-center">Labs</span>
          <span className="flex-1 text-center">Total</span>
        </div>
      </div> */}
        {triadsData?.map((item) => <TriadItem key={item.triad.id} triadItem={item} />)}
        <Slideover
          showSlide={showVitality}
          onHide={() => setShowVitality(false)}
          className="w-3/5 3xl:w-1/2"
          dismissable
          showCloseIcon
        >
          <VitalityDetails vitality={vitality} />
        </Slideover>
      </>
    </div>
  )
}

type Props = {
  triadsData: TriadData[]
  vitalityIndex?: Observation
  vitality: Observation[]
}

export { Overview }
