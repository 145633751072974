import { faEnvelope, faMars, faVenus } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Patient, type Reference, asReference, getFirstEmail, humanNameAsString } from "fhir"
import { type FieldProps } from "formik"
import { AutoComplete } from "primereact/autocomplete"
import { classNames } from "primereact/utils"
import { type FC, useDeferredValue, useState } from "react"

import { usePatientsRefs } from "patients"
import { calculateAge, strCapitalize } from "utils"

import { FormField } from "./FormField"

const AutoCompletePatientField: FC<Props> = ({
  field,
  label,
  className,
  isReadonly,
  horizontal,
  inputClassName,
  withPatientDetails,
  onSelect,
}) => {
  const [filter, setFilter] = useState<string | undefined>()
  const deferredFilter = useDeferredValue(filter)
  const enabledFetch = (deferredFilter?.length ?? 0) >= 3
  const { refetch } = usePatientsRefs({ filter: enabledFetch ? deferredFilter : "" })
  const [filteredItems, setFilteredItems] = useState<Patient[] | Reference[]>()

  const searchPatientsRefs = async () => {
    const { data } = await refetch()
    if (withPatientDetails) {
      setFilteredItems([...(data?.patients ?? [])])
      return
    }
    setFilteredItems([...(data?.patientRefs ?? [])])
  }

  return (
    <FormField field={field} className={className} horizontal={horizontal} label={label}>
      {({ field: { name, value }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => (
        <AutoComplete
          id={name}
          name={name}
          suggestions={filteredItems}
          field="display"
          minLength={3}
          delay={400}
          readOnly={isReadonly}
          completeMethod={searchPatientsRefs}
          onBlur={() => {
            if (!(value as Reference)?.id) {
              setFilter("")
              setFieldValue(name, "")
            }
          }}
          onChange={({ value }) => {
            if (typeof value !== "string") return
            onSelect?.(undefined)
            setFilter(value.trim())
            setFieldValue(name, value ? { display: value } : "")
          }}
          onSelect={({ value }) => {
            setFieldValue(name, withPatientDetails ? asReference(value) : value)
            onSelect?.(value)
          }}
          value={value?.display}
          className={classNames(
            "p-inputtext-sm",
            {
              "p-invalid": touched && error,
              horizontal: horizontal,
            },
            inputClassName,
          )}
          inputClassName={inputClassName}
          appendTo="self"
          panelClassName="w-full"
          itemTemplate={withPatientDetails ? PatientItemTemplate : undefined}
        />
      )}
    </FormField>
  )
}

const PatientItemTemplate = (patient: Patient) => (
  <div>
    <div className="mb-1 text-sm font-medium text-gray-900 capitalize">{humanNameAsString(patient?.name?.[0])}</div>
    <div className="flex gap-2 divide-x divide-gray-400 truncate text-xs text-gray-500">
      <span>
        <span title="Age">{calculateAge(patient?.birthDate)}</span>
        {patient?.gender && (
          <FontAwesomeIcon
            icon={patient?.gender === "male" ? faMars : faVenus}
            title={strCapitalize(patient?.gender ?? "")}
            className="pl-1"
          />
        )}
      </span>
      <span title="Email" className="pl-2">
        <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
        <span>{getFirstEmail(patient?.telecom)}</span>
      </span>
    </div>
  </div>
)

type Props = {
  field: string
  label?: string
  className?: string
  isReadonly?: boolean
  horizontal?: boolean
  inputClassName?: string
  withPatientDetails?: boolean
  onSelect?(_?: Patient): void
}

export { AutoCompletePatientField, PatientItemTemplate }
