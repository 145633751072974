import { asReference } from "fhir"
import pluralize from "pluralize"
import { type FC, useCallback, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { MC_PUBLISHER } from "data"
import { useOpenEncounter } from "encounter"
import { useAppModuleContext } from "internals"
import { useOrganizationContext } from "organization"
import { usePatchPatient } from "patients"

import { Button } from "../../../components/Buttons"
import { ConfirmDialog } from "../../../components/ConfirmDialog"
import { LoadingView } from "../../../components/LoadingView"
import { useMedicationKnowledge } from "../../../meds"
import { ModulesId } from "../../../Module"
import {
  useCarePlans,
  useEnrollPlan,
  usePlanContext,
  usePlanDefinitions,
  usePlanMedicationsDataBind,
} from "../../hooks"
import { PLAN_SUBVIEWS, PlanContext } from "../../types"
import { getContextLabel } from "../../utils"
import { PlanSelection } from "./PlanSelection"

const PlanEnrollment: FC = () => {
  const chartContext = useChartContext()
  const { currentOrganizationId, partOfId, loggedInPractitionerRoleRef } = useOrganizationContext()
  const { appModules, isModuleActive } = useAppModuleContext()
  const { plan, patientId, patient, navigateToSubview, updateCurrentPlan, handleConfigurePlan } = usePlanContext()
  const { openEncounterRef } = useOpenEncounter(patientId)
  const [searchParams, setSearchParams] = useSearchParams()

  const [showSexConfirmation, setShowSexConfirmation] = useState(false)

  const { activePDs, plans, isLoading, isFetching } = useCarePlans({
    patientId,
    statusFilter: ["active", "draft"],
  })

  useEffect(() => {
    chartContext.setSearchData({ showSearch: false })
  }, [])

  const {
    planDefinitions,
    isLoading: isLoadingPDs,
    medCodes,
  } = usePlanDefinitions({
    patientGender: patient.gender?.includes("male") ? patient.gender : undefined,
    organizationId: currentOrganizationId,
    orgPartOfId: partOfId ?? currentOrganizationId,
  })

  const { medicationKnowledge, isLoading: isLoadingMKs } = useMedicationKnowledge({
    medCodes: medCodes.join(","),
    hasCIDInOrg: currentOrganizationId,
    enabled: !!medCodes.length,
  })
  const { plans: availablePLans } = usePlanMedicationsDataBind(planDefinitions, medicationKnowledge)

  const { enrollPlan, isEnrolling } = useEnrollPlan((cpId: string) => callExternalAction(cpId))

  const enroll = useCallback(
    (pdId?: string) =>
      enrollPlan({
        planId: pdId ?? (plan?.definition?.id as string),
        author: loggedInPractitionerRoleRef,
        patient: asReference(patient),
        encounter: openEncounterRef,
      }),

    [enrollPlan, plan?.definition?.id, loggedInPractitionerRoleRef, patient, openEncounterRef],
  )

  const selectedPlanGender = useMemo(
    () =>
      plan?.definition?.useContext?.find((c) => c.code.code === "gender")?.value?.CodeableConcept?.coding?.[0]
        ?.code as string,
    [plan],
  )

  const { patchPatient, isPatching } = usePatchPatient(enroll)

  const handleEnrollment = useCallback(
    (pdId?: string) => {
      if (!patient.gender && selectedPlanGender && patient.gender !== selectedPlanGender) setShowSexConfirmation(true)
      else enroll(pdId)
    },
    [enroll, patient.gender, selectedPlanGender],
  )

  const callExternalAction = useCallback(
    (planId: string, pdToken?: string) => {
      if (isModuleActive(ModulesId.LABS) || searchParams.get("context") !== PlanContext.PLANS) {
        const planData =
          plans.find((pd) => pd.carePlan.id === planId) ??
          plans.find(
            (p) =>
              p.planDefinition.id === planId ||
              (!!pdToken && [p.definitionToken, p.originalDefinitionToken].includes(pdToken)),
          )

        if (planData) {
          handleConfigurePlan(planData.carePlan.id as string)
        } else navigateToSubview(PLAN_SUBVIEWS.CONFIG, planId)
        /* Navigate back */
      } else onCancel()
    },
    [handleConfigurePlan, navigateToSubview, plans, searchParams],
  )

  const showDetails = useCallback(
    (pdId: string, pdToken?: string) => {
      const planId = plans.find(
        (planData) =>
          planData.planDefinition.id === pdId ||
          [planData.definitionToken, planData.originalDefinitionToken].includes(pdToken as string),
      )?.carePlan.id
      navigateToSubview(PLAN_SUBVIEWS.DETAILS, planId)
    },
    [navigateToSubview, plans],
  )

  const goBackToMC = () =>
    setSearchParams({
      ...searchParams,
      ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
      view: ModulesId.MC,
      context: PlanContext.MC_ORDERS,
    })

  const onCancel = useCallback(
    () => (searchParams.get("view") !== ModulesId.PLANS ? goBackToMC() : navigateToSubview(undefined)),
    [searchParams],
  )

  const filteredPlans = useMemo(
    () =>
      searchParams.get("context") === PlanContext.MC_ORDERS
        ? availablePLans?.filter(({ definition }) => definition.publisher === MC_PUBLISHER)
        : availablePLans?.filter(({ definition }) => definition.publisher !== MC_PUBLISHER),
    [availablePLans, searchParams],
  )

  const contextLabel = useMemo(() => getContextLabel(searchParams.get("context") as PlanContext), [searchParams])

  if (isEnrolling || isLoading || isLoadingPDs || isFetching || isLoadingMKs) return <LoadingView />

  return (
    <div className="flex h-full flex-col gap-3 divide-y divide-gray-200 overflow-hidden px-5">
      <h2 className="leading-6 font-semibold capitalize">{pluralize(contextLabel)} Available</h2>
      <PlanSelection
        selectedPlan={plan}
        selectPlan={updateCurrentPlan}
        activePDs={activePDs}
        isLoadingPDs={isLoading || isFetching || isLoadingPDs}
        planDefinitions={filteredPlans}
        emptyIcon={appModules[ModulesId.PLANS].getIcon()}
        showDetails={showDetails}
        showConfig={callExternalAction}
        contextLabel={contextLabel}
      />

      <div className="flex shrink-0 justify-end gap-3 px-4 py-4">
        <Button label="Close" buttonStyle="default" size="lg" disabled={isEnrolling} onClick={onCancel} />
        <Button
          label={`Enroll ${contextLabel}`}
          size="lg"
          loading={isEnrolling}
          disabled={isEnrolling || !plan || !!activePDs[plan?.definitionToken]}
          onClick={() => handleEnrollment()}
        />
      </div>

      <ConfirmDialog
        confirmElement={
          <p>
            The gender of this patient is <strong>{patient.gender as string}</strong>. By enrolling this {contextLabel},
            the patient's gender will be changed to <strong>{selectedPlanGender}</strong>
          </p>
        }
        visible={showSexConfirmation}
        isLoading={isPatching || isEnrolling}
        onConfirm={() => {
          patchPatient({ patientId, patientData: { gender: selectedPlanGender, meta: patient.meta } })
        }}
        hideDialog={() => setShowSexConfirmation(false)}
      />
    </div>
  )
}

export { PlanEnrollment }
