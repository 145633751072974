import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { LexicalTypeaheadMenuPlugin, type MenuRenderFn } from "@lexical/react/LexicalTypeaheadMenuPlugin"
import { type TextNode, $createTextNode } from "lexical"
import { useCallback, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { useLoginContext } from "security"
import { useOrganizationContext } from "organization"

import { useFetchSuggestions } from "commons"

import { useBasicTypeaheadTriggerMatch } from "../hooks/useBasicTypeaheadTriggerMatch"
import type { SnippetsTypeaheadOption } from "../components/SnippetsTypeHeadOption"
import { SnippetSuggestionItem } from "../components/SnippetSuggestionItem"

const SnippetsTypeHeadPlugin = (): JSX.Element | null => {
  const [editor] = useLexicalComposerContext()
  const [queryString, setQueryString] = useState<string | null>(null)
  const [showCustomDataSuggestions, setShowCustomDataSuggestions] = useState(true)

  const { loggedInPractitionerId } = useLoginContext()
  const { currentOrganizationId } = useOrganizationContext()

  const { fetchSnippets } = useFetchSuggestions([loggedInPractitionerId, currentOrganizationId])

  const checkForTriggerMatch = useBasicTypeaheadTriggerMatch("#", {
    minLength: 0,
  })

  const checkForCustomDataTriggerMatch = useCallback(
    (text: string) => {
      const match = checkForTriggerMatch(text, editor)
      if (match !== null) {
        setShowCustomDataSuggestions(true)
      }
      return match
    },
    [checkForTriggerMatch, editor],
  )

  const onSelectOption = useCallback(
    (selectedOption: SnippetsTypeaheadOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(() => {
        if (nodeToReplace) {
          const textNode = $createTextNode(selectedOption.metadata.value)
          nodeToReplace.replace(textNode)
          textNode.select()
        }
        closeMenu()
      })
    },
    [editor],
  )

  const [options, setOptions] = useState<SnippetsTypeaheadOption[]>([])
  const createFilteredOptions = async (queryString: string | null) => {
    if (queryString === null) {
      return []
    }

    const snippets = await fetchSnippets(queryString)
    return snippets.map(({ id, value }) => ({
      key: id,
      metadata: {
        id,
        value,
      },
    })) as SnippetsTypeaheadOption[]
  }

  useEffect(() => {
    const fetchOptions = async () => {
      const options = await createFilteredOptions(queryString)
      setOptions(options)
    }
    fetchOptions()
  }, [queryString])

  const renderSuggestionsMenu = (
    anchorElementRef: { current: Element | DocumentFragment | null },
    {
      selectedIndex,
      selectOptionAndCleanUp,
      setHighlightedIndex,
    }: {
      selectedIndex: number
      selectOptionAndCleanUp: (option: SnippetsTypeaheadOption) => void
      setHighlightedIndex: (index: number) => void
    },
  ) => {
    if (!showCustomDataSuggestions || anchorElementRef.current == null || options.length === 0) {
      return null
    }
    return anchorElementRef.current && options.length
      ? createPortal(
          <div className="max-h-[320px] w-[180px] min-w-[90px] gap-0.5 overflow-hidden overflow-y-auto rounded bg-white shadow-md">
            <ul>
              {options.map((option: SnippetsTypeaheadOption, index: number) => (
                <SnippetSuggestionItem
                  key={option.key}
                  index={index}
                  isSelected={selectedIndex === index}
                  onClick={() => {
                    setHighlightedIndex(index)
                    selectOptionAndCleanUp(option)
                  }}
                  onMouseEnter={() => {
                    setHighlightedIndex(index)
                  }}
                  option={option}
                />
              ))}
            </ul>
          </div>,
          anchorElementRef.current,
        )
      : null
  }

  return (
    <LexicalTypeaheadMenuPlugin<SnippetsTypeaheadOption>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForCustomDataTriggerMatch}
      options={options}
      anchorClassName="z-[10000]"
      menuRenderFn={renderSuggestionsMenu as MenuRenderFn<SnippetsTypeaheadOption>}
    />
  )
}

export { SnippetsTypeHeadPlugin }
