import { useQuery } from "@tanstack/react-query"
import {
  type Bundle,
  type ChargeItem,
  type Invoice,
  type Parameters,
  type RequestGroup,
  getResource,
  getResources,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import type { CustomError } from "commons"

import type { InvoiceData } from "../types"
import { getInvoiceData } from "../utils"
import { ordersQueryKeys } from "../query-keys"

const useInvoicePreview = (patientId: string, rg?: RequestGroup, enabled = true) => {
  const { read, operationRequest } = useClient()
  /* Disable cahe query when RG updated */
  const queryKey = ordersQueryKeys.invoicePreview(patientId, rg)

  const generate = async () => {
    const requestGroup = (await read({ endpoint: "RequestGroup", id: rg?.id })) as RequestGroup
    if (requestGroup.status != "draft")
      throw new Error("It seems someone else has already finished this order before you")

    const parametersList = (await operationRequest({
      endpoint: `Patient/${patientId}/cpoe`,
      method: "GET",
      operation: "invoice-preview",
    })) as Parameters[]

    return parametersList?.reduce<Array<InvoiceData>>((acc, { parameter }) => {
      const invoiceBundle = parameter?.find((p) => p.name === "invoice-bundle")?.resource as Bundle

      if (!invoiceBundle) {
        return acc
      }

      const invoice = getResource<Invoice>(invoiceBundle, "Invoice")
      const chargeItems = getResources<ChargeItem>(invoiceBundle, "ChargeItem")
      const summary = parameter?.find((p) => p.name === "summary")?.resource as Parameters

      return [...acc, { invoice, chargeItems, summary }]
    }, [])
  }

  const {
    isError,
    isLoading: isLoadingPreview,
    data,
    error,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: generate,
    enabled,
    meta: { context: { queryKey, patientId } },
    gcTime: 10,
    refetchOnWindowFocus: false,
  })

  const customData = useMemo(() => {
    return data?.map(({ invoice, summary }) => {
      return { ...getInvoiceData(invoice), invoice, summary }
    })
  }, [data])

  return { isLoadingPreview, invoiceData: customData, error: error as CustomError | null, isError, refetch }
}

export { useInvoicePreview }
