import { faExternalLink, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"

const KpListItem = ({
  label,
  content,
  externalActionLabel,
  onExternalLinkClick,
  grayed = false,
  isLoading = false,
}: Props) => {
  return (
    <div className="grid items-center px-5 py-4 sm:gap-4 sm:py-5 xl:grid-cols-12">
      <dt className="truncate text-sm font-medium text-gray-500 xl:col-span-4">{label ?? ""}</dt>
      <div className="mt-0 flex w-full justify-between space-x-1 overflow-x-hidden md:mt-1 xl:col-span-8">
        <dd
          className={classNames("truncate text-sm", {
            "sm:col-start-5": !label,
            "text-gray-500": grayed,
            "text-gray-900": !grayed,
          })}
          title={typeof content === "string" ? content : undefined}
        >
          {content}
        </dd>
        {isLoading ? (
          <span className="pr-1 text-right text-sm">
            <FontAwesomeIcon icon={faSpinner} className="text-gray-500" spin />
          </span>
        ) : (
          <>
            {externalActionLabel && (
              <span className="pr-1 text-right text-sm">
                <FontAwesomeIcon
                  icon={faExternalLink}
                  className="cursor-pointer text-slate-400"
                  title={externalActionLabel}
                  onClick={onExternalLinkClick}
                />
              </span>
            )}
          </>
        )}
      </div>
    </div>
  )
}

type Props = {
  content: string | JSX.Element
  label?: string
  externalActionLabel?: string
  onExternalLinkClick?(): void
  grayed?: boolean
  isLoading?: boolean
}

export { KpListItem }
