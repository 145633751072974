import { faCalendarClock } from "@fortawesome/pro-regular-svg-icons"
import { faStop } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { MedicationKnowledge, MedicationRequest } from "fhir"
import pluralize from "pluralize"
import { Checkbox } from "primereact/checkbox"
import { ConfirmDialog } from "primereact/confirmdialog"
import { SelectButton } from "primereact/selectbutton"
import { startTransition, useCallback, useId, useMemo, useState } from "react"

import {
  FooterActions,
  GroupedList,
  InfiniteScroll,
  ModulesId,
  SearchWithStatus,
  SectionContainer,
  SkeletonLoader,
  StackedListItem,
} from "commons"
import { useProductPrices } from "commons/hooks"
import {
  MedicationKnowledgeDetails,
  RescheduleDialog,
  useMedReducerState,
  useMedicationRequestGroupByDate,
  useMedicationRequests,
  useRescheduleMedicationRequest,
  useStopMedicationRequest,
} from "commons/meds"
import { useAppModuleContext } from "modules"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { completeMR, prescriptionViewOptions, stopMR } from "../data"
import { useCompleteMrOrder } from "../hooks"
import type { Contents } from "../types"
import { prescriptionItemModel } from "./prescriptions/prescriptionItemModel"

const PrescriptionsRefillsContainer = ({ activeContent, onUpdateContent }: Props) => {
  const loaderKey = useId()
  const { patientId } = usePatientContext()
  const { appModules } = useAppModuleContext()
  const { currentOrganizationId, hasCreditCard } = useOrganizationContext()
  const [selectedDates, setSelectedDates] = useState<string[]>([])
  const [showRescheduleDialog, setShowRescheduleDialog] = useState(false)
  const [itemActionClicked, onActionCliked] = useState("")
  const [showStopDialog, setShowStopDialog] = useState(false)
  const [selectedMK, setSelectedMK] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()
  const [selectedMRId, setSelectedMRId] = useState<string | null>(null)

  const { statusFilter, searchFilter, updateSearchText } = useMedReducerState({
    statusFilter: ["active", "on-hold"],
  })

  const {
    medicationRequests,
    medicationDispenses,
    medicationKnowledges,
    isLoading,
    fetchNextPage,
    hasNextPage,
    medsProductConfigurations,
  } = useMedicationRequests({
    patientId: patientId,
    category: "medication",
    statusFilter,
    searchText: searchFilter,
    autoship: true,
  })
  const { productPrices } = useProductPrices({
    organizationId: currentOrganizationId,
    productsConfigurations: medsProductConfigurations,
  })

  const { medicationRequestDataGroupByDate: mrDateGroups } = useMedicationRequestGroupByDate(
    medicationRequests,
    medicationKnowledges,
    productPrices,
    medicationDispenses,
  )

  const { rescheduleMedicationRequest, isLoading: isRescheduling } = useRescheduleMedicationRequest(() => {
    setShowRescheduleDialog(false)
    setSelectedDates([])
    setSelectedMRId(null)
  })

  const { stopMedicationRequest, isLoading: isStopping } = useStopMedicationRequest(() => {
    onActionCliked("")
    setSelectedDates([])
    setShowStopDialog(false)
  })
  const { completeMedicationRequest, isCompleting } = useCompleteMrOrder(() => onActionCliked(""))

  const handleSelect = (date: string, checked: boolean) => {
    if (checked) {
      setSelectedDates((selectedDates) => [...selectedDates, date])
    } else {
      setSelectedDates((selectedDates) => selectedDates.filter((d) => d !== date))
    }
  }

  const getSelectedMRs = useCallback(
    (state?: string[]) => {
      const groups = mrDateGroups.filter((mrg) => selectedDates.includes(mrg.key))
      return groups.reduce<string[]>((acc, g) => {
        const ids =
          g.items.reduce((acc, item) => {
            if (state) {
              const itemHasState = state.includes(item.medicationRequestInfo?.status)
              return [...acc, ...(itemHasState ? [item.medicationRequestInfo.id as string] : [])]
            } else return [...acc, item.medicationRequestInfo.id as string]
          }, Array<string>()) ?? []
        return [...acc, ...ids]
      }, [])
    },
    [selectedDates, mrDateGroups],
  )

  const handleReschedule = (rescheduleDate: string) => {
    rescheduleMedicationRequest({
      medicationRequests: selectedMRId ? [selectedMRId] : getSelectedMRs(["active", "on-hold"]),
      rescheduleDate,
    })
  }

  const stop = (medReqId: string) => {
    stopMR(medReqId, () => {
      onActionCliked(medReqId)
      stopMedicationRequest([medReqId])
    })
  }

  const complete = (medReqId: string) => {
    completeMR(medReqId, () => {
      onActionCliked(medReqId)
      completeMedicationRequest(medReqId)
    })
  }

  const reschedule = (medReqId: string) => {
    setSelectedMRId(medReqId)
    setShowRescheduleDialog(true)
  }

  const handleStop = (show: boolean) => {
    setShowStopDialog(show)
  }

  const addOptions = useMemo(
    () => [
      {
        label: "Reschedule",
        icon: faCalendarClock,
        command: () => setShowRescheduleDialog(true),
        disabled: !selectedDates.length,
      },
      {
        label: "Stop",
        disabled: !selectedDates.length,
        icon: faStop,
        command: () => handleStop(true),
        style: "default",
      },
    ],
    [selectedDates.length],
  )

  const loader = () => (
    <div className="flex flex-1 flex-col overflow-auto">
      <SkeletonLoader
        key={loaderKey}
        repeats={3}
        containerClassName="p-5 overflow-auto"
        loaderType="list"
        skeletonShape="rectangle"
        skeletonSize="6rem"
        extraLine
      />
    </div>
  )

  return (
    <SectionContainer>
      <div className="my-3 inline-flex h-12 w-full justify-between px-3">
        <SelectButton
          value={activeContent}
          options={prescriptionViewOptions}
          optionLabel="name"
          optionValue="value"
          allowEmpty={false}
          onChange={(e) => onUpdateContent(e.value as Contents)}
        />
        <SearchWithStatus
          placeholder="Search products"
          onSearch={(filter) => {
            startTransition(() => {
              updateSearchText(filter ?? "")
            })
          }}
        />
      </div>
      {isLoading ? (
        loader()
      ) : (
        <div className="flex flex-1 flex-col overflow-auto">
          <InfiniteScroll
            hasMore={hasNextPage}
            loadMore={() => fetchNextPage()}
            useWindow={false}
            loader={loader()}
            className="grow"
          >
            <GroupedList
              className="grow"
              groups={mrDateGroups}
              renderItem={(medication) => (
                <StackedListItem
                  key={`${medication.medicationRequestInfo.id}-${medication.medicationRequestInfo.status}`}
                  modelData={prescriptionItemModel({
                    mrData: medication,
                    stop,
                    complete,
                    reschedule,
                    hiddenPrices: true,
                    loading: (isStopping || isCompleting) && itemActionClicked === medication.medicationRequestInfo.id,
                    preview: () =>
                      setSelectedMK({ mk: medication.medicationKnowledge, mr: medication.medicationRequestInfo }),
                    hideOrganizationPrice: !hasCreditCard,
                  })}
                />
              )}
              renderDivider={(key) => (
                <div className="sticky top-0 z-10 border-t border-b border-gray-200 bg-gray-50 py-1 text-sm font-medium text-gray-500">
                  <div className="flex items-center gap-2 px-2 py-1">
                    <Checkbox
                      inputId={key}
                      checked={selectedDates.includes(key)}
                      onChange={(e) => handleSelect(key, e.checked ?? false)}
                    />
                    <label htmlFor={key} className="text-base">
                      {key}
                    </label>
                  </div>
                </div>
              )}
              renderEmptyState={() => (
                <div className="m-auto flex h-full grow flex-col justify-center text-center">
                  <FontAwesomeIcon
                    icon={appModules[ModulesId.MEDICATIONR].getIcon()}
                    size="2x"
                    className="text-slate-400"
                  />
                  <h3 className="mt-2 text-sm font-semibold text-gray-900">No Scheduled Orders Found</h3>
                </div>
              )}
            />
          </InfiniteScroll>
        </div>
      )}
      <MedicationKnowledgeDetails
        selectedMK={selectedMK?.mk}
        mr={selectedMK?.mr}
        onHide={() => setSelectedMK(undefined)}
      />

      <FooterActions actions={addOptions} actionsDisabled={isStopping || isRescheduling} />
      {showRescheduleDialog && (
        <RescheduleDialog
          onHide={() => {
            setShowRescheduleDialog(false)
            setSelectedMRId(null)
          }}
          hiddenWarning={!!selectedMRId}
          onReschedule={handleReschedule}
          title={`Reschedule prescription${selectedMRId ? "" : "s"}`}
          isLoading={isRescheduling}
        />
      )}
      <ConfirmDialog
        message={
          <>
            <p>{`Are you sure you want to stop orders for ${pluralize("date", selectedDates.length)} ${selectedDates.join(",")}?`}</p>
            <p>*Notes: Only active or on-hold orders can be stopped</p>
          </>
        }
        header="Confirmation"
        acceptLabel="Stop"
        rejectLabel="Cancel"
        rejectClassName="button-default"
        acceptClassName="button-primary"
        accept={() => stopMedicationRequest(getSelectedMRs(["active", "on-hold"]))}
        visible={showStopDialog}
        acceptIcon={isStopping ? "pi pi-spin pi-spinner" : undefined}
        onHide={() => handleStop(false)}
        reject={() => handleStop(false)}
      />
      <ConfirmDialog />
    </SectionContainer>
  )
}

type Props = {
  activeContent: Contents
  onUpdateContent(activeContent: Contents): void
}

export { PrescriptionsRefillsContainer }
