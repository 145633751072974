import { type FieldProps, useFormikContext } from "formik"
import { Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { type FC } from "react"
import type { AnyObject } from "yup/lib/object"

import { CheckBoxField, FormField } from "commons"
import { commonsDispenseInterval, onDemandDispenseIntervalOption, oneTimeDispenseIntervalOption } from "commons/meds"

import type { MedicationRequestFormData } from "../../types"
import { getDispenseIntervalOptions } from "../../utils"

const DispenseTypeFields: FC<Props> = ({ onChange: onDispenseChange }) => {
  const { isSubmitting, values, setFieldValue } = useFormikContext<MedicationRequestFormData>()
  const refills = values.dispenseRequest?.numberOfRepeatsAllowed
  const isAutoOrder = values.isAutoOrder
  const isOnDemand = Boolean(refills && !isAutoOrder)

  const intervalOptions = getDispenseIntervalOptions({ isOnDemand, refills })

  return (
    <div className="mb-1 flex w-full flex-1 flex-col gap-4 pt-2">
      <div className="flex items-center gap-2">
        <label className="text-sm font-medium text-gray-700">Dispense Type</label>
        <hr className="flex-1" />
      </div>
      <div className="grid flex-1 grid-cols-4 items-center gap-6">
        <CheckBoxField
          label="Auto Order"
          field="isAutoOrder"
          disabled={!refills}
          onCheck={(isAutoOrder) => {
            if (isAutoOrder) {
              setFieldValue("dispenseRequest.dispenseInterval", commonsDispenseInterval[1].value)
            }
          }}
        />
        <div className="flex w-full flex-col gap-2">
          <label className="text-sm font-medium text-gray-700">Frecuency</label>
          <FormField field="dispenseRequest.dispenseInterval">
            {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
              <Dropdown
                options={intervalOptions}
                optionLabel="label"
                optionValue="value"
                id={name}
                name={name}
                value={
                  isOnDemand
                    ? onDemandDispenseIntervalOption.value
                    : !refills
                      ? oneTimeDispenseIntervalOption.value
                      : value
                }
                onChange={(e) => {
                  let repeatsAllowed = values.dispenseRequest?.numberOfRepeatsAllowed || 1
                  if (!e.value?.value) repeatsAllowed = 0

                  setFieldValue("dispenseRequest.numberOfRepeatsAllowed", repeatsAllowed)
                  onChange(e)
                  onDispenseChange?.(e.value)
                }}
                className={classNames("p-inputtext-sm small-trigger", {
                  "p-invalid": touched && error,
                })}
                disabled={isSubmitting || !isAutoOrder || !refills}
              />
            )}
          </FormField>
        </div>
      </div>
    </div>
  )
}

type Props = {
  onChange?(value: AnyObject | number | null | undefined): void
}

export { DispenseTypeFields }
