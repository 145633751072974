import { faLocationDot, faUser } from "@fortawesome/pro-regular-svg-icons"

import { MenuStyles, type StackedListItemProps } from "commons"
import { getBadgeColor, getStringAddress, strCapitalize } from "utils"
import { defaultEditRemoveMenu } from "utils-components"

import type { CoverageData } from "../types"

const coverageModelBuilder = ({
  coverage,
  onDelete,
  onEdit,
  onlyAddressDetail,
  hideBadge,
  lineItemClassname,
}: {
  coverage: CoverageData
  onEdit?: () => void
  onDelete?: () => void
  onlyAddressDetail?: boolean
  hideBadge?: boolean
  lineItemClassname?: string
}): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [{ name: "Carrier", value: strCapitalize(coverage.payor?.[0]?.display ?? "") }],
    },
    ...(coverage.payor[0].display
      ? [
          {
            lineItems: [
              ...(!onlyAddressDetail
                ? [
                    {
                      name: "Subscriber",
                      value:
                        coverage.beneficiary.id === coverage.subscriber?.id ? "Self" : coverage.subscriber?.display,
                      icon: faUser,
                    },
                    {
                      name: "Subscriber ID",
                      value: `#${coverage.subscriberId}`,
                    },
                  ]
                : []),
              {
                name: "Payor Address",
                component: (
                  <span className="line-clamp-1 @3xl:max-w-72">
                    <p className="truncate">{getStringAddress(coverage.payor[0].address)}</p>
                  </span>
                ),
                icon: faLocationDot,
              },
            ],
          },
        ]
      : []),
  ],
  lineItemClassname,
  badge: hideBadge ? undefined : getBadgeColor(coverage.status),
  menu: defaultEditRemoveMenu(onEdit, onDelete),
  menuStyle: onEdit && onDelete ? MenuStyles.Dropdown : MenuStyles.ActionItems,
})

export { coverageModelBuilder }
