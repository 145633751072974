import { useQuery } from "@tanstack/react-query"
import { type Practitioner, asReference, getResource } from "fhir"

import { useClient } from "api"

import { practitionerQueryKeys } from "../query-keys"

const usePractitioner = (practitionerId: string | undefined, enabled = true) => {
  const { search } = useClient()
  const queryKey = practitionerQueryKeys.details(practitionerId)

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _id: `${practitionerId}`,
        "active:not": "false",
      })

      const bundle = await search({ endpoint: `Practitioner`, filters, signal })
      const practitioner = getResource<Practitioner>(bundle, "Practitioner")

      if (!practitioner) {
        throw new Error("Not found", { cause: { name: "404", message: "Not patient found" } })
      }

      return { practitioner }
    },
    enabled: enabled && !!practitionerId,
    meta: { context: { queryKey, practitionerId } },
  })

  return {
    practitioner: data?.practitioner as Practitioner,
    practitionerRef: data?.practitioner ? asReference(data.practitioner) : undefined,
    isLoading,
    reloadPractitioner: refetch,
  }
}

export { usePractitioner }
