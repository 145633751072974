import groupBy from "lodash/groupBy"
import type { Practitioner } from "fhir"

import type { PractitionerInfo } from "commons/types"

const getGroupedPractitioners = (practitionersInfo: PractitionerInfo[]) => {
  const groupedPractitioners = groupBy(
    practitionersInfo,
    ({ practitioner }) => practitioner.name?.[0]?.family?.[0].toUpperCase() ?? "other",
  )

  const practitionerGroups = Object.entries(groupedPractitioners)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .map(([key, items = []]) => ({
      key,
      name: key,
      items,
    }))

  return practitionerGroups
}

const practitionerMatchesQuery = (practitioner: Practitioner, searchText: string) =>
  practitioner.name?.[0]?.family?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
  practitioner.name?.[0]?.given?.join(" ").toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
  practitioner.telecom?.some(
    ({ system, value }) => system === "email" && value?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()),
  )

export { getGroupedPractitioners, practitionerMatchesQuery }
