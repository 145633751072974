import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { faPencil } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { Address } from "fhir"
import { type FormikHelpers, type FormikValues, useFormikContext } from "formik"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { type FC, useMemo, useState } from "react"
import type { AnyObject } from "yup/lib/object"

import { emptyAddress } from "data"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"
import { areAddressesEquals, getAddressSchema, getStringAddress, isPoBoxAddress } from "utils"

import { AddressVerificationFeedback } from "../components/AddressVerificationFeedback"
import { AddFieldArrayItemButton } from "../components/Buttons"
import { CardListItem } from "../components/CardListItem"
import { useSmartyAddressVerification, useSmartyAddressVerificationContext } from "../hooks"
import { sanitizeCustomAddress } from "../validations"
import { AddressField } from "./AddressField"
import { DialogFormContainer } from "./DialogFormContainer"
import { FormField } from "./FormField"

const ShippingAddressField: FC<Props> = ({
  fieldPath,
  defaultAddressType,
  label = "Shipping address",
  showLabel = true,
  showAdd = true,
  onChange,
  className,
  hideOrganizationAddress,
}) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>()
  const { patient } = usePatientContext()
  const { currentOrganizationBillingAddress: organizationAddress } = useOrganizationContext()

  const [showFormDialog, setShowFormDialog] = useState(false)

  const patientPermanentAddress = patient.address && patient.address.filter((address) => address.use !== "temp")

  const shippingAddress: Address | undefined = useMemo(() => {
    const subPath = fieldPath.split(".")
    if (subPath.length > 1) return values?.[subPath[0]]?.[subPath[1]]
    else if (subPath.length > 2) return values?.[subPath[0]]?.[subPath[1]]?.[subPath[2]]
    else return values?.[subPath[0]]
  }, [values])

  const isPatientAddress = useMemo(
    () =>
      shippingAddress &&
      patientPermanentAddress &&
      patientPermanentAddress.some((address) => areAddressesEquals(shippingAddress, address as Address)),
    [shippingAddress, patientPermanentAddress],
  )
  const isOrganizationAddress = useMemo(
    () => shippingAddress && organizationAddress && areAddressesEquals(shippingAddress, organizationAddress),
    [shippingAddress, organizationAddress],
  )
  const isCustomAddress = !isPatientAddress && !isOrganizationAddress && !!shippingAddress
  const [customAddress, setCustomAddress] = useState(isCustomAddress ? shippingAddress : undefined)
  const showAddCustomAddressButton = !customAddress && showAdd
  const addressFormInitialValue = isCustomAddress ? shippingAddress : { ...emptyAddress, type: defaultAddressType }

  const handleAddressSelection = (address: Address) => {
    if (!areAddressesEquals(shippingAddress ?? {}, address)) {
      setFieldValue(fieldPath, address)

      onChange?.(address)
    }
  }

  const showDialog = () => {
    setShowFormDialog(true)
  }

  const hideDialog = () => {
    setShowFormDialog(false)
  }

  const { checkAddress, clearVerificationInfo } = useSmartyAddressVerification(true)

  const handleSubmit = (address?: Address, formikHelpers?: FormikHelpers<FormikValues>) => {
    if (address) {
      const sanitizedAddress = sanitizeCustomAddress(address)

      checkAddress(sanitizedAddress, formikHelpers, () => {
        setCustomAddress(sanitizedAddress)
        handleAddressSelection(sanitizedAddress)

        clearVerificationInfo()
        hideDialog()
      })
    }
  }

  const renderAddressItem = (address: Address) => (
    <div className="mx-4 w-full items-center font-semibold">
      {isPoBoxAddress(address) && (
        <>
          <Tooltip
            target=".tooltiped"
            event="hover"
            position="left"
            content="It looks like you're trying to set up a P.O. Box address. Please note that P.O. Boxes may not be suitable for faster deliveries."
          />
          <span className="tooltiped mr-4 cursor-pointer">
            <FontAwesomeIcon icon={faInfoCircle} className="text-orange-500" />
          </span>
        </>
      )}
      {getStringAddress(address)}
    </div>
  )

  return (
    <div className={classNames("relative flex flex-col pb-4", className)}>
      <FormField field={fieldPath} label={showLabel ? label : undefined} containerClassName="flex flex-col gap-2">
        {patientPermanentAddress &&
          patientPermanentAddress.map((patientAddress, index) => (
            <CardListItem
              className={classNames("cursor-pointer rounded-md border border-gray-100 hover:bg-gray-100", {
                "border-slate-400 bg-slate-100":
                  !!shippingAddress && areAddressesEquals(shippingAddress, patientAddress),
              })}
              lastElementClass=""
              contentHeader={renderAddressItem(patientAddress)}
              key={patientAddress.id ?? index}
              onContentCliked={() => {
                handleAddressSelection(patientAddress)
              }}
            />
          ))}
        {!hideOrganizationAddress && organizationAddress && (
          <CardListItem
            className={classNames("cursor-pointer rounded-md border border-gray-100 hover:bg-gray-100", {
              "border-slate-400 bg-slate-100": isOrganizationAddress,
            })}
            lastElementClass=""
            contentHeader={renderAddressItem(organizationAddress)}
            onContentCliked={() => {
              handleAddressSelection(organizationAddress)
            }}
          />
        )}
        {customAddress && (
          <CardListItem
            className={classNames("cursor-pointer rounded-md border border-gray-100 hover:bg-gray-100", {
              "border-slate-400 bg-slate-100": isCustomAddress,
            })}
            lastElementClass=""
            contentHeader={renderAddressItem(customAddress)}
            onContentCliked={() => {
              handleAddressSelection(customAddress)
            }}
            actionItem={{
              icon: <FontAwesomeIcon icon={faPencil} size="sm" />,
              command: () => {
                handleAddressSelection(customAddress)
                showDialog()
              },
            }}
          />
        )}
      </FormField>
      {showAddCustomAddressButton && (
        <AddFieldArrayItemButton className="border-none" label="Add custom address" onClick={showDialog} />
      )}
      <DialogFormContainer
        initialValue={addressFormInitialValue}
        title="Custom Address"
        showForm={showFormDialog}
        onCancel={() => {
          clearVerificationInfo()
          hideDialog()
        }}
        useFormik
        onSubmit={handleSubmit}
        validationSchema={getAddressSchema()}
      >
        <AddressFieldWithVerification parentFieldName={fieldPath} />
      </DialogFormContainer>
    </div>
  )
}

const AddressFieldWithVerification: FC<AddressFieldWithVerificationProps> = ({ parentFieldName }) => {
  const { setFieldValue } = useFormikContext()
  const { addressVerificationInfo, autoCompleteRecommendedAddress, bypassAddressValidation } =
    useSmartyAddressVerificationContext()

  return (
    <div className="flex flex-col justify-between divide-y divide-gray-300">
      <div className="p-fluid relative grid gap-4">
        <fieldset className="p-fluid relative grid grid-cols-2 gap-4">
          <AddressField showTypeUseField={false} />
        </fieldset>
        <AddressVerificationFeedback
          addressVerificationInfo={addressVerificationInfo}
          handleAutoCompleteRecommendedAddress={() => autoCompleteRecommendedAddress?.(setFieldValue, parentFieldName)}
          handleBypassAddressValidation={bypassAddressValidation}
        />
      </div>
    </div>
  )
}

type AddressFieldWithVerificationProps = {
  parentFieldName?: string
}

type Props = {
  label?: string
  fieldPath: string
  defaultAddressType?: { code: string; label: string }
  showAdd?: boolean
  showLabel?: boolean
  className?: string
  onChange?(value: AnyObject): void
  hideOrganizationAddress?: boolean
}

export { ShippingAddressField }
