import { type DialogProps, Dialog } from "primereact/dialog"
import { classNames } from "primereact/utils"
import type { PropsWithChildren } from "react"

import type { DialogAppendToType } from "../types"
import { Button } from "./Buttons"

const ModalDialog = ({
  visible,
  title,
  appendTo,
  showCancel,
  showAccept,
  showButtons,
  showCloseIcon = !showButtons && !showCancel,
  acceptType,
  cancelLabel = "Close",
  acceptLabel = "Accept",
  acceptLoading,
  acceptDisabled,
  cancelDisabled,
  onAccept,
  onCancel,
  children,
  onHide,
  header,
  className,
  ...dialogProps
}: PropsWithChildren<Props>) => {
  return (
    <Dialog
      {...dialogProps}
      modal
      visible={visible}
      appendTo={appendTo}
      onHide={onHide ?? onCancel}
      className={classNames("paddingless w-full sm:w-4/5 md:w-2/3 lg:w-1/3", className)}
      header={header ?? title}
      closable={showCloseIcon}
    >
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 flex-col divide-y divide-gray-200 overflow-hidden bg-white">
          <div className="flex flex-1 flex-col overflow-hidden">
            <div className="space-y-4 overflow-y-auto p-2 pt-0 sm:px-4 sm:pb-4">{children}</div>
          </div>
          {(showCancel || showAccept || showButtons) && (
            <div className="flex shrink-0 justify-end space-x-3 px-2 py-4 sm:px-4">
              {(showButtons || showCancel) && (
                <Button
                  label={cancelLabel}
                  buttonStyle="default"
                  size="xl"
                  disabled={cancelDisabled}
                  onClick={onCancel}
                />
              )}
              {(showButtons || showAccept) && (
                <Button
                  label={acceptLabel}
                  type={acceptType}
                  size="xl"
                  loading={acceptLoading}
                  disabled={acceptDisabled}
                  onClick={onAccept}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
}

type Props = {
  visible: boolean
  className?: string
  title?: string
  showCloseIcon?: boolean
  appendTo?: DialogAppendToType
  showCancel?: boolean
  showAccept?: boolean
  showButtons?: boolean
  acceptType?: "button" | "submit"
  cancelLabel?: string
  acceptLabel?: string
  acceptLoading?: boolean
  acceptDisabled?: boolean
  cancelDisabled?: boolean
  onAccept?(): void
  onCancel(): void
  onHide?(): void
} & Omit<DialogProps, "onHide">

export { ModalDialog }
