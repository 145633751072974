import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useId } from "react"
import { codeableConceptAsString, type Procedure } from "fhir"

import { useChartContext } from "chart-view"
import { Accordion, ModulesId, SearchWithStatus, SkeletonLoader, StackedListItem } from "commons"
import {
  procedureStatus,
  useProcedureReducerState,
  type ConfigurationItem,
  type ProcedureData,
} from "commons/procedures"
import { useAppModuleContext } from "modules"
import { usePatientContext } from "patients"
import { SYSTEM_VALUES } from "system-values"

import { usePatientProcedures } from "../../hooks"
import { MedicationItem } from "../details/MedicationItem"
import { proceduresItemModel } from "../procedureModelBuilder"

const ProceduresKnowledge: React.FC = () => {
  const { patientId } = usePatientContext()
  const { showSubModule } = useChartContext()
  const { appModules, appSubModules } = useAppModuleContext()

  const { statusFilter, searchFilter, updateFilters, updateSearchText } = useProcedureReducerState({
    statusFilter: procedureStatus.map(({ code }) => code),
  })

  const { proceduresData, isLoading } = usePatientProcedures({
    patientId,
    textFilter: searchFilter,
    statusFilter,
  })

  const showProcedure = (procedureId: string) =>
    showSubModule({ subModule: appSubModules.procedures[ModulesId.PROCEDURES_DETAILS], subModulesData: procedureId })

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <div className="mt-1 flex flex-1 flex-col overflow-hidden">
      <SearchWithStatus
        className="mx-5 my-3"
        placeholder="Search procedures"
        options={procedureStatus}
        selectedItems={statusFilter}
        onStatusCheck={updateFilters}
        onSearch={(filter) => updateSearchText(filter ?? "")}
      />
      {isLoading ? (
        loader()
      ) : !proceduresData.length ? (
        <div className="m-auto text-center">
          <FontAwesomeIcon icon={appModules[ModulesId.MEDICATIONR].getIcon()} size="2x" className="text-slate-400" />
          <h3 className="mt-2 text-sm font-semibold text-gray-900">No procedures found</h3>
        </div>
      ) : (
        <div className="h-full overflow-auto">
          <Accordion
            data={proceduresData}
            headerTemplate={(item: ProcedureData) => (
              <StackedListItem
                modelData={proceduresItemModel({
                  procedureData: item,
                  onShowDetails: () => showProcedure(item.procedure.id as string),
                })}
                className="@container !py-0"
              />
            )}
            contentTemplate={(item) =>
              ProceduresMedicationItems({ items: item.configurationItem, procedure: item.procedure })
            }
            className="grow overflow-y-auto"
            headerClassName="!p-0"
          />
        </div>
      )}
    </div>
  )
}

const ProceduresMedicationItems = ({ items, procedure }: ProceduresMedicationItemsProps) => {
  const isMassage =
    procedure?.code?.coding?.find((cc) => cc.system === SYSTEM_VALUES.PROCEDURE_TYPE)?.code === "massage-procedure"
  const isBotox =
    procedure?.code?.coding?.find((cc) => cc.system === SYSTEM_VALUES.PROCEDURE_TYPE)?.code === "botox-procedure"

  return (
    <div className="flex flex-col">
      {items.map((item, index) => (
        <MedicationItem
          className="border-0 !p-1 !pl-4 text-sm"
          key={index}
          item={item}
          isMassage={isMassage}
          isBotox={isBotox}
          title={
            isMassage
              ? `Massage ${index + 1}`
              : codeableConceptAsString(item?.medicationRequest?.medication?.CodeableConcept)
          }
          expanded={true}
        />
      ))}
    </div>
  )
}

type ProceduresMedicationItemsProps = {
  items: ConfigurationItem[]
  procedure: Procedure
}

export { ProceduresKnowledge }
