import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons"
import { classNames } from "primereact/utils"

import type { SubModule } from "../Module"

const CardMenu = ({ menuItems, itemCommand }: CardMenuProps) => {
  return (
    <div>
      {menuItems.map((item) => (
        <CardMenuItem key={item.getId()} {...{ item, command: () => itemCommand?.(item) }} />
      ))}
    </div>
  )
}

const CardMenuItem = ({ item, showItemMenu = false, command }: CardMenuItemProps) => {
  const description = item.getDescription()

  return (
    <div
      className="mb-5 flex h-20 cursor-pointer rounded-md border border-slate-200 shadow-xs last:mb-10 hover:bg-gray-50"
      onClick={() => command?.()}
    >
      <div
        className={classNames(
          "flex h-full w-20 items-center justify-center rounded-tl-md rounded-bl-md bg-slate-100 font-bold text-primary",
        )}
      >
        <FontAwesomeIcon icon={item.getIcon()} size="2x" />
      </div>
      <div className="flex flex-1 flex-col justify-center pl-4">
        <span className="block text-sm font-[600] text-gray-800">{item.getDisplay()}</span>
        {description && <span className="block text-xs text-gray-500">{item.getDescription()}</span>}
      </div>
      {showItemMenu && (
        <div className="flex items-center pr-5 text-gray-400">
          <FontAwesomeIcon icon={faEllipsisVertical} size="2x" />
        </div>
      )}
    </div>
  )
}

type CardMenuProps = {
  menuItems: SubModule[]
  itemCommand?(item?: SubModule): void
}

export type CardMenuItemProps = {
  item: SubModule
  showItemMenu?: boolean
  className?: string
  command?(): void
}

export { CardMenu, CardMenuItem }
