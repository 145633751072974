import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTasks, faPlus } from "@fortawesome/pro-regular-svg-icons"

import { Button } from "commons"

const TaskEmptyState = ({ onAdd }: Props) => (
  <div className="flex h-full w-full items-center justify-center bg-white">
    <div className="flex flex-col items-center">
      <FontAwesomeIcon icon={faTasks} size="3x" className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No tasks found</h3>
      <p className="mt-1 text-sm text-gray-500">Get started by creating a new task.</p>
      <Button label="New Task" icon={faPlus} className="p-button-sm mt-6" onClick={onAdd} />
    </div>
  </div>
)

type Props = {
  onAdd(): void
}

export { TaskEmptyState }
