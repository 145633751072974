import { faSignOut } from "@fortawesome/pro-light-svg-icons"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "primereact/button"

import type { CustomError } from "commons"
import { ERROR_CAUSE } from "data"
import { useAuth } from "security"

const UnauthorizedErrorView = ({ error }: { error?: CustomError }) => {
  const { user, logout } = useAuth()
  const name = user?.name ?? "unspecified name"
  const email = user?.email ?? "unspecified email"

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="text-center">
        <p className="p-error text-5xl">401</p>
        <h1 className="py-2 text-4xl font-bold">{ERROR_CAUSE.UNAUTHORIZED}</h1>
        <p className="py-2 pb-4 text-gray-400">
          Sorry, logged in user <span className="font-bold">{name}</span> with email{" "}
          <span className="font-bold">{email}</span> has not access
        </p>
        {error && (
          <div className="rounded-lg bg-indigo-100 p-2 text-sm text-gray-600">
            <span className="flex items-center">
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
              {error?.cause?.message}
            </span>
          </div>
        )}
        <Button onClick={() => logout()} className="p-button button-primary mt-4">
          <span className="flex items-center">
            <FontAwesomeIcon icon={faSignOut} className="mr-2" /> Logout
          </span>
        </Button>
      </div>
    </div>
  )
}

export { UnauthorizedErrorView }
