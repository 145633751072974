import { type IconDefinition } from "@fortawesome/pro-light-svg-icons"

import { StackedListContainer } from "commons"
import { conditionModelBuilder } from "conditions"

import { WrapUpSection } from "./WrapUpSection"
import type { IntakeData } from "../../types"

const ConditionsSection = ({ conditions, total, icon, hideSpacer }: Props) => (
  <WrapUpSection
    subSectionTitle="Conditions"
    isEmpty={!total}
    emptyMesage="No conditions added during this encounter"
    icon={icon}
    className="flex w-full flex-row text-sm"
    inlineContent={!hideSpacer}
  >
    <StackedListContainer
      data={conditions ?? []}
      itemModelBuilder={(item) => ({ ...conditionModelBuilder(item), badge: undefined })}
      className="w-full"
      itemsClassName="!pl-0 py-4"
    />
  </WrapUpSection>
)

type Props = {
  total?: number
  icon: IconDefinition
  hideSpacer?: boolean
} & Pick<IntakeData, "conditions">

export { ConditionsSection }
