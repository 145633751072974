import type { FC } from "react"

import { EDIT_ORDER_STEPS } from "../../types"
import { MrOrderProvider } from "./context/MrOrderProvider"
import { MedicationOrderEditConfig } from "./MedicationOrderEditConfig"
import { MedicationOrderEditPreview } from "./MedicationOrderEditPreview"

const MedicationOrderEditContainer: FC = () => (
  <div className="flex h-full flex-col overflow-hidden p-3 pb-0">
    <MrOrderProvider>
      {(step) => (step === EDIT_ORDER_STEPS.CONFIG ? <MedicationOrderEditConfig /> : <MedicationOrderEditPreview />)}
    </MrOrderProvider>
  </div>
)

export { MedicationOrderEditContainer }
