import { useParams } from "react-router-dom"

import { AppModuleProvider } from "internals"

import { MainNavbar } from "./MainNavbar"
import { ApplySettings } from "./ApplySettings"
import { OrganizationProvider } from "../context/OrganizationProvider"
import { Router } from "./Router"

const OrganizationContainer = () => {
  const { orgId } = useParams()

  if (!orgId) {
    throw new Error("Wrong URL param", {
      cause: {
        name: "500",
        message: "Missing organization ID param",
      },
    })
  }

  return (
    <AppModuleProvider orgId={orgId}>
      <OrganizationProvider organizationId={orgId}>
        <ApplySettings />
        <div className="flex h-full flex-col">
          <MainNavbar />
          <Router />
        </div>
      </OrganizationProvider>
    </AppModuleProvider>
  )
}

export { OrganizationContainer }
