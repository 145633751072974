import { faCalendarCheck, faCalendarDays } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Task, codeableConceptAsString } from "fhir"
import { useNavigate } from "react-router-dom"

import { Badge, ListItem } from "commons"
import { useOrganizationContext } from "organization"
import { getTaskDate, taskDateToString } from "tasks"
import { getBadgeColor } from "utils"

const LaboratoryTaskItem = ({ task }: Props) => {
  const { currentOrganizationId } = useOrganizationContext()
  const navigate = useNavigate()

  const Head = (
    <>
      {task.code && (
        <p title="Code" className="overflow-hidden text-ellipsis whitespace-nowrap">
          {codeableConceptAsString(task.code)}
        </p>
      )}
    </>
  )
  const CustomChip = (
    <div className="inline-flex w-fit min-w-40 justify-end gap-4">
      {task.status && <Badge title="Status" {...getBadgeColor(task.status)} />}
      {task.priority && <Badge title="Priority" {...getBadgeColor(task.priority)} />}
    </div>
  )

  const Details = (
    <>
      <span title="Authored on" className="flex items-center">
        <FontAwesomeIcon icon={faCalendarDays} className="mr-1.5 text-gray-400" />
        {taskDateToString(task.authoredOn as string)}
      </span>
      <span title="Scheduled for" className="flex items-center pl-2">
        <FontAwesomeIcon icon={faCalendarCheck} className="mr-1.5 text-gray-400" />
        {getTaskDate(task)}
      </span>
    </>
  )

  return (
    <ListItem
      header={Head}
      subHeader={Details}
      chip={CustomChip}
      className="cursor-pointer overflow-hidden rounded-lg border"
      externalAction="Task"
      onContentClick={() => {
        navigate(`/orgs/${currentOrganizationId}/tasks?id=${task.id}`)
      }}
    />
  )
}

type Props = {
  task: Task
}

export { LaboratoryTaskItem }
