import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useId, useState } from "react"

import { useChartContext } from "chart-view"
import {
  ConfirmDialog,
  InfiniteScroll,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
  useSendToPatient,
} from "commons"
import { invoiceStatusCodes } from "data"
import { useAppModuleContext } from "internals"
import { useCPOEContext } from "orders"
import { usePatientContext } from "patients"

import { useInvoices } from "../hooks"
import { invoiceModelBuilder } from "./invoiceModelBuilder"

const InvoiceListContainer = () => {
  const cpoeCtx = useCPOEContext()
  const chartContext = useChartContext()
  const { patientId } = usePatientContext()
  const { appModules } = useAppModuleContext()
  const { sendOrderToPatient, isSendingToPatient } = useSendToPatient()
  const [invoiceToSend, setInvoiceToSend] = useState("")

  useEffect(() => {
    chartContext.setSearchData({
      placeholder: "Search invoices",
      selectedStatus: ["draft", "balanced", "issued"],
      statusOptions: invoiceStatusCodes,
    })

    return () => {
      chartContext.setSearchData({ showSearch: false })
    }
  }, [])

  useEffect(() => {
    if (cpoeCtx.ordersCount >= 0) reloadInvoices()
  }, [cpoeCtx.ordersCount])

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType={"two-lines"} />

  const { invoiceData, isLoading, fetchNextPage, hasNextPage, reloadInvoices } = useInvoices({
    patientId,
    statusFilter: chartContext.searchData.selectedStatus,
    searchText: chartContext.searchData.filter,
  })

  const showSelectedInvoice = (invoiceId: string) => {
    chartContext.showModule({ module: ModulesId.INVOICE, moduleParams: { invoiceId } })
  }

  const sendToPatientInvoice = (invoiceId: string) => {
    setInvoiceToSend(invoiceId)
  }

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      {isLoading ? (
        loader()
      ) : !invoiceData.length ? (
        <div className="flex h-full flex-col items-center justify-center">
          <FontAwesomeIcon icon={appModules[ModulesId.INVOICE].getIcon()} size="2x" className="text-slate-400" />
          <h3 className="mt-2 text-sm font-semibold text-gray-900">No invoices found</h3>
        </div>
      ) : (
        <div className="h-full overflow-auto bg-white">
          <InfiniteScroll loadMore={() => fetchNextPage()} hasMore={hasNextPage} loader={loader()}>
            <StackedListContainer
              data={invoiceData}
              itemModelBuilder={(item) =>
                invoiceModelBuilder({
                  invoiceData: item,
                  showInvoice: () => showSelectedInvoice(item.invoice.id as string),
                  showAsExternal: false,
                  sendToPatientInvoice: () => sendToPatientInvoice(item.invoice.id as string),
                })
              }
              itemsClassName="py-4"
            />
          </InfiniteScroll>
        </div>
      )}

      <ConfirmDialog
        confirmText="Are you sure you want to send this invoice to the patient?"
        actionName="Send"
        visible={!!invoiceToSend || isSendingToPatient}
        isLoading={isSendingToPatient}
        onConfirm={() => {
          sendOrderToPatient(invoiceToSend)
        }}
        hideDialog={() => {
          setInvoiceToSend("")
        }}
      />
    </div>
  )
}

export { InvoiceListContainer }
