import { CheckBoxField, InputField, PeriodField } from "commons"
import { type FC } from "react"
import { startOfToday } from "date-fns/startOfToday"
import { useFormikContext } from "formik"
import type { PractitionerRoleNotAvailableArray } from "fhir"

import { DayHours } from "../availability/DayHours"

const TimeOffForm: FC = () => {
  const {
    values: { allDay },
    setFieldValue,
  } = useFormikContext<PractitionerRoleNotAvailableArray>()

  const setAllDay = (val: boolean) => setFieldValue("allDay", val)

  return (
    <>
      <InputField field="description" label="Description" />
      <div className="inline-flex w-full items-center gap-x-4">
        <PeriodField
          field="during"
          startLabel="Begin"
          endLabel="End"
          className="w-1/2"
          showTime={false}
          minDate={startOfToday()}
          stringFormatType="ISO_8601_DATE"
        />
      </div>
      <div className="inline-flex w-full items-start gap-x-4">
        <CheckBoxField field="allDay" label="All Day" className="whitespace-nowrap" onCheck={setAllDay} />
        {!allDay && <DayHours startLabel="Start" endLabel="End" onRemoveDay={() => setAllDay(true)} />}
      </div>
    </>
  )
}

export { TimeOffForm }
