import type { DatesSetArg, EventInput } from "@fullcalendar/core"
import {
  differenceInDays,
  differenceInHours,
  endOfMonth,
  isFirstDayOfMonth,
  isSameDay,
  parse,
  startOfMonth,
  startOfToday,
} from "date-fns"
import { toZonedTime } from "date-fns-tz"

import { formatsByTypes, SLOT_TYPE_DEFAULT_COLOR, UNIT_DAYS } from "data"
import { EventType } from "commons/types"
import { getAppointmentEventData } from "calendar"

import { type PractitionerCalendarEvent } from "./types"

const getEvents = (practCalendarEvent: PractitionerCalendarEvent) => {
  const { appointments, slots } = practCalendarEvent

  const apptEvents = appointments.reduce<EventInput[]>((events, calendarAppointment) => {
    const event = getAppointmentEventData(calendarAppointment)
    return [...events, event]
  }, [])

  const slotsEvents = slots.reduce<EventInput[]>((events, slot) => {
    // All day when hours difference  is approximately a full day
    const allDay = differenceInHours(slot.end, slot.start, { roundingMethod: "ceil" }) >= 24 + -1

    const event = {
      id: slot.id,
      title: slot.comment ?? "No title",
      // Show UTC date when all day otherwise show zoned date-time
      start: allDay ? toZonedTime(slot.start.toString(), "UTC", { timeZone: "UTC" }) : new Date(slot.start),
      end: allDay ? toZonedTime(slot.end.toString(), "UTC", { timeZone: "UTC" }) : new Date(slot.end),
      status: slot.status,
      backgroundColor: "#ef4444",
      borderColor: "#ef4444",
      extendedProps: {
        id: slot.id,
        color: SLOT_TYPE_DEFAULT_COLOR,
        type: "Time Off",
        status: slot.status,
        eventType: EventType.SLOT,
        slot,
        allDay,
      },
    }

    return [...events, event]
  }, [])

  return [...apptEvents, ...slotsEvents]
}

const getAppointmentsDateRangeFromDatesSet = (datesSet?: DatesSetArg) => {
  if (!datesSet) return undefined

  const { start, end } = datesSet

  const monthStartDate = startOfMonth(start)
  const monthEndDate = endOfMonth(end)

  return { start: monthStartDate, end: monthEndDate }
}

const getCalendarDateFromDatesSet = (datesSet?: DatesSetArg) => {
  if (!datesSet) return undefined

  const { start, end } = datesSet

  if (!isSameDay(start, end)) {
    if (isFirstDayOfMonth(start) || differenceInDays(end, start) <= UNIT_DAYS.WEEK) return start

    const newDate = new Date(start.getFullYear(), start.getMonth(), 1)
    newDate.setMonth(start.getMonth() + 1)
    return newDate
  }

  return start
}

const parseTimeFrame = (timePart: string) => parse(timePart, formatsByTypes.SHORT_TIME_WITH_SECONDS, startOfToday())

export { getAppointmentsDateRangeFromDatesSet, getCalendarDateFromDatesSet, getEvents, parseTimeFrame }
