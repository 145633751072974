import { faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { Reference } from "fhir"
import { type FieldProps, ErrorMessage, Field, getIn, useFormikContext } from "formik"
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { type FC, useCallback, useMemo, useState } from "react"

import { useCheckEmailExists, useValidateEmailEdit } from "hooks"

const EXIST_MAIL_ERROR = "This email address is in use"

const EmailField: FC<Props> = ({
  field,
  label,
  horizontal,
  floatLabel,
  className,
  inputClassName,
  disabled,
  autocomplete,
  initialValue: initialValueProp,
  handleChange,
  validateWithBrowser = false,
  validateDuplicate = false,
  isPractitionerRole = false,
  subject,
}) => {
  const { checkEmailExists, isCheckingEmail } = useCheckEmailExists()
  const { validateEmailEdit, isValidatingEmail } = useValidateEmailEdit()
  const [prevValue, setPrevValue] = useState<string>()
  const { errors } = useFormikContext()
  const fieldError = useMemo(() => getIn(errors, field), [errors])

  const { getFieldMeta } = useFormikContext()
  const initialValue = initialValueProp ?? getFieldMeta(field).initialValue

  const checkEmail = async (email: string) =>
    subject
      ? await validateEmailEdit({ emailToCheck: email, subject })
      : await checkEmailExists({ emailToCheck: email, isPractitionerRole })

  const validateField = useCallback(
    async (value: string) => {
      const { isValid, msg } = emailValidation(value)
      if (!isValid) return msg

      if (validateDuplicate && initialValue !== value && prevValue !== value) {
        const existsMail = await checkEmail(value)
        if (existsMail) return EXIST_MAIL_ERROR
        setPrevValue(value)
        return undefined
      }

      // When is a well formed valid email still check if it's duplicated if not then no errors
      return isValid && String(fieldError).localeCompare(EXIST_MAIL_ERROR) === 0 ? fieldError : undefined
    },
    [prevValue, fieldError],
  )

  return (
    <Field name={field} validate={validateField}>
      {({ field: { name, value, onChange }, meta: { touched, error }, form: { setFieldTouched } }: FieldProps) => (
        <div
          className={classNames(
            "field relative",
            horizontal && "inline-flex justify-between",
            floatLabel && "float-label relative mt-1 block",
            !horizontal && !floatLabel && "flex flex-col",
            className,
          )}
        >
          {label && !floatLabel && (
            <label
              htmlFor={name}
              className={classNames("mb-2 text-sm font-medium text-gray-700", { "mt-2 mr-3 mb-0": horizontal })}
            >
              {label}
            </label>
          )}
          <InputText
            autoComplete={autocomplete}
            aria-autocomplete="none"
            type={validateWithBrowser ? "email" : "text"}
            id={name}
            name={name}
            onChange={(e) => handleChange?.(e.target.value) ?? onChange(e)}
            onFocus={() => setPrevValue(value)}
            onBlur={() => {
              if (validateDuplicate) {
                const { isValid } = emailValidation(value)

                if (isValid && prevValue !== value) {
                  setFieldTouched(field, true)
                  checkEmail(value)
                }
              }
            }}
            value={value}
            disabled={disabled}
            className={classNames(
              "p-inputtext-sm",
              { "p-invalid": touched && error, horizontal: horizontal },
              inputClassName,
            )}
          />

          {label && floatLabel && (
            <label htmlFor={name} className="absolute top-2 left-3 text-sm text-gray-400 transition-all ease-in-out">
              {label}
            </label>
          )}

          <div className="field-error-spacing flex items-start">
            <small>
              {error ? (
                <ErrorMessage name={field}>{(msg) => <span className="p-error mr-2">{msg}</span>}</ErrorMessage>
              ) : isCheckingEmail || isValidatingEmail ? (
                <span className="text-slate-500">
                  <FontAwesomeIcon icon={faSpinner} spin />
                  <span className="ml-1">checking availability.</span>
                </span>
              ) : null}
            </small>
          </div>
        </div>
      )}
    </Field>
  )
}

const emailValidation = (value: string) => {
  const check = { isValid: true, msg: "" }

  if (!value) {
    check.isValid = false
    check.msg = "Email address is required"
  } else {
    if (
      !/^([a-zA-Z0-9]([_]*|[.+][a-zA-Z0-9]+|[-]+[a-zA-Z0-9]+)*)+@[a-zA-Z0-9]+([.|-]{0,1}[a-zA-Z0-9])*(\.[a-zA-Z]{2,})+$/.test(
        value,
      )
    ) {
      check.isValid = false
      check.msg = "Invalid email address"
    } else if (/@evexias.com/.test(value)) {
      check.isValid = false
      check.msg = "This email cannot be used"
    }
  }

  return check
}

type Props = {
  field: string
  label?: string
  className?: string
  horizontal?: boolean
  floatLabel?: boolean
  inputClassName?: string
  validation?(value: string): void
  disabled?: boolean
  autocomplete?: string
  fieldName?: string
  handleChange?(email: string): void
  validateWithBrowser?: boolean
  validateDuplicate?: boolean
  validateRules?: boolean
  initialValue?: string
  isPractitionerRole?: boolean
  subject?: Reference
}

export { EmailField }
