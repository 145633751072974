import { Badge } from "commons"
import type { Observation } from "fhir"
import { classNames } from "primereact/utils"

import { INTERPRETATION_CODES } from "data"
import { SYSTEM_VALUES } from "system-values"

import type { TriadData, OrganData } from "../../types"
import { triads } from "../../data"
import { hasObservationValue } from "../../utils"

const TriadLabDetails = ({ triad }: Props) => {
  return (
    <div className="flex p-2">
      <span
        className={classNames(
          "flex h-8 w-8 items-center justify-center rounded-full border text-xs",
          triads[triad.id].borderColor,
        )}
      >
        {triad.triad?.value?.Quantity?.value}
      </span>
      <img src={triads[triad.id].img} alt="" className="w-24 self-start" />
      <div className="mr-4 ml-14 flex flex-1 flex-col gap-4">
        {triad.organs.map((o, index) => (
          <TriadOrganDetails key={o?.id ?? index} organ={o} />
        ))}
      </div>
    </div>
  )
}

const TriadOrganDetails = ({ organ }: { organ: OrganData }) => {
  const labData = organ.labData.filter(hasObservationValue)

  if (!labData.length) return null

  return (
    <div className="flex flex-col gap-1">
      <div className="flex h-10 items-center rounded-md bg-gray-100 px-2 text-lg font-medium">
        {organ.organ.code.text}
      </div>
      <div className="flex flex-col gap-1">
        {labData.map((lab, index) => (
          <TriadLabItem key={lab?.id ?? index} lab={lab} />
        ))}
      </div>
    </div>
  )
}

const TriadLabItem = ({ lab }: { lab: Observation }) => {
  const normalRange =
    lab?.referenceRange?.find((r) => r.type?.coding?.[0]?.code === "normal")?.text ?? lab?.referenceRange?.[0]?.text
  const interpretationCode =
    lab?.interpretation?.find((i) => i.coding?.[0]?.system === SYSTEM_VALUES.OBSERVATION_INTERPRETATION)?.coding?.[0]
      ?.code ?? ""

  const risk = INTERPRETATION_CODES[interpretationCode]

  return (
    <div className="grid h-10 grid-cols-3 items-center rounded-md border px-2">
      <span className="font-medium">{lab?.code.text}</span>
      {!!lab?.value && (
        <div className="flex justify-center gap-1 py-1">
          <span className="w-28 rounded-md bg-gray-100 text-center font-medium">{normalRange}</span>
          <span className="w-28 rounded-md bg-gray-200 text-center font-medium">
            {lab?.value?.Quantity?.value
              ? lab.value.Quantity.value % 1 === 0
                ? lab.value.Quantity.value
                : lab.value.Quantity.value.toFixed(2)
              : lab?.value?.string}
          </span>
        </div>
      )}
      {!!risk && (
        <div className="text-right">
          <Badge
            text={risk}
            colorStyle={risk === "Optimal Normal" ? "green" : risk.includes("Alert") ? "red" : "yellow"}
            className="my-2"
          />
        </div>
      )}
    </div>
  )
}

type Props = {
  triad: TriadData
}

export { TriadLabDetails }
