import { ResponsiveLine } from "@nivo/line"
import { format, getYear } from "date-fns"
import { classNames } from "primereact/utils"
import { useMemo } from "react"

import type { VitalRecord, ColData } from "../types"

const VitalChart = ({ vitalRecords, vitalName, relatedVitalRecords, relatedVitalName, className }: Props) => {
  const getVitalValues = (identifier: string, data?: VitalRecord[]) =>
    data?.reduce(
      (acc, vital) => {
        const y = vital.value?.value
        const x = vital.date && format(vital.date, "yyyy-MM-dd")
        return { ...acc, ...(x && y ? { data: [...acc.data, { x, y }] } : {}) }
      },
      { id: identifier, data: [] } as DataRecord,
    )

  const vitalData = getVitalValues(vitalName, vitalRecords)
  const unit = vitalRecords?.[0]?.value?.unit
  const relatedVitalData = relatedVitalName && getVitalValues(relatedVitalName, relatedVitalRecords)

  const minWithDynamicClass = useMemo(() => {
    const dataYears = [...(vitalData?.data ?? []), ...(relatedVitalData?.data ?? [])].reduce((acc, { x }) => {
      const yearInDate = getYear(x)
      if (!acc.includes(yearInDate)) return [...acc, yearInDate]
      return acc
    }, Array<number>())
    const maxEntries = dataYears.length
    return maxEntries < 10 ? "min-w-[40rem]" : maxEntries < 20 ? "min-w-[100rem]" : "min-w-[200rem]"
  }, [vitalData?.data.length, relatedVitalData?.data.length])

  if (!vitalData?.data.length) return null

  return (
    <div className={classNames("h-full w-full", minWithDynamicClass, className)}>
      <ResponsiveLine
        axisBottom={{
          format: "%Y %b",
          legend: "Date",
          legendOffset: 18,
          legendPosition: "end",
          tickValues: vitalRecords?.length ?? 5,
        }}
        axisLeft={{
          legend: unit,
          legendOffset: -38,
          legendPosition: "middle",
          tickPadding: 5,
        }}
        curve="monotoneX"
        enablePointLabel
        enableTouchCrosshair
        margin={{
          bottom: 24,
          left: 44,
          right: 20,
          top: 20,
        }}
        motionConfig="gentle"
        colors={["#9ca3af", "#e5e7eb"]}
        pointSize={10}
        pointColor={{ theme: "labels.text.fill" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        enableGridX={false}
        useMesh
        xFormat="time:%Y-%m-%d"
        xScale={{
          format: "%Y-%m-%d",
          precision: "day",
          type: "time",
          useUTC: false,
        }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        data={[vitalData, ...(relatedVitalData ? [relatedVitalData] : [])]}
        animate
        tooltip={({
          point: {
            color,
            data: { xFormatted, yFormatted },
          },
        }) => (
          <div className="z-10 inline-flex items-center rounded-lg border bg-white p-2">
            <div style={{ backgroundColor: color }} className="mr-1 h-3 w-3 border" />
            <div className="flex flex-wrap text-sm text-gray-500">
              x:{xFormatted}, y:{yFormatted}
            </div>
          </div>
        )}
      />
    </div>
  )
}

type Props = {
  vitalRecords?: VitalRecord[]
  vitalName: keyof ColData
  relatedVitalName?: keyof ColData
  relatedVitalRecords?: VitalRecord[]
  className?: string
}

type DataRecord = { id: string; data: Array<{ y: number; x: string }> }

export { VitalChart }
