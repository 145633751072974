import { faEye } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"
import { classNames } from "primereact/utils"

import "./SearchWithStatus.css"

const specialCharRegex = /[$]*\\*/g

const SearchWithStatus = ({ selectedItems, options, className, onStatusCheck, onSearch, placeholder }: Props) => (
  <div className={classNames("search-status-dropdown relative flex", className)}>
    <div className="p-inputgroup relative">
      <InputText
        type="search"
        placeholder={placeholder ?? "Search"}
        className="search-input"
        onChange={(event) => {
          event.currentTarget.value = event.currentTarget?.value.replaceAll(specialCharRegex, "").trimStart()
          onSearch(event.currentTarget.value)
        }}
      />
      {options && options.length > 1 && (
        <span className="p-inputgroup-addon relative h-fit">
          <FontAwesomeIcon className="text-slate-400" size="1x" icon={faEye} />
          <MultiSelect
            value={selectedItems}
            options={options}
            optionLabel="display"
            scrollHeight="300px"
            optionValue="code"
            filter={false}
            showSelectAll={false}
            onChange={(e) => onStatusCheck?.(e.value)}
            panelClassName="hidden-header"
            className="hidden-labels custom-trigger"
          />
        </span>
      )}
    </div>
    <span className="absolute left-2.5 z-10 flex h-full items-center">
      <i className="pi pi-search" />
    </span>
  </div>
)

type Props = {
  className?: string
  options?: Array<{ code: string; display: string }>
  selectedItems?: string[]
  onStatusCheck?(codes: string[]): void
  onSearch(filter?: string): void
  placeholder?: string
}

export { SearchWithStatus }
