import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Address, getAddress } from "fhir"
import { type FormikHelpers, type FormikProps, Form, Formik, useFormikContext } from "formik"
import { Dialog } from "primereact/dialog"
import { Message } from "primereact/message"
import { Tooltip } from "primereact/tooltip"
import { type FC, useRef, useState } from "react"

import { AddFieldArrayItemButton, AddressField, AddressVerificationFeedback, Button, SkeletonLoader } from "commons"
import { useSmartyAddressVerification, useSmartyAddressVerificationContext } from "commons/hooks"
import { emptyAddress } from "data"
import { useUpdatePatientShippingAddress } from "patients"
import { getAddressSchema, getHomeAddress, isPoBoxAddress } from "utils"

import { useCPOERequestsContext } from "../hooks"

const OrdersShippingAddress: FC<Props> = ({ nutrasShippingAddress, showMedsAddress, isLoading, className }) => {
  const formikRef = useRef<FormikProps<Address>>(null)
  const { setIsProcessingActions, patient, addressErrorMessage, isProcessingActions } = useCPOERequestsContext()

  const defaultAddress = getHomeAddress(patient?.address)
  const [initialValues, setInitialValues] = useState<Address | null>(null)

  const { checkAddress, clearVerificationInfo } = useSmartyAddressVerification()

  const handleMedsButtonClick = () => {
    setInitialValues({ ...emptyAddress, ...(nutrasShippingAddress ?? defaultAddress), type: "postal", country: "US" })
  }

  const hideDialog = () => {
    clearVerificationInfo()
    setInitialValues(null)
  }

  const { updateShippingAddress, isUpdating: isUpdatingShippingAddress } = useUpdatePatientShippingAddress(
    hideDialog,
    (_, error) => {
      setIsProcessingActions(false)
      if (error) formikRef?.current?.setSubmitting(false)
    },
  )

  const onSubmitAddress = (address: Address, formikHelpers?: FormikHelpers<Address>) => {
    checkAddress(address, formikHelpers, () => {
      clearVerificationInfo()

      updateShippingAddress({ address, patient: patient })
      setIsProcessingActions(true)
    })
  }

  if (isLoading) return <SkeletonLoader repeats={4} loaderType="one-line" />
  return (
    <>
      <div className={className}>
        {showMedsAddress && (
          <ShippingAddressItem
            emptyMessage="Add new address"
            address={nutrasShippingAddress}
            isDisabled={isUpdatingShippingAddress || isProcessingActions}
            onButtonClick={handleMedsButtonClick}
            label="Patient Address"
          />
        )}
      </div>
      {!!addressErrorMessage && (
        <Message
          severity="error"
          className="my-3 w-full px-3 py-1.5 text-sm"
          pt={{ text: { className: "text-sm" } }}
          text={addressErrorMessage}
        />
      )}
      <Dialog
        className="shipping-address-form paddingless w-[40vw] min-w-[500px]"
        visible={!!initialValues}
        onHide={hideDialog}
        header={`${!initialValues?.line?.[0] ? "Add" : "Edit"} ${
          initialValues?.type === "physical" ? "physical" : "shipping"
        } address`}
      >
        <Formik
          innerRef={formikRef}
          initialValues={initialValues ?? {}}
          onSubmit={onSubmitAddress}
          validationSchema={getAddressSchema()}
        >
          <AddressForm onCancel={hideDialog} />
        </Formik>
      </Dialog>
    </>
  )
}

const AddressForm = ({ onCancel }: AddressFormProps) => {
  const { isSubmitting, setFieldValue } = useFormikContext()
  const { addressVerificationInfo, autoCompleteRecommendedAddress, bypassAddressValidation } =
    useSmartyAddressVerificationContext()

  return (
    <Form autoComplete="off" aria-autocomplete="none" className="w-full px-5">
      <div className="flex flex-col justify-between divide-y divide-gray-300">
        <div className="p-fluid relative grid gap-4">
          <fieldset className="p-fluid relative grid grid-cols-2 gap-4">
            <AddressField showTypeUseField={false} />
          </fieldset>
        </div>
        <AddressVerificationFeedback
          addressVerificationInfo={addressVerificationInfo}
          handleAutoCompleteRecommendedAddress={() => autoCompleteRecommendedAddress?.(setFieldValue)}
          handleBypassAddressValidation={bypassAddressValidation}
        />
      </div>

      <div className="mt-4 flex w-full justify-end border-t-2 border-slate-200 bg-white py-4 lg:pt-4">
        <Button
          label="Close"
          type="button"
          buttonStyle="default"
          disabled={isSubmitting}
          onClick={onCancel}
          className="mr-3"
        />
        <Button type="submit" label="Save" buttonStyle="primary" loading={isSubmitting} disabled={isSubmitting} />
      </div>
    </Form>
  )
}

type AddressFormProps = {
  onCancel: () => void
}

const ShippingAddressItem = ({
  address,
  label,
  isDisabled,
  emptyMessage,
  isRequired = true,
  onButtonClick,
}: {
  label: string
  address?: Address
  isDisabled?: boolean
  emptyMessage: string
  isRequired?: boolean
  onButtonClick: () => void
}) => (
  <>
    <h4 className="inline-flex w-full items-center justify-between text-sm font-medium">
      {label} <hr className="ml-3 flex-1 border" />
    </h4>

    {address ? (
      <div className="my-3 flex items-center justify-between pl-5">
        <p className="bg-white text-sm">
          {isPoBoxAddress(address) && (
            <>
              <Tooltip
                target=".tooltiped"
                event="hover"
                position="left"
                content="It looks like you're trying to set up a P.O. Box address. Please note that P.O. Boxes may not be suitable for faster deliveries."
              />
              <span className="tooltiped mr-4 cursor-pointer">
                <FontAwesomeIcon icon={faInfoCircle} className="text-orange-500" />
              </span>
            </>
          )}
          {getAddress([address])}
        </p>
        <Button
          icon={faEdit}
          className="p-button-text p-button-sm no-focusable max-w-fit"
          type="button"
          buttonStyle="text"
          onClick={onButtonClick}
          disabled={isDisabled}
          title="Edit"
        />
      </div>
    ) : (
      <>
        {isRequired && (
          <Message
            severity="error"
            className="w-full px-3 py-1.5 text-sm"
            pt={{ text: { className: "text-sm" } }}
            text="Patient's home address is required"
          />
        )}
        <AddFieldArrayItemButton label={emptyMessage} onClick={onButtonClick} />
      </>
    )}
  </>
)

type Props = {
  nutrasShippingAddress?: Address
  showMedsAddress: boolean
  isLoading?: boolean
  className?: string
}

export { OrdersShippingAddress }
