import { faEnvelope, faMobile } from "@fortawesome/pro-regular-svg-icons"
import { faSearch } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  type CodeableConcept,
  type Reference,
  type RelatedPerson,
  asReference,
  codeableConceptAsString,
  getFirstPhone,
  humanNameAsString,
} from "fhir"
import { type FC, useEffect, useMemo } from "react"

import {
  type StackedListItemProps,
  AddFieldArrayItemButton,
  ConfirmDialog,
  DialogFormContainer,
  SkeletonLoader,
  StackedListContainer,
  useCrudReducer,
} from "commons"
import { useReplaceFormContext } from "commons/hooks"
import { usePatientContext } from "patients"
import { strCapitalize } from "utils"
import { defaultEditRemoveMenu } from "utils-components"

import { useCreateRelatedPerson, useDeleteRelatedPerson, useRelatedPerson, useRPLinkedCoverages } from "../hooks"
import { RelatedPersonForm } from "./RelatedPersonForm"
import { getRelatedPersonInitialValues, relatedPersonValidationSchema } from "./validations"

const RelatedPersonContainer: FC<Props> = ({
  autoSelect,
  selectedCode,
  selectedPerson,
  setSelectedPerson,
  setSelectedRelationship,
}) => {
  const { patientId } = usePatientContext()
  const { relatedPeople, personRefbyCode, prIds, isLoading } = useRelatedPerson(patientId)
  const { rpLinkedCoverages, isLoadingRPLink } = useRPLinkedCoverages(patientId, prIds)

  const { showSlide, initialValue, deleteIndex, add, edit, reset, setDeleteIndex } = useCrudReducer({
    defaultEntity: getRelatedPersonInitialValues(patientId),
  })

  const { createRelatedPerson, isAdding } = useCreateRelatedPerson((rp) => {
    setSelectedPerson(asReference(rp))
  }, reset)
  const { deleteRelatedPerson, isDeleting } = useDeleteRelatedPerson((rpId) => {
    if (rpId && selectedPerson === rpId) {
      setSelectedPerson(undefined)
    }
  }, reset)

  useEffect(() => {
    if (!isLoading && autoSelect) {
      setSelectedPerson(
        personRefbyCode[selectedCode] ?? relatedPeople?.[0]?.id ? asReference(relatedPeople[0]) : undefined,
      )
    }
  }, [selectedCode, isLoading])

  const onSubmit = (data: RelatedPerson) => createRelatedPerson(data)

  const dialogFormProps = useMemo(() => {
    return {
      title: "Subscriber",
      showForm: showSlide || isAdding,
      useFormik: true,
      initialValue,
      validationSchema: relatedPersonValidationSchema,
      saveLabel: initialValue.id ? "Save" : "Add",
      children: <RelatedPersonForm />,
      onCancel: reset,
      onSubmit: onSubmit,
    }
  }, [showSlide, isAdding])

  const dialogContext = useReplaceFormContext<RelatedPerson>()

  useEffect(() => {
    if (dialogContext) {
      dialogContext.setReplacementContent?.({ ...dialogFormProps })
    }
  }, [dialogFormProps])

  if (isLoading || isLoadingRPLink) return <SkeletonLoader loaderType="two-lines" repeats={2} />

  return (
    <>
      {relatedPeople.length > 0 ? (
        <StackedListContainer
          itemsClassName="px-2 py-4"
          data={relatedPeople}
          itemModelBuilder={(item) =>
            relatedPersonModel(
              item,
              item.id === selectedPerson,
              () => {
                setSelectedPerson(asReference(item))
                setSelectedRelationship(item.relationship?.[0])
              },
              () =>
                edit(
                  item?.address?.[0]?.type === undefined
                    ? { ...item, address: item?.address?.with(0, { ...item?.address?.[0], type: "home" }) }
                    : item,
                ),
              !rpLinkedCoverages.includes(item.id as string) ? () => setDeleteIndex(item.id) : undefined,
            )
          }
        />
      ) : (
        <div className="flex flex-col items-center justify-center py-5">
          <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
          <p className="pt-1 text-xs text-slate-500">No related person added yet</p>
        </div>
      )}
      <AddFieldArrayItemButton className="px-2 py-4" onClick={add} />
      {!dialogContext && <DialogFormContainer {...dialogFormProps} />}

      <ConfirmDialog
        confirmText="Are you sure you want to remove this related person?"
        actionName="Remove"
        visible={deleteIndex !== undefined || isDeleting}
        isLoading={isDeleting}
        onConfirm={() => deleteRelatedPerson(deleteIndex as string)}
        hideDialog={() => setDeleteIndex(undefined)}
      />
    </>
  )
}

const relatedPersonModel = (
  relatedPerson: RelatedPerson,
  isSelected: boolean,
  onClick: () => void,
  onEdit: () => void,
  onDelete?: () => void,
): StackedListItemProps => ({
  onClick,
  itemClassName: isSelected ? "ring-2 ring-slate-500 ring-inset rounded-lg" : "",
  leftData: [
    {
      lineItems: [
        { name: "Name", value: humanNameAsString(relatedPerson?.name?.[0]) },
        ...(relatedPerson
          ? [
              {
                name: "Relationship",
                value: `(${strCapitalize(codeableConceptAsString(relatedPerson?.relationship?.[0]))})`,
              },
            ]
          : []),
      ],
    },
    {
      lineItems: [
        ...(relatedPerson?.telecom?.[0]
          ? [
              {
                name: strCapitalize(relatedPerson?.telecom[0].system as string),
                value: relatedPerson?.telecom[0].value,
                icon: faEnvelope,
              },
            ]
          : []),
        ...(relatedPerson?.telecom?.[1]
          ? [
              {
                name: strCapitalize(relatedPerson?.telecom[1].system as string),
                value: getFirstPhone(relatedPerson?.telecom),
                icon: faMobile,
              },
            ]
          : []),
      ],
    },
  ],
  menu: defaultEditRemoveMenu(onEdit, onDelete),
})

type Props = {
  autoSelect: boolean
  selectedCode: string
  selectedPerson?: string
  setSelectedPerson(personRef: Reference | undefined): void
  setSelectedRelationship(code: CodeableConcept | undefined): void
}

export { RelatedPersonContainer }
