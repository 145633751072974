import { faCreditCard, faFileInvoiceDollar, faInfoCircle, faScannerGun } from "@fortawesome/pro-regular-svg-icons"
import { faCalendarDays, faPaperPlane } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isPatient, type Invoice, type PaymentReconciliation } from "fhir"
import { Tooltip } from "primereact/tooltip"

import type { StackedListItemProps } from "commons"
import { formatsByTypes, invoiceStatusCodes } from "data"
import { classNames } from "primereact/utils"
import { SYSTEM_VALUES } from "system-values"
import { formatDate, getBadgeColor, getMoneyCurrencyAlt } from "utils"

const invoiceModelBuilder = ({
  invoiceData,
  showInvoice,
  showAsExternal,
  sendToPatientInvoice,
}: InvoiceModelBuilderArgs): StackedListItemProps => {
  const { invoice, payRecs } = invoiceData

  const identifier = invoice.identifier?.find(({ system }) => system === SYSTEM_VALUES.INVOICE_INDENTIFIER)?.value

  const invoiceDate = invoice.date && formatDate(new Date(invoice.date), formatsByTypes.SHORT_DATE)
  const itemsCount = invoice.lineItem?.filter((item) => item.priceComponent?.[0]?.type === "base").length
  const price = invoice?.totalGross?.value
    ? getMoneyCurrencyAlt(invoice?.totalGross.currency) + invoice.totalGross.value.toFixed(2)
    : "$0.00"
  const billingType = isPatient(invoice.recipient) ? "Patient" : "Practice"

  const successfulPayments = payRecs?.filter((payRec) => payRec.paymentStatus === "succeeded") ?? []

  const getPaymentMethodDisplay = (): string => {
    if (successfulPayments.length > 1) {
      return "Split payment"
    }

    const payment = successfulPayments[0]

    if (payment?.creditCard?.last4Digits && payment?.creditCard?.type) {
      return `${payment.creditCard.type}-${payment.creditCard.last4Digits}`
    }

    return "Credit Card"
  }

  const paymentMethodLineItemValue = getPaymentMethodDisplay()

  return {
    leftData: [
      {
        lineItems: [{ name: "Invoice number", value: identifier ? `#${identifier}` : "Unspecified number" }],
      },
      {
        lineItems: [
          { name: "Issued date", value: invoiceDate, icon: faCalendarDays },
          { name: "Items", value: `${itemsCount}`, icon: faScannerGun },
          { name: "Bill To", value: billingType, icon: faFileInvoiceDollar },
          ...(invoice.status === "balanced" && successfulPayments.length > 0
            ? [
                {
                  name: "Payment Method",
                  ...(paymentMethodLineItemValue === "Split payment"
                    ? {
                        component: renderSplitPaymentInfo({
                          invoiceId: invoice.id as string,
                          payments: successfulPayments,
                        }),
                      }
                    : { value: paymentMethodLineItemValue }),
                  icon: faCreditCard,
                },
              ]
            : []),
          { name: "Total Net", value: `${price}` },
        ],
      },
    ],
    menu: [
      {
        label: "Send to patient",
        icon: <FontAwesomeIcon icon={faPaperPlane} size="sm" className="mr-2" />,
        command: sendToPatientInvoice,
        disabled: invoice.status === "balanced",
      },
    ],
    onClick: !showAsExternal ? showInvoice : undefined,
    badge: {
      ...getBadgeColor(invoice.status),
      text: invoiceStatusCodes.find((status) => status.code === invoice.status)?.display ?? "Unknown",
    },
  }
}

const renderSplitPaymentInfo = ({ invoiceId, payments }: SplitPaymentInfoProps) => {
  const sortedPayments = [...payments].sort(
    (a, b) => new Date(b.meta?.lastUpdated ?? "").getTime() - new Date(a.meta?.lastUpdated ?? "").getTime(),
  )

  return (
    <div className="ml-3 flex items-center gap-2">
      Split payment
      <FontAwesomeIcon icon={faInfoCircle} className={classNames("text-gray-500", `split-payment-info-${invoiceId}`)} />
      <Tooltip target={`.split-payment-info-${invoiceId}`} position="right" className="m-0 p-0 text-xs">
        <div className="flex flex-col gap-1">
          {sortedPayments.map((payment) => (
            <div key={payment.id}>
              - ({formatDate(new Date(payment.meta?.lastUpdated ?? ""), formatsByTypes.SHORT_DATE)}){" "}
              {payment.creditCard?.type}-{payment.creditCard?.last4Digits}
            </div>
          ))}
        </div>
      </Tooltip>
    </div>
  )
}

interface SplitPaymentInfoProps {
  invoiceId: string
  payments: PaymentReconciliation[]
}

interface InvoiceModelBuilderArgs {
  invoiceData: {
    invoice: Invoice
    payRecs?: PaymentReconciliation[]
  }
  showInvoice?: () => void
  showAsExternal?: boolean
  sendToPatientInvoice?: () => void
}

export { invoiceModelBuilder }
