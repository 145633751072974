import { useMutation, useQueryClient } from "@tanstack/react-query"
import { asDeleteBundleEntry, type Bundle } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { medsQueryKeys } from "commons/meds"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { ordersQueryKeys } from "orders"
import { usePatientContext } from "patients"

const useDeleteMultipleMedicationRequests = (onSuccess?: () => void) => {
  const { transaction } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const delMR = async (medicationRequestIds: string[]) => {
    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: medicationRequestIds.map((id) => asDeleteBundleEntry({ id, resourceType: "MedicationRequest" })),
    }

    if (!medicationRequestIds.length) return

    return transaction(bundle)
  }

  const { mutate: deleteMedicationRequests, isPending: isDeleting } = useMutation({
    mutationFn: delMR,
    onError: (error: CustomError, medicationRequestId) => {
      displayNotificationError(registerErrorTrace(error, { medicationRequestId }))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: medsQueryKeys.medicationRequestList(patientId, "medication", ["active"]),
        refetchType: "all",
      })
      queryClient.refetchQueries({ queryKey: medsQueryKeys.draft.list(patientId, "medication") })
      queryClient.refetchQueries({ queryKey: ordersQueryKeys.count.withPatientId(patientId), type: "all" })
      await queryClient.invalidateQueries({
        queryKey: ordersQueryKeys.list(patientId),
      })
      onSuccess?.()
      // datadogLogs.logger.info("Prescription discarded successfully!", { patientId })
    },
  })

  return { deleteMedicationRequests, isDeleting }
}

export { useDeleteMultipleMedicationRequests }
