import { type Composition, type MedicationKnowledge, type Reference, codeableConceptAsString } from "fhir"
import { useFormikContext } from "formik"
import { Checkbox } from "primereact/checkbox"
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace"
import { type Dispatch, type FC, type SetStateAction, useCallback, useEffect, useMemo, useState } from "react"

import { SkeletonLoader } from "commons"
import { useOpenEncounter } from "encounter"
import { usePatientContext } from "patients"
import { getCommonCode } from "utils"

import { getMedsProductConfigurations } from "../../../utils"
import { useAlgorithmMKInventory, useProcedureCatalogs } from "../../hooks"
import type { CalculatorOutput, ConfigurationItem, InventoryData, ProcedureData } from "../../types"
import { getInvCode } from "../../utils/formatters"
import { getMedInitialValues } from "../../utils/transformers"
import type { NewMedData } from "../MedicationInventoryList"
import { pelletModel } from "../pelletModel"
import { CalculatorRecomendations } from "./CalculatorRecomendations"
import { SuggestedMedItem } from "./SuggestedMedItem"

const SuggestedMeds: FC<Props> = ({
  suggestedMeds,
  recommended: allRecommended,
  notes,
  supplements,
  carePlanId,
  setDisableSave,
}) => {
  const { patientId, patientRef } = usePatientContext()
  const { setSubmitting, setFieldValue } = useFormikContext<ProcedureData>()

  const { catalogs } = useProcedureCatalogs()
  const catalogId = catalogs?.[0]?.id as string

  const medProductConfigurations = getMedsProductConfigurations({ meds: suggestedMeds }) ?? []
  const medCodes = medProductConfigurations.map(({ code }) => code)
  const { inventory, isLoading } = useAlgorithmMKInventory(catalogId, medCodes, catalogId !== undefined)

  const recommended = useMemo(
    () => allRecommended.filter(({ relatedMedsIds }) => relatedMedsIds[0] !== undefined),
    [allRecommended],
  )

  const [effectiveSuggestedMeds, setEffectiveSuggestedMeds] = useState<SuggestedMedsData>()
  const [allSelectedItemsBySection, setAllSelectedItemsBySection] = useState<Record<string, ConfigurationItem[]>>({})

  const getDosageValue = (mk: MedicationKnowledge): number =>
    mk?.administrationGuidelines?.[0]?.dosage?.[0]?.dosage?.[0]?.doseAndRate?.[0]?.dose?.Quantity?.value ?? 0

  const getMkStock = useCallback(
    (mk: MedicationKnowledge): { isOnStock: boolean; stock: number } => {
      const mkInventory = inventory[getInvCode(mk.code?.coding)]

      return {
        isOnStock: !!mkInventory,
        stock: mkInventory ? mkInventory.reduce((acc, { quantity }) => acc + quantity, 0) : 0,
      }
    },
    [inventory],
  )

  const getEffectiveSuggestedMeds = useCallback(
    (
      relatedSuggestedMeds: MedicationKnowledge[],
      recommendedRelatedTo: CalculatorOutput["recommended"][0],
    ): Array<MedWithStockAndDosage> => {
      const recommendedDose = recommendedRelatedTo.dosage?.doseAndRate?.[0]?.dose?.Quantity?.value ?? 0

      if (recommendedDose === 0) {
        const minimumDosageMedMK = relatedSuggestedMeds.reduce((lowest, mk) => {
          const currentDosage = getDosageValue(mk)
          const lowestDosage = getDosageValue(lowest)

          if (currentDosage === undefined) return lowest

          if (lowestDosage === undefined || currentDosage < lowestDosage) {
            return mk
          }

          return lowest
        }, relatedSuggestedMeds[0])

        const { stock, isOnStock } = getMkStock(minimumDosageMedMK)

        return isOnStock ? [{ qty: 1, mk: minimumDosageMedMK, stock: stock, recommendedDose }] : []
      }

      // Commented lines are part of the greedy implementation
      // just in case is needed in the future

      // let remindingDosage = recommendedDose

      const effectiveMedsWithStockAndAllowedDoseWithQty = relatedSuggestedMeds
        .sort((a, b) => getDosageValue(b) - getDosageValue(a))
        .reduce((effectiveSuggestedMeds: Array<MedWithStockAndDosage>, mk) => {
          const { stock, isOnStock } = getMkStock(mk)
          const mkDose = getDosageValue(mk)

          if (!isOnStock || mkDose > recommendedDose) return effectiveSuggestedMeds

          //let qty = Math.floor(remindingDosage / mkDose)

          //if (qty > stock) {
          //   qty = stock
          //}

          //remindingDosage -= qty * mkDose

          return [...effectiveSuggestedMeds, { qty: 1, mk, stock, recommendedDose }]
        }, [] as Array<MedWithStockAndDosage>)

      return effectiveMedsWithStockAndAllowedDoseWithQty
    },
    [inventory, suggestedMeds, recommended],
  )

  useEffect(() => {
    if (!isLoading) {
      setSubmitting(false)
      let effectiveSuggestedMeds: SuggestedMedsData = {}
      recommended.forEach((recommendedMed) => {
        const code = codeableConceptAsString(recommendedMed.codeableConcept)
        const relatedSuggestedMeds = suggestedMeds?.filter((mk) => recommendedMed.relatedMedsIds.includes(mk.id!))
        const effectiveSuggestedMedsThatAreRelated = getEffectiveSuggestedMeds(relatedSuggestedMeds, recommendedMed)

        effectiveSuggestedMeds = {
          ...effectiveSuggestedMeds,
          [code]: effectiveSuggestedMedsThatAreRelated,
        }
      })

      setEffectiveSuggestedMeds(effectiveSuggestedMeds)
    }
  }, [isLoading])

  useEffect(() => {
    const allSelectedItems = recommended.flatMap(({ codeableConcept }) => {
      const code = codeableConceptAsString(codeableConcept)
      return allSelectedItemsBySection[code]
    })

    const noneSelected = Object.values(allSelectedItemsBySection).every((sectionSelection) => !sectionSelection.length)
    setDisableSave(noneSelected)

    setFieldValue("configurationItem", allSelectedItems)
  }, [allSelectedItemsBySection, recommended])

  return (
    <>
      {isLoading || !catalogId ? (
        <SkeletonLoader containerClassName="flex flex-col mt-4" loaderType="two-lines" repeats={5} />
      ) : (
        <div className="mt-4 space-y-6 overflow-y-auto px-1">
          <CalculatorRecomendations
            notes={notes}
            recommended={allRecommended}
            supplements={supplements}
            patientId={patientId}
            planId={carePlanId}
          />

          {!!effectiveSuggestedMeds && (
            <section>
              <span>Recommended pellets</span>
              <div className="gap-6 divide-y-2 divide-gray-200">
                {recommended.map(({ codeableConcept }) => {
                  const code = codeableConceptAsString(codeableConcept)
                  const suggestedMed = effectiveSuggestedMeds[code]
                  return (
                    <SuggestedMedsSection
                      key={code}
                      id={code}
                      suggestedMeds={suggestedMed}
                      inventoryData={{ isLoadingInventory: isLoading, inventory }}
                      catalogsData={{ catalogId, data: catalogs }}
                      patientInfo={{ id: patientId, reference: patientRef }}
                      handleSetSelectedMeds={setAllSelectedItemsBySection}
                      setDisableSave={setDisableSave}
                    />
                  )
                })}
              </div>
            </section>
          )}
        </div>
      )}
    </>
  )
}

const MAXIMUM_RELATED_MEDS_SHOW_PER_RECOMMENDED_DOSAGE = 4

const SuggestedMedsSection = ({
  id: recommendedRelatedToCode,
  suggestedMeds,
  inventoryData,
  catalogsData,
  patientInfo,
  handleSetSelectedMeds,
  setDisableSave,
}: SuggestedSectionProps) => {
  const { id: patientId, reference: patientReference } = patientInfo
  const { inventory, isLoadingInventory } = inventoryData
  const { data: catalogs, catalogId } = catalogsData

  const { openEncounterRef } = useOpenEncounter(patientId)
  const [selectedMeds, setSelectedMeds] = useState<SuggestedNewMedData[]>([])

  useEffect(() => {
    setDisableSave(isLoadingInventory || !catalogId)

    if (!isLoadingInventory) {
      const meds = selectedMeds.map(({ mk, catalogAuthor, invData, quantity }) => {
        const med = getMedInitialValues(mk, quantity, catalogAuthor, patientReference, invData, openEncounterRef)
        return med
      })

      handleSetSelectedMeds((prev) => ({
        ...prev,
        [recommendedRelatedToCode]: meds,
      }))
    }
  }, [selectedMeds])

  const processMKSelection = useCallback(
    (checked: boolean, mk: MedicationKnowledge, quantity: number, updateSate: boolean = true) => {
      if (mk) {
        const medCode = getCommonCode({ codes: mk?.code?.coding })
        const mkCatalog = catalogs.find((c) => c.id === mk?.catalogHeader?.[0].id)
        const mkInStock = inventory[getInvCode(mk?.code?.coding)]
        const newMed =
          checked && !!mkInStock
            ? {
                mk,
                catalogAuthor: mkCatalog?.author?.[0] as Reference,
                invData: mkInStock,
                quantity,
              }
            : undefined

        if (updateSate) {
          setSelectedMeds((selectedMeds) => [
            ...selectedMeds.filter((med) => getCommonCode({ codes: med.mk.code?.coding }) !== medCode),
            ...(newMed ? [newMed] : []),
          ])
        }

        return newMed
      }
    },
    [inventory, catalogs],
  )

  const handleSelectAll = useCallback(
    (unselectAll: boolean = false, specificCodes?: string[]) => {
      const updatedMeds = Array<SuggestedNewMedData>()
      if (!unselectAll) {
        suggestedMeds
          .filter(
            ({ mk }) => !specificCodes?.length || specificCodes.includes(getCommonCode({ codes: mk.code?.coding })),
          )
          .forEach(({ mk }) => {
            const newMed = processMKSelection(true, mk, 1, false)

            if (newMed) updatedMeds.push(newMed)
          })
      }
      setSelectedMeds(updatedMeds)
    },
    [processMKSelection, suggestedMeds],
  )

  const isSelected = useCallback(
    (mk: MedicationKnowledge) => {
      const inSelection = selectedMeds.some(
        ({ mk: selectedMK }) =>
          getCommonCode({ codes: mk.code?.coding, fallback: "1" }) ===
          getCommonCode({ codes: selectedMK.code?.coding, fallback: "2" }),
      )

      return { selected: inSelection }
    },
    [selectedMeds],
  )

  const firstSuggestedMedsBatch = suggestedMeds.slice(0, MAXIMUM_RELATED_MEDS_SHOW_PER_RECOMMENDED_DOSAGE)
  const secondSuggestedMedsBatch = suggestedMeds.slice(MAXIMUM_RELATED_MEDS_SHOW_PER_RECOMMENDED_DOSAGE)

  return (
    <>
      {suggestedMeds.length > 0 && (
        <div className="flex flex-col pt-6 pb-6">
          <div className="flex flex-1 gap-2 text-sm font-medium text-gray-900">
            <Checkbox
              onChange={(e) => (e.checked ? handleSelectAll() : handleSelectAll(true))}
              checked={
                selectedMeds.length === firstSuggestedMedsBatch.length || selectedMeds.length === suggestedMeds.length
              }
              disabled={suggestedMeds.length === 0}
            />
            <span>Select all</span>
          </div>

          <SuggestedMedItems
            meds={firstSuggestedMedsBatch}
            isSelected={isSelected}
            processMKSelection={processMKSelection}
          />

          {secondSuggestedMedsBatch.length > 0 && (
            <Inplace
              unstyled
              pt={{
                content: {
                  className: "border-t border-gray-200",
                },
                display: {
                  className: "text-gray-900 font-medium text-sm",
                },
              }}
            >
              <InplaceDisplay>View More</InplaceDisplay>
              <InplaceContent>
                <SuggestedMedItems
                  meds={secondSuggestedMedsBatch}
                  isSelected={isSelected}
                  processMKSelection={processMKSelection}
                />
              </InplaceContent>
            </Inplace>
          )}
        </div>
      )}
    </>
  )
}

const SuggestedMedItems = ({ meds, isSelected, processMKSelection }: SuggestedMedItemsProps) => (
  <div className="divide-y divide-gray-200">
    {meds.map(({ qty, mk, stock }) => {
      const { selected } = isSelected(mk)

      return (
        <SuggestedMedItem
          key={mk.id}
          model={pelletModel({
            medicationKnowledge: mk,
          })}
          stockQuantity={stock}
          initialQuantity={qty}
          checked={selected}
          onChange={(s, q) => processMKSelection(s, mk, q)}
        />
      )
    })}
  </div>
)

type Props = CalculatorOutput & {
  setDisableSave(state: boolean): void
  carePlanId?: string
}

type SuggestedSectionProps = {
  id: string
  suggestedMeds: MedWithStockAndDosage[]
  patientInfo: { id: string; reference: Reference }
  inventoryData: { isLoadingInventory: boolean; inventory: Record<string, InventoryData[]> }
  catalogsData: { data: Composition[]; catalogId: string }
  handleSetSelectedMeds: Dispatch<SetStateAction<Record<string, ConfigurationItem[]>>>
  setDisableSave: (state: boolean) => void
}

type SuggestedMedItemsProps = {
  meds: Array<{ qty: number; mk: MedicationKnowledge; stock: number }>
  isSelected: (mk: MedicationKnowledge) => {
    selected: boolean
  }
  processMKSelection: (
    checked: boolean,
    mk: MedicationKnowledge,
    quantity: number,
    updateSate?: boolean,
  ) =>
    | {
        mk: MedicationKnowledge
        catalogAuthor: Reference
        invData: InventoryData[]
        quantity: number
      }
    | undefined
}

export type SuggestedNewMedData = NewMedData & {
  quantity: number
}

type MedWithStockAndDosage = { qty: number; mk: MedicationKnowledge; stock: number; recommendedDose: number }

type SuggestedMedsData = Record<string, MedWithStockAndDosage[]>

export { SuggestedMeds }
