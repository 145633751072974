import {
  faCalendarDays,
  faSdCards,
  faSquareArrowDown,
  faSquareArrowUp,
  faTrashCan,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { DocumentReference } from "fhir"
import { useId, useState } from "react"

import {
  type StackedListItemProps,
  ConfirmDialog,
  DataContainerSlideoverForm,
  InfiniteScroll,
  SkeletonLoader,
  StackedListContainer,
  useAzureContainer,
} from "commons"
import { azureContainer, formatsByTypes } from "data"
import { formatDate, sanitizeURL } from "utils"
import { useLoginContext } from "security"

import { useDeletePatientDocument, usePatientContext, usePatientDocuments, useShowPatientDocument } from "../hooks"
import { PatientDocumentForm } from "./PatientDocumentForm"
import { documentInitialValues } from "./validations"

const PatientDocuments = () => {
  const { patientId, patientRef } = usePatientContext()
  const { loggedInPractitioner } = useLoginContext()
  const { documents, fetchNextPage, hasNextPage, isLoading, refetchDocs } = usePatientDocuments(patientId)
  const { showDocument, isLoading: isLoadingDocument } = useShowPatientDocument(() => setScopedDownloadItem(""))
  const [showSlide, setShowSlide] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState<string>()

  const { uploadFile } = useAzureContainer(azureContainer.docs, () => refetchDocs())

  const { removeDocument, isDeleting } = useDeletePatientDocument(() => setDeleteIndex(undefined))

  const handleDownload = (url: string, docRefId?: string) => {
    setScopedDownloadItem(docRefId ?? "")
    showDocument({ url: sanitizeURL(url) })
  }

  const handleShowSlide = () => {
    setShowSlide(true)
  }
  const handleHideSlide = () => {
    setShowSlide(false)
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  const [scopedDownloadItem, setScopedDownloadItem] = useState("")

  if (isLoading) return loader()

  return (
    <DataContainerSlideoverForm
      hasData={!!documents?.length}
      showSlide={showSlide}
      formTitle="New document"
      formInitialValue={documentInitialValues(patientRef, loggedInPractitioner)}
      subMessageDataNotFound={false}
      onCancel={handleHideSlide}
      onSubmit={(data) => {
        uploadFile({ file: data.attachment as File, subject: data.subject, category: data.category }).finally(
          handleHideSlide,
        )
      }}
      customAddButtonText="Upload new document"
      customAddIcon={faSquareArrowUp}
      onButtonAddClick={handleShowSlide}
      form={<PatientDocumentForm />}
    >
      <div className="h-full overflow-auto bg-white">
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <StackedListContainer
            data={documents ?? []}
            itemModelBuilder={(item) =>
              documentModelBuilder(
                item,
                handleDownload,
                isLoadingDocument && scopedDownloadItem === item.id,
                setDeleteIndex,
              )
            }
          />
        </InfiniteScroll>
        <ConfirmDialog
          confirmText="Are you sure you want to remove this document?"
          actionName="Remove"
          visible={!!deleteIndex || isDeleting}
          isLoading={isDeleting}
          onConfirm={() => removeDocument(deleteIndex as string)}
          hideDialog={() => setDeleteIndex(undefined)}
        />
      </div>
    </DataContainerSlideoverForm>
  )
}

const documentModelBuilder = (
  document: DocumentReference,
  download: (url: string, docRefId?: string) => void,
  isLoading: boolean,
  onDelete: (docId: string) => void,
): StackedListItemProps => {
  const attachment = document.content[0].attachment

  return {
    leftData: [
      { lineItems: [{ name: "Title", value: attachment.title ?? "Unspecified" }] },
      {
        lineItems: [
          ...(attachment.creation
            ? [
                {
                  name: "Recorded date",
                  value: formatDate(new Date(attachment.creation), formatsByTypes.LONG_DATETIME),
                  icon: faCalendarDays,
                },
              ]
            : []),
          ...(attachment.size
            ? [
                {
                  name: "Storage size",
                  value: `${bytesToMegaBytes(attachment.size)}MB`,
                  icon: faSdCards,
                },
              ]
            : []),
        ],
      },
    ],
    menu: [
      ...(attachment.url
        ? [
            {
              label: "Download",
              icon: <FontAwesomeIcon icon={faSquareArrowDown} size="sm" />,
              command: () => {
                download(document.content[0].attachment.url as string, document.id)
              },
            },
          ]
        : []),
      {
        label: "Delete",
        icon: <FontAwesomeIcon icon={faTrashCan} size="sm" />,
        command: () => onDelete(document.id as string),
      },
    ],
    isLoading,
  }
}

const bytesToMegaBytes = (bytes: number) => (bytes / (1024 * 1024)).toFixed(2)

export { PatientDocuments }
