import { AutocompleteCodeableConceptField, MentionTextAreaField, SwitchField, NumberField, ValueSetIds } from "commons"

const FamilyHistoryConditionForm = ({ fieldPath }: Props) => (
  <div className="p-fluid relative grid gap-4">
    <div className="flex justify-between space-x-6">
      <AutocompleteCodeableConceptField
        field={`${fieldPath}.code`}
        label="Code"
        valueSetId={ValueSetIds.ICD10}
        className="flex-1"
        validation={(code) => (!code?.coding ? "Code is required" : undefined)}
      />
      <SwitchField field={`${fieldPath}.contributedToDeath`} label="Contributed to Death" />
    </div>
    <div className="flex justify-between space-x-6">
      <AutocompleteCodeableConceptField
        field={`${fieldPath}.outcome`}
        label="Outcome"
        valueSetId={ValueSetIds.ICD10}
        className="w-full"
      />
      <NumberField field={`${fieldPath}.onset['Age']['value']`} label="Age" className="w-full" />
    </div>
    <MentionTextAreaField field={`${fieldPath}.note[0].text`} label="Note" rows={3} />
  </div>
)

type Props = {
  fieldPath: string
}
export { FamilyHistoryConditionForm }
