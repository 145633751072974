import { faEnvelopes } from "@fortawesome/pro-regular-svg-icons"
import { faCalendar, faExclamationTriangle, faPaperPlane } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns"
import { type CommunicationRequest, codeableConceptAsString } from "fhir"
import { useEffect, useId, useState } from "react"

import { useChartContext } from "chart-view"
import {
  type StackedListItemProps,
  ConfirmDialog,
  EmptyMessage,
  InfiniteScroll,
  SkeletonLoader,
  Slideover,
  StackedListContainer,
} from "commons"
import { formatsByTypes } from "data"
import { usePatientContext } from "patients"
import { formatDate, getBadgeColor, strCapitalize } from "utils"

import { communicationRequestStatus, communicationStatusReason } from "../data"
import { useCommunicationRequests, useResendCommunicationRequest } from "../hooks"
import type { CommunicationData } from "../types"

const EmailsContainer = () => {
  const loaderKey = useId()
  const { patientId } = usePatientContext()
  const [showSlide, setShowSlide] = useState(false)
  const [communicationDetails, setCommunicationDetails] = useState<CommunicationData>()
  const communicationRequestStatusCodes = communicationRequestStatus.map(({ code }) => code)
  const communicationStatusReasonCodes = communicationStatusReason.map(({ code }) => code)
  const [crToSend, setCRToSend] = useState<CommunicationRequest>()

  const chartContext = useChartContext()

  const statusFilter = chartContext.searchData.selectedStatus?.filter((status) =>
    communicationRequestStatusCodes.includes(status),
  )

  const statusReasonFilter = chartContext.searchData.selectedStatus?.filter((status) =>
    communicationStatusReasonCodes.includes(status),
  )

  const showDetails = (data: CommunicationData) => {
    setCommunicationDetails(data)
    setShowSlide(true)
  }

  const hideDetails = () => {
    setShowSlide(false)
  }

  useEffect(() => {
    chartContext.setSearchData({
      placeholder: "Search emails",
      selectedStatus: [...communicationRequestStatusCodes, ...["dropped", "deferred", "bounce", "spam", "unsubscribe"]],
      statusOptions: [...communicationRequestStatus, ...communicationStatusReason],
    })
  }, [])

  const { communicationRequestsWithCommunication, isLoading, hasNextPage, fetchNextPage } = useCommunicationRequests(
    patientId,
    chartContext.searchData.filter,
    statusFilter,
    statusReasonFilter,
  )
  const { resendCR, isResending } = useResendCommunicationRequest()

  const resend = () => {
    const cr = {
      ...crToSend,
      status: "active",
      authoredOn: new Date().toISOString(),
    } as CommunicationRequest

    delete cr.id
    delete cr.statusReason

    resendCR(cr)
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  if (isLoading) {
    return loader()
  }

  if (!communicationRequestsWithCommunication.length) {
    return <EmptyMessage icon={faEnvelopes} message="No emails found" />
  }

  return (
    <>
      <div className="h-full overflow-auto">
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <StackedListContainer
            data={communicationRequestsWithCommunication}
            itemModelBuilder={(item) =>
              emailsItemModel(item, showDetails, () => setCRToSend(item.communicationRequest))
            }
          />
        </InfiniteScroll>
      </div>
      <Slideover
        showSlide={showSlide}
        onHide={hideDetails}
        title="Email Details"
        showCancel
        cancelLabel="Close"
        dismissable
      >
        <div className="flex flex-col gap-12">
          <EmailDetails detail={communicationDetails as CommunicationData} />
        </div>
      </Slideover>
      <ConfirmDialog
        visible={!!crToSend || isResending}
        isLoading={isResending}
        hideDialog={() => setCRToSend(undefined)}
        onConfirm={() => resend()}
        confirmText="Are you sure you want to resend this email?"
      />
    </>
  )
}

const emailsItemModel = (
  communicationData: CommunicationData,
  showDetails: (value: CommunicationData) => void,
  resend: () => void,
): StackedListItemProps => {
  const { communicationRequest, communication } = communicationData
  const communicationStatus = communication?.statusReason?.coding?.[0]?.code ?? communicationRequest.status

  return {
    leftData: [
      {
        lineItems: [{ value: strCapitalize(codeableConceptAsString(communicationRequest.category?.[0]) as string) }],
      },
      {
        lineItems: [
          ...(communicationRequest.authoredOn
            ? [
                {
                  name: "Authored on",
                  value: formatDate(parseISO(communicationRequest.authoredOn), formatsByTypes.LONG_DATE),
                  icon: faCalendar,
                },
              ]
            : []),
          { name: "Priority", value: communicationRequest.priority, icon: faExclamationTriangle },
        ],
      },
    ],
    badge: getBadgeColor(communicationStatus),
    onClick: () => showDetails(communicationData),
    menu: [
      {
        icon: <FontAwesomeIcon icon={faPaperPlane} />,
        label: "Resend",
        command: resend,
        disabled: communicationRequest.status === "active",
      },
    ],
  }
}

const EmailDetails = ({ detail: { communicationRequest, communication } }: { detail: CommunicationData }) => {
  return (
    <div className="col-span-2 m-0 flex flex-col overflow-auto p-0">
      <div className="mt-2 overflow-auto p-1 text-sm">
        <div className="font-bold">Categories</div>
        <div className="mb-3 p-1 text-slate-400">
          {communicationRequest.category?.length
            ? communicationRequest.category
                ?.map((category) => strCapitalize(codeableConceptAsString(category)))
                .join(", ")
            : "N/A"}
        </div>

        <div className="font-bold">Authored on</div>
        <div className="mb-3 p-1 text-slate-400">
          {communicationRequest.authoredOn
            ? formatDate(parseISO(communicationRequest.authoredOn as string), formatsByTypes.LONG_DATE)
            : "N/A"}
        </div>

        <div className="font-bold">Priority</div>
        <div className="mb-3 p-1 text-slate-400">{communicationRequest.priority ?? "N/A"}</div>

        <div className="font-bold">Status</div>
        <div className="mb-3 p-1 text-slate-400 capitalize">
          {communication?.statusReason?.coding?.[0]?.code ?? communicationRequest.status ?? "N/A"}
        </div>

        {communicationRequest.status === "completed" && (
          <>
            <div className="font-bold">Reason</div>
            <div className="mb-3 p-1 text-slate-400 capitalize">{communication?.statusReason?.text ?? "N/A"}</div>
          </>
        )}
      </div>
    </div>
  )
}

export { EmailsContainer }
