import { type SyntheticEvent, useEffect, useId, useMemo, useReducer, useRef, useState } from "react"
import { asReference } from "fhir"
import { faSearch } from "@fortawesome/pro-light-svg-icons"
import type { Menu } from "primereact/menu"

import { EmptyMessage, InfiniteScroll, SearchInput, SkeletonLoader } from "commons"
import { ChatContainer } from "communication"

import { useOrganizationPatients } from "../hooks"
import { ContactListItem } from "./ContactListItem"
import { PatientInfo } from "./PatientInfo"
import { NewChatMenu } from "./NewChatMenu"
import { ChatFiltersMenu } from "./ChatFiltersMenu"
import { ChatStatus, chatStatusOptions } from "../data"
import type { PatientChat, FilterProps } from "../types"

const MessagesView: React.FC = () => {
  const { searchText, search } = useReducerState()
  const [selectedPatient, setPatient] = useState<PatientChat | undefined>(undefined)
  const { patients, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useOrganizationPatients(searchText)
  const selectedPatientRef = useRef(selectedPatient)
  const [selectedChatStatus, setSelectedChatStatus] = useState<ChatStatus[]>([])
  const patientChats = useMemo(() => {
    if (!selectedChatStatus.length || selectedChatStatus.length === chatStatusOptions.length) return patients
    return selectedChatStatus[0] === ChatStatus.READ
      ? patients.filter(({ unreadCount }) => unreadCount === 0)
      : patients.filter(({ unreadCount }) => unreadCount > 0)
  }, [selectedChatStatus, patients])

  useEffect(() => {
    if (selectedPatientRef.current) {
      const currentPatient = patients?.find((pat) => pat?.sender.id === selectedPatientRef.current?.sender.id)

      setPatient(currentPatient)
    }
  }, [patients])

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="list" />

  const menu = useRef<Menu>(null)

  const onToggleMenu = (event: SyntheticEvent<Element, Event>) => {
    event.stopPropagation()
    menu.current?.toggle(event)
  }

  const showChat = (patient: PatientChat) => {
    selectedPatientRef.current = patient
    setPatient(patient)
  }

  return (
    <div className="flex h-full flex-1 overflow-hidden">
      <div className="flex w-2/5 flex-col border-r border-gray-200">
        <div className="flex flex-col gap-4 border-b border-gray-200 p-4 drop-shadow-xs">
          <div className="flex items-center justify-end gap-6">
            <NewChatMenu showChat={showChat} initialSearchText={searchText} ref={menu} onToggleMenu={onToggleMenu} />
            <ChatFiltersMenu selectedItems={selectedChatStatus} onSelect={setSelectedChatStatus} />
          </div>
          <SearchInput isLoading={isLoading || isFetchingNextPage} search={search} />
        </div>
        <div className="grow overflow-y-auto">
          {isLoading ? (
            loader()
          ) : patientChats?.length ? (
            <InfiniteScroll loadMore={() => fetchNextPage()} hasMore={hasNextPage} loader={loader()}>
              <ul className="divide-y divide-gray-200">
                {patientChats?.map(({ sender, unreadCount, unreadCommunications }) => (
                  <ContactListItem
                    key={sender.id}
                    onClick={() => showChat({ sender, unreadCount, unreadCommunications })}
                    patient={sender}
                    unread={unreadCount}
                    isSelected={sender.id === selectedPatient?.sender.id}
                  />
                ))}
              </ul>
            </InfiniteScroll>
          ) : (
            <div className="flex h-full items-center">
              <EmptyMessage
                icon={faSearch}
                message="No results found"
                subMessage="Start new chat"
                actionText="New chat"
                action={onToggleMenu}
              />
            </div>
          )}
        </div>
      </div>
      <div className="w-full p-4">
        {selectedPatient && (
          <ChatContainer
            key={selectedPatient.sender.id}
            patient={selectedPatient.sender}
            patientId={selectedPatient.sender.id as string}
            unreadedCount={selectedPatient.unreadCount}
            unreadMessages={selectedPatient.unreadCommunications ?? []}
            patientRef={asReference(selectedPatient.sender)}
          />
        )}
      </div>
      <div className="w-3/5 overflow-y-auto xl:w-2/5">
        {selectedPatient && (
          <div className="h-full border-l border-gray-200 py-4 pl-3">
            <PatientInfo
              closePanel={() => {
                selectedPatientRef.current = undefined
                setPatient(undefined)
              }}
              patient={selectedPatient.sender}
              patientId={selectedPatient.sender.id as string}
            />
          </div>
        )}
      </div>
    </div>
  )
}
export { MessagesView }

const initialState = {
  searchText: "",
  practitioner: undefined,
  gender: "",
} as FilterProps

const reducer = (
  state: FilterProps,
  { type, payload }: { type: "reset" | "search" | "filter"; payload?: string | FilterProps },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }
    case "search":
      return { ...state, searchText: payload as string }

    default:
      return state
  }
}

const useReducerState = () => {
  const state = initialState
  const [{ searchText, gender, practitioner }, dispatch] = useReducer(reducer, state)

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const search = (searchText: string) => {
    dispatch({ type: "search", payload: searchText })
  }

  return { searchText, gender, practitioner, search, reset }
}
