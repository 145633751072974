import { type FieldProps } from "formik"
import type { FC } from "react"

import { FormField } from "./FormField"
import { BubbleEditor } from "./lexical/Editor"

const EditorField: FC<Props> = ({ field, label, className, placeholder, disabled, readOnly, autoFocus }) => {
  return (
    <FormField field={field} className={className} label={label}>
      {({ field: { name, value }, form: { setFieldValue } }: FieldProps) => {
        return (
          <BubbleEditor
            initialValue={value}
            onChange={(newValue) => setFieldValue(name, newValue)}
            readOnly={readOnly}
            autoFocus={autoFocus}
            disabled={disabled}
            placeholder={placeholder}
          />
        )
      }}
    </FormField>
  )
}

type Props = {
  field: string
  label?: string
  className?: string
  inputClassName?: string
  labelClassName?: string
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  autoFocus?: boolean
}

export { EditorField }
