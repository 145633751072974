import type { TabPanel } from "primereact/tabview"
import { classNames } from "primereact/utils"
import { Children } from "react"

const VerticalTabView = ({ children, selectedPanel, panelChange, tabsFooter }: Props) => (
  <div className="flex h-full overflow-hidden p-2">
    <div className="flex max-w-lg min-w-fit flex-col p-1 pr-0">
      <div className="grow overflow-y-auto">
        {Children.map(children, (child, index) =>
          child?.props.visible ?? true ? (
            <div
              className={classNames(
                "flex cursor-pointer items-center border-r border-b border-r-slate-100 border-b-slate-100 p-3 py-4 transition delay-75 ease-in-out hover:bg-slate-50",
                {
                  "bg-slate-100": index === selectedPanel,
                },
              )}
              onClick={() => panelChange(index)}
            >
              {child?.props.headerTemplate ? child?.props.headerTemplate : child?.props.header}
            </div>
          ) : (
            <></>
          ),
        )}
      </div>
      {tabsFooter}
    </div>
    <div className="flex w-full flex-col overflow-hidden pb-0">
      {Children.map(children, (child, index) => (index === selectedPanel ? child.props.children : <></>))}
    </div>
  </div>
)

type Props = {
  selectedPanel?: number
  panelChange(index: number): void
  children: TabPanel | TabPanel[] | JSX.Element | JSX.Element[]
  tabsFooter?: JSX.Element
}

export { VerticalTabView }
