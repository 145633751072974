import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type MedicationKnowledge, type Reference, codeableConceptAsString } from "fhir"
import { useFormikContext } from "formik"
import { Chip } from "primereact/chip"
import { type FC, useEffect, useMemo, useState } from "react"

import { SlideoverWithFilter, StackedListItemCheckeable } from "commons"
import { MedicationCatalogListFilters, useMedCatalogReducerState, useMedicationKnowledge } from "commons/meds"
import { getCommonCode } from "utils"

import { useMEInventory, useProcedureCatalogs } from "../hooks"
import type { InventoryData, ProcedureData } from "../types"
import { getInvCode } from "../utils/formatters"
import { convertProcedureKindToProductType, getProcedureKind } from "../utils/transformers"
import { pelletModel } from "./pelletModel"

const MedicationInventoryList: FC<Props> = ({ onHide, allowMultiple = true }: Props) => {
  const { catalogs, isLoading: isLoadingCatalogs } = useProcedureCatalogs()
  const {
    values: { procedure },
  } = useFormikContext<ProcedureData>()

  const procedureKind = useMemo(() => getProcedureKind(procedure), [procedure])
  const productTypeFilter = useMemo(() => convertProcedureKindToProductType(procedureKind), [procedureKind])

  const { selectedCatalog, searchFilter, setSelectedCatalog, setSearchFilter } = useMedCatalogReducerState()
  const { isLoading: isLoadingInventory, inventoryData, invMedCodes } = useMEInventory(selectedCatalog)

  const { medicationKnowledge, isLoading: isLoadingMKs } = useMedicationKnowledge({
    filter: searchFilter,
    catalogId: selectedCatalog,
    procedureProductType: productTypeFilter,
    medCodes: invMedCodes.join(","),
    enabled: invMedCodes.length > 0,
  })

  const {
    values: { configurationItem },
  } = useFormikContext<ProcedureData>()

  const [showSlide, setShowSlide] = useState(true)
  const [newMeds, setNewMeds] = useState<NewMedData[]>([])
  const [deletedMeds, setDeletedMeds] = useState<number[]>([])

  const { addedMedCodes, pillMeds } = useMemo(() => {
    const { addedMedCodes, initialMeds } = configurationItem.reduce<{
      addedMedCodes: string[]
      initialMeds: MedicationKnowledge[]
    }>(
      (acc, med, medIndex) => ({
        addedMedCodes: [
          ...acc.addedMedCodes,
          getCommonCode({ codes: med.medicationRequest?.medication?.CodeableConcept?.coding }),
        ],
        initialMeds: !deletedMeds.includes(medIndex)
          ? [...acc.initialMeds, { code: med.medicationRequest?.medication?.CodeableConcept }]
          : acc.initialMeds,
      }),
      { addedMedCodes: [], initialMeds: [] },
    )
    const pillMeds = newMeds.map((med) => med.mk).concat(initialMeds)

    return { addedMedCodes, pillMeds }
  }, [deletedMeds, configurationItem, newMeds])

  const processMKSelection = (checked: boolean, mk: MedicationKnowledge) => {
    const medCode = getCommonCode({ codes: mk?.code?.coding })
    const medIndex = addedMedCodes.findIndex((med) => med === medCode)

    if (checked) {
      if (medIndex !== -1) {
        setDeletedMeds(deletedMeds.filter((med) => med !== medIndex))
      } else {
        const mkCatalog = catalogs.find((c) => c.id === mk.catalogHeader?.[0].id)
        setNewMeds([
          ...newMeds,
          {
            mk,
            catalogAuthor: mkCatalog?.author?.[0] as Reference,
            invData: inventoryData.get(getInvCode(mk?.code?.coding)) as InventoryData[],
          },
        ])
      }
    } else {
      setNewMeds(newMeds.filter((med) => getCommonCode({ codes: med.mk.code?.coding }) !== medCode))
      if (medIndex !== -1) setDeletedMeds([...deletedMeds, medIndex])
    }
  }

  useEffect(() => {
    if (catalogs.length > 0) setSelectedCatalog(catalogs[0].id)
  }, [catalogs])

  const isLoading = isLoadingCatalogs || isLoadingInventory || isLoadingMKs
  const containerLayoutClass = "divide-y divide-gray-200 overflow-y-auto px-6"

  return (
    <SlideoverWithFilter
      showSlide={showSlide}
      onHide={() => {
        setShowSlide(false)
        setTimeout(onHide, 200)
      }}
      title="Add new medication"
      childrenContainerClassName="flex flex-col flex-1 overflow-hidden"
      showButtons
      acceptDisabled={newMeds.length === 0 && deletedMeds.length === 0}
      onAccept={() => {
        setShowSlide(false)
        setTimeout(() => onHide({ save: true, newMeds, deletedMeds }), 200)
      }}
      isLoading={isLoading}
      customFilter={
        <div className="flex flex-col gap-4">
          <MedicationCatalogListFilters
            isLoading={isLoading}
            catalogs={catalogs}
            catalog={selectedCatalog}
            setSearchFilter={setSearchFilter}
            setSelectedCatalog={setSelectedCatalog}
          />
          <div className="w-full overflow-x-scroll">
            <div className="flex h-10 gap-2 pb-0.5">
              {!pillMeds.length ? (
                <p className="text-md place-self-center text-slate-400">No medications added yet</p>
              ) : (
                pillMeds.map((med) => (
                  <Chip
                    key={getCommonCode({ codes: med.code?.coding })}
                    className="max-h-[2rem] bg-primary text-center text-sm whitespace-nowrap text-white"
                    label={codeableConceptAsString(med.code)}
                    removable
                    onRemove={() => {
                      processMKSelection(false, med)
                      return true
                    }}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      }
    >
      {!medicationKnowledge.length ? (
        <div className="mt-4 flex h-full flex-col items-center justify-center">
          <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
          <p className="text-md place-self-center pt-4 pb-2 text-slate-400">No medications found</p>
        </div>
      ) : (
        <div className={containerLayoutClass}>
          {medicationKnowledge.map((mk, index) => {
            const isChecked = pillMeds.some(
              (med) => getCommonCode({ codes: med.code?.coding }) === getCommonCode({ codes: mk.code?.coding }),
            )
            return (
              <StackedListItemCheckeable
                key={index}
                checked={isChecked}
                modelData={pelletModel({
                  medicationKnowledge: mk,
                })}
                onCheck={(s) => processMKSelection(s, mk)}
                modeAuto={false}
                disabled={!!pillMeds.length && !isChecked && !allowMultiple}
              />
            )
          })}
        </div>
      )}
    </SlideoverWithFilter>
  )
}

type Props = {
  onHide({ save, newMeds, deletedMeds }: { save?: boolean; newMeds?: NewMedData[]; deletedMeds?: number[] }): void
  allowMultiple?: boolean
}

export type NewMedData = {
  mk: MedicationKnowledge
  catalogAuthor: Reference
  invData: InventoryData[]
}

export { MedicationInventoryList }
