import { faCalendarXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Duration, sliceEvents } from "@fullcalendar/core"
import type { ViewProps } from "@fullcalendar/core/internal"
import { humanNameAsString, type Appointment } from "fhir"

import type { CalendarEventExtraInfo } from "commons/types"
import { AgendaAppointmentItem } from "appointments"

const AgendaView = (props: ViewProps) => {
  const segs = sliceEvents(props as ViewProps & { nextDayThreshold: Duration }, true) // allDay=true

  return (
    <section className="h-full max-h-[94.5vh] overflow-y-auto rounded-md border border-solid border-slate-200 pl-3 shadow-md">
      <article className="pr-2">
        <div className="overflow-hidden bg-white">
          <ul className="divide-y divide-gray-200 pt-3">
            {segs.map((event, index) => {
              const { type, appointment, id, patient, color, status } = event.def
                .extendedProps as CalendarEventExtraInfo

              return (
                <AgendaAppointmentItem
                  key={id ?? index}
                  appointment={appointment as Appointment}
                  patientAvatar={patient?.photo?.[0]?.url}
                  patientName={humanNameAsString(patient?.name?.[0])}
                  patientId={patient?.id}
                  type={type}
                  color={color}
                  status={status}
                  start={appointment?.start}
                  end={appointment?.end}
                  onUnbook={() => (props as AgendaViewExtendedProps).unbook(event.def.extendedProps.appointment)}
                />
              )
            })}
          </ul>
        </div>
      </article>
      {!segs?.length && (
        <div className="flex h-full grow flex-col items-center justify-center">
          <FontAwesomeIcon icon={faCalendarXmark} size="3x" className="text-slate-400" />
          <p className="text-md place-self-center pt-4 pb-2 text-slate-400">No appointments</p>
        </div>
      )}
    </section>
  )
}

type AgendaViewExtendedProps = {
  action: (appointment: Appointment) => void
  unbook: (appointment: Appointment) => void
} & ViewProps

export { AgendaView }
