import { type Quantity, isMedicationKnowledge } from "fhir"
import { type FieldProps, ErrorMessage, Field, useFormikContext } from "formik"
import { Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { type FC, useEffect, useMemo } from "react"
import type { AnyObject } from "yup/lib/object"

import { ValueSetIds } from "commons"
import { useValueSet } from "value-set"

import type { MedicationRequestFormData } from "../../types"

const PrescriptionUnitField: FC<Props> = ({ disabled, onChange: onValueChange }) => {
  const { isSubmitting, getFieldMeta, values, getFieldHelpers } = useFormikContext<MedicationRequestFormData>()
  const { codes: quantityUnits, isLoading } = useValueSet({ valueSetId: ValueSetIds.PACKAGING_QTY_UNIT })
  const { value } = getFieldMeta("prescriptionQuantity")
  const { setValue } = getFieldHelpers("prescriptionQuantity")
  const fieldValue = value as undefined | Quantity
  const unitOfMeasureOptions = useMemo(
    () =>
      quantityUnits?.map(({ code, display, system }) => ({
        label: display,
        value: {
          code,
          unit: display,
          system,
          ...(fieldValue?.value !== undefined ? { value: fieldValue?.value } : {}),
        },
      })),
    [quantityUnits, fieldValue?.value],
  )

  useEffect(() => {
    if (isMedicationKnowledge(values?.medicationField)) {
      const { packaging } = values.medicationField
      const packageQty = packaging?.quantity
      setValue(unitOfMeasureOptions?.find(({ value: { code } }) => code === packageQty?.code)?.value)
    }
  }, [values?.medicationField, unitOfMeasureOptions])

  return (
    <div className="relative flex w-full flex-col gap-2">
      <label className="mr-2 h-fit text-sm font-medium text-gray-700">Unit</label>
      <Field name="prescriptionQuantity">
        {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => {
          return (
            <Dropdown
              options={unitOfMeasureOptions}
              optionLabel="label"
              optionValue="value"
              id={name}
              name={name}
              value={value}
              onChange={(e) => {
                onChange(e)
                onValueChange?.(e.value)
              }}
              className={classNames("p-inputtext-sm small-trigger min-w-[9rem]", {
                "p-invalid": touched && error,
              })}
              disabled={disabled || isSubmitting}
              dataKey="value"
              loading={isLoading}
            />
          )
        }}
      </Field>
      <ErrorMessage name="prescriptionQuantity">
        {(msg) => <small className="p-error absolute bottom-0 mt-1 h-2">{msg}</small>}
      </ErrorMessage>
    </div>
  )
}

type Props = {
  disabled?: boolean
  onChange?(value: AnyObject | number | null | undefined): void
}

export { PrescriptionUnitField }
