import { faCalendarXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Duration, type EventRenderRange, sliceEvents } from "@fullcalendar/core"
import type { ViewProps } from "@fullcalendar/core/internal"
import { humanNameAsString, type Appointment, type Slot } from "fhir"

import { type CalendarEventExtraInfo, EventType } from "commons/types"
import { AgendaAppointmentItem } from "appointments"

import { AgendaSlotItem } from "./AgendaSlotItem"

const AgendaView = (props: ViewProps) => {
  const segs = sliceEvents(props as ViewProps & { nextDayThreshold: Duration }, true) // allDay=true

  const renderAgendaItem = (event: EventRenderRange) => {
    const { type, appointment, id, patient, status, color, eventType, slot } = event.def
      .extendedProps as CalendarEventExtraInfo

    switch (eventType) {
      case EventType.APPT:
        return (
          <AgendaAppointmentItem
            key={id}
            start={appointment?.start}
            end={appointment?.end}
            appointment={appointment}
            status={status}
            patientName={humanNameAsString(patient?.name?.[0])}
            patientId={patient?.id}
            type={type}
            color={color}
            onUnbook={appointment ? () => (props as AgendaViewExtendedProps).unbook(appointment) : undefined}
          />
        )
      case EventType.SLOT: {
        return (
          <AgendaSlotItem
            key={id}
            start={slot?.start}
            end={slot?.end}
            status={status}
            description={event.def.title}
            type={type}
            color={color}
            onReleaseSlot={slot ? () => (props as AgendaViewExtendedProps).release(slot) : undefined}
          />
        )
      }
    }
  }

  return (
    <section className="h-full max-h-[94.5vh] overflow-y-auto rounded-md border border-solid border-slate-200 pl-3 shadow-md">
      <article className="pr-2">
        <div className="overflow-hidden bg-white">
          <ul className="divide-y divide-gray-200 pt-3">{segs.map((event) => renderAgendaItem(event))}</ul>
        </div>
      </article>
      {!segs?.length && (
        <div className="flex h-full grow flex-col items-center justify-center">
          <FontAwesomeIcon icon={faCalendarXmark} size="3x" className="text-slate-400" />
          <p className="text-md place-self-center pt-4 pb-2 text-slate-400">No events</p>
        </div>
      )}
    </section>
  )
}

type AgendaViewExtendedProps = {
  unbook: (appointment: Appointment) => void
  release: (slot: Slot) => void
} & ViewProps

export { AgendaView }
