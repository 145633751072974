import { faUserMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons"
import { type Observation, codeableConceptAsString } from "fhir"
import type { FC, ReactNode } from "react"

import { VITAL_CODE, VITAL_ICON_MAP, VitalsKnowledgeItem } from "vitals"

const IntakeObservationsSection: FC<Props> = ({ observations, elementDetail }) => {
  const renderObservationData = (observation: Observation) => {
    const code = observation && codeableConceptAsString(observation.code)
    const value =
      observation?.value?.Quantity && `${observation.value.Quantity.value} ${observation.value.Quantity.unit}`

    return code && value ? (
      <div className="flex w-full flex-wrap items-center justify-between px-3">
        <VitalsKnowledgeItem
          label={code}
          itemContent={value}
          icon={VITAL_ICON_MAP[observation.code.coding?.[0]?.code as string] ?? faUserMagnifyingGlass}
          className="py-3"
          lastElementClassName="border-none"
          key={observation.id}
        />
        {elementDetail}
      </div>
    ) : undefined
  }

  const { vitals, notes } = observations.reduce(
    (acc, ob) => {
      if (ob.category?.some(({ coding }) => coding?.some(({ code }) => code === VITAL_CODE)))
        return { ...acc, vitals: [...acc.vitals, ob] }
      return { ...acc, notes: [...acc.notes, ob] }
    },
    { vitals: [], notes: [] } as { vitals: Observation[]; notes: Observation[] },
  )

  return (
    <>
      {!!notes.length && (
        <div className="gap-4">
          <h2 className="text-gray-900">Symptom Score</h2>
          <div className="flex flex-wrap items-center gap-4">{notes.map((obs) => renderObservationData(obs))}</div>
        </div>
      )}
      {!!vitals.length && (
        <div className="gap-4">
          <h2 className="text-gray-900">Vitals</h2>
          <div className="flex flex-wrap items-center gap-4">{vitals.map((obs) => renderObservationData(obs))}</div>
        </div>
      )}
    </>
  )
}

type Props = {
  observations: Observation[]
  elementDetail?: string | ReactNode
}

export { IntakeObservationsSection }
