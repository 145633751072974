import { type FieldProps, ErrorMessage, Field } from "formik"
import { Checkbox } from "primereact/checkbox"
import { classNames } from "primereact/utils"
import type { FC } from "react"

const CheckBoxField: FC<Props> = ({
  field,
  label,
  className,
  onCheck,
  isOnlyInfo = false,
  labelClassName,
  disabled,
}) => (
  <Field name={field}>
    {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
      <div className={classNames("field flex items-center gap-1", className)}>
        {isOnlyInfo ? (
          <Checkbox inputId={name} name={name} readOnly disabled checked />
        ) : (
          <Checkbox
            inputId={name}
            name={name}
            onChange={(e) => {
              onChange(e)
              onCheck?.(!!e.checked)
            }}
            checked={value}
            className={classNames({ "p-invalid": touched && error })}
            disabled={disabled}
          />
        )}
        {label && (
          <label
            htmlFor={name}
            className={classNames("text-sm font-medium", labelClassName, {
              "cursor-pointer text-gray-700": !disabled,
              "text-gray-400": disabled,
            })}
          >
            {label}
          </label>
        )}
        <div className="p-error field-error-spacing flex items-start">
          <ErrorMessage name={field}>{(msg) => <small>{msg}</small>}</ErrorMessage>
        </div>
      </div>
    )}
  </Field>
)

type Props = {
  field: string
  label?: string
  className?: string
  labelClassName?: string
  rows?: number
  onCheck?(checked: boolean): void
  isOnlyInfo?: boolean
  disabled?: boolean
}

export { CheckBoxField }
