import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip } from "primereact/tooltip"

export type TIconSettingBtn = {
  name?: string
  fontIcon: IconDefinition
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  mode?: TSettingsBtnMode
  disabled?: boolean
}

type TSettingsBtns = {
  hide?: boolean
  settings: TIconSettingBtn[]
  className?: string
}

export type TSettingsBtnMode = keyof typeof BG

const BG = {
  standard: "#374151",
  danger: "#dc2626",
} as const

export const SettingsBtns = ({ hide = false, settings, className }: TSettingsBtns) => (
  <div className={`${hide && "hidden"} ${className}`}>
    {settings?.map((setting, idx) => (
      <IconSettingBtn
        key={idx}
        name={setting?.name}
        mode={setting?.mode}
        onClick={setting?.onClick}
        fontIcon={setting.fontIcon}
        disabled={setting?.disabled}
      />
    ))}
  </div>
)

const IconSettingBtn = ({ fontIcon, name, onClick, mode = "standard", disabled = false }: TIconSettingBtn) => (
  <>
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${name?.replace(/\s/g, "")} flex size-9 items-center justify-center rounded-full duration-100 ${!disabled && "opacity-85 hover:opacity-100"}`}
      style={{ background: disabled ? `${BG[mode]}50` : BG[mode] }}
    >
      <FontAwesomeIcon className="m-auto text-lg" color="#fff" icon={fontIcon} />
    </button>
    <Tooltip target={`.${name?.replace(/\s/g, "")}`} showOnDisabled autoHide content={name} position="top" />
  </>
)
