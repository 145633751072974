import { useMutation, useQuery } from "@tanstack/react-query"
import { asReference, type Address, type Parameters, type Reference } from "fhir"

import { useClient } from "api"
import { type CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { useOrganizationContext } from "organization"
import { displayNotificationWarningUpdateAvailable } from "utils"

const useCheckEmailExists = (onSuccess?: (data: boolean) => void, onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const { currentOrganization } = useOrganizationContext()

  const checkEmail = async ({
    emailToCheck,
    isPractitionerRole,
  }: {
    emailToCheck?: string
    isPractitionerRole?: boolean
  }) => {
    if (!emailToCheck) return false

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "email",
          value: {
            string: emailToCheck,
          },
        },
        { name: "resourceType", value: { string: isPractitionerRole ? "Practitioner" : "Patient" } },
        ...(!isPractitionerRole
          ? [{ name: "organization", value: { Reference: asReference(currentOrganization) } }]
          : []),
      ],
    }

    const results = await operationRequest<Parameters>({
      endpoint: "",
      method: "POST",
      operation: "email-exists-v2",
      parameters,
    })
    return results.parameter?.find((param) => param.name === "exist")?.value?.boolean ?? false
  }

  const {
    mutateAsync: checkEmailExists,
    isPending,
    data,
    isIdle,
  } = useMutation({
    mutationKey: ["check-email-exists"],
    mutationFn: checkEmail,
    onError: (error: CustomError, email) => {
      displayNotificationError(registerErrorTrace(error, { email }))
    },
    onSuccess: (data) => {
      onSuccess?.(data)
    },
    onSettled,
  })

  return { checkEmailExists, isCheckingEmail: isPending, exists: data ?? false, hasBeenChecked: !isIdle }
}

const useValidateEmailEdit = () => {
  const { operationRequest } = useClient()

  const validateEmail = async ({ emailToCheck, subject }: { emailToCheck?: string; subject: Reference }) => {
    if (!emailToCheck) return false

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "new-email",
          value: {
            string: emailToCheck,
          },
        },
      ],
    }

    try {
      const results = await operationRequest<Parameters>({
        endpoint: `${subject.resourceType}/${subject.id}`,
        method: "POST",
        operation: "validate-edit-email",
        parameters,
      })
      return results.parameter?.find((param) => param.name === "exist")?.value?.boolean ?? true
    } catch (error) {
      if ((error as CustomError)?.cause?.message?.includes("already in use")) return true
      else displayNotificationError(registerErrorTrace(error as CustomError, { emailToCheck }))
    }
  }

  const {
    mutateAsync: validateEmailEdit,
    isPending,
    data,
    isIdle,
  } = useMutation({
    mutationKey: ["validate-email-edit"],
    mutationFn: validateEmail,
    onError: (error: CustomError, email) => {
      displayNotificationError(registerErrorTrace(error, { email }))
    },
  })

  return { validateEmailEdit, isValidatingEmail: isPending, exists: data ?? false, hasBeenChecked: !isIdle }
}

const useCheckNewReleaseAvailable = () => {
  const currentDomain = window.location.origin
  const queryKey = ["check-new-realease-available", currentDomain]
  const isDevelopment = process.env.NODE_ENV === "development"

  useQuery({
    queryKey,
    queryFn: async () => {
      const response = await fetch(`${currentDomain}/${window?.VITE_APP_ENV_VARS_FILENAME}`, {
        cache: "no-store",
        headers: {
          Origin: currentDomain,
        },
      }).then((res) => res.text())

      const currentAppVersion = window?.VITE_APP_VERSION ?? ""

      const versionMatch = response.match(/window\.VITE_APP_VERSION="([\d.]+)";/)
      const newAppVersion = versionMatch?.[1] ?? ""

      const isNewAvailableVersion = currentAppVersion !== newAppVersion

      isNewAvailableVersion &&
        displayNotificationWarningUpdateAvailable(
          "An updated version of the application is available. Update to get the latest version.",
        )

      return null
    },
    meta: { context: { queryKey } },
    refetchInterval: 240000,
    enabled: !isDevelopment,
  })
}

const useIsSameAddress = () => {
  const { operationRequest } = useClient()

  const sameAddress = async ({ address1, address2 }: { address1: Address; address2: Address }): Promise<boolean> => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "address1",
          value: {
            Address: {
              use: address1.use,
              city: address1.city,
              line: address1.line,
              type: address1.type,
              state: address1.state,
              country: address1.country,
              postalCode: address1.postalCode,
            },
          },
        },
        {
          name: "address2",
          value: {
            Address: {
              use: address2.use,
              city: address2.city,
              line: address2.line,
              type: address2.type,
              state: address2.state,
              country: address2.country,
              postalCode: address2.postalCode,
            },
          },
        },
      ],
    }

    const responseParameters = await operationRequest<Parameters>({
      endpoint: `Patient`,
      method: "POST",
      operation: "is-same-address",
      parameters,
    })

    const isSameAddress =
      responseParameters.parameter?.find(({ name }) => name === "IsSameAddress")?.value?.boolean ?? false

    return isSameAddress
  }

  const { mutateAsync: isSameAddress, isPending: isChecking } = useMutation({
    mutationFn: sameAddress,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { isSameAddress, isChecking }
}

export { useCheckEmailExists, useCheckNewReleaseAvailable, useIsSameAddress, useValidateEmailEdit }

