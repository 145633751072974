import { useFormikContext } from "formik"
import { useMemo, useState } from "react"

import { BillingField } from "commons"
import { type LaboratoryOrder, getDefaultLabAddress } from "commons/labs"
import { BILLING_TYPES_CODES, DEFAULT_BLOOD_DRAWN_PANELS_LIST } from "data"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"
import { SYSTEM_VALUES } from "system-values"

import { RemovePanelsDialog } from "./RemovePanelsDialog"

const PERFORMER_WITH_ONLY_CASH_BILLING_IDENTIFIER = ["am-lab"]

const BillingDropdownField = ({ field, label }: Props) => {
  const { isExemptLabPayment, isUnrestrictedPerformer, performerLabsEnabled, currentOrganizationBillingAddress } =
    useOrganizationContext()
  const {
    patient: { address },
  } = usePatientContext()
  const {
    values: {
      order: { patientAddress, performer },
      panels,
      deletedPanels,
    },
    setFieldValue,
  } = useFormikContext<LaboratoryOrder>()

  const effectiveAddress = useMemo(() => {
    if (patientAddress) return [patientAddress]
    if (address?.length) return address
    if (currentOrganizationBillingAddress) return [currentOrganizationBillingAddress]
    return []
  }, [patientAddress, address, currentOrganizationBillingAddress])

  const physicalOrAnyValidAddress = getDefaultLabAddress(effectiveAddress)

  const performerWithOnlyCashBilling = useMemo(
    () =>
      performerLabsEnabled.some(
        ({ lab }) =>
          lab.id === performer?.[0]?.id &&
          lab.identifier?.some(
            ({ value, system }) =>
              PERFORMER_WITH_ONLY_CASH_BILLING_IDENTIFIER.includes(value as string) &&
              system === SYSTEM_VALUES.EXTERNAL_IDENTIFIER,
          ),
      ),
    [performer, performerLabsEnabled],
  )

  const showOnlyInsuranceBilling = useMemo(
    () =>
      !performerWithOnlyCashBilling &&
      Array.from<string>(
        JSON.parse(window.VITE_APP_STATES_WITH_RESTRICTED_BILLING?.replace(/'/g, '"') ?? "[]"),
      )?.includes(physicalOrAnyValidAddress?.state as string),
    [physicalOrAnyValidAddress?.state, window.VITE_APP_STATES_WITH_RESTRICTED_BILLING],
  )

  const [showDeletePanelsConfirm, setShowDeletePanelsConfirm] = useState(false)

  const panelsWithouhtPrice = panels.filter(
    (panel) =>
      !panel.price &&
      !panel.profile.code?.coding?.some(({ code }) => DEFAULT_BLOOD_DRAWN_PANELS_LIST.includes(code as string)),
  )

  const handleChange = (value: string) => {
    setShowDeletePanelsConfirm(
      value !== BILLING_TYPES_CODES.INSURANCE && !isExemptLabPayment && panelsWithouhtPrice.length > 0,
    )
  }

  const cancelRemovePanels = () => {
    setFieldValue("billingType", "insurance")
    setShowDeletePanelsConfirm(false)
  }

  const removeNoPricePanels = () => {
    const panelsToDelete = panels.filter(
      (panel) =>
        panel.profile.id &&
        !panel.price &&
        !panel.profile.code?.coding?.some(({ code }) => DEFAULT_BLOOD_DRAWN_PANELS_LIST.includes(code as string)),
    )
    setFieldValue("deletedPanels", [
      ...(deletedPanels ?? []),
      ...panelsToDelete.filter((panel) => panel.profile.id !== undefined),
    ])

    setFieldValue(
      "panels",
      panels.filter(
        (panel) =>
          !!panel.price &&
          panel.profile.code?.coding?.some(({ code }) => DEFAULT_BLOOD_DRAWN_PANELS_LIST.includes(code as string)),
      ),
    )

    setShowDeletePanelsConfirm(false)
  }

  return (
    <>
      <BillingField
        insuranceField={field}
        insuranceLabel={label}
        showBillingLabel={false}
        className="pl-6"
        onBillingChange={handleChange}
        hideInsuranceOption={performerWithOnlyCashBilling}
        hideNoInsuranceOptions={!isUnrestrictedPerformer(performer?.[0]?.id as string) && showOnlyInsuranceBilling}
      />
      {showDeletePanelsConfirm && (
        <RemovePanelsDialog panels={panelsWithouhtPrice} onCancel={cancelRemovePanels} onRemove={removeNoPricePanels} />
      )}
    </>
  )
}

type Props = {
  label?: string
  field: string
}

export { BillingDropdownField }
