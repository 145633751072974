import type { Quantity } from "fhir"
import { classNames } from "primereact/utils"
import { useId } from "react"

import { SkeletonLoader } from "commons"
import { usePatientContext } from "patients"
import { getQuantity } from "utils"

import { useVitals } from "../hooks"
import { VITAL_ICON_MAP } from "../utils"
import { VitalsKnowledgeItem } from "./VitalsKnowledgeItem"

const VitalsKnowledge = () => {
  const loaderKey = useId()
  const { patientId } = usePatientContext()
  const { latestVitals, isLoading } = useVitals(patientId)

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="one-line" />

  if (isLoading) return loader()

  return (
    <div className="grid h-full grow overflow-hidden">
      <div className={classNames("mt-1 overflow-auto")}>
        <VitalsKnowledgeItem
          icon={VITAL_ICON_MAP.height}
          label="Height"
          itemContent={getQuantity(latestVitals?.height?.value as Quantity)}
          behavior={latestVitals?.height?.behavior}
        />
        <VitalsKnowledgeItem
          icon={VITAL_ICON_MAP.weight}
          label="Weight"
          itemContent={getQuantity(latestVitals?.weight?.value as Quantity)}
          behavior={latestVitals?.weight?.behavior}
        />
        <VitalsKnowledgeItem
          icon={VITAL_ICON_MAP.heartRate}
          label="Heart rate"
          itemContent={getQuantity(latestVitals?.heartRate?.value as Quantity)}
          behavior={latestVitals?.heartRate?.behavior}
        />
        <VitalsKnowledgeItem
          icon={VITAL_ICON_MAP.respiratoryRate}
          label="Respiratory rate"
          itemContent={getQuantity(latestVitals?.respiratoryRate?.value as Quantity)}
          behavior={latestVitals?.respiratoryRate?.behavior}
        />
        <VitalsKnowledgeItem
          icon={VITAL_ICON_MAP.oxygenSaturation}
          label="SPO2"
          itemContent={getQuantity(latestVitals?.oxygenSaturation?.value as Quantity)}
          behavior={latestVitals?.oxygenSaturation?.behavior}
        />
        <VitalsKnowledgeItem
          icon={VITAL_ICON_MAP.temperature}
          label="Temperature"
          itemContent={getQuantity(latestVitals?.temperature?.value as Quantity)}
          behavior={latestVitals?.temperature?.behavior}
        />
        <VitalsKnowledgeItem
          icon={VITAL_ICON_MAP.bloodPressure}
          label="Blood pressure"
          itemContent={latestVitals?.bloodPressure?.value as string}
          behavior={latestVitals?.bloodPressure?.behavior}
        />
      </div>
    </div>
  )
}

export { VitalsKnowledge }
