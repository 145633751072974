import { faSearch } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type MedicationKnowledge, isMedicationKnowledge } from "fhir"
import { type FieldArrayRenderProps, ErrorMessage, FieldArray, useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { type FC, useEffect } from "react"

import { AddFieldArrayItemButton, CheckBoxField } from "commons"
import { isInjectableMK } from "commons/meds"

import type { MedicationDosage, MedicationRequestFormData } from "../../types"
import { serializeDosage } from "../../utils/formatters"
import { calculateAmountQuantity, calculateTotalDoseQuantity, getDosageInitialValues } from "../../utils/transformers"
import { PrescriptionSigFields } from "./PrescriptionSigFields"

const PrescriptionDosages: FC<Props> = ({ readOnly }) => {
  const {
    setFieldValue,
    initialValues,
    values: { medicationField, dosages, structured },
  } = useFormikContext<MedicationRequestFormData>()

  useEffect(() => {
    if (isMedicationKnowledge(medicationField)) {
      const { intendedRoute, amount } = medicationField as MedicationKnowledge
      if (initialValues.dosageInstruction?.length && !dosages?.length) {
        const initialDosages = initialValues.dosageInstruction.flatMap((dose) =>
          serializeDosage(dose, intendedRoute, getDosageInitialValues(medicationField)),
        )
        setFieldValue("dosages", initialDosages)
        return
      }
      if (JSON.stringify(dosages) === JSON.stringify(initialValues.dosages)) {
        return
      }

      const totalDoseQty = calculateTotalDoseQuantity(dosages ?? [], isInjectableMK(medicationField))
      const amountQty = calculateAmountQuantity(totalDoseQty, amount)

      if ((initialValues.dispenseRequest?.quantity?.value ?? 0) <= amountQty)
        setFieldValue("dispenseRequest.quantity.value", amountQty || 1)
    }
  }, [medicationField, dosages, initialValues.dosages, initialValues.dispenseRequest?.quantity?.value])

  return readOnly ? (
    <FieldArray name="dosages">
      {({ form: { getFieldMeta }, name }: FieldArrayRenderProps) => {
        const fieldValue = getFieldMeta<MedicationDosage[]>(name).value
        return (
          <div className="mb-3 grid w-full grid-flow-row-dense gap-2 3xl:grid-cols-3 lg:grid-cols-2">
            {fieldValue?.flatMap(({ instructionText }, index) => (
              <p key={`${index}-${instructionText}`}>{instructionText ?? ""}</p>
            ))}
          </div>
        )
      }}
    </FieldArray>
  ) : (
    <FieldArray name="dosages">
      {(props: FieldArrayRenderProps) => {
        const {
          remove,
          name,
          push,
          form: { getFieldMeta },
        } = props

        const fieldValue = getFieldMeta<MedicationDosage[]>(name).value
        const fError = getFieldMeta(name).error
        const fTouched = getFieldMeta(name).touched

        const showAddButton = !!fieldValue?.[0]?.instructionText || structured

        const onAddButtonClick = () => {
          const mk = getFieldMeta<MedicationKnowledge>("medicationField").value
          push(getDosageInitialValues(isMedicationKnowledge(mk) ? mk : undefined))
        }

        return (
          <div className="p-fluid relative">
            <div className="mb-2 flex flex-row justify-between">
              <label className="text-sm font-medium text-gray-700">Sig</label>
              <CheckBoxField field="structured" label="Structured" />
            </div>
            {fieldValue && Array.isArray(fieldValue) && fieldValue.length > 0 ? (
              <ul className="@container">
                {structured && (
                  <div className={classNames("flex gap-x-2", { "pr-10": fieldValue.length > 1 })}>
                    <div className="mb-4 grid w-full grid-cols-4 gap-x-6 text-sm font-medium text-gray-700">
                      <label className="w-full">Take</label>
                      <label className="w-full">Frequency</label>
                      <label className="w-full">By</label>
                      <label className="w-full">For</label>
                    </div>
                  </div>
                )}
                {fieldValue.map((_, index: number, fields) => (
                  <div key={index}>
                    <PrescriptionSigFields
                      index={index}
                      fieldPath={`${name}.${index}`}
                      onRemove={() => remove(index)}
                      isStructured={structured}
                      removeDisabled={fields.length === 1}
                    />
                    {!structured && (
                      <ErrorMessage name={`${name}.${index}`}>
                        {(msg) => typeof msg === "string" && <small className="p-error">{msg}</small>}
                      </ErrorMessage>
                    )}
                  </div>
                ))}
              </ul>
            ) : (
              <div
                className={classNames("my-2 flex w-full flex-col items-center justify-center py-5", {
                  "rounded-md border border-red-600": fError && fTouched,
                })}
              >
                <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
                <p className="pt-1 text-xs text-slate-500">No dosages added yet</p>
              </div>
            )}

            <ErrorMessage name={name}>
              {(msg) =>
                typeof msg === "string" && (
                  <small id={`errorMessage.${name}`} className="p-error">
                    {msg}
                  </small>
                )
              }
            </ErrorMessage>

            {showAddButton && <AddFieldArrayItemButton onClick={onAddButtonClick} />}
          </div>
        )
      }}
    </FieldArray>
  )
}

type Props = {
  readOnly?: boolean
}

export { PrescriptionDosages }
