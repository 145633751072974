import { faCapsules } from "@fortawesome/pro-regular-svg-icons"
import { useMemo, useState } from "react"

import { useChartContext } from "chart-view"
import { EmptyMessage, FooterActions, ModulesId } from "commons"
import { type Supplement, RecommendationsContainer } from "commons/procedures"
import { useOpenEncounter } from "encounter"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"
import { useAuth } from "security"

import { triads } from "../../data"
import { TriadFilter } from "./TriadFilter"

const NutraView = ({ supplements }: Props) => {
  const { AppSubModules } = useAuth()
  const { patientId } = usePatientContext()
  const { currentOrganizationId, loggedInPractitionerRole, organizationPractitionersInfo } = useOrganizationContext()
  const { openEncounterRef } = useOpenEncounter(patientId)
  const { hasGP, showSubModule } = useChartContext()

  const [disabledTriads, setDisabledTriads] = useState<Record<string, boolean>>({})

  const gotoDraftMR = () => showSubModule({ subModule: AppSubModules.medicationRequest[ModulesId.MR_DRAFT] })

  const effectiveSupplements = useMemo(
    () => supplements.filter(({ triads }) => triads?.some((tId) => !disabledTriads[tId])),
    [supplements, disabledTriads],
  )

  return supplements.length ? (
    <div className="relative flex h-full w-full flex-col overflow-hidden">
      <TriadFilter {...{ disabledTriads, setDisabledTriads }} />
      <RecommendationsContainer
        defaultPerformer={{
          id: "metabolic-code-supplements",
          display: "Metabolic Code Supplements",
          resourceType: "Organization",
        }}
        supplements={effectiveSupplements}
        origanizationId={currentOrganizationId}
        practitionerRole={loggedInPractitionerRole}
        practitionersInfo={organizationPractitionersInfo}
        footer={({ isCreating, onClick, selectedSupplements }) => (
          <FooterActions
            actions={[
              {
                label: "Create new nutraceutical order",
                icon: faCapsules,
                command: onClick,
                disabled: !selectedSupplements.length || !hasGP,
                loading: isCreating,
              },
            ]}
          />
        )}
        openEncounter={openEncounterRef}
        onCreateSettled={gotoDraftMR}
        className="overflow-y-auto"
        recomendationContainerClassName="px-4"
        triads={triads}
      />
    </div>
  ) : (
    <EmptyMessage icon={faCapsules} message="No medications suggested in plan" subMessage={false} />
  )
}

type Props = {
  supplements: Supplement[]
}

export { NutraView }
