import { Badge, DropdownMenu } from "commons"
import { type Duration, type Person, type Quantity, humanNameAsString } from "fhir"
import { getBadgeColor } from "utils"

const actionsBodyTemplate = ({
  externalAction,
}: {
  externalAction: { label: string; icon: JSX.Element; command: () => void }[]
}) => (
  <div className="flex items-center justify-between">
    {externalAction.length > 1 ? (
      <DropdownMenu dropdownMenuItems={externalAction} />
    ) : (
      <button
        key={1}
        title={externalAction?.[0]?.label}
        onClick={externalAction?.[0]?.command}
        className="flex cursor-pointer items-center justify-center rounded-md p-1 text-gray-500 hover:text-primary-hover"
      >
        {externalAction?.[0]?.icon}
      </button>
    )}
  </div>
)

const statusBodyTemplate = ({ status }: { status: string }) => (
  <Badge className="@md:inline-flex" {...getBadgeColor(status)} size="md" />
)

const durationBodyTemplate = ({ duration }: { duration?: Duration }) => {
  return duration?.value && `${duration.value} ${duration.unit ?? duration.code ?? "unit(s)"}`
}

const quantityBodyTemplate = (qty?: Quantity) => {
  return qty?.value && `${qty.value} ${qty.unit ?? qty.code ?? "unit(s)"}`
}

const personBodyTemplate = ({ name }: Partial<Person>) => humanNameAsString(name?.[0], "")

const booleanBodyTemplate = (value?: boolean) => (value ? "Yes" : "No")

export {
  actionsBodyTemplate,
  booleanBodyTemplate,
  durationBodyTemplate,
  personBodyTemplate,
  quantityBodyTemplate,
  statusBodyTemplate,
}
