import { computePosition, offset, shift, autoPlacement, flip, inline, hide, type Middleware } from "@floating-ui/dom"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $getSelection, $isRangeSelection } from "lexical"
import { useCallback, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"

import { type FloatingMenuCoords, FloatingMenu } from "../components/FloatingMenu"
import { usePointerInteractions } from "../hooks/usePointerInteractions"

const DOM_ELEMENT = typeof document !== "undefined" ? document.body : null

const FloatingMenuPlugin = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [coords, setCoords] = useState<FloatingMenuCoords>(undefined)
  const [editor] = useLexicalComposerContext()

  const { isPointerDown, isPointerReleased } = usePointerInteractions()

  const calculatePosition = useCallback(() => {
    const domSelection = getSelection()
    const domRange = domSelection?.rangeCount !== 0 && domSelection?.getRangeAt(0)

    if (!domRange || !ref.current || isPointerDown) return setCoords(undefined)

    const elementsToAvoid = Array.from(document.querySelectorAll(".editor-note"))

    const middleware: Middleware[] = [
      offset(10),
      inline(),
      shift({ padding: 8 }),
      flip({ padding: 8 }),
      autoPlacement({
        padding: 8,
        allowedPlacements: [
          "top",
          "top-start",
          "top-end",
          "bottom",
          "bottom-start",
          "bottom-end",
          "right",
          "right-start",
        ],
      }),
      hide({
        strategy: "referenceHidden",
        boundary: elementsToAvoid.length > 0 ? elementsToAvoid : undefined,
      }),
    ]

    computePosition(domRange, ref.current, {
      placement: "right-start",
      middleware,
    })
      .then((pos) => {
        if (pos.middlewareData.hide?.referenceHidden) {
          setCoords(undefined)
          return
        }

        setCoords({ x: pos.x, y: pos.y })
      })
      .catch(() => {
        setCoords(undefined)
      })
  }, [isPointerDown])

  const $handleSelectionChange = useCallback(() => {
    if (editor.isComposing() || editor.getRootElement() !== document.activeElement) {
      setCoords(undefined)
      return
    }

    const selection = $getSelection()

    if ($isRangeSelection(selection) && !selection.anchor.is(selection.focus)) {
      calculatePosition()
    } else {
      setCoords(undefined)
    }
  }, [editor, calculatePosition])

  useEffect(() => {
    const unregisterListener = editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => $handleSelectionChange())
    })
    return unregisterListener
  }, [editor, $handleSelectionChange])

  const show = coords !== undefined

  useEffect(() => {
    if (!show && isPointerReleased) {
      editor.getEditorState().read(() => $handleSelectionChange())
    }
  }, [isPointerReleased, $handleSelectionChange, editor, show])

  return DOM_ELEMENT ? createPortal(<FloatingMenu ref={ref} editor={editor} coords={coords} />, DOM_ELEMENT) : null
}

export { FloatingMenuPlugin }
