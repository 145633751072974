import type { ContactPoint } from "fhir"
import { useMemo } from "react"

import { BILLING_TYPES_CODES } from "data"

const useInsuredPatientNoPhone = ({
  billingType,
  telecom,
}: {
  billingType: string | undefined
  telecom: ContactPoint[] | undefined
}) => {
  const isInsuranceOrderPatientNoPhone = useMemo(
    () => billingType === BILLING_TYPES_CODES.INSURANCE && !telecom?.find(({ system }) => system === "phone")?.value,
    [billingType, telecom],
  )

  return { isInsuranceOrderPatientNoPhone }
}

const PatientPhoneRequiredMessage = "Patient's phone number required"

export { useInsuredPatientNoPhone, PatientPhoneRequiredMessage }
