import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faEllipsisVertical, faExternalLink, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Menu } from "primereact/menu"
import type { MenuItem as MenuItemProps } from "primereact/menuitem"
import { classNames } from "primereact/utils"
import { type ReactHTML, type ReactNode, useRef } from "react"
import { Checkbox } from "primereact/checkbox"

import { MenuStyles } from "../types"
import { type BadgeProps, Badge } from "./Badge"

const StackedListItem = ({
  modelData: {
    itemClassName,
    leftData,
    rightData,
    image,
    badge,
    menu,
    menuStyle = MenuStyles.Dropdown,
    isLoading,
    columnStyle = "Edge",
    onClick,
    onClickImage,
    lineItemClassname,
    leadingRightElement,
  },
  rowHover,
  itemPadding,
  className = "py-4",
  allowExpandItems = false,
  contentClassName,
  customTag = "li",
  keepMenuSpace = true,
  checkable,
  checked = false,
  disabled,
  onCheck,
}: StackedListProps) => {
  const menuRef = useRef<Menu>(null)

  const Tag = customTag ?? "li"

  return (
    <Tag
      className={classNames(
        "flex pl-3 @md:items-center",
        className,
        itemClassName,
        onClick ? "cursor-pointer" : "cursor-default",
        {
          "hover:bg-gray-50": rowHover,
          "px-4 sm:px-6": itemPadding,
        },
      )}
    >
      {image && (
        <div className={classNames("flex shrink-0", { "cursor-pointer": onClickImage })} onClick={onClickImage}>
          {image}
        </div>
      )}
      {checkable && (
        <div className="mr-3">
          <Checkbox
            checked={checked}
            disabled={disabled}
            onChange={(e) => {
              e.stopPropagation()
              onCheck?.(e.checked ?? false)
            }}
          />
        </div>
      )}
      <div
        className={classNames(
          "min-w-0 grow",
          {
            "@md:flex @md:justify-between": columnStyle === "Edge",
            "@md:grid @md:grid-cols-2": columnStyle === "Grid",
            "px-4": image,
            "@md:items-center": !rightData || (rightData.length === 1 && leftData && leftData.length <= 2),
          },
          contentClassName,
        )}
        onClick={onClick}
      >
        <div className="truncate">
          <div className="flex flex-col">
            {leftData?.map(
              ({ lineItems: lineItem, withVerticalDivider }, line) =>
                lineItem.length > 0 && (
                  <div
                    key={`ml${line}`}
                    className={classNames("flex-wrap items-baseline gap-1 text-sm", {
                      flex: !allowExpandItems,
                      "font-medium text-gray-500": line === 0,
                      "mt-1 text-xs text-gray-400": line !== 0,
                      "divide-x-2 divide-gray-200": withVerticalDivider,
                    })}
                  >
                    {lineItem.map((item, index) =>
                      item.component ? (
                        <span key={`ml${line}i${index}`} className="min-w-0" title={item.name}>
                          {item.component}
                        </span>
                      ) : (
                        <span
                          key={`ml${line}i${index}`}
                          className={classNames("items-baseline truncate @md:flex", lineItemClassname, {
                            "mt-1 @sm:pr-1 @md:mt-0": index !== 0,
                            "cursor-pointer": item.onClick,
                            "@md:pr-1 @md:first:pl-0": withVerticalDivider,
                            "@md:mr-3": !withVerticalDivider && index !== 0,
                          })}
                          title={item.name ?? item.value}
                          onClick={item.onClick}
                        >
                          {item.icon && (
                            <FontAwesomeIcon
                              icon={item.icon}
                              className="fa-fw mr-1.5 h-3.5 w-3.5 shrink-0 text-gray-400"
                            />
                          )}
                          {item.value}
                        </span>
                      ),
                    )}
                  </div>
                ),
            )}
          </div>
        </div>
        {rightData && (
          <div
            className={classNames("w-fit @sm:mt-2 @md:mt-0 @md:flex @md:flex-col @md:text-right", {
              "@md:items-end": columnStyle === "Edge",
              "@md:items-start": columnStyle === "Grid",
              "justify-center": !!badge,
              "mr-11": !!keepMenuSpace && !menu?.length,
            })}
          >
            {badge && (
              <Badge
                className={classNames("whitespace-nowrap @md:inline-flex", {
                  hidden: !rightData,
                })}
                text={badge.text}
                colorStyle={badge.colorStyle}
                size={badge.size}
              />
            )}
            {rightData.map(({ lineItems: lineItem }, line) => (
              <div
                key={`sl${line}`}
                className={classNames("text-xs text-gray-400 @md:flex", {
                  "mt-1": !!badge || line !== 0,
                })}
              >
                {lineItem.map((item, index) =>
                  item.component ? (
                    <span key={`ml${line}i${index}`} className="min-w-0">
                      {item.component}
                    </span>
                  ) : (
                    <p
                      key={`sl${line}i${index}`}
                      className={classNames("items-center truncate @md:flex", {
                        "mt-1 @md:mt-0 @md:ml-3": index !== 0,
                        "cursor-pointer": item.onClick,
                      })}
                      title={item.name ?? item.value}
                      onClick={item.onClick}
                    >
                      {item.icon && (
                        <FontAwesomeIcon icon={item.icon} className="fa-fw mr-1.5 h-3.5 w-3.5 shrink-0 text-gray-400" />
                      )}
                      {item.value}
                    </p>
                  ),
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      {leadingRightElement && <div className="ml-4 min-w-28 text-left">{leadingRightElement}</div>}
      {badge && !rightData && (
        <div className="flex min-w-20 justify-end">
          <Badge
            className={classNames(
              "h-fit whitespace-nowrap",
              {
                "mr-11": !!keepMenuSpace && !menu?.length,
              },
              badge.className,
            )}
            text={badge.text}
            colorStyle={badge.colorStyle}
            size={badge.size}
          />
        </div>
      )}
      {(isLoading || (menu && menu.length > 0)) && (
        <div className="mr-1 ml-3 flex shrink-0 self-center text-sm text-gray-500">
          {isLoading ? (
            <span className="flex items-center justify-center">
              <FontAwesomeIcon icon={faSpinner} spin className="fa-fw" size="lg" />
            </span>
          ) : menuStyle === MenuStyles.ExternalAction ? (
            <div className="flex items-center gap-2">
              {menu?.map((menuItem, index) => (
                <span
                  key={index}
                  className={classNames("flex h-5 w-5 items-center justify-end", {
                    "cursor-pointer": !menuItem.disabled,
                  })}
                  title={menuItem.label}
                  onClick={(event) => {
                    !menuItem.disabled && menuItem.command?.({ originalEvent: event, item: menuItem })
                  }}
                >
                  {menuItem.loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin className="fa-fw text-gray-400" />
                  ) : (
                    menuItem.icon ?? <FontAwesomeIcon icon={faExternalLink} className="fa-fw text-gray-400" />
                  )}
                </span>
              ))}
            </div>
          ) : menuStyle === MenuStyles.ActionItems ? (
            menu?.map((menuItem, index) => (
              <span
                className={classNames(
                  "mx-1 inline-flex h-6 w-6 items-center justify-center rounded-full text-gray-400 hover:border hover:border-gray-200 hover:bg-gray-100",
                  { "cursor-pointer": !menuItem.disabled },
                )}
                title={menuItem.label}
                onClick={(event) => {
                  !menuItem.disabled && menuItem.command?.({ originalEvent: event, item: menuItem })
                }}
                key={menuItem.label ?? index}
              >
                {menuItem.icon ?? menuItem.label}
              </span>
            ))
          ) : (
            <span
              className="flex cursor-pointer rounded-full p-1 hover:bg-gray-100"
              title="Menu"
              onClick={(event) => {
                menuRef.current && menuRef.current.toggle(event)
              }}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} size="lg" className="fa-fw" />
              <Menu model={menu} popup ref={menuRef} id="popup_menu" style={{ fontSize: "small" }} />
            </span>
          )}
        </div>
      )}
    </Tag>
  )
}

export type CommonProps = {
  /** Apply row hovered style */
  rowHover?: boolean
  /** Apply horizontal padding */
  itemPadding?: boolean
  allowExpandItems?: boolean
}

export type StackedListProps = CommonProps & {
  className?: string
  modelData: StackedListItemProps
  contentClassName?: string
  customTag?: keyof ReactHTML
  keepMenuSpace?: boolean
  checkable?: boolean
  checked?: boolean
  disabled?: boolean
  onCheck?(checked: boolean): void
}

export type StackedListItemProps = {
  itemClassName?: string
  lineItemClassname?: string
  /** Image */
  image?: ReactNode
  onClickImage?(): void
  /** Left Column Data */
  leftData?: StackedLineItemProps[]
  /** Right Column Data */
  rightData?: StackedLineItemProps[]
  badge?: BadgeProps
  /** Dropdown menu */
  menu?: MenuItem[]
  /** Element to be shown just before all elements on the right */
  leadingRightElement?: ReactNode
  menuStyle?: MenuStyles
  isLoading?: boolean
  columnStyle?: "Edge" | "Grid"
  onClick?(): void
}

type MenuItem = MenuItemProps & { loading?: boolean }

export type StackedLineItemProps = {
  lineItems: StackedItemProps[]
  withVerticalDivider?: boolean
}

export type StackedItemProps = {
  name?: string
  value?: string
  component?: JSX.Element
  icon?: IconDefinition
  onClick?(): void
}

export { StackedListItem }
