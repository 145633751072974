import { faEye, faPencil, faQrcode, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dialog } from "primereact/dialog"
import { classNames } from "primereact/utils"
import { type FC, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { SurveyContainer, useQRUrl } from "surveys"
import { strCapitalize } from "utils"

import { Badge } from "../../../components/Badge"
import { ModalDialog } from "../../../components/ModalDialog"
import type { QuestionnaireData } from "../../../types"
import { useQrCodeGenerator } from "../../hooks"

const CPQuestionnaires: FC<Props> = ({ questionnaires, isUpdatingSurvey, isMCPlan, onSurveyHide, className }) => {
  const { getQrImage } = useQrCodeGenerator()
  const [imageUrl, setImageUrl] = useState("")
  const [showQrModal, setShowQrModal] = useState(false)
  const [questPreview, setQuestPreview] = useState<string>()
  const [params, setParams] = useSearchParams()

  const { getQRUrl, isLoading: isLoadingQRUrl } = useQRUrl((data) => {
    const uri = data?.link?.value?.uri
    if (uri) {
      const image = getQrImage(uri)
      if (!image) return
      setImageUrl(image)
      setShowQrModal(true)
    }
  })

  const previewQuest = (questId?: string) => {
    setQuestPreview(questId)
    questId ? params.set("qrId", questId) : params.delete("qrId")
    setParams(params)
  }

  const hideDialog = async () => {
    previewQuest(undefined)
    await onSurveyHide()
  }

  const hideDialogWithDelay = () => {
    setTimeout(hideDialog, 1000)
  }

  return (
    <>
      <div className={classNames("flex flex-col space-y-2 divide-y divide-gray-200 p-3", className)}>
        {questionnaires?.map((q) => (
          <div key={q.qResponse?.id} className="flex w-full flex-row items-center justify-between pt-2">
            <div className="flex flex-1 justify-between">
              <span className="w-full text-sm text-gray-500">{q.questionnaire.title}</span>
              <Badge
                text={strCapitalize(q.qResponse?.status?.replace("-", " ") ?? "unknow")}
                colorStyle={q.qResponse?.status === "completed" ? "green" : "yellow"}
                className="mx-2 h-min shrink-0"
              />
              <span className="flex flex-row">
                {q.qResponse?.id && !isMCPlan && (
                  <FontAwesomeIcon
                    icon={isLoadingQRUrl ? faSpinner : faQrcode}
                    className={classNames("mx-2 cursor-pointer text-gray-400", {
                      "animate-spin": isLoadingQRUrl,
                    })}
                    title="Generate QR"
                    onClick={() => getQRUrl(q.qResponse?.id as string)}
                    size="sm"
                  />
                )}
                {q.qResponse?.id && (
                  <FontAwesomeIcon
                    icon={q.qResponse?.status === "completed" ? faEye : faPencil}
                    className="mx-2 cursor-pointer text-gray-400"
                    title={`${q.qResponse?.status === "completed" ? "Show" : "Answer"} survey`}
                    onClick={() => (isUpdatingSurvey ? undefined : previewQuest(q.qResponse?.id as string))}
                    aria-disabled={isUpdatingSurvey}
                    size="sm"
                  />
                )}
              </span>
            </div>
          </div>
        ))}
      </div>
      <ModalDialog onCancel={() => setShowQrModal(false)} showCloseIcon visible={showQrModal}>
        <img className="m-auto" src={imageUrl} alt="QR" />
      </ModalDialog>
      <Dialog
        visible={!!questPreview}
        onHide={hideDialog}
        draggable={false}
        resizable={false}
        className="h-4/5 w-2/3"
        header="Preview questionnaire"
      >
        <SurveyContainer onFinish={hideDialogWithDelay} />
      </Dialog>
    </>
  )
}

type Props = {
  questionnaires?: QuestionnaireData[]
  isUpdatingSurvey: boolean
  isMCPlan: boolean
  onSurveyHide(): void
  className?: string
}

export { CPQuestionnaires }
