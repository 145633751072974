import type { FC } from "react"

import { CodeableConceptField, DateField, InputField, NumberField } from "commons"
import { observationCodes, observationInterpretations } from "data"

const LabResultForm: FC<Props> = ({ fieldPath }) => (
  <div className="p-fluid relative mb-5 grid gap-4 space-y-2">
    <CodeableConceptField
      field={`${fieldPath}.code`}
      label="Code"
      codes={observationCodes}
      validation={(code) => (!code?.coding?.[0].code ? "Code is required" : undefined)}
    />
    <span className="grid grid-cols-4 gap-4">
      <NumberField
        field={`${fieldPath}.value.Quantity.value`}
        label="Value"
        className="col-span-1"
        min={0}
        validation={(value) => (!value ? "Quantity is required" : undefined)}
      />
      <InputField
        field={`${fieldPath}.value.Quantity.unit`}
        label="Unit"
        className="col-span-1"
        validation={(value) => (!value ? "Unit is required" : undefined)}
      />
      <CodeableConceptField
        field={`${fieldPath}.interpretation[0]`}
        label="Interpretation"
        codes={observationInterpretations}
        className="col-span-2"
        validation={(code) => (!code?.coding?.[0]?.code ? "Interpretation is required" : undefined)}
      />
    </span>
    <span className="grid grid-cols-4 gap-4">
      <NumberField
        field={`${fieldPath}.referenceRange[0].high.value`}
        label="Reference high"
        className="col-span-1"
        min={0}
      />
      <NumberField
        field={`${fieldPath}.referenceRange[0].low.value`}
        label="Reference low"
        className="col-span-1"
        min={0}
      />
      <DateField
        field={`${fieldPath}.effective.dateTime`}
        label="Effective"
        stringFormatType="ISO_8601_DATETIME"
        showTime
        className="col-span-2"
      />
    </span>
  </div>
)

type Props = {
  fieldPath: string
}

export { LabResultForm }
