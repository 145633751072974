import { SkeletonLoader } from "commons"

const NetworkError = () => (
  <div className="banner-container h-24 border-b border-gray-200 drop-shadow-xs">
    <div className="relative flex h-full">
      <SkeletonLoader repeats={1} loaderType="list" containerClassName="w-1/2  " />
      <SkeletonLoader repeats={1} loaderType="two-lines" containerClassName="w-1/2 " />
    </div>
    <div className="px-4 py-2">
      <SkeletonLoader repeats={8} loaderType="two-lines" />
    </div>
  </div>
)

export { NetworkError }
