import type { EventInput } from "@fullcalendar/core"
import { isSameDay, isSameMonth } from "date-fns"
import { Badge } from "primereact/badge"
import { type CalendarDateTemplateEvent, type CalendarMonthChangeEvent, Calendar } from "primereact/calendar"
import { Tooltip } from "primereact/tooltip"
import type { FormEvent } from "primereact/ts-helpers"
import { type SyntheticEvent, useEffect, useRef, useState } from "react"
import { classNames } from "primereact/utils"
import pluralize from "pluralize"

import { EventType, type CalendarEventExtraInfo } from "commons/types"

import "./AppointmentCalendar.css"

const AppointmentCalendar = ({ currentDate, selectDate, currentDateEvents, onMonthChange }: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(currentDate ?? new Date())
  const prevMonthStartDate = useRef<Date>(new Date())

  const handleChange = (e: FormEvent<Date, SyntheticEvent<Element, Event>>) => {
    if (currentDate && isSameMonth(currentDate, e.value as Date)) {
      setSelectedDate(e.value as Date)
      selectDate(e.value as Date)
    }
  }

  useEffect(() => {
    if (!!currentDate && !isSameDay(prevMonthStartDate.current, currentDate)) {
      setSelectedDate(currentDate)
      prevMonthStartDate.current = currentDate
    }
  }, [currentDate])

  const dateTemplate = (date: CalendarDateTemplateEvent) => {
    const dateStr = `${date?.year}-${date?.month + 1}-${date?.day}`
    const events = currentDateEvents(new Date(date?.year, date?.month, date?.day))
    const { appointments, slots } = events.reduce<{ appointments: EventInput[]; slots: EventInput[] }>(
      (acc, event) => {
        const eventType = (event.extendedProps as CalendarEventExtraInfo | undefined)?.eventType

        return {
          ...acc,
          ...(eventType === EventType.APPT
            ? { appointments: [...acc.appointments, event] }
            : { slots: [...acc.slots, event] }),
        }
      },
      { appointments: [], slots: [] },
    )

    const count = appointments.length + slots.length

    const onlySlot = !!slots.length && !appointments.length
    const onlyAppointment = !!appointments.length && !slots.length

    return count > 0 ? (
      <>
        <span
          className={classNames(`grid h-full w-full content-center justify-center date_${dateStr}`, {
            "rounded-full border border-red-500 bg-transparent": onlySlot,
          })}
        >
          {date?.day}
          {!onlySlot && (
            <Tooltip
              target={`.date_${dateStr}`}
              content={`${count} ${onlySlot ? pluralize("slot", count) : onlyAppointment ? pluralize("appointment", count) : pluralize("event", count)}`}
              position="top"
              event="hover"
              className="m-0 p-0 text-xs"
            />
          )}
        </span>
        {!onlySlot && (
          <Badge
            severity={slots.length ? "warning" : "info"}
            className={`absolute top-0.5 right-0.5 date_${dateStr}`}
          />
        )}
      </>
    ) : (
      <>{date?.day}</>
    )
  }

  return (
    <Calendar
      value={selectedDate}
      className="custom-appointment-calendar grow"
      panelClassName="border-none border-0"
      onChange={handleChange}
      inline
      dateTemplate={dateTemplate}
      onMonthChange={onMonthChange}
    />
  )
}

type Props = {
  currentDate?: Date
  selectDate(date: Date): void
  currentDateEvents(date: Date): Array<EventInput>
  onMonthChange?(_: CalendarMonthChangeEvent): void
}
export { AppointmentCalendar }
