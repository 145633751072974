import { faFilter } from "@fortawesome/pro-solid-svg-icons"
import { OverlayPanel } from "primereact/overlaypanel"
import { classNames } from "primereact/utils"
import { type CSSProperties, type FC, type PropsWithChildren, type ReactNode, useRef } from "react"
import type { AnyObject } from "yup/lib/types"

import { type Action, Button, FooterActions, SearchInput } from "commons"

const SectionContainer: FC<PropsWithChildren<Props>> = ({
  actions,
  children,
  filters,
  currentFilters,
  searchText,
  loading,
  placeholder,
  onSearch,
  onReset,
  overlayStyles = { width: "15vw" },
  containerClassName = "overflow-auto grow min-h-max",
}) => {
  const overlayFilter = useRef<OverlayPanel>(null)

  const hasFilter =
    !!currentFilters && Object.values(currentFilters).some((value) => (Array.isArray(value) ? !!value.length : !!value))

  return (
    <>
      <div className="absolute top-8 right-8 flex h-12 w-96 items-start justify-end gap-2 py-1">
        <SearchInput
          initialValue={searchText}
          search={onSearch}
          autoFocus={false}
          placeholder={placeholder}
          isLoading={loading}
          className="flex h-full w-full"
        />
        {!!filters && (
          <>
            <Button
              icon={faFilter}
              iconClassName={!hasFilter ? "text-slate-400" : "text-primary"}
              buttonStyle="default"
              size="lg"
              className="h-full w-12 justify-center text-center"
              onClick={(e) => overlayFilter?.current?.toggle(e)}
              loading={loading}
            />
            <OverlayPanel
              ref={overlayFilter}
              showCloseIcon={false}
              dismissable
              id="overlay_panel"
              className="form-panel pb-3"
              breakpoints={{ "1330px": "25vw", "1024px": "45vw", "960px": "75vw", "640px": "90vw" }}
              style={overlayStyles}
            >
              <div className="mr-5 ml-3 flex flex-col gap-2 divide-y divide-gray-400 bg-white">
                <div className="flex w-full justify-end bg-white">
                  <Button
                    type="button"
                    label="Clear all"
                    disabled={!hasFilter}
                    onClick={onReset}
                    buttonStyle="text"
                    className="p-button-sm"
                  />
                </div>
                {filters}
              </div>
            </OverlayPanel>
          </>
        )}
      </div>
      <section className={classNames("flex flex-col", containerClassName)}>{children}</section>
      {!!actions?.length && <FooterActions actions={actions} />}
    </>
  )
}

type Props = {
  actions?: Action[]
  filters?: ReactNode
  currentFilters?: AnyObject
  searchText?: string
  placeholder?: string
  overlayStyles?: CSSProperties
  loading?: boolean
  onSearch: (searchText: string) => void
  onReset: () => void
  containerClassName?: string
}

export { SectionContainer }
