import { humanNameAsString } from "fhir"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { useMemo, useState } from "react"

import { useOrganizationContext, useOrganizationPractitioners } from "organization"

const DosespotPractitionerSelect = ({ setPractitionerRoleIdSelected }: IProps) => {
  const { currentOrganizationId } = useOrganizationContext()
  const { organizationPractitionersInfo, isLoading } = useOrganizationPractitioners({
    organizationId: currentOrganizationId,
  })
  const [practitionerRoleId, setPractitionerRoleId] = useState<string>()

  const practitioners = useMemo(
    () =>
      organizationPractitionersInfo.reduce<{ label: string; value: string }[]>(
        (prev, { hasDosespotSetup, practitioner, practitionerRoleRef }) => {
          if (hasDosespotSetup && practitionerRoleRef?.id) {
            return [...prev, { label: humanNameAsString(practitioner.name?.[0]), value: practitionerRoleRef.id }]
          }

          return prev
        },
        [],
      ),
    [organizationPractitionersInfo],
  )

  return (
    <div className="flex h-full w-full items-center justify-center bg-white">
      <div className="absolute flex min-w-[25%] flex-col justify-center rounded-xl bg-white shadow-xs shadow-gray-600 xl:min-w-[30%]">
        <div className="flex h-16 items-center justify-center rounded-t-xl bg-gray-50 font-bold">
          Select a practitioner
        </div>
        <div className="px-20 pt-10 pb-20">
          <div className="mt-8 w-full xl:mt-14">
            <Dropdown
              className="w-full"
              options={practitioners}
              optionLabel="label"
              optionValue="value"
              value={practitionerRoleId}
              filterBy="label"
              resetFilterOnHide
              showFilterClear
              filter
              onChange={(e) => {
                if (e.value) {
                  setPractitionerRoleId(e.value)
                }
              }}
            />
          </div>
          <Button
            className="button-primary mt-10 w-full text-center"
            label="Accept"
            onClick={practitionerRoleId ? () => setPractitionerRoleIdSelected(practitionerRoleId) : undefined}
            loading={isLoading}
            disabled={!practitionerRoleId}
          />
        </div>
      </div>
    </div>
  )
}

type IProps = {
  setPractitionerRoleIdSelected: React.Dispatch<React.SetStateAction<string | undefined>>
}

export { DosespotPractitionerSelect }
