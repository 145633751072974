import { useFormikContext } from "formik"
import { RadioButton } from "primereact/radiobutton"

import { CheckBoxField, DateField, FormField, InputField, LoadingView, ValueSetIds } from "commons"
import { useValueSet } from "value-set"

import { DayHours } from "./DayHours"
import type { SpecialHoursData } from "./types"

const SpecialHourForm = ({ usedDays }: Props) => {
  const { codes, isLoading } = useValueSet({ valueSetId: ValueSetIds.HOURS_OPERATIONS_FREQUENCY })
  const { setFieldValue } = useFormikContext<SpecialHoursData>()

  const removeHours = () => {
    setFieldValue("closed", true)
    setFieldValue("hours", [])
  }

  const onToggleClosed = (checked: boolean) =>
    setFieldValue("hours", checked ? [] : [{ openingTime: "00:00:00", closingTime: "00:00:00" }])

  return (
    <div className="flex flex-col gap-4">
      <InputField label="Description" field="description" />
      <div className="flex justify-between gap-6">
        <DateField
          label="Date"
          field="date"
          className="w-1/3"
          validation={(value) => usedDays?.includes(value) && "This date is already in use"}
        />
        <FormField label="Repeat" field="frequency" className="w-3/5">
          {isLoading ? (
            <div className="h-8 w-6">
              <LoadingView />
            </div>
          ) : (
            ({ field: { value } }) => (
              <div className="flex gap-6">
                {codes?.map((coding, i) => (
                  <RepeatItem
                    key={i}
                    label={coding.display ?? ""}
                    checked={coding.code === value?.coding?.[0]?.code}
                    onChecked={() => setFieldValue("frequency", { text: coding.display, coding: [{ ...coding }] })}
                  />
                ))}
              </div>
            )
          )}
        </FormField>
      </div>
      <div className="flex items-start justify-between">
        <CheckBoxField label="Closed" field="closed" onCheck={onToggleClosed} />
        <DayHours className="w-3/5" onRemoveDay={removeHours} />
      </div>
    </div>
  )
}

const RepeatItem = ({ label, checked, onChecked }: RepeatItemProps) => (
  <div className="flex gap-2">
    <RadioButton inputId={`repeat-item-${label}`} checked={checked} onChange={onChecked} />
    <label htmlFor={`repeat-item-${label}`} className="cursor-pointer text-sm">
      {label}
    </label>
  </div>
)

type Props = {
  usedDays?: string[]
}

type RepeatItemProps = {
  label: string
  checked: boolean
  onChecked(): void
}

export { SpecialHourForm }
