import { faLocationDot } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type AccountBETACreditCardArray, type Address, getAddress } from "fhir"
import { ErrorMessage, useFormikContext } from "formik"
import { Checkbox } from "primereact/checkbox"
import { useMemo, useState } from "react"

import { AddressField, InputField } from "commons"
import { SmartyAddressVerificationProvider } from "commons/context"
import { emptyAddress } from "data"
import { formatCreditCardNumber } from "utils"

import { CreditcardNumberField } from "./CreditcardNumberField"
import { ExpirationDateField } from "./ExpirationDateField"

const CreditCardForm = ({ isEditing, showBillingAddress = true, shippingAddress }: Props) => {
  const { values, setValues, isSubmitting, errors, touched } = useFormikContext<AccountBETACreditCardArray>()

  const isSameAddress = useMemo(
    () => (shippingAddress ? JSON.stringify(values["billingAddress"]) === JSON.stringify(shippingAddress) : false),
    [shippingAddress, values],
  )
  const [useShipping, setUseShipping] = useState(isSameAddress)

  const onChange = (value: boolean) => {
    if (shippingAddress) {
      setValues((prevValues) => ({
        ...prevValues,
        billingAddress: value ? shippingAddress : isEditing ? prevValues["billingAddress"] : emptyAddress,
      }))
    }

    setUseShipping(value)
  }

  const renderBasicInfo = () => (
    <>
      {isEditing ? (
        <div className="flex gap-4">
          <div className="flex-1">
            <div className="mb-1 text-sm">Number</div>
            <div className="mb-2 block h-11 cursor-default truncate rounded-md border border-slate-200 p-3 text-sm text-gray-400 select-none">
              {formatCreditCardNumber(values.last4Digits as string, values.type)}
            </div>
          </div>
          <div className="w-1/3">
            <div className="mb-1 text-sm">CVV</div>
            <div className="mb-2 block h-11 cursor-default truncate rounded-md border border-slate-200 p-3 text-sm text-gray-400 select-none">
              ****
            </div>
          </div>
        </div>
      ) : (
        <CreditcardNumberField field="number" label="Number" disabled={isSubmitting} />
      )}
      {isEditing && <ExpirationDateField field="expirationMonth" label="Expiration date" disabled />}
      <InputField field="cardHolderName" label="Cardholder name" disabled={isEditing} />
    </>
  )

  return (
    <div className="flex flex-col gap-4">
      {showBillingAddress ? (
        <fieldset className="p-fluid flex flex-col gap-4 p-3">
          <legend>Basic information</legend>
          {renderBasicInfo()}
        </fieldset>
      ) : (
        renderBasicInfo()
      )}

      {showBillingAddress && (
        <fieldset className="p-fluid flex flex-col gap-4 p-3">
          <legend>Billing Address</legend>
          {shippingAddress && (
            <div className="w-full">
              <div className="flex items-center gap-1 text-sm">
                <Checkbox
                  inputId="useshipping"
                  onChange={(e) => onChange(e.checked ?? false)}
                  checked={useShipping}
                  value="Use shipping address"
                />
                <label
                  htmlFor="useshipping"
                  className="cursor-pointer text-sm text-gray-500"
                  title="Use shipping address"
                >
                  <FontAwesomeIcon icon={faLocationDot} className="mr-1" />
                  {getAddress([shippingAddress])}
                </label>
              </div>
              <span className="p-error">
                {!!(touched.billingAddress && errors.billingAddress) && (
                  <ErrorMessage name="">{() => <small>Missing information in address</small>}</ErrorMessage>
                )}
              </span>
            </div>
          )}
          {!useShipping && (
            <SmartyAddressVerificationProvider>
              <AddressField parentFieldName="billingAddress" showTypeUseField={false} disabled={isSubmitting} />
            </SmartyAddressVerificationProvider>
          )}
        </fieldset>
      )}
    </div>
  )
}

type Props = {
  isEditing: boolean
  showBillingAddress?: boolean
  shippingAddress?: Address
}

export { CreditCardForm }
