import { asReference, type Quantity } from "fhir"
import type { FormikHelpers } from "formik"
import { useMountEffect } from "primereact/hooks"
import { useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { Button, ConfirmDialog, FormContainer, MentionTextAreaField, ModulesId } from "commons"
import { useAppModuleContext } from "modules"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { usePatientContext } from "patients"
import { useLoginContext } from "security"

import { FORCED_PRESCRIPTIION_CATEGORY } from "../../data"
import type { MedicationRequestFormData } from "../../types"
import {
  exceedsDaysSupplyLimit,
  getPractitionersInfoWithNPIAndLifefileId,
  prescriptionValidationSchema,
} from "../../utils"
import { PrescriptionAddressWarningMessage } from "./PrescriptionAddressWarningMessage"
import { PrescriptionForm } from "./PrescriptionForm"
import { PrescriptionSupplyLimitWarningMessage } from "./PrescriptionSupplyLimitWarningMessage"

const PrescriptionFormContainer = ({
  initialValues,
  isEditing,
  isRenewing,
  editAddressHidden,
  onSubmit,
  onCancel,
}: Props) => {
  const { loggedInPractitioner } = useLoginContext()
  const { currentOrganizationId } = useOrganizationContext()
  const { isModuleActive } = useAppModuleContext()
  const { organizationPractitionersInfo } = useOrganizationPractitioners({ organizationId: currentOrganizationId })
  const practionersInfoWithValidNPIAndLifefileId = useMemo(
    () => getPractitionersInfoWithNPIAndLifefileId(organizationPractitionersInfo),
    [organizationPractitionersInfo],
  )

  const { patient } = usePatientContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const [mrData, setMRData] = useState<Quantity>()
  const [mrRestriction, setMRRestriction] = useState<string>()
  const [supplyLimitExceeded, setSupplyLimitExceeded] = useState(false)

  const missingPatientAddress = !patient.address?.length

  useMountEffect(() => {
    if (isModuleActive(ModulesId.INTAKE) && !["allergies", "conditions"].includes(searchParams.get("kp") as string)) {
      searchParams.set("kp", "allergies")
      setSearchParams(searchParams)
    }
  })

  return (
    <div className="min-h-0 grow">
      <FormContainer
        title={`${isEditing ? "Update" : isRenewing ? "Reorder" : "Create"} Prescription`}
        initialValue={initialValues}
        onSubmit={onSubmit}
        onCancel={onCancel}
        hideButtonsDivider
        saveLabel={`${isEditing ? "Update" : isRenewing ? "Reorder" : "Create"} Prescription`}
        validationSchema={prescriptionValidationSchema(practionersInfoWithValidNPIAndLifefileId)}
        innerContainerClassName="px-4 space-y-4 pb-6"
        footerClassName="border-t border-gray-200"
        disableSave={missingPatientAddress}
        customSaveButton={({ values, validate, isSubmitting, submitForm }) => {
          const shouldShowAlert =
            ["capsule", "tablet", "troche", "capsule-er", "rapid-odt", "tablet-er"].includes(
              values.prescriptionQuantity?.code ?? "",
            ) && values.dispenseRequest?.quantity?.value === 1
          const exceedsLimit = exceedsDaysSupplyLimit(values)

          return (
            <Button
              label={`${isEditing ? "Update" : isRenewing ? "Reorder" : "Create"} Prescription`}
              type="button"
              loading={isSubmitting}
              disabled={missingPatientAddress}
              onClick={async () => {
                const errors = await validate()

                if (exceedsLimit && !Object.keys(errors).length) {
                  setSupplyLimitExceeded(true)
                } else if (shouldShowAlert && !Object.keys(errors).length) {
                  setMRData(values.prescriptionQuantity)
                } else {
                  submitForm()
                }
              }}
            />
          )
        }}
        customHeader={({ values }) => (
          <div className="px-4">
            <PrescriptionSupplyLimitWarningMessage medicationRequestFormData={values} />
          </div>
        )}
      >
        {({ isSubmitting, setSubmitting, values, setFieldError, setFieldValue }) => (
          <>
            {missingPatientAddress && (
              <PrescriptionAddressWarningMessage
                message="In order to create a prescription the patient should have an address on file."
                actionText="Add it here."
              />
            )}
            <PrescriptionForm
              isEditing={isEditing}
              isRenewing={isRenewing}
              practitionersInfo={practionersInfoWithValidNPIAndLifefileId}
              editAddressHidden={editAddressHidden}
              onBypassValidations={setMRRestriction}
            />

            <ConfirmDialog
              key="quantity-overwrite-dialog"
              confirmText={`You are creating a prescription with only 1 ${mrData?.code}. Do you wish to proceed anyway?`}
              actionName="Accept"
              actionType="submit"
              visible={!!mrData}
              isLoading={isSubmitting}
              hideDialog={() => {
                setMRData(undefined)
                setSubmitting(false)
              }}
              waitToFinish
              appendTo="self"
            />
            <ConfirmDialog
              key="supply-overwrite-dialog"
              confirmElement={
                <div>
                  <p>This prescription exceeds the allowed supply limit. The pharmacy may reject it as invalid.</p>
                  <p>Do you wish to proceed anyway?</p>
                </div>
              }
              rejectAction="Edit Rx"
              actionName="Submit Rx"
              actionType="submit"
              visible={supplyLimitExceeded}
              isLoading={isSubmitting}
              hideDialog={() => {
                setSupplyLimitExceeded(false)
                setSubmitting(false)
              }}
              waitToFinish
              appendTo="self"
            />
            <ConfirmDialog
              key="restriction-overwrite-dialog"
              className="w-[90%] lg:w-2/5"
              actionName="Accept"
              actionType="button"
              rejectAction="Cancel"
              visible={!!mrRestriction}
              hideDialog={() => {
                setMRRestriction(undefined)
              }}
              appendTo="self"
              confirmElement={
                <>
                  <p className="p-error mb-2 text-sm">{mrRestriction}</p>
                  <MentionTextAreaField
                    field="note[1].text"
                    rows={5}
                    className="mt-2"
                    inputClassName="w-full"
                    disabled={isSubmitting}
                    placeholder="Please provide a note to the pharmacist regarding this override request"
                    validation={(val) => (!val ? "This field is required" : undefined)}
                  />
                  <p className="mt-2 text-sm">
                    This script might not be approved due to the restrictions above. Do you wish to proceed anyway?
                  </p>
                </>
              }
              confirmDisabled={!values.note?.[1]?.text}
              onConfirm={() => {
                setFieldValue("category", [
                  ...(values.category ?? []).filter(
                    ({ coding }) => coding?.[0]?.code !== FORCED_PRESCRIPTIION_CATEGORY.coding?.[0]?.code,
                  ),
                  FORCED_PRESCRIPTIION_CATEGORY,
                ]).finally(() => setFieldError("medicationField", undefined))
                setFieldValue("note[1].author", { Reference: asReference(loggedInPractitioner) })
              }}
            />
          </>
        )}
      </FormContainer>
    </div>
  )
}

type Props = {
  initialValues: MedicationRequestFormData
  isEditing?: boolean
  isRenewing?: boolean
  onSubmit(_: MedicationRequestFormData, formikHelpers?: FormikHelpers<MedicationRequestFormData>): void
  isSubmitting?: boolean
  onCancel(): void
  editAddressHidden?: boolean
}

export { PrescriptionFormContainer }
