import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { ChargeItemDefinition, MedicationDispense, MedicationKnowledge, MedicationRequest } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { medsQueryKeys } from "commons/meds"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { ordersQueryKeys } from "orders"
import { usePatientContext } from "patients"
import { displayNotificationSuccess } from "utils"

import { eprescribeQueryKeys } from "../query-keys"

const useUpdateMedicationRequest = (onSettled: () => void) => {
  const queryClient = useQueryClient()
  const { update } = useClient()
  const { patientId } = usePatientContext()
  const queryKey = eprescribeQueryKeys.draft.list(patientId)

  const newMR = async (medicationRequest: MedicationRequest) =>
    update("MedicationRequest", medicationRequest.id as string, medicationRequest)

  const { mutate: updateMedicationRequest, isPending: isUpdating } = useMutation({
    mutationFn: newMR,
    onMutate: (newMR) => {
      queryClient.cancelQueries({ queryKey, fetchStatus: "fetching" })
      const previousData = queryClient.getQueryData(queryKey)
      queryClient.setQueryData<DraftMedData>(queryKey, (old) => {
        const index = old?.medicationRequests?.findIndex((mr) => mr.id === newMR.id)
        if (index !== undefined && index !== -1) {
          return {
            ...old,
            medicationRequests: [
              ...(old?.medicationRequests?.slice(0, index) as MedicationRequest[]),
              newMR,
              ...(old?.medicationRequests?.slice(index + 1) as MedicationRequest[]),
            ],
          } as DraftMedData
        }
        return old as DraftMedData
      })

      return previousData
    },
    onSuccess: async () => {
      queryClient.refetchQueries({ queryKey: medsQueryKeys.draft.list(patientId, "medication") })

      await queryClient.invalidateQueries({
        queryKey: ordersQueryKeys.list(patientId),
      })
      displayNotificationSuccess("Prescription updated successfully!")
      // datadogLogs.logger.info(`Prescription ${data.id} updated successfully!`, { data })
    },
    onError: async (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        await queryClient.invalidateQueries({ queryKey, refetchType: "all" })
      } else {
        await queryClient.setQueryData(queryKey, context)
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey, refetchType: "all" })
      onSettled()
    },
  })

  return { updateMedicationRequest, isUpdating }
}

type DraftMedData = {
  medicationRequests: MedicationRequest[] | undefined
  medicationDispenses: MedicationDispense[] | undefined
  medicationKnowledges: MedicationKnowledge[] | undefined
  chargeItemDefinitions: ChargeItemDefinition[] | undefined
  medCodes: string
  isLoading: boolean
  draftMedicationRequestsCount: number
  total: number
  reloadMedications: () => void
}

export { useUpdateMedicationRequest }
