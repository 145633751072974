import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink } from "@fortawesome/pro-light-svg-icons"
import type { FallbackProps } from "react-error-boundary"
import type { FC } from "react"
import { Link } from "react-router-dom"

import type { CustomError } from "commons"
import { ERROR_CAUSE } from "data"

const NotFoundErrorView: FC<Props> = ({ error, resetErrorBoundary }) => {
  const message = error.cause?.message ?? ERROR_CAUSE.RESOURCE_NOT_FOUND
  const code = error.cause?.name ?? "404"

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="text-center">
        <p className="p-error text-5xl">{code}</p>
        <h1 className="py-2 text-4xl font-bold">{ERROR_CAUSE.RESOURCE_NOT_FOUND}</h1>
        <p className="pt-2 pb-4 text-gray-400">{message}</p>
        <div className="m-auto flex w-fit justify-center">
          <Link to="/" onClick={resetErrorBoundary} className="p-button p-button-primary">
            <span>
              Go to Home <FontAwesomeIcon icon={faExternalLink} className="pl-1" />
            </span>
          </Link>
        </div>
      </div>
    </div>
  )
}

type Props = Pick<FallbackProps, "resetErrorBoundary"> & { error: CustomError }

export { NotFoundErrorView }
