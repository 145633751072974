import type { Reference } from "fhir"

const patientsQueryKeys = {
  all: ["patients"] as const,
  list: (currentOrganizationId: string, filter: string, gender?: string, practitioner?: Reference) => [
    ...patientsQueryKeys.all,
    currentOrganizationId,
    filter,
    gender,
    practitioner,
  ],
  details: (patientId?: string) => ["patient", patientId],
  invitations: {
    all: ["patientInvitation"] as const,
    list: (patientId: string, listAll?: boolean) => [...patientsQueryKeys.invitations.all, patientId, listAll],
  },
  documents: {
    all: ["patient/documents"],
    list: (patientId: string) => [...patientsQueryKeys.documents.all, patientId],
  },
  refs: (currentOrganizationId: string, filter?: string) => ["patientsRefs", currentOrganizationId, filter],
}

export { patientsQueryKeys }
