import type { Parameters } from "fhir"
import type { FC } from "react"

import { classNames } from "primereact/utils"
import { getSummaryParameter } from "../utils"

const OrderSummary: FC<Props> = ({ summary, currency, className, titleClassName }) => {
  const discounts = getSummaryParameter(summary, "discounts-total") as number | undefined
  const creditNote = getSummaryParameter(summary, "credit-note") as number | undefined
  const debitNote = getSummaryParameter(summary, "debit-note") as number | undefined

  return (
    <section className={classNames("sticky top-0", className)}>
      <div className="flex flex-col">
        <h3 className={titleClassName}>Summary</h3>
        <div className="w-full flex-1 text-sm">
          <div className="flex justify-between border-b border-gray-200 py-2">
            <span className="inline-block">Subtotal</span>
            <span className="inline-block text-right">
              {currency + (getSummaryParameter(summary, "subtotal") as number).toFixed(2)}
            </span>
          </div>

          <div className="flex justify-between border-b border-gray-200 py-2">
            <span className="inline-block">Taxes</span>
            <span className="inline-block text-right">
              {currency + (getSummaryParameter(summary, "taxes") as number).toFixed(2)}
            </span>
          </div>
          {!!discounts && (
            <div className="flex flex-col space-y-1 border-b border-gray-200 py-2">
              <div className="flex justify-between">
                <span className="inline-block">Discounts total</span>
                <span className="inline-block text-right">{`-${currency + (discounts * -1).toFixed(2)}`}</span>
              </div>
            </div>
          )}
          {!!creditNote && (
            <div className="flex flex-col space-y-1 border-b border-gray-200 py-2">
              <div className="flex justify-between">
                <span className="inline-block">Credit Note</span>
                <span className="inline-block text-right">{`-${currency + (creditNote * -1).toFixed(2)}`}</span>
              </div>
            </div>
          )}
          {!!debitNote && (
            <div className="flex flex-col space-y-1 border-b border-gray-200 py-2">
              <div className="flex justify-between">
                <span className="inline-block">Debit Note</span>
                <span className="inline-block text-right">{`${currency + debitNote.toFixed(2)}`}</span>
              </div>
            </div>
          )}
          <div className="flex justify-between py-2 font-bold">
            <span className="inline-block">Total</span>
            <span className="inline-block text-right">
              {currency + (getSummaryParameter(summary, "total") as number).toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}

type Props = {
  summary: Parameters
  currency: string
  hasMedicationItems: boolean
  className?: string
  titleClassName?: string
}

export { OrderSummary }
