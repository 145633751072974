import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { classNames } from "primereact/utils"
import type { FC, PropsWithChildren } from "react"

import { EmptyMessage, SkeletonLoader } from "commons"

const WrapUpSection: FC<PropsWithChildren<Props>> = ({
  sectionTitle,
  subSectionTitle,
  className,
  isLoading,
  isEmpty,
  emptyMesage,
  icon = faSearch,
  children,
  showEmptyMessage = false,
  containerClassName,
  inlineContent,
}) =>
  !isLoading && isEmpty && !showEmptyMessage ? null : (
    <div className={classNames("grid grid-cols-3 pt-6 last:pb-2", containerClassName)}>
      {sectionTitle && <span className="sticky top-0 z-20 bg-white text-gray-900">{sectionTitle}</span>}
      {subSectionTitle && (
        <div className="sticky top-5 z-10 col-span-full inline-flex items-center space-x-2 bg-white">
          <span className="font-light whitespace-nowrap text-gray-500">{subSectionTitle}</span>
          <hr className="h-0.5 w-full rounded-full bg-gray-100" />
        </div>
      )}

      {isLoading ? (
        <SkeletonLoader repeats={2} loaderType="two-lines" containerClassName="col-span-full" />
      ) : isEmpty ? (
        <EmptyMessage
          message={emptyMesage}
          icon={icon}
          subMessage={false}
          className="col-span-full py-2"
          addButtonStyle="outlined"
        />
      ) : (
        <div
          className={classNames(
            "w-full text-sm",
            inlineContent ? "col-span-2 col-start-2" : "col-span-full col-start-1",
            className,
          )}
        >
          {children}
        </div>
      )}
    </div>
  )

type Props = {
  sectionTitle?: string
  subSectionTitle?: string
  className?: string
  isLoading?: boolean
  isEmpty?: boolean
  emptyMesage?: string
  icon?: IconDefinition
  showEmptyMessage?: boolean
  containerClassName?: string
  inlineContent?: boolean
}

export { WrapUpSection }
