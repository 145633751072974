import { useQuery } from "@tanstack/react-query"
import { type AuditEvent, type CommunicationRequest, getResources, getResourcesByTypeAsIndex } from "fhir"
import { useMemo } from "react"
import { format } from "date-fns"

import { useClient } from "api"
import { formatsByTypes } from "data"

import { patientsQueryKeys } from "../query-keys"

const usePatientInvitation = (patientId: string, listAll?: boolean) => {
  const { search } = useClient()
  const queryKey = patientsQueryKeys.invitations.list(patientId, listAll)

  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        category: "invitation",
        ...(!listAll ? { occurrence: `ge${format(new Date(), formatsByTypes.ISO_8601_DATE)}` } : {}),
        _count: !listAll ? "1" : "100",
        _sort: "-createdAt",
        _elements: "status, id, occurrence",
        _revinclude: "AuditEvent:entity:CommunicationRequest",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/CommunicationRequest`, filters, signal })
      const invitation = getResources<CommunicationRequest>(bundle, "CommunicationRequest")
      const auditEvents = getResourcesByTypeAsIndex<AuditEvent>(
        bundle,
        "AuditEvent",
        ({ entity }) => entity?.[0]?.what?.id,
      )

      return { invitation, auditEvents }
    },
    meta: { context: { queryKey, patientId } },
  })

  const invitationsByDate = useMemo(() => {
    const initialMap = new Map<
      string,
      (Pick<CommunicationRequest, "status" | "id"> & { email?: string; recorded?: string; expires?: string })[]
    >()
    return (
      data?.invitation.reduce((map, { occurrence, status, id }) => {
        const auditEvent = data.auditEvents?.[id as string]
        const patientRefAgent = auditEvent?.agent?.find(({ requestor }) => !requestor)
        const email = patientRefAgent?.altId
        const date = auditEvent?.recorded && format(auditEvent?.recorded, formatsByTypes.ISO_8601_DATE)
        const invitesInDate = map.get(date as string)
        if (invitesInDate) invitesInDate.push({ id, expires: occurrence?.dateTime, status, email, recorded: date })
        else if (date) map.set(date, [{ id, expires: occurrence?.dateTime, status, email, recorded: date }])
        return map
      }, initialMap) ?? initialMap
    )
  }, [data])

  return {
    invitation: data?.invitation?.[0],
    invitationsByDate: Array.from(invitationsByDate.entries()).map(([date, entries]) => ({
      date,
      items: entries,
    })),
    isLoading,
    isFetching,
  }
}

export { usePatientInvitation }
