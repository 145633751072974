import { type Reference } from "fhir"
import { classNames } from "primereact/utils"
import { type FC } from "react"

import { AssessmentNote, AssessmentNoteType } from "assessments"
import { ModulesId, SkeletonLoader } from "commons"
import { useAppModuleContext } from "internals"

import { EncounterDataProvider } from "../../context/EncounterContext"
import { useEncounterData } from "../../hooks"
import { encounterQueryKeys } from "../../query-keys"
import IntakeWrapUp from "./IntakeWrapUp"
import { LabsSection } from "./LabsSection"
import { MedsSection } from "./MedsSection"
import { NutrasSection } from "./NutrasSection"
import { PlansSection } from "./PlansSection"
import { ProceduresSection } from "./ProceduresSection"
import { QuestionnairesSection } from "./QuestionnairesSection"
import { WrapUpSection } from "./WrapUpSection"
import { LabResultsNotesSection } from "./LabResultsNotesSection"

const EncounterWrapUp: FC<Props> = ({ encounterId, patientId, patientRef, organizationId, className }) => {
  const { appModules, isModuleActive } = useAppModuleContext()

  const {
    encounterData,
    encounterRef,
    totalAllergies,
    totalConditions,
    totalEnteredLabs,
    totalFH,
    totalLabOrders,
    totalLabResultsWithNotes,
    totalMedStatements,
    totalPlans,
    totalProcedures,
    totalQs,
    totalSocialHistory,
    totalVitals,
    isLoading,
  } = useEncounterData(patientId, encounterId, organizationId)

  if (isLoading)
    return (
      <SkeletonLoader
        loaderType="two-lines"
        repeats={6}
        containerClassName={classNames("flex flex-col gap-y-6 px-2 @container", className)}
      />
    )

  return (
    <EncounterDataProvider
      encounterData={encounterData}
      totalAllergies={totalAllergies}
      totalConditions={totalConditions}
      totalEnteredLabs={totalEnteredLabs}
      totalFH={totalFH}
      totalMedStatements={totalMedStatements}
      totalSocialHistory={totalSocialHistory}
      totalVitals={totalVitals ?? 0}
      totalLabOrders={totalLabOrders}
      totalPlans={totalPlans}
      totalProcedures={totalProcedures}
      totalQs={totalQs}
      totalLabResultsWithNotes={totalLabResultsWithNotes}
    >
      <div className={classNames("@container flex flex-col divide-y divide-gray-200 px-2 *:pb-6", className)}>
        {encounterData?.reasonCode?.[0]?.text && (
          <WrapUpSection
            sectionTitle="Chief complaint"
            className="flex flex-row"
            containerClassName="first:border-t border-gray-200"
            inlineContent
          >
            <p className="w-full pr-7 text-sm">{encounterData?.reasonCode?.[0]?.text}</p>
          </WrapUpSection>
        )}
        {isModuleActive(ModulesId.INTAKE) && <IntakeWrapUp />}
        {isModuleActive(ModulesId.LABSORDER) && (
          <>
            <LabsSection
              encounterId={encounterId}
              patientId={patientId}
              icon={appModules[ModulesId.LABSORDER]?.getIcon()}
            />
            <LabResultsNotesSection
              encounterId={encounterId}
              patientId={patientId}
              icon={appModules[ModulesId.LABSORDER]?.getIcon()}
            />
          </>
        )}
        {isModuleActive(ModulesId.SURVEY) && <QuestionnairesSection icon={appModules[ModulesId.SURVEY]?.getIcon()} />}
        {isModuleActive(ModulesId.PROCEDURES) && (
          <ProceduresSection
            encounter={encounterId}
            patientId={patientId}
            icon={appModules[ModulesId.PROCEDURES]?.getIcon()}
          />
        )}
        {isModuleActive(ModulesId.EPRESCRIBE) && (
          <MedsSection
            encounterId={encounterId}
            patientId={patientId}
            icon={appModules[ModulesId.EPRESCRIBE]?.getIcon()}
          />
        )}
        {isModuleActive(ModulesId.MEDICATIONR) && (
          <NutrasSection
            encounterId={encounterId}
            patientId={patientId}
            icon={appModules[ModulesId.MEDICATIONR]?.getIcon()}
          />
        )}
        {isModuleActive(ModulesId.PLANS) && <PlansSection icon={appModules[ModulesId.PLANS]?.getIcon()} />}
        <WrapUpSection sectionTitle="Assessment" className="flex w-full flex-row text-sm" inlineContent>
          <AssessmentNote
            assessment={encounterData?.assessment}
            encounterRef={encounterRef}
            patientRef={patientRef}
            queriesToInvalidate={encounterQueryKeys.data(patientId, encounterId)}
            className="@container w-full !flex-initial"
            type={AssessmentNoteType.ASSESSMENT}
          />
        </WrapUpSection>
        <WrapUpSection sectionTitle="Plan" className="flex w-full flex-row text-sm" inlineContent>
          <AssessmentNote
            assessment={encounterData?.assessment}
            encounterRef={encounterRef}
            patientRef={patientRef}
            queriesToInvalidate={encounterQueryKeys.data(patientId, encounterId)}
            className="@container w-full !flex-initial"
            type={AssessmentNoteType.PLAN}
          />
        </WrapUpSection>
      </div>
    </EncounterDataProvider>
  )
}

type Props = {
  encounterId: string
  patientId: string
  patientRef: Reference
  organizationId: string
  className?: string
}

export { EncounterWrapUp }
