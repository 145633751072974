import { faCropAlt } from "@fortawesome/pro-regular-svg-icons"
import { Dialog } from "primereact/dialog"
import { FileUpload } from "primereact/fileupload"
import { useEffect, useRef, useState } from "react"
import { type Crop, Component as ReactCrop } from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"

import defaultAvatar from "assets/default-user.png"

import { useAzureContainer } from "../hooks"
import { Button } from "./Buttons"

const AvatarCrop = ({ visible, onHide, container, userId, onUpload }: Props) => {
  const { uploadFile, isUploading } = useAzureContainer(container)

  const fileInput = useRef<FileUpload>(null)

  const [file, setFile] = useState<string>()
  const [crop, setCrop] = useState<Crop>()
  const [image, setImage] = useState<HTMLImageElement>()

  useEffect(() => {
    setFile(undefined)
    setCrop(undefined)
    setImage(undefined)
  }, [visible])

  useEffect(() => {
    const size = Math.min(image?.width ?? 100, image?.height ?? 100)
    setCrop({ unit: "px", x: 0, y: 0, width: size, height: size })
  }, [image])

  const cropImage = (upload = false) => {
    if (image && crop) {
      const canvas = document.createElement("canvas")
      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height
      canvas.width = canvas.height = upload ? Math.min(crop.width, 192) : crop.width
      const canvasContext = canvas.getContext("2d")
      canvasContext?.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height,
      )
      if (upload) {
        canvas.toBlob((blob) => {
          blob &&
            uploadFile({ file: new File([blob], `${userId}.png`) }).then((url) => {
              onUpload(url)
              onHide()
            })
        })
      } else {
        setFile(canvas.toDataURL())
        setCrop({ unit: "px", x: 0, y: 0, width: canvas.width, height: canvas.height })
      }
    }
  }

  const modalFooter = (
    <div className="flex w-full justify-end border-t border-gray-200 pt-5">
      <Button label="Cancel" buttonStyle="default" onClick={onHide} disabled={isUploading} />
      <Button
        label="Crop"
        icon={faCropAlt}
        disabled={!crop || crop.width === 0 || isUploading}
        onClick={() => cropImage()}
      />
      <Button
        label="Crop & Upload"
        disabled={!crop || crop.width === 0 || isUploading}
        loading={isUploading}
        onClick={() => cropImage(true)}
      />
    </div>
  )

  return (
    <Dialog
      id="avatarCrop"
      visible={visible}
      onHide={onHide}
      draggable={false}
      className="h-full w-full lg:h-fit lg:w-1/3"
      header="Update avatar photo"
      footer={modalFooter}
    >
      <div className="m-5 flex flex-col items-center justify-center">
        {file ? (
          <>
            <ReactCrop crop={crop} onChange={(c) => setCrop(c)} aspect={1} circularCrop>
              <img src={file} onLoad={(e) => setImage(e.currentTarget)} alt="" />
            </ReactCrop>
          </>
        ) : (
          <img src={defaultAvatar} className="h-1/2 rounded-full opacity-50" alt="" />
        )}
        <FileUpload
          ref={fileInput}
          className="mt-8 text-xs"
          mode="basic"
          chooseOptions={{ label: "Select a picture", icon: "pi pi-camera" }}
          accept="image/*"
          customUpload
          disabled={isUploading}
          onSelect={(e) => {
            setCrop(undefined)
            setImage(undefined)
            setFile(URL.createObjectURL(e.files[0]))
            fileInput.current?.clear()
          }}
        />
      </div>
    </Dialog>
  )
}

type Props = {
  visible: boolean
  onHide(): void
  container: string
  userId: string
  onUpload(url: string): void
}

export { AvatarCrop }
