import type { FC } from "react"

import { EditorField, InputField } from "commons"

const AssessmentForm: FC = () => (
  <div className="p-fluid relative mb-3 grid gap-4">
    <div className="flex justify-between space-x-6">
      <InputField field="title" label="Title" className="flex-1" />
    </div>
    <div className="flex justify-between space-x-6">
      <EditorField field="section[0].text.div" label="Notes" className="flex-1" inputClassName="h-full min-h-96" />
    </div>
  </div>
)

export { AssessmentForm }
