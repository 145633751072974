import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { QueryKey } from "@tanstack/react-query"
import { asReference, type Annotation, type Reference } from "fhir"
import { classNames } from "primereact/utils"
import { type FC } from "react"

import { useLoginContext } from "security"

import { DataContainerForm, MentionTextAreaField } from "../forms"
import { useCrudReducer, useUpdateMedicationRequest, useUpdateOrder } from "../hooks"
import { useUpdateProcedure } from "../procedures"
import { noteValidationSchema } from "./validation"
import type { NotesUseContext } from "./Notes"
import { NoteItem } from "./NoteItem"
import { usePatchObservation } from "../labs"

const InlineNoteContainer: FC<Props> = ({
  parentId: id,
  context,
  orderNote = [],
  queriesToInvalidate,
  className,
  contentClassName,
  inputRows = 10,
  buttonClassName,
  notShowFullDataNotFound = false,
  encounter,
  forceSingleNote,
}) => {
  const { loggedInPractitioner } = useLoginContext()

  const {
    initialValue,
    reset,
    showSlide: showForm,
    editWithIndex: edit,
    editIndex,
    add,
  } = useCrudReducer({
    defaultEntity: {
      text: "",
      author: {
        Reference: asReference(loggedInPractitioner),
      },
    } as Annotation,
  })

  const { updateOrder } = useUpdateOrder(reset, queriesToInvalidate)
  const { updateMedicationRequest } = useUpdateMedicationRequest(reset, queriesToInvalidate)
  const { updateProcedure } = useUpdateProcedure(reset, queriesToInvalidate)
  const { patchObservation } = usePatchObservation(queriesToInvalidate)

  const updateMutation = (data: { id: string; note: Annotation[] }) => {
    switch (context) {
      case "MedicationRequest":
        updateMedicationRequest(data)
        break
      case "Procedure":
        updateProcedure(data)
        break
      case "ServiceRequest":
        updateOrder(data)
        break
      case "Observation":
        patchObservation({ ...data, encounter }, { onSettled: reset })
        break
      default:
        console.error(`Context ${context} not supported`)
    }
  }

  const onSubmit = (note: Annotation) => {
    const currentData = { id, note: [...(orderNote.length > 0 ? orderNote : [])] }

    if (editIndex !== undefined) {
      if (note?.text === currentData?.note[editIndex]?.text) {
        reset()
        return
      }
      currentData.note[editIndex] = note
    } else {
      currentData.note.push(note)
    }

    updateMutation(currentData)
  }

  const onRemoveNote = (index: number) => {
    const currentData = { id, note: [...(orderNote.length > 0 ? orderNote : [])] }
    currentData.note.splice(index, 1)
    updateMutation(currentData)
  }

  return (
    <DataContainerForm
      form={<MentionTextAreaField field="text" className="p-1" rows={inputRows} autoFocus />}
      formInitialValue={initialValue}
      onSubmit={onSubmit}
      onCancel={reset}
      enableReinitialize
      disableSave={false}
      showForm={showForm}
      hasData={notShowFullDataNotFound ? true : !!orderNote?.length}
      itemTitle="note"
      itemTitleNotFound="note"
      customEmptyAddButtonText="Add note"
      cancelButtonLabel="Cancel"
      className={className}
      onButtonAddClick={add}
      subMessageDataNotFound="Get started by adding a new note"
      customAddButton={
        <div
          className={classNames(
            "mt-1 inline-flex shrink-0 cursor-pointer items-center gap-1 pr-2 pl-1 text-sm text-primary hover:text-primary-hover",
            buttonClassName,
          )}
          onClick={add}
        >
          <FontAwesomeIcon className="fa-fw" icon={faCirclePlus} />
          <span className="text-sm">Add Note</span>
        </div>
      }
      containerClassName="!overflow-visible"
      disableContainerClipping
      validationSchema={noteValidationSchema}
      innerContainerClassName="mb-4"
      footerClassName="!pb-2 !mb-0"
      saveButtonStyle="outlined"
      showCustomButton={forceSingleNote ? !orderNote?.length : true}
    >
      {!!orderNote?.length && (
        <div className={classNames("flex flex-col gap-y-4", contentClassName)}>
          {orderNote?.map((note, index) => (
            <NoteItem
              key={note.id ?? index}
              note={note}
              index={index}
              onRemoveNote={onRemoveNote}
              onEditNote={edit}
              loggedInPractitionerId={loggedInPractitioner?.id as string}
            />
          ))}
        </div>
      )}
    </DataContainerForm>
  )
}

type Props = {
  parentId: string
  context: NotesUseContext
  orderNote: Annotation[] | undefined
  className?: string
  contentClassName?: string
  inputRows?: number
  queriesToInvalidate: QueryKey
  buttonClassName?: string
  notShowFullDataNotFound?: boolean
  encounter?: Reference
  forceSingleNote?: boolean
}

export { InlineNoteContainer }
