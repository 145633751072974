import { codeableConceptAsString } from "fhir"
import type { FC } from "react"
// eslint-disable-next-line import/no-named-as-default
import DOMPurify from "dompurify"

import { useOpenEncounter } from "encounter"
import { useOrganizationContext } from "organization"

import type { CalculatorOutput } from "../../types"
import { RecommendationsContainer } from "./recomendations/RecommendationsContainer"
import { RxRecomendations } from "./recomendations/RxRecomendations"

const CalculatorRecomendations: FC<Props> = ({ patientId, notes, recommended, supplements, planId }) => {
  const { currentOrganizationId, loggedInPractitionerRole, organizationPractitionersInfo } = useOrganizationContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  return (
    <>
      <div className="flex flex-col">
        <div className="font-medium text-gray-900">Recommended dosage</div>
        {recommended.map(({ codeableConcept, dosage }, index) => (
          <li key={index} className="text-gray-700">
            {`${codeableConceptAsString(codeableConcept)}: ${dosage.text}`}
          </li>
        ))}
      </div>
      <div className="flex flex-col">
        <div className="font-medium text-gray-900">Considerations</div>
        {notes.map((note, index) => (
          <li
            key={index}
            className="editor-note space-x-6 text-gray-700"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(note.text, { ALLOWED_ATTR: ["class", "style", "src", "href"] }),
            }}
          />
        ))}
      </div>
      {!!supplements?.length && (
        <RecommendationsContainer
          header={<div className="font-medium text-gray-900">Recommended supplements</div>}
          supplements={supplements}
          defaultPerformer={{ id: "ERRNOTDEFINED", resourceType: "Organization" }}
          origanizationId={currentOrganizationId}
          practitionerRole={loggedInPractitionerRole}
          practitionersInfo={organizationPractitionersInfo}
          className="grow"
          openEncounter={openEncounterRef}
          recommendationClassName=""
        />
      )}
      <RxRecomendations planId={planId} />
    </>
  )
}

type Props = Omit<CalculatorOutput, "suggestedMeds"> & { patientId: string; planId?: string }

export { CalculatorRecomendations }
