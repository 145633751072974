import {
  type ChargeItem,
  type Invoice,
  type Parameters,
  type ServiceRequest,
  getResource,
  getResources,
  isServiceRequest,
} from "fhir"
import { Tooltip } from "primereact/tooltip"
import { useCallback, useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { Button, ModulesId } from "commons"
import { getInvoiceData } from "orders"
import { displayNotificationSuccess } from "utils"

import { useMrOrderContext, useUpdateMrOrder } from "../../hooks"
import { EDIT_ORDER_STEPS } from "../../types"

const MedicationOrderEditFooter = () => {
  const { showModule } = useChartContext()
  const { currentStep, moveStep, serviceRequest, editedMedicationRequests, markedToDeleteMeds, invoice, isEditable } =
    useMrOrderContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const isFirstStep = currentStep === EDIT_ORDER_STEPS.CONFIG
  const isLastStep = currentStep === EDIT_ORDER_STEPS.PREVIEW

  const { updateOrder, isUpdating } = useUpdateMrOrder()

  const handleClose = () => {
    searchParams.delete("edit-order")
    setSearchParams(searchParams)
  }

  const handleUpdateOrder = useCallback(() => {
    updateOrder(
      {
        orderId: serviceRequest?.id ?? "",
        medicationRequests: Array.from(editedMedicationRequests.values()),
        transient: true,
        markedToDelete: markedToDeleteMeds,
      },
      {
        onSuccess: (data) => {
          const invoice = getResource<Invoice>(data, "Invoice")
          const chargeItems = getResources<ChargeItem>(data, "ChargeItem")
          const summary = getResource<Parameters>(data, "Parameters")

          moveStep({ previous: false, invoiceData: { invoice, chargeItems, summary, ...getInvoiceData(invoice) } })
        },
      },
    )
  }, [markedToDeleteMeds, editedMedicationRequests])

  const handleFinishOrder = useCallback(() => {
    updateOrder(
      {
        orderId: serviceRequest?.id ?? "",
        medicationRequests: Array.from(editedMedicationRequests.values()),
        transient: false,
        markedToDelete: markedToDeleteMeds,
      },
      {
        //Handle redirect to new order
        onSuccess: (bundle, { orderId }) => {
          const order = getResources<ServiceRequest>(bundle, "ServiceRequest").find(({ replaces }) =>
            replaces?.some((item) => isServiceRequest(item) && item.id === orderId),
          )
          displayNotificationSuccess("Order updated successfully!")
          showModule({
            module: ModulesId.EPRESCRIBE,
            moduleParams: { subview: "history", order: order?.id ?? orderId },
          })
        },
      },
    )
  }, [markedToDeleteMeds, editedMedicationRequests])

  useEffect(() => {
    if (!serviceRequest?.id || !invoice || !isEditable) {
      handleClose()
    }
  }, [serviceRequest?.id, invoice, isEditable])

  return (
    <div className="mt-3 flex shrink-0 justify-end gap-3 border-t-2 border-gray-200 px-1 py-4">
      <div className="flex items-center justify-end">
        <Button type="button" label="Close" buttonStyle="default" className="mr-2" size="xl" onClick={handleClose} />
        {!isFirstStep && (
          <Button
            type="button"
            label="Previous"
            buttonStyle="default"
            className="mr-2"
            size="xl"
            onClick={() => moveStep({ previous: true })}
          />
        )}
        {!isLastStep ? (
          <span className="tooltiped">
            <Button
              type="button"
              label="Next"
              buttonStyle="primary"
              className="mr-2"
              size="xl"
              loading={isUpdating}
              disabled={!editedMedicationRequests.size && !markedToDeleteMeds?.length}
              onClick={handleUpdateOrder}
            />
          </span>
        ) : (
          <Button
            type="button"
            label="Finish"
            buttonStyle="primary"
            className="mr-2"
            size="xl"
            loading={isUpdating}
            disabled={!editedMedicationRequests.size}
            onClick={handleFinishOrder}
          />
        )}
        <Tooltip
          target=".tooltiped"
          event="hover"
          position="left"
          content="Please edit at least one prescription to continue"
          disabled={!!editedMedicationRequests.size}
        />
      </div>
    </div>
  )
}

export { MedicationOrderEditFooter }
