import { useFavicon } from "primereact/hooks"

import { useOrganizationContext } from "organization"

const ApplySettings = () => {
  const { branding, currentOrganization } = useOrganizationContext()

  useFavicon(branding.iconLogo ?? "", "icon")
  const hslValues = (hslColor: string, forHover = false) => {
    const values = hslColor
      .replace("%", "")
      .split(" ")
      .map((v) => parseInt(v))

    if (forHover) {
      return [values[0], values[1] - 7, values[2] > 20 ? values[2] - 7 : values[2] - 3]
    }

    return values
  }

  const applyBrandingColor = (colorName: string, brandingColor?: string) => {
    if (brandingColor && new RegExp(/^\d+deg \d+% \d+%$/g).test(brandingColor)) {
      const brandingColorHsl = hslValues(brandingColor)
      const brandingColorHslHover = hslValues(brandingColor, true)

      document.body.style.setProperty(
        `--color-${colorName}`,
        `hsl(${brandingColorHsl[0]}deg ${brandingColorHsl[1]}% ${brandingColorHsl[2]}%)`,
      )
      document.body.style.setProperty(
        `--color-${colorName}-hover`,
        `hsl(${brandingColorHslHover[0]}deg ${brandingColorHslHover[1]}% ${brandingColorHslHover[2]}%)`,
      )
    }
  }

  applyBrandingColor("primary", branding.primaryColor)
  applyBrandingColor("secondary", branding.secondaryColor)
  applyBrandingColor("photo-bg-color", branding.photoBgColor)

  if (currentOrganization.name) document.title = currentOrganization.name

  return null
}

export { ApplySettings }
