import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { MedicationStatement } from "fhir"
import { type Dispatch, type FC, type ReactNode, type SetStateAction, useEffect } from "react"

import { classNames } from "primereact/utils"

import {
  AddFieldArrayItemButton,
  DialogFormContainer,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
  useCrudReducer,
} from "commons"
import { useOpenEncounter } from "encounter"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

import { useCreateMedicationStatement, useMedicationsStatement } from "../hooks"
import { MedicationStatementForm } from "./MedicationStatementForm"
import { medicationModel } from "./medStatementModuelBuilder"
import { getInitialValues, medicationStatementValidationSchema, sanitize } from "./validations"

const PatientMedicationStatements: FC<Props> = ({
  onSubmit,
  queryData,
  header,
  className = "border-t border-gray-200 mt-6 pt-4",
  title = "Patient medication statements",
  forceReset,
  supportingInfo,
}) => {
  const { patientId, patientRef } = usePatientContext()
  const { appSubModules } = useAppModuleContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { showSlide, initialValue, reset, add } = useCrudReducer({
    defaultEntity: getInitialValues(patientRef, openEncounterRef),
  })

  const { medications, isLoading } = useMedicationsStatement({ patientId, enabled: !queryData })
  const { createMedication } = useCreateMedicationStatement(reset)

  const handleSubmit = (medication: MedicationStatement) => {
    if (onSubmit) onSubmit(medication)
    else createMedication(sanitize(medication))
  }

  useEffect(() => {
    if (forceReset?.reset) {
      reset()

      forceReset?.setReset(false)
    }
  }, [forceReset])

  return (
    <div className={classNames("flex flex-col", className)}>
      {header ?? <span className="text-gray-900">{title}</span>}
      {queryData?.isLoading || isLoading ? (
        <SkeletonLoader repeats={2} loaderType="two-lines" />
      ) : (
        <div className="flex flex-1 flex-col overflow-y-auto bg-white">
          {queryData?.medications?.length || medications?.length ? (
            <StackedListContainer
              data={queryData?.medications ?? medications ?? []}
              itemModelBuilder={(item) => medicationModel(item)}
            />
          ) : (
            <div className="m-auto text-center">
              <FontAwesomeIcon
                icon={appSubModules[ModulesId.INTAKE][ModulesId.MEDICATIONE].getIcon()}
                size="2x"
                className="text-slate-400"
              />
              <h3 className="mt-2 text-sm font-semibold text-gray-900">No medications found</h3>
            </div>
          )}
          <AddFieldArrayItemButton label={`Add ${title.toLocaleLowerCase()}`} onClick={add} />
        </div>
      )}
      <DialogFormContainer
        title={`Add ${title.toLocaleLowerCase()}`}
        showForm={showSlide}
        onSubmit={handleSubmit}
        onCancel={reset}
        initialValue={initialValue}
        validationSchema={medicationStatementValidationSchema}
        useFormik
        supportingInfo={supportingInfo}
      >
        <MedicationStatementForm />
      </DialogFormContainer>
    </div>
  )
}

type Props = {
  title?: string
  header?: ReactNode
  className?: string
  onSubmit?(medication: MedicationStatement): void
  queryData?: { medications?: MedicationStatement[]; isLoading?: boolean }
  forceReset?: { reset: boolean; setReset: Dispatch<SetStateAction<boolean>> }
  supportingInfo?: ReactNode
}

export { PatientMedicationStatements }
