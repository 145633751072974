import { usePatientContext } from "patients"

import type { BodyZones } from "../../types"
import { BodyPoint } from "./svg_data/BodyPoint"
import { PelletSelection } from "./svg_data/PelletSelection"
import { manBodyPoints, manHeadPoints, womanBodyPoints, womanHeadPoints } from "./svg_data/data"
import manFace from "./svg_data/man-silhouette-face.svg"
import manBody from "./svg_data/man-silhouette-front-back.svg"
import womanFace from "./svg_data/woman-silhouette-face.svg"
import womanBody from "./svg_data/woman-silhouette-front-back.svg"

const BodySites = ({ isPellectSelection, bodyZone, selectedPoint, disabled, onSelectPoint, showPointOrder }: Props) => {
  const {
    patient: { gender },
  } = usePatientContext()
  const bodySvg =
    bodyZone === "full-body" || isPellectSelection
      ? gender === "male"
        ? manBody
        : womanBody
      : gender === "male"
        ? manFace
        : womanFace
  const points =
    bodyZone === "full-body"
      ? gender === "male"
        ? manBodyPoints.split("|")
        : womanBodyPoints.split("|")
      : gender === "male"
        ? manHeadPoints.split("|")
        : womanHeadPoints.split("|")

  return (
    <>
      <img src={bodySvg} className="absolute top-0 aspect-square bg-cover focus:shadow-none" alt={bodyZone} />
      <svg
        viewBox="0 0 1000 1000"
        preserveAspectRatio="xMidYMid meet"
        className="absolute top-0 aspect-square focus:shadow-none"
      >
        {isPellectSelection ? (
          <PelletSelection
            gender={gender as string}
            selectedPoint={selectedPoint}
            onSelectPoint={onSelectPoint}
            disabled={(selectedPoint?.length as number) > 1}
          />
        ) : (
          <>
            {points.map((pi, index) => {
              const coords = pi.split(":")
              return (
                <BodyPoint
                  key={pi + index}
                  x={coords[0]}
                  y={coords[1]}
                  bodyZone={bodyZone}
                  code={pi}
                  selectedPoint={selectedPoint}
                  onSelectPoint={onSelectPoint}
                  disabled={disabled}
                  showPointOrder={showPointOrder}
                />
              )
            })}
          </>
        )}
      </svg>
    </>
  )
}

type Props = {
  isPellectSelection: boolean
  bodyZone: BodyZones
  selectedPoint?: string[]
  onSelectPoint?(point: string): void
  disabled?: boolean
  showPointOrder?: boolean
}

export { BodySites }
