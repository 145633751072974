import { faLink, faSpinner } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { MedicationKnowledge, MedicationRequest } from "fhir"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import type { FC, PropsWithChildren } from "react"

import { BILLING_TYPES_CODES, MEDICATION_PRODUCT_TYPE, mrCategoryCodes } from "data"
import { MedicationKnowledgeImage } from "medication-requests"
import { useOrganizationContext } from "organization"
import { SYSTEM_VALUES } from "system-values"
import { getBillingTypeCode, getCodingBySystem, openLinkInNewTab } from "utils"

import { GENERIC_BILLING_TYPE } from "commons/types"
import { SkeletonLoader } from "../../components/SkeletonLoader"
import { useProductPrices } from "../../hooks"
import { getMedsProductConfigurations, getPriceByCode } from "../../utils"
import { useMonograph } from "../hooks"
import { MedicationKnowledgeContraindications } from "./MedicationKnowledgeContraindications"

const MedicationKnowledgeDetails: FC<PropsWithChildren<Props>> = ({
  selectedMK,
  onHide,
  showImgFallback,
  mr,
  children: extraContent,
}) => {
  const { currentOrganizationId, hasCreditCard } = useOrganizationContext()
  const { monograph, isLoading } = useMonograph(selectedMK?.monograph?.[0].source?.id ?? "")
  const medsProductConfigurations = getMedsProductConfigurations({
    meds: [...(selectedMK ? [selectedMK] : []), ...(mr ? [mr] : [])],
    specifiedQuantity: true,
  })
  const { productPrices, isLoading: isLoadingProductPrices } = useProductPrices({
    organizationId: currentOrganizationId,
    productsConfigurations: medsProductConfigurations,
  })
  const isRx = selectedMK?.productType?.[0]?.coding?.[0]?.code === MEDICATION_PRODUCT_TYPE.RX
  const lfID = selectedMK?.code?.coding?.find((c) => c.system === SYSTEM_VALUES.LIFEFILE_MEDICATION)?.code
  const ndc = selectedMK?.code?.coding?.find((c) => c.system === SYSTEM_VALUES.MEDICATION_NDC)?.code
  const doseForm = selectedMK?.doseForm?.coding?.[0]?.display
  const route = selectedMK?.intendedRoute?.[0]?.coding?.[0]?.display?.replace(/use|route/, "")?.trim()

  const patientPrice =
    getPriceByCode({
      productPrices,
      medCoding: selectedMK?.code?.coding,
      quantity: mr?.dispenseRequest?.quantity?.value,
      billingType: GENERIC_BILLING_TYPE.BILL_PATIENT,
    })?.value ?? 0

  const practicePrice =
    getPriceByCode({
      productPrices,
      medCoding: selectedMK?.code?.coding,
      quantity: mr?.dispenseRequest?.quantity?.value,
      billingType: GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE,
    })?.value ?? 0

  const packing = selectedMK?.packaging?.type?.coding?.[0]?.display ?? ""
  const flavor = selectedMK?.drugCharacteristic?.find((c) =>
    c.type?.coding?.some((coding) => coding.system === SYSTEM_VALUES.DRUG_CHARACTERISTIC),
  )?.value?.string
  const billingTypeCode = getBillingTypeCode(mr)
  const amount =
    selectedMK?.amount?.value && selectedMK?.amount?.unit
      ? `${selectedMK?.amount?.value} ${selectedMK?.amount?.unit}`
      : "unspecified"

  const handleShowMonographPDF = (url?: string) => {
    openLinkInNewTab(url)
  }
  const renderModalFooter = () => (
    <div className="mt-2">
      <Button label="Close" className="button-default" onClick={onHide} />
    </div>
  )

  return (
    <Dialog
      header={
        <div className="flex justify-between sm:max-xl:flex-col">
          <div className="flex flex-col gap-1">
            <span>{selectedMK?.code?.text}</span>
            <div className="flex gap-1 divide-x divide-gray-200 text-sm font-normal text-gray-400">
              {selectedMK?.manufacturer?.display && (
                <span className="pl-1 first:pl-0">
                  Pharmacy: {selectedMK.manufacturer.display.replace("Farmakeio", "FarmaKeio")}
                </span>
              )}
              {lfID && <span className="pl-1 first:pl-0">ID: {lfID}</span>}
              {ndc && <span className="pl-1 first:pl-0">NDC: {ndc}</span>}
            </div>
            <div className="flex gap-1 divide-x divide-gray-200 text-sm font-normal text-gray-400">
              {doseForm && <span className="pl-1 first:pl-0">Drug Form: {doseForm}</span>}
              {<span className="pl-1 first:pl-0">Amount: {amount}</span>}
              {packing && <span className="pl-1 first:pl-0">Pkg: {packing}</span>}
              {route && <span className="pl-1 first:pl-0">Route: {route}</span>}
              {route?.toLowerCase()?.includes("oral") && (
                <span className="pl-1 first:pl-0">Flavor: {flavor ? `Flavor: ${flavor}` : "No Flavor"}</span>
              )}
            </div>
          </div>
          {!isRx && (
            <div className="mr-4 flex flex-col pt-1">
              {isLoadingProductPrices ? (
                <SkeletonLoader loaderType="plain-text" repeats={1} containerClassName="w-28" />
              ) : (
                <>
                  {Boolean(patientPrice) && billingTypeCode !== BILLING_TYPES_CODES.BILL_PRACTICE && (
                    <div className="flex flex-row justify-end gap-1 text-sm font-normal text-gray-500 sm:max-xl:justify-start">
                      <span className="flex flex-none">{!hasCreditCard ? "" : "Patient "}Price:</span>
                      <span>${patientPrice.toFixed(2)}</span>
                    </div>
                  )}
                  {hasCreditCard &&
                    Boolean(practicePrice) &&
                    billingTypeCode !== BILLING_TYPES_CODES.BILL_PATIENT &&
                    getCodingBySystem(mr?.category, SYSTEM_VALUES.MEDICATION_REQUEST_CATEGORY)?.code !==
                      mrCategoryCodes.nutraceutical.code && (
                      <div className="flex flex-row justify-end gap-1 text-sm font-normal text-gray-500 sm:max-xl:justify-start">
                        <span className="flex flex-none">Practice Price:</span>
                        <span>${practicePrice.toFixed(2)}</span>
                      </div>
                    )}
                </>
              )}
            </div>
          )}
        </div>
      }
      visible={selectedMK !== undefined}
      draggable={false}
      dismissableMask={true}
      style={{ width: "50vw" }}
      onHide={onHide}
      footer={renderModalFooter}
      closable
    >
      <div className="flex flex-col">
        <div className={selectedMK?.drugCharacteristic || showImgFallback ? "p-2" : ""}>
          <MedicationKnowledgeImage
            drugCharacteristic={selectedMK?.drugCharacteristic}
            className="float-left mr-3 w-96"
            showAll
            showFallback={showImgFallback}
          />
          <p>{selectedMK?.text?.div}</p>
        </div>

        {selectedMK?.administrationGuidelines && (
          <div className="my-2">
            <h3 className="font-medium uppercase">Directions:</h3>
            <div className="flex flex-col gap-2 divide-y divide-gray-200 text-sm">
              {selectedMK?.administrationGuidelines.map(({ dosage }, indexAG) => (
                <div key={`${indexAG}`} className="grid grid-cols-2 gap-2 pt-2 text-gray-500 first:pt-0">
                  {dosage?.map(({ dosage }, indexDT) =>
                    dosage.map((dosage, indexD) => <p key={`${indexAG}${indexDT}${indexD}`}>{dosage.text}</p>),
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {selectedMK?.ingredient && (
          <div className="my-2">
            <h1 className="font-bold uppercase">Ingredients:</h1>
            <div className="grid grid-flow-row-dense grid-cols-2 gap-x-6 gap-y-2 text-gray-500">
              {selectedMK.ingredient.map(({ item, strength }, index) => (
                <span key={index}>
                  {item?.CodeableConcept?.text}:{" "}
                  {!/\d/.test(strength?.numerator?.unit ?? "") && strength?.numerator?.value}{" "}
                  {strength?.numerator?.unit}
                </span>
              ))}
            </div>
          </div>
        )}
        {selectedMK?.contraindication && (
          <div className="my-2">
            <h1 className="font-medium uppercase">Contraindications:</h1>
            <MedicationKnowledgeContraindications contraindications={selectedMK?.contraindication} />
          </div>
        )}
        {selectedMK?.monograph && (
          <div className="my-2">
            <h1 className="font-medium uppercase">Monograph</h1>
            {isLoading ? (
              <span className="text-sm text-slate-500">
                <FontAwesomeIcon icon={faSpinner} spin />
                <span className="ml-1">loading monograph.</span>
              </span>
            ) : monograph ? (
              <span className="cursor-pointer text-sm text-blue-500 hover:underline">
                <FontAwesomeIcon icon={faLink} className="mr-1" />
                <span onClick={() => handleShowMonographPDF(monograph.content[0].attachment.url)}>
                  {monograph.content[0].attachment.title}
                </span>
              </span>
            ) : null}
          </div>
        )}
        {extraContent}
      </div>
    </Dialog>
  )
}

type Props = {
  selectedMK?: MedicationKnowledge
  showImgFallback?: boolean
  onHide(): void
  mr?: MedicationRequest
}

export { MedicationKnowledgeDetails }
