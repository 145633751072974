import { type FC } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faCalendar, faText } from "@fortawesome/pro-regular-svg-icons"
import { differenceInMinutes, format } from "date-fns"

import { Badge, Button } from "commons"
import { getBadgeColor } from "utils"
import { formatsByTypes } from "data"
import type { CalendarEventDetails } from "commons/types"

const SlotDetails: FC<Props> = ({ calendarEvent: { event, extraInfo }, onRelease }) => {
  const { type, status, color, slot } = extraInfo

  const duration = !!event.start && !!event.end && differenceInMinutes(event.end, event.start)

  return (
    <>
      <div className="flex items-center justify-between border-b border-gray-200 p-4">
        <h2 className="inline-flex items-center text-base font-semibold text-gray-700">
          <div className="mr-2 h-2 w-2 rounded-full" style={{ backgroundColor: color }} />
          {type}
        </h2>
        <Badge {...getBadgeColor(status)} />
      </div>

      <div className="grid grid-cols-2 gap-x-8 gap-y-4 p-6">
        <div className="space-y-2">
          <div className="flex items-center gap-2 text-gray-500">
            <FontAwesomeIcon icon={faCalendar} className="h-3 w-3" />
            Date
          </div>
          <span className="text-sm font-normal text-gray-700">
            {format(event.start as Date, formatsByTypes.SHORT_DATE)}
          </span>
        </div>

        {duration && (
          <div className="space-y-2">
            <div className="flex items-center gap-2 text-gray-500">
              <FontAwesomeIcon icon={faClock} className="h-3 w-3" />
              Time
            </div>
            <span>
              <div className="text-sm font-normal text-gray-700">
                {format(event.start as Date, formatsByTypes.SHORT_TIME)} -{" "}
                {format(event.end as Date, formatsByTypes.TIME)}
              </div>
              <div className="text-gray-500">{duration} minutes</div>
            </span>
          </div>
        )}
        <div className="col-span-full space-y-2">
          <div className="flex items-center gap-2 text-gray-500">
            <FontAwesomeIcon icon={faText} className="h-3 w-3" />
            Description
          </div>
          <span className="truncate text-sm font-normal text-gray-700">{slot?.comment ?? "No description"}</span>
        </div>
      </div>
      {onRelease && (
        <div className="flex items-center justify-end space-x-3 rounded-b-lg border-t border-gray-200 bg-white p-4">
          <Button buttonStyle="text" label="Release" className="text-slate-400" size="sm" onClick={onRelease} />
        </div>
      )}
    </>
  )
}

type Props = {
  calendarEvent: CalendarEventDetails
  onRelease?: () => void
}

export { SlotDetails }
