import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { Address, Coding } from "fhir"
import { useFormikContext } from "formik"
import { IconField } from "primereact/iconfield"
import { InputIcon } from "primereact/inputicon"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { useMemo } from "react"

import { useValueSet } from "value-set"

import { isPoBoxAddress } from "utils"
import type { SuggestionsEntity } from "../hooks/useSmartyAddressAutoCompletion"
import { ValueSetIds } from "../types"
import { AutoCompleteAddressField } from "./AutoCompleteAddressField"
import { DropdownField } from "./DropdownField"
import { InputField } from "./InputField"
import { addressTypes } from "./data"

const AddressField = ({
  parentFieldName,
  showAutoComplete = true,
  disabled = false,
  showTypeUseField = true,
  validAddressTypes,
  className = "col-span-2",
}: Props) => {
  const parentFieldFullName = parentFieldName ? parentFieldName + "." : ""

  const { codes: availableStates } = useValueSet({ valueSetId: ValueSetIds.CONTINENTAL_USA_STATES })

  const { values, setFieldValue } = useFormikContext<Address>()

  const isAddressPoBox = useMemo(() => isPoBoxAddress(values), [values])

  const handleAddressAutoComplete = (suggestion: SuggestionsEntity) => {
    Object.entries(suggestion).forEach(([key, value]) => {
      switch (key) {
        case "street_line": {
          setFieldValue(`${parentFieldFullName}line[0]`, value)
          break
        }

        case "secondary": {
          setFieldValue(`${parentFieldFullName}line[1]`, value)
          break
        }

        case "zipcode": {
          setFieldValue(`${parentFieldFullName}postalCode`, value)
          break
        }

        case "city":
          setFieldValue(`${parentFieldFullName}city`, value)
          break

        case "state": {
          setFieldValue(`${parentFieldFullName}state`, value)
          break
        }
      }
    })
  }

  return (
    <>
      <div className={classNames("@container w-full", className)}>
        <IconField iconPosition="right">
          {showAutoComplete ? (
            <AutoCompleteAddressField
              field={`${parentFieldFullName}line[0]`}
              onAutoCompleteAddress={handleAddressAutoComplete}
              label="Address Line 1"
              placeholder={`Enter street ` + `\u0430` + `ddress`}
              isReadonly={disabled}
              className="relative"
            />
          ) : (
            <InputField
              field={`${parentFieldFullName}line[0]`}
              label="Address Line 1"
              placeholder={`Enter street ` + `\u0430` + `ddress`}
              disabled={disabled}
              autocomplete="off"
              spellcheck={false}
              type="text"
              className="relative"
            />
          )}
          {isAddressPoBox ? (
            <InputIcon className="flex items-center">
              <Tooltip
                target=".tooltiped"
                event="hover"
                position="left"
                content="It looks like you're trying to set up a P.O. Box address. Please note that P.O. Boxes may not be suitable for faster deliveries."
              />
              <FontAwesomeIcon icon={faInfoCircle} className="tooltiped cursor-pointer text-orange-500" />
            </InputIcon>
          ) : (
            <InputIcon />
          )}
        </IconField>
        <InputField
          field={`${parentFieldFullName}line[1]`}
          label="Address Line 2"
          disabled={disabled}
          type="text"
          autocomplete="off"
          spellcheck={false}
        />
        <div
          className={classNames(
            "p-fluid grid grid-cols-1 gap-4",
            showTypeUseField ? "@sm:grid-cols-4" : "@sm:grid-cols-3",
          )}
        >
          {showTypeUseField && (
            <DropdownField
              field={`${parentFieldFullName}type`}
              label="Use"
              className="grow"
              options={validAddressTypes ?? addressTypes}
              showDefaultValue
              disabled={disabled}
            />
          )}
          <InputField
            field={`${parentFieldFullName}city`}
            label="City"
            className="grow"
            disabled={disabled}
            autocomplete="off"
            spellcheck={false}
            type="text"
          />
          <DropdownField
            field={`${parentFieldFullName}state`}
            label="State"
            className="grow"
            options={availableStates as Coding[]}
            optionLabel="display"
            disabled={disabled}
          />
          <InputField
            field={`${parentFieldFullName}postalCode`}
            label="ZIP Code"
            className="grow"
            disabled={disabled}
            autocomplete="off"
            spellcheck={false}
            type="text"
          />
        </div>
      </div>
    </>
  )
}

type Props = {
  parentFieldName?: string
  showAutoComplete?: boolean
  disabled?: boolean
  showTypeUseField?: boolean
  validAddressTypes?: {
    code: string
    label: string
  }[]
  className?: string
}

export { AddressField }
