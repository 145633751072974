import { type FC } from "react"
import type { Reference } from "fhir"
import type { QueryKey } from "@tanstack/react-query"

import { DialogFormContainer } from "commons"

import { INITIAL_VALUES, sanitize, notAvailableValidationSchema } from "./validations"
import { TimeOffForm } from "./TimeOffForm"
import type { TimeOffHours } from "../../types"
import { useSetTimeOff } from "../../hooks/useSetTimeOff"

const TimeOffFormModal: FC<Props> = ({ onHide, currentCalendarQuery, visible, practitioner }) => {
  const { addTimeOff } = useSetTimeOff({ practitioner, queryKeyToInvalidate: currentCalendarQuery, onSettled: onHide })

  const onSubmit = (data: TimeOffHours) => {
    addTimeOff({ notAvailable: sanitize(data) })
  }

  return (
    <DialogFormContainer
      title="New Day Off"
      showForm={!!visible}
      initialValue={INITIAL_VALUES}
      validationSchema={notAvailableValidationSchema}
      useFormik
      onSubmit={onSubmit}
      onCancel={onHide}
      saveLabel="Save"
    >
      <TimeOffForm />
    </DialogFormContainer>
  )
}

type Props = {
  practitioner: Reference
  visible?: boolean
  currentCalendarQuery: QueryKey
  onHide(): void
}

export { TimeOffFormModal }
