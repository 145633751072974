import type { CustomError } from "commons"
import { ERROR_CAUSE } from "data"
import { useAuth } from "security"

const ForbiddenErrorView = ({ error }: { error?: CustomError }) => {
  const { logout } = useAuth()

  setTimeout(() => {
    logout(true)
  }, 1000)

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="text-center">
        <p className="p-error text-5xl">403</p>
        <h1 className="py-2 text-4xl font-bold">{ERROR_CAUSE.FORBIDDEN}</h1>
        <p className="py-2 pb-4 text-gray-400">You are about to be logged out, your session is expired</p>
        {error && <p className="py-2 pb-4 text-sm text-gray-400">{error?.cause?.message}</p>}
      </div>
    </div>
  )
}

export { ForbiddenErrorView }
