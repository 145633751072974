import type { Reference } from "fhir"
import { useFormikContext } from "formik"

import { DropdownField, InputField, NumberField } from "commons"
import { sumPrice } from "utils"

import type { PDParams } from "../../../types"

const LabPanelForm = ({ performerLabsOptions, isEditing }: Props) => {
  const {
    values: { fee, basePrice },
  } = useFormikContext<PDParams>()

  return (
    <div className="divide-y divide-gray-200 pt-1.5">
      <div className="p-fluid grid gap-2">
        <InputField field="title" placeholder="Add a name" disabled={isEditing} />
        <DropdownField
          optionLabel="display"
          optionValue=""
          field="laboratory"
          placeholder="Select Laboratory"
          options={performerLabsOptions}
          dataKey="id"
          disabled={isEditing}
        />
      </div>
      <div className="mt-6 flex flex-1 flex-col justify-center space-y-3">
        <div className="mb-2 flex items-center justify-between pt-2 text-sm">
          <span className="font-semibold">Subtotal</span>
          <p className="font-semibold">${basePrice.toFixed(2)}</p>
        </div>
        <div className="flex flex-1 flex-col justify-center space-y-3 divide-y divide-gray-200">
          <div className="flex items-center justify-between pb-1">
            <span className="text-sm font-semibold">Fee</span>
            <NumberField
              field="fee"
              horizontal
              min={0}
              mode="currency"
              placeholder="$0.00"
              maxFractionDigits={2}
              showButtons={false}
            />
          </div>
          <div className="flex justify-between pt-2">
            <span className="text-sm font-semibold">Total</span>
            <span className="text-sm font-semibold">${sumPrice(basePrice, fee ?? 0).sum.toFixed(2)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

type Props = {
  performerLabsOptions: Reference[]
  isEditing?: boolean
  isLoadingData?: boolean
}

export { LabPanelForm }
