import type { Dosage, MedicationKnowledge, MedicationKnowledgeAdministrationGuidelinesArray } from "fhir"
import { useFormikContext } from "formik"
import { useCallback, type FC /* , useEffect, useState */ } from "react"

import { PatientAllergies } from "allergies"
import {
  type PractitionerInfo,
  addressTypes,
  MentionTextAreaField,
  ModulesId,
  NumberField,
  PractitionerRoleDropdownField,
  ReferenceDropdownField,
  ShippingAddressField,
} from "commons"
import { commonsDispenseInterval, getAdministrationGuideline /* , useMedicationAvailability */ } from "commons/meds"
import { PatientConditions } from "conditions"
import { useAppModuleContext } from "modules"
import { useOrganizationContext } from "organization"
/* import { getCommonCode } from "utils" */

import type { MedicationRequestFormData } from "../../types"
import { getMKDispenseLimit } from "../../utils"
import { PrescriptionSigProvider } from "../prescriptions/context/PrescriptionSigContext"
import { AutocompleteMedicationField } from "./AutocompleteMedicationField"
import { DispenseTypeFields } from "./DispenseTypeFields"
import { PrescriptionDosages } from "./PrescriptionDosages"
import { PrescriptionPrices } from "./PrescriptionPrices"
import { PrescriptionUnitField } from "./PrescriptionUnitField"
import { FORCED_PRESCRIPTIION_CATEGORY } from "eprescribe/data"

const PrescriptionForm: FC<Props> = ({
  practitionersInfo,
  isEditing,
  isRenewing,
  allowedMedicationCodes,
  medRecommendedDosage,
  hidePrescriberField,
  editAddressHidden,
  onBypassValidations,
}) => {
  const { rxCatalogs } = useOrganizationContext()
  const { isModuleActive } = useAppModuleContext()
  const { values, isSubmitting, initialValues, setFieldValue } = useFormikContext<MedicationRequestFormData>()
  const { medicationField, dispenseRequest } = values
  const hasPackaging = !!(medicationField as MedicationKnowledge)?.packaging?.type
  const catalogsAuthorRef = rxCatalogs.map((c) => c.author[0])
  const medicationDispenseLimit = getMKDispenseLimit(medicationField as MedicationKnowledge)
  const numberOfRefills = dispenseRequest?.numberOfRepeatsAllowed

  /* const { isLoadingAvailability, medAvailability, medQuantityAvailable, refetchMedAvailability } =
    useMedicationAvailability(
      getCommonCode({ codes: medicationField?.code?.coding ?? values?.medicationKnowledge?.code?.coding }),
      values?.dispenseRequest?.quantity?.value ?? 1,
    )
  const [isDFO, setIsDFO] = useState(values?.dispenseRequest?.initialFill?.isDfo ?? false)
  const [warningAvailability, setWarningAvailability] = useState(!medAvailability)

  useEffect(() => {
    if (!isLoadingAvailability && !medQuantityAvailable && !isSubmitting) {
      setFieldValue("dispenseRequest.initialFill.isDfo", false)
      setIsDFO(false)
      setWarningAvailability(true)
    }
  }, [isLoadingAvailability, medQuantityAvailable, isSubmitting])

  const updateDFO = async (state: boolean) => {
    setIsDFO(state)
    if (state) {
      const { data } = await refetchMedAvailability()
      setWarningAvailability(!data?.medQuantityAvailable)
      data?.medQuantityAvailable ? setFieldValue("dispenseRequest.initialFill.isDfo", true) : setIsDFO(false)
    } else setFieldValue("dispenseRequest.initialFill.isDfo", false)
  } */

  const handleRefillsChange = useCallback(
    (refillsValue: number) => {
      const refillsTresholdCrossed = Boolean(numberOfRefills) !== Boolean(refillsValue)
      if (refillsTresholdCrossed) {
        setFieldValue("isAutoOrder", !!refillsValue)
        setFieldValue("dispenseRequest.dispenseInterval", commonsDispenseInterval[refillsValue ? 1 : 0].value)
      }
    },
    [numberOfRefills],
  )

  const onChangeMed = () => {
    // When medication is changed, remove the forced prescription category if any an allow to restart the validations
    setFieldValue("category", [
      ...(values.category ?? []).filter(
        ({ coding }) => coding?.[0]?.code !== FORCED_PRESCRIPTIION_CATEGORY.coding?.[0]?.code,
      ),
    ])
    setFieldValue("note", initialValues.note)
  }

  return (
    <>
      <div className="p-fluid relative border-b border-gray-200 pb-4">
        <AutocompleteMedicationField
          field="medicationField"
          disabled={isEditing || isRenewing}
          skipValidation={isEditing}
          label="Medication"
          allowedMedicationCodes={allowedMedicationCodes}
          onProceedAnyway={onBypassValidations}
          onChange={onChangeMed}
        />
      </div>
      <PrescriptionSigProvider
        medRecommendedDosage={medRecommendedDosage}
        getAdministrationGuideline={getAdministrationGuideline}
      >
        <PrescriptionDosages />
      </PrescriptionSigProvider>
      <div className="flex space-x-6 pb-4">
        {!hidePrescriberField && (
          <PractitionerRoleDropdownField
            field="requester"
            label="Prescriber"
            options={practitionersInfo ?? []}
            className="flex-1"
            disabled={isSubmitting}
            useFilter={false}
          />
        )}
        <ReferenceDropdownField
          className="flex-1"
          label="Pharmacy"
          options={catalogsAuthorRef}
          field="dispenseRequest.performer"
          filter={false}
        />
      </div>
      <div className="flex flex-col">
        <div className="mb-1 grid flex-1 grid-cols-4 gap-6">
          <NumberField
            field="dispenseRequest.quantity.value"
            label="Quantity"
            min={1}
            allowEmpty={false}
            disabled={isSubmitting}
            max={medicationDispenseLimit}
            subMessage={
              medicationDispenseLimit
                ? `Max dispense limit for this medication is (${medicationDispenseLimit})`
                : undefined
            }
            className="w-full"
            inputClassName="min-w-0"
          />
          <PrescriptionUnitField disabled={hasPackaging} />
          <NumberField
            field="dispenseRequest.numberOfRepeatsAllowed"
            label="Refills"
            className="w-full"
            inputClassName="min-w-0"
            min={0}
            disabled={isSubmitting}
            onChange={handleRefillsChange}
          />
        </div>
        <DispenseTypeFields />
        {(medicationField?.id || medicationField?.code) && (
          <div className="flex items-center justify-between border-t border-gray-200 py-5">
            {/* {isModuleActive(ModulesId.CHECKOUT_INVENTORY) && (
              <div className="flex justify-between items-center gap-2 w-28">
                <span className="text-sm inline-flex items-center" title="Dispense from office">
                  DFO
                  {isDFO && isLoadingAvailability && <FontAwesomeIcon icon={faCircleNotch} spin className="ml-2" />}
                  {!medQuantityAvailable && (warningAvailability || isDFO) && !isLoadingAvailability && (
                    <span
                      id={`warningAvailability${values.id}`}
                      className={classNames({ "p-disabled": isSubmitting })}
                      data-pr-tooltip={`Warning: There is no availability to dispense this medication from office.
                          ${
                            medAvailability
                              ? `There is only ${medAvailability} ${pluralize(values?.dispenseRequest?.quantity?.unit?.toLowerCase() ?? "unit", medAvailability)} available.`
                              : ""
                          }`}
                    >
                      <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 text-lg" />
                      <Tooltip target={`#warningAvailability${values.id}`} position="bottom" />
                    </span>
                  )}
                </span>
                <InputSwitch checked={isDFO} onChange={(e) => updateDFO(e.value)} disabled={isSubmitting} />
              </div>
            )} */}
            <PrescriptionPrices />
          </div>
        )}
      </div>

      {!editAddressHidden && (
        <ShippingAddressField
          fieldPath="dispenseRequest.shippingAddress"
          defaultAddressType={addressTypes.find(({ code }) => code === "postal")}
        />
      )}

      <MentionTextAreaField
        field="note[0].text"
        label="Note"
        rows={5}
        className="mt-2"
        inputClassName="w-full h-full"
        disabled={isSubmitting}
      />
      {isModuleActive(ModulesId.INTAKE) && (
        <>
          <PatientConditions />
          <PatientAllergies />
        </>
      )}
    </>
  )
}

type Props = {
  practitionersInfo: PractitionerInfo[]
  isEditing?: boolean
  isRenewing?: boolean
  allowedMedicationCodes?: string[]
  medRecommendedDosage?: Record<string, Dosage[]>
  getAdministrationGuideline?: (
    mk: MedicationKnowledge,
    medRecommendedDosage?: Record<string, Dosage[]>,
  ) => MedicationKnowledgeAdministrationGuidelinesArray | undefined
  hidePrescriberField?: boolean
  editAddressHidden?: boolean
  onBypassValidations?(error: string): void
}

export { PrescriptionForm }
