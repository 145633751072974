import type { PropsWithChildren } from "react"

import { SearchInput } from "commons"

import "../../commons/components/SearchWithFilters.css"

const OrderSearchWithFilters = ({
  isLoading,
  onTextFilter,
  formContent,
  placeholder = "Search",
  autoFocus,
  children,
  countDisplay,
}: SearchWithFiltersProps) => (
  <div className="flex h-full flex-col overflow-hidden">
    <div className="w-full border-b px-6 py-4 drop-shadow">
      <SearchInput
        className="mb-2 w-full max-w-96 flex-1"
        search={(searchText) => onTextFilter?.(searchText)}
        isLoading={isLoading}
        placeholder={placeholder}
        autoFocus={autoFocus}
      />
      {countDisplay}
    </div>
    <div className="flex h-full gap-4 overflow-hidden bg-light-gray p-4">
      <div className="max-h-full w-1/4 max-w-96 self-start overflow-auto rounded-2xl bg-white p-4">{formContent}</div>
      <div className="flex h-full grow flex-col overflow-auto rounded-2xl bg-white p-4">{children}</div>
    </div>
  </div>
)

export type SearchWithFiltersProps = PropsWithChildren & {
  autoFocus?: boolean
  formContent?: JSX.Element
  countDisplay?: JSX.Element
  isLoading: boolean
  placeholder?: string
  buttonClassName?: string
  onTextFilter?(searchText?: string): void
}

export { OrderSearchWithFilters }
