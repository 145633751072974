import { type Patient, type Reference, isPractitioner } from "fhir"
import { Checkbox } from "primereact/checkbox"
import { useEffect, useMemo, useState } from "react"

import { AutocompleteRefsWithChips, Button, ModalDialog, PatientItemTemplate } from "commons"
import { useOrganizationContext } from "organization"
import { usePatientsRefs } from "patients"

const AppointmentFilters = ({ visible, onCancel, filteredHS, patients, practs, updateFilters }: Props) => {
  const { healthcareServicesRefs, healthcareServices } = useOrganizationContext()
  const [filter, setFilter] = useState<string>()
  const [filteredPatient, setFilteredPatient] = useState<Patient[]>([])
  const { refetch, isLoading } = usePatientsRefs({
    filter,
    patientId: patients?.join(","),
  })

  const practitioners = useMemo(
    () =>
      healthcareServices.reduce<Reference[]>((acc, hs) => {
        const practRefs = hs.participant?.filter(isPractitioner) ?? []
        return [...acc, ...practRefs.filter((ref) => !acc.some((p) => p.id === ref.id))]
      }, []),
    [healthcareServices],
  )

  const [selectedHS, setSelectedHS] = useState<string[]>(filteredHS)
  const [selectedPatient, setSelectedPatient] = useState<Reference[]>([])
  const [selectedPract, setSelectedPract] = useState<Reference[]>(
    practitioners.filter((pract) => practs?.includes(pract.id as string)),
  )

  useEffect(() => {
    patients.length && initPatientsRefs()
  }, [])

  const initPatientsRefs = async () => {
    const { data } = await refetch()
    setSelectedPatient(data?.patientRefs ?? [])
  }

  const searchPatientsRefs = async () => {
    const { data } = await refetch()
    setFilteredPatient(data?.patients?.filter((p) => !selectedPatient.some(({ id }) => id === p.id)) ?? [])
  }

  const saveFilters = () => {
    updateFilters(
      selectedHS.join(","),
      selectedPatient.map((p) => p.id as string).join(","),
      selectedPract.map((p) => p.id as string).join(","),
    )
    onCancel()
  }

  return (
    <ModalDialog
      visible={visible}
      header={
        <div className="flex justify-between">
          <span>Filters</span>
          {(!!selectedHS?.length || !!selectedPatient?.length || !!selectedPract?.length) && (
            <Button
              label="Clear All"
              buttonStyle="text"
              onClick={() => {
                setSelectedHS([])
                setSelectedPatient([])
                setSelectedPract([])
              }}
              size="xs"
              className="mr-1"
            />
          )}
        </div>
      }
      draggable={false}
      resizable={false}
      showButtons
      acceptLabel="Filter"
      onCancel={onCancel}
      onAccept={saveFilters}
    >
      <div className="flex flex-col gap-4 divide-y divide-gray-200">
        <div className="flex flex-col gap-2 pt-1">
          <div className="flex justify-between">
            <span className="font-medium text-gray-900">Appointment Type</span>
            {!!selectedHS?.length && (
              <Button label="Clear" buttonStyle="text" onClick={() => setSelectedHS([])} size="xs" className="mr-1" />
            )}
          </div>
          <div className="grid grid-cols-2 gap-2">
            {healthcareServicesRefs.map((hs) => (
              <label key={hs.id} className="flex items-center">
                <Checkbox
                  className="checkbox-sm"
                  checked={selectedHS?.includes(hs.id as string) ?? false}
                  onChange={(e) =>
                    setSelectedHS([
                      ...selectedHS.filter((hId) => hId !== hs.id),
                      ...(e.target.checked ? [hs.id as string] : []),
                    ])
                  }
                />
                <span className="cursor-pointer text-sm">{hs.display ?? hs.id}</span>
              </label>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-2 pt-4">
          <div className="flex justify-between">
            <span className="font-medium text-gray-900">Practitioners</span>
            {!!selectedPract?.length && (
              <Button
                label="Clear"
                buttonStyle="text"
                onClick={() => setSelectedPract([])}
                size="xs"
                className="mr-1"
              />
            )}
          </div>
          <AutocompleteRefsWithChips
            suggestionList={practitioners}
            noItemFound="No practitioners found"
            selectedReference={selectedPract}
            setSelectedReference={setSelectedPract}
          />
        </div>
        <div className="flex flex-col gap-2 pt-4">
          <div className="flex justify-between">
            <span className="font-medium text-gray-900">Patients</span>
            {!!selectedPatient?.length && (
              <Button
                label="Clear"
                buttonStyle="text"
                onClick={() => setSelectedPatient([])}
                size="xs"
                className="mr-1"
              />
            )}
          </div>
          <AutocompleteRefsWithChips
            suggestionList={filteredPatient}
            noItemFound="No patients found"
            selectedReference={selectedPatient}
            setSelectedReference={setSelectedPatient}
            filter={filter}
            setFilter={setFilter}
            onSearch={searchPatientsRefs}
            isLoading={isLoading && !!patients.length}
            itemTemplate={PatientItemTemplate}
          />
        </div>
      </div>
    </ModalDialog>
  )
}

type Props = {
  visible: boolean
  onCancel(): void
  filteredHS: string[]
  patients: string[]
  practs: string[]
  updateFilters(filteredHS: string, patients: string, practs: string): void
}

export { AppointmentFilters }
