import { type Device, asReference } from "fhir"

import { DataContainerSlideoverForm, SkeletonLoader, StackedListContainer, useCrudReducer } from "commons"
import {
  DeviceForm,
  deviceModel,
  getDeviceInitialValues,
  useCreateUpdateDevice,
  useReaders,
  useRegisterReader,
} from "commons/readers"
import { useOrganizationContext } from "organization"

const PaymentReaders = () => {
  const { currentOrganization, currentOrganizationId } = useOrganizationContext()
  const { showSlide, initialValue, isNew, add, edit, reset } = useCrudReducer({
    defaultEntity: {
      ...getDeviceInitialValues(asReference(currentOrganization)),
      registrationCode: undefined,
    } as Device,
  })

  const { readers, locationsByReader, isLoading: isLoadingReaders } = useReaders(currentOrganizationId)
  const { registerReader } = useRegisterReader({ orgId: currentOrganizationId, onSettled: reset })
  const { createOrUpdateDevice } = useCreateUpdateDevice({ onSettled: reset })

  const onSubmit = (reader: Device & { registrationCode: string }) => {
    if (isNew)
      registerReader({ readerLabel: reader.deviceName?.[0].name as string, registrationCode: reader.registrationCode })
    else createOrUpdateDevice(reader)
  }

  if (isLoadingReaders) return <SkeletonLoader loaderType="two-lines" repeats={1} />

  return (
    <DataContainerSlideoverForm
      formTitle="Reader"
      height="calc(100% - 8rem)"
      showSlide={showSlide}
      hasData={!!readers?.length}
      onButtonAddClick={add}
      formInitialValue={initialValue}
      form={<DeviceForm isReaderForm isEditing={!isNew} />}
      onSubmit={onSubmit}
      onCancel={reset}
      customAddButtonText="Add new reader"
      subMessageDataNotFound="Get started by adding a new Reader"
    >
      <div className="h-full overflow-auto bg-white">
        <StackedListContainer
          data={readers ?? []}
          itemPadding
          itemModelBuilder={(item) =>
            deviceModel({
              device: item,
              onEdit: () => edit(item),
              isReader: true,
              locationName: locationsByReader?.[item.id as string]?.name,
            })
          }
        />
      </div>
    </DataContainerSlideoverForm>
  )
}

export { PaymentReaders }
