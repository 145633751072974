import { faPills } from "@fortawesome/pro-regular-svg-icons"
import { useState } from "react"
import { getFirstPhoneNoFallback } from "fhir"

import { EmptyMessage } from "commons"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { usePatientContext } from "patients"
import { getDosespotDisableWarningMessage, getHomeAddressIndex } from "utils"

import { DosespotIframe } from "./DosespotIframe"
import { DosespotPractitionerSelect } from "./DosespotPractitionerSelect"

const DosespotContainer = () => {
  const { currentOrganizationId, isAdmin, hasDosespotSetup, hasPractitionerDosespotSetup, loggedInPractitionerRole } =
    useOrganizationContext()
  const { patient } = usePatientContext()
  const [practitionerRoleIdSelected, setPractitionerRoleIdSelected] = useState<string | undefined>(
    hasDosespotSetup && hasPractitionerDosespotSetup ? loggedInPractitionerRole?.id : undefined,
  )

  const { organizationPractitionersInfo } = useOrganizationPractitioners({ organizationId: currentOrganizationId })
  const missingInfoMessage = getDosespotDisableWarningMessage(
    isAdmin,
    !!patient?.gender,
    !!patient?.birthDate,
    hasDosespotSetup,
    hasPractitionerDosespotSetup,
    getHomeAddressIndex(patient.address) !== -1,
    !!getFirstPhoneNoFallback(patient?.telecom),
    organizationPractitionersInfo.filter(({ hasDosespotSetup }) => hasDosespotSetup),
  )

  if (missingInfoMessage) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <EmptyMessage icon={faPills} message="Dosespot missing information" subMessage={missingInfoMessage} />
      </div>
    )
  }

  return !practitionerRoleIdSelected ? (
    <DosespotPractitionerSelect setPractitionerRoleIdSelected={setPractitionerRoleIdSelected} />
  ) : (
    <DosespotIframe practitionerRoleId={practitionerRoleIdSelected} />
  )
}

export { DosespotContainer }
