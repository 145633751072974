import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import type { FC } from "react"

import { ModulesId } from "commons"
import { MedicationOrderListItem, useMrOrdersResources } from "commons/meds"
import { useChartContext } from "chart-view"

import { WrapUpSection } from "../components/details/WrapUpSection"

const NutrasSection: FC<Props> = ({ patientId, encounterId, icon }) => {
  const { showModule } = useChartContext()

  const { mrOrderData, isLoading, total } = useMrOrdersResources({
    patientId,
    subcategory: "nutraceutical-order",
    encounter: encounterId,
    statusFilter: ["active", "completed"],
  })

  const goToOrderDetails = (order: string) => {
    showModule({
      module: ModulesId.MEDICATIONR,
      moduleParams: { order },
    })
  }

  return (
    <WrapUpSection
      sectionTitle="Nutraceuticals"
      isLoading={isLoading}
      isEmpty={!total}
      icon={icon}
      emptyMesage="No nutraceutical's orders placed during this encounter"
      className="w-full list-none divide-y divide-gray-200 self-end pb-2 text-sm"
    >
      {mrOrderData?.map((item) => (
        <MedicationOrderListItem
          key={item.serviceRequest?.id}
          mrOrderData={item}
          onItemClicked={() => goToOrderDetails(item.serviceRequest.id as string)}
          className="py-4 pr-7 !pl-0"
        />
      ))}
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounterId: string
  icon: IconDefinition
}

export { NutrasSection }
