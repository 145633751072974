import { useState, type FC } from "react"
import { faMinusSquare, type IconDefinition } from "@fortawesome/pro-solid-svg-icons"
import { faSquare, faTrashCan, faCalendarClock } from "@fortawesome/pro-regular-svg-icons"

import { Button } from "commons"
import { useRefillOnMedicationRequests, useValidateRefillOnDemand, type MedicationRequestData } from "commons/meds"

import { useDeleteMultipleMedicationRequests } from "../../hooks"
import { getBulkActionsItems } from "../../utils"
import { ConfirmDiscardDialog } from "./ConfirmDiscardDialog"
import { RefillOnDialog } from "./RefillOnDialog"
import { showBulkDiscardNotification, showBulkRefillNotification } from "../helpers"

const PrescriptionsBulkActions: FC<Props> = ({ selectedItems, toggleSelectAll }) => {
  const [showDiscardDialog, setShowDiscardDialog] = useState(false)
  const [showRefillOnDialog, setShowRefillOnDialog] = useState(false)
  const { selectedItemsIds, itemsIdsToBeDiscarded, itemsIdsToBeScheduledForRefill } = getBulkActionsItems(selectedItems)
  const [isRefillAllowed, setIsRefillAllowed] = useState(true)
  const [initialRefillDate, setInitialRefillDate] = useState(new Date())
  const [refillNotAllowedReason, setRefillNotAllowedReason] = useState<string | undefined>(undefined)

  const { deleteMedicationRequests, isDeleting } = useDeleteMultipleMedicationRequests(() => {
    toggleSelectAll()
    setShowDiscardDialog(false)
    showBulkDiscardNotification(selectedItems.length, itemsIdsToBeDiscarded.length)
  })

  const { validateRefillOnDemand, isValidating } = useValidateRefillOnDemand(({ validationResponse }) => {
    setIsRefillAllowed(!!validationResponse.isValid)
    if (validationResponse.isValid) {
      setInitialRefillDate(new Date(validationResponse.earliestRefillDate!))
    } else {
      setRefillNotAllowedReason(validationResponse.failureReason ?? "")
    }
    setShowRefillOnDialog(true)
  })
  const { refillOnMedicationRequest, isScheduling } = useRefillOnMedicationRequests(() => {
    toggleSelectAll()
    setShowRefillOnDialog(false)
    showBulkRefillNotification(selectedItems.length, itemsIdsToBeScheduledForRefill.length)
  })

  const handleDiscard = () => {
    deleteMedicationRequests(itemsIdsToBeDiscarded)
  }

  const handleRefillOn = () => {
    if (!itemsIdsToBeScheduledForRefill.length) {
      setIsRefillAllowed(false)
      setRefillNotAllowedReason("Only active and on-demand prescriptions can be scheduled")
      setShowRefillOnDialog(true)
    } else {
      validateRefillOnDemand({ medicationRequests: itemsIdsToBeScheduledForRefill })
    }
  }

  const handleScheduleRefill = (scheduleDate: string) => {
    refillOnMedicationRequest({
      medicationRequests: itemsIdsToBeScheduledForRefill,
      scheduleDate,
    })
  }

  const bulkActionItems: ActionButtonProps[] = [
    {
      icon: faCalendarClock,
      title: "Refill-On",
      onClick: handleRefillOn,
      disabled: !selectedItems.length,
      loading: isValidating,
    },
    {
      icon: faTrashCan,
      title: "Discard",
      onClick: () => setShowDiscardDialog(true),
      disabled: !selectedItems.length,
      loading: isDeleting,
    },
  ]

  return (
    <div className="flex items-center gap-6">
      <div className="flex items-center gap-3">
        <Button
          icon={selectedItems.length ? faMinusSquare : faSquare}
          iconClassName="text-lg"
          className="p-0"
          buttonStyle="text"
          onClick={toggleSelectAll}
          style={{ padding: 0 }}
        />
        <div className="flex gap-1 text-sm text-gray-500">
          <span className="min-w-3">{selectedItems.length}</span>
          <span>selected</span>
        </div>
      </div>
      {
        <ul className="flex items-center gap-4">
          {bulkActionItems.map((item, index) => (
            <li key={index}>
              <ActionButton {...item} />
            </li>
          ))}
        </ul>
      }
      <ConfirmDiscardDialog
        selectedItemsCount={selectedItemsIds.length}
        visible={showDiscardDialog || isDeleting}
        loading={isDeleting}
        onAccept={handleDiscard}
        onCancel={() => setShowDiscardDialog(false)}
      />
      {showRefillOnDialog && (
        <RefillOnDialog
          title="Refill-On"
          visible={showRefillOnDialog || isScheduling}
          isLoading={isScheduling}
          onRefillOn={handleScheduleRefill}
          onHide={() => setShowRefillOnDialog(false)}
          isRefillAllowed={isRefillAllowed}
          refillNotAllowedReason={refillNotAllowedReason}
          initialDate={initialRefillDate}
          acceptLabel={isRefillAllowed ? "Schedule" : "Accept"}
        />
      )}
    </div>
  )
}

type ActionButtonProps = {
  icon: IconDefinition
  title: string
  onClick: () => void
  disabled: boolean
  loading: boolean
}

const ActionButton: FC<ActionButtonProps> = ({ icon, title, onClick, disabled, loading }) => (
  <Button
    icon={icon}
    title={title}
    buttonStyle="text"
    className="border border-gray-200"
    onClick={onClick}
    loading={loading}
    disabled={disabled}
  />
)

type Props = {
  selectedItems: MedicationRequestData[]
  toggleSelectAll(): void
}

export { PrescriptionsBulkActions }
