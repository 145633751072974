import { type MedicationDispense, codeableConceptAsString } from "fhir"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarDays } from "@fortawesome/pro-solid-svg-icons"
import { classNames } from "primereact/utils"
import { Chip } from "primereact/chip"
import { parseISO } from "date-fns"

import { formatDate } from "utils"
import { ListItem } from "commons"
import { formatsByTypes } from "data"

const MedicationDispenseListItem = ({ medicationRequest, onClick }: Props) => {
  const getStatusColor = (status: string) => {
    return {
      "is-success": status === "in-progress",
    }
  }

  const head = (
    <>
      <p title="Medication">{codeableConceptAsString(medicationRequest?.medication?.CodeableConcept)}</p>
      {medicationRequest.status && (
        <span title="Status">
          <Chip
            label={medicationRequest.status}
            className={classNames("custom-chip ml-2", getStatusColor(medicationRequest.status))}
          />
        </span>
      )}
    </>
  )

  const details = (
    <>
      {medicationRequest.whenPrepared && (
        <span title="Date" className="flex items-center">
          <FontAwesomeIcon icon={faCalendarDays} className="mr-1.5 text-gray-400" />
          {formatDate(parseISO(medicationRequest.whenPrepared), formatsByTypes.LONG_DATETIME)}
        </span>
      )}
    </>
  )

  return <ListItem header={head} subHeader={details} onContentClick={onClick} />
}

type Props = {
  medicationRequest: MedicationDispense
  onClick?(): void
}

export { MedicationDispenseListItem }
