import type { TriggerFn } from "@lexical/react/LexicalTypeaheadMenuPlugin"
import { useCallback } from "react"

export const PUNCTUATION = "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;"

const useBasicTypeaheadTriggerMatch = (
  trigger: string,
  { minLength = 1, maxLength = 75 }: { minLength?: number; maxLength?: number },
): TriggerFn => {
  return useCallback(
    (text: string) => {
      const validChars = "[^" + trigger + PUNCTUATION + "\\s]"
      const TypeaheadTriggerRegex = new RegExp(
        "(^||\\()(" + "[" + trigger + "]" + "((?:" + validChars + "){0," + maxLength + "})" + ")$",
      )
      const match = TypeaheadTriggerRegex.exec(text)
      if (match !== null) {
        const maybeLeadingWhitespace = match[1]
        const matchingString = match[3]
        if (matchingString.length >= minLength) {
          return {
            leadOffset: match.index + maybeLeadingWhitespace.length,
            matchingString,
            replaceableString: match[2],
          }
        }
      }
      return null
    },
    [maxLength, minLength, trigger],
  )
}

export { useBasicTypeaheadTriggerMatch }
