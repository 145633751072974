import { faBarcode, faSearch } from "@fortawesome/pro-regular-svg-icons"
import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type CodeableConcept, type InventoryItem, type MedicationKnowledge, asReference } from "fhir"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { useId, useMemo, useState } from "react"

import { DialogFormContainer, EmptyMessage, InfiniteScroll, SkeletonLoader, useCrudReducer } from "commons"
import { getMKDisplayText } from "commons/meds"
import { useOrganizationContext } from "organization"
import { getCommonCode } from "utils"

import { useCreateInventoryItem, useInventory } from "../../hooks"
import { InventoryForm } from "./InventoryForm"
import { getInventoryInitialValues, inventoryItemValidationSchema } from "./validations"
import { SectionContainer } from "../../utils/SectionContainer"
import { actionsBodyTemplate, statusBodyTemplate } from "../../utils/templates"

const InventoryContainer = () => {
  const { currentOrganization, location } = useOrganizationContext()
  const [textFilter, setTextFilter] = useState<string>()

  const {
    showSlide: showForm,
    add,
    isNew,
    initialValue,
    edit,
    reset,
  } = useCrudReducer({
    defaultEntity: getInventoryInitialValues(asReference(currentOrganization), location ? asReference(location) : {}),
  })

  const { isLoading, inventoryItems, mkSkuCodes, hasNextPage, fetchNextPage } = useInventory({
    textFilter,
  })

  const onSearch = (payload: string | undefined) => setTextFilter(payload)

  const onReset = () => {
    reset()
    onSearch(undefined)
  }

  const addOptions = [
    {
      label: "Add to invenntory",
      command: () => add(),
    },
  ]

  const { createInventoryItem } = useCreateInventoryItem(reset)

  const handleSubmit = (item: InventoryItem & { med?: MedicationKnowledge }) => {
    createInventoryItem(item)
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" containerClassName="flex-1" />

  const inventoryItemsData = useMemo(
    () =>
      inventoryItems.map((item) => ({
        inventoryData: {
          code: item.code,
          name: item.name?.[0]?.name ?? item.code?.[0]?.text,
        },
        status: item.status,
        total: item.netContent?.value,
        externalAction: [
          {
            label: "Edit",
            icon: <FontAwesomeIcon icon={faEdit} size="sm" />,
            command: () =>
              edit({
                ...item,
                med: {
                  code: item.code?.[0],
                  textDisplayedInField: getMKDisplayText({ code: item.code?.[0] } as MedicationKnowledge),
                },
              }),
          },
        ],
      })),
    [inventoryItems],
  )

  const inventoryItemBodyTemplate = ({
    inventoryData: { code, name },
  }: {
    inventoryData: { code: CodeableConcept[]; name: string }
  }) => {
    const sku = getCommonCode({ codes: code?.[0]?.coding })

    const header = (
      <div
        title="Item name"
        className="line-clamp-1 max-w-sm overflow-hidden font-medium break-words text-ellipsis whitespace-normal 3xl:max-w-full lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl"
      >
        {name}
      </div>
    )

    const details = (
      <>
        <span title="Code" className="flex items-center">
          <FontAwesomeIcon icon={faBarcode} className="mr-1.5 text-gray-500" />
          {sku}
        </span>
      </>
    )

    return (
      <span className="block">
        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="flex w-full items-center">
            <div className="flex">
              <div className="truncate">
                <div className="flex text-sm">{header}</div>
                <div className="mt-2 flex">
                  <div className="flex items-center gap-2 divide-x divide-gray-400 text-xs text-gray-400">
                    {details}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }

  return (
    <SectionContainer
      actions={addOptions}
      onReset={onReset}
      onSearch={onSearch}
      placeholder="Search inventory"
      searchText={textFilter}
      containerClassName="flex-1 overflow-y-auto"
      loading={isLoading}
    >
      {inventoryItemsData.length ? (
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <DataTable value={inventoryItemsData} loading={isLoading}>
            <Column field="inventoryData" body={inventoryItemBodyTemplate} headerClassName="bg-transparent" />
            <Column field="total" header="Availability" bodyClassName="text-xs" headerClassName="default-header" />
            <Column field="status" header="Status" body={statusBodyTemplate} headerClassName="default-header" />
            <Column field="external_action" headerClassName="bg-transparent" body={actionsBodyTemplate} />
          </DataTable>
        </InfiniteScroll>
      ) : (
        <div className="flex h-full">
          <EmptyMessage itemTitle="Inventory item" icon={faSearch} />
        </div>
      )}

      <DialogFormContainer
        onCancel={reset}
        showForm={showForm}
        onSubmit={handleSubmit}
        initialValue={initialValue}
        validationSchema={inventoryItemValidationSchema}
        saveLabel={isNew ? "Add" : "Update"}
        useFormik
        title={`${isNew ? "Add" : "Edit"} Inventory Item`}
      >
        <InventoryForm isEditing={!isNew} mksOnInventoryCodes={mkSkuCodes} />
      </DialogFormContainer>
    </SectionContainer>
  )
}

export { InventoryContainer }
