import { faTrash } from "@fortawesome/pro-regular-svg-icons"
import type { Composition } from "fhir"
import { Checkbox } from "primereact/checkbox"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { useState, type FC } from "react"
import { classNames } from "primereact/utils"

import { Button } from "commons"

import "./SnippetNote.css"

const SnippetNote: FC<SnippetNoteProps> = ({
  snippet: defaultSnippetValue,
  onRemove,
  onSave,
  deleteMode,
  isMarkedForDelete = false,
}) => {
  const [isEditing, setIsEditing] = useState(defaultSnippetValue.r5_name === "" || false)
  const [textTimeout, setTextTimeout] = useState<NodeJS.Timeout>()
  const [snippet, setSnippet] = useState(defaultSnippetValue)

  const handleKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSnippet = { ...snippet, r5_name: e.target.value }
    setSnippet(newSnippet)
  }

  const debouncedTextSave = (newSnippet: Composition) => {
    if (textTimeout) {
      clearTimeout(textTimeout)
    }

    const timeout = setTimeout(() => {
      onSave(newSnippet)
    }, 3000)

    setTextTimeout(timeout)
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newSnippet = {
      ...snippet,
      r5_note: {
        text: e.target.value,
      },
    }
    setSnippet(newSnippet)
    debouncedTextSave(newSnippet)
  }

  const handleTextBlur = () => {
    if (textTimeout) {
      clearTimeout(textTimeout)
    }
    if (snippet.r5_note?.text !== defaultSnippetValue.r5_note?.text) {
      onSave(snippet)
    }
  }

  return (
    <div className="flex flex-row items-center gap-2">
      {deleteMode === "multiple" && (
        <div className={classNames({ "mr-3 h-3.5 w-3.5 rounded-xs": !snippet?.id })}>
          {snippet?.id && <Checkbox checked={isMarkedForDelete} onChange={onRemove} className="checkbox-sm mt-2" />}
        </div>
      )}
      <div className="flex w-full flex-col gap-2">
        <div className="flex flex-row justify-between">
          <div className="cursor-pointer" onClick={() => setIsEditing(true)}>
            <span className="mr-1 rounded-xs bg-gray-100 px-1 text-sm font-medium">#</span>
            {isEditing ? (
              <InputText
                value={snippet.r5_name}
                onChange={handleKeyChange}
                className="no-focus-outline-inputtext mr-1 h-[1.25rem] rounded-xs px-1 text-sm font-medium"
                style={{ width: `${snippet.r5_name?.length || 9}ch` }}
                placeholder="Enter key"
                autoFocus
                onBlur={() => {
                  setIsEditing(false)
                  if (snippet.r5_name?.trim() && snippet.r5_name?.trim() !== defaultSnippetValue.r5_name?.trim()) {
                    onSave(snippet)
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && snippet.r5_name?.trim()) {
                    setIsEditing(false)
                    onSave(snippet)
                  }
                }}
              />
            ) : (
              <span className={classNames("text-sm", { "font-medium text-gray-500": !snippet.r5_name })}>
                {snippet.r5_name || "Enter key"}
              </span>
            )}
          </div>
          <div className="flex justify-end">
            {deleteMode === "single" && snippet?.id && (
              <Button onClick={onRemove} buttonStyle="text" icon={faTrash} className="!pr-0" />
            )}
          </div>
        </div>

        <InputTextarea
          value={snippet.r5_note?.text}
          placeholder="Enter snippet text..."
          onChange={handleTextChange}
          onBlur={handleTextBlur}
          onFocusCapture={(e) => e.currentTarget.setAttribute("spellcheck", "true")}
          onBlurCapture={(e) => e.currentTarget.setAttribute("spellcheck", "false")}
          rows={3}
          className="no-focus-outline w-full text-gray-500 hover:text-gray-700 focus:text-gray-700"
        />
      </div>
    </div>
  )
}

type SnippetNoteProps = {
  snippet: Composition
  onRemove: () => void
  onSave: (newSnippet: Composition) => void
  deleteMode?: "multiple" | "single"
  isMarkedForDelete?: boolean
}

export { SnippetNote }
