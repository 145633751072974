import type { ChargeItemDefinition } from "fhir"
import { RadioButton } from "primereact/radiobutton"
import { SYSTEM_VALUES } from "system-values"

import { getFeePrice, getMoneyCurrencyAlt } from "utils"

import { useCPOERequestsContext } from "../hooks"

const OrderFeeListItem = ({ cid, selected, totalItemsCost, selectCid }: Props) => {
  const { isProcessingActions } = useCPOERequestsContext()
  const isFreeShepping = cid.code?.coding?.find(({ code }) => code === "free-shipping")
  const basePrice = getFeePrice(cid.propertyGroup?.[0].priceComponent)
  const price = totalItemsCost > 100 && isFreeShepping ? 0 : basePrice?.amount?.value ?? 0
  const feeDisplay = cid.code?.coding?.find((coding) =>
    ([SYSTEM_VALUES.SHIPPING_METHOD, SYSTEM_VALUES.SERVICE_FEE] as string[]).includes(coding.system as string),
  )?.display

  return (
    <div className="mt-2 flex w-full items-center px-4 text-sm">
      <RadioButton
        inputId={`cid${cid.id}`}
        name="shipping"
        value={cid}
        checked={selected}
        onChange={selectCid}
        disabled={isProcessingActions}
      />
      <label htmlFor={`cid${cid.id}`} className="flex w-full cursor-pointer">
        <div className="flex flex-1 flex-col space-y-1 text-sm">
          <div className="mx-2 mt-1 flex w-full items-center justify-between">
            <span title="Code" className="font-bold">
              {feeDisplay}
            </span>
            <span title="Price" className="font-semibold">
              {getMoneyCurrencyAlt(basePrice?.amount?.currency)}
              {price.toFixed(2)}
            </span>
          </div>
          <div className="mx-2 inline-flex text-slate-400">{cid.code?.text}</div>
        </div>
      </label>
    </div>
  )
}

type Props = {
  cid: ChargeItemDefinition
  selected: boolean
  totalItemsCost: number
  selectCid(): void
}

export { OrderFeeListItem }
