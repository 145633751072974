import { type IconDefinition, faLink, faLocationDot } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useIsMutating } from "@tanstack/react-query"
import type { DataTableSelectionMultipleChangeEvent } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { TabPanel } from "primereact/tabview"
import { type FC, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { Button, ModulesId, SplitButton, VerticalTabView } from "commons"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"
import { SYSTEM_VALUES } from "system-values"

import { useCWSearch, useCWSetup } from "../hooks"
import type { PersonOption } from "../types"
import { HgPatientLinkAddress } from "./HgPatientLinkAddress"
import { CWPersonsTable } from "./PersonSelectionTable"

const views = ["labs", "vitals", "conditions", "allergies", "social-history", "family-history", "medication-statements"]

const Patient360: FC = () => {
  const { appSubModules } = useAppModuleContext()
  const [params, setParams] = useSearchParams()
  const [showDialog, setShowDialog] = useState<"CW" | "HgPatient" | undefined>(undefined)
  const [selectedView, setSelectedView] = useState(0)

  const { patient, patientId, refreshPatientData } = usePatientContext()
  const { persons, isLoading, updatePersonsSelection } = useCWSearch(patientId)

  const isLinkedPatient = patient.identifier?.some(({ system }) => system === SYSTEM_VALUES.HEALTH_GORILLA_COMMON_WELL)

  const { setup, isLoading: isSettingUp } = useCWSetup(patientId, () => {
    setShowDialog(undefined)
    refreshPatientData()
  })
  const previewInfoMutations = useIsMutating({ mutationKey: ["preview-patient-info"], exact: true })
  const isMutating = previewInfoMutations !== 0

  const openDialog = (view: "CW" | "HgPatient") => {
    setShowDialog(view)
  }

  const closeDialog = () => {
    setShowDialog(undefined)
  }

  const handleSelectionChange = (e: DataTableSelectionMultipleChangeEvent<PersonOption[]>) => {
    updatePersonsSelection(e.value)
  }

  const handleSetup = () => {
    setup({ personsSelection: persons, isNew: !isLinkedPatient && !persons.some(({ selected }) => selected) })
  }

  useEffect(() => {
    const subView = params.get("subview")
    const viewIndex = views.findIndex((view) => view === subView)
    setSelectedView(viewIndex !== -1 ? viewIndex : 0)
  }, [params])

  const handlePanelChange = (index: number) => {
    params.set("subview", views[index] ?? "basic-info")
    setParams(params)
  }

  const hasUsableAddress = useMemo(() => {
    return patient.address?.some(({ use }) => !!use) ?? false
  }, [patient.address])

  return (
    <>
      <VerticalTabView
        selectedPanel={selectedView}
        panelChange={handlePanelChange}
        tabsFooter={
          <div className="flex min-w-60 justify-center border-t-2 border-gray-100 px-2 py-4">
            <SplitButton
              icon={faLink}
              autoUpdateMainAction
              actions={[
                {
                  icon: faLink,
                  label: isLinkedPatient ? "Update linked patient" : "Link patient(s)",
                  onSelectClick: () => openDialog("CW"),
                  disabled: isMutating || !hasUsableAddress,
                  description: "Select the patient(s) do you want to link",
                },
                {
                  icon: faLocationDot,
                  label: "Search addresses",
                  onSelectClick: () => openDialog("HgPatient"),
                  description: "Add more addresses to help increase the records retrieved from the network",
                },
              ]}
              loading={isLoading}
              disabled={isMutating}
              defaultActionIndex={!hasUsableAddress ? 1 : undefined}
            />
          </div>
        }
      >
        {Object.values(appSubModules[ModulesId.PATIENT_360]).map((submodule) => (
          <TabPanel
            key={submodule.getId()}
            headerTemplate={<HeaderTemplate title={submodule.getDisplay()} icon={submodule.getIcon()} />}
          >
            <span className="mb-4 ml-2">{submodule.getDisplay()}</span>
            {submodule.renderContent()}
          </TabPanel>
        ))}
      </VerticalTabView>
      <Dialog
        visible={!!showDialog}
        onHide={closeDialog}
        draggable={false}
        className="min-h-[550px] w-full md:h-2/3 md:w-3/4 lg:w-3/5 2xl:w-1/2"
        header={showDialog === "CW" ? "Link network persons" : "Patient addresses"}
        footer={
          showDialog === "CW" && (
            <div className="mt-2">
              <Button label="Close" className="p-button button-default" disabled={isSettingUp} onClick={closeDialog} />
              <Button
                label="Confirm"
                className="p-button button-primary"
                disabled={isSettingUp}
                loading={isSettingUp}
                onClick={() => (showDialog === "CW" ? handleSetup() : () => ({}))}
              />
            </div>
          )
        }
      >
        {showDialog === "CW" ? (
          <CWPersonsTable persons={persons} onSelectionChange={handleSelectionChange} />
        ) : (
          <HgPatientLinkAddress patient={patient} />
        )}
      </Dialog>
    </>
  )
}

const HeaderTemplate = ({ title, icon }: { title: string; icon: IconDefinition }) => (
  <div className="flex min-w-[10rem] items-center">
    <FontAwesomeIcon icon={icon} />
    <span className="ml-2">{title}</span>
  </div>
)

export { Patient360 }
