import {
  faLocationDot,
  faUser,
  faUserDoctor,
  faCalendarClock,
  faCalendarDay,
  faCalendarDays,
  faMemoCircleInfo,
} from "@fortawesome/pro-solid-svg-icons"
import { format } from "date-fns/format"
import { type FC, Fragment, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { formatsByTypes } from "data"

import { MenuStyles } from "../../types"
import { ConfirmDialog } from "../../components/ConfirmDialog"
import { EmptyMessage } from "../../components/EmptyMessage"
import { StackedListContainer } from "../../components/StackedListContainer"
import type { StackedListItemProps } from "../../components/StackedListItem"
import type { ActivityEventData } from "../types"

const ActivityHistory: FC<Props> = ({ activityRecordsByMR }) => {
  const [memoText, setShowMemo] = useState<string>()
  const hasActivityRecord = !!(activityRecordsByMR && Object.keys(activityRecordsByMR).length)

  return hasActivityRecord ? (
    <>
      <div className="flex h-full w-full flex-col overflow-y-auto">
        {hasActivityRecord && (
          <>
            {Object.entries(activityRecordsByMR).map(([mrId, mrActivity]) => (
              <Fragment key={mrId}>
                <div className="relative mt-6 mb-2 border-t border-gray-200">
                  <span className="absolute top-0 -translate-y-1/2 bg-white px-2 text-sm font-medium text-gray-500">
                    {mrActivity[0]?.entity?.[0]?.name}
                  </span>
                </div>
                <StackedListContainer
                  key={mrId}
                  data={mrActivity as ActivityEventData[]}
                  className="grid w-full list-none grid-flow-row !divide-none px-3"
                  itemModelBuilder={(item) => modelBuilder({ ...item, onShowMemo: setShowMemo })}
                />
              </Fragment>
            ))}
          </>
        )}
      </div>
      <ConfirmDialog
        hideDialog={() => setShowMemo(undefined)}
        closable
        closeOnEscape
        headerTitle="Memo"
        confirmText={memoText}
        visible={!!memoText}
      />
    </>
  ) : (
    <EmptyMessage
      icon={faCalendarClock}
      message="No activity recorded for this order"
      subMessage={false}
      className="h-full content-center"
    />
  )
}

const modelBuilder = ({
  participants,
  from,
  to,
  recorded,
  event,
  location,
  memo,
  onShowMemo,
}: ActivityEventData & { onShowMemo(memo: string): void }): StackedListItemProps => {
  return {
    leftData: [
      {
        lineItems: [{ name: "Event", value: event?.[0]?.display ?? event?.[0]?.code ?? "Unspecified" }],
      },
      {
        lineItems: [
          ...participants.map((p) => ({
            name: p.type,
            value: `${p.requestor ? "Requestor: " : ""}${p.name}`,
            icon: p.type === "Practitioner" ? faUserDoctor : faUser,
          })),
          {
            name: "Date",
            value: format(recorded, formatsByTypes.SHORT_DATETIME_WITH_TIMEZONE),
            icon: faCalendarDays,
          },
          ...(location?.display ? [{ name: "Location", value: location.display, icon: faLocationDot }] : []),
          ...(from
            ? [
                {
                  name: "Rescheduled from",
                  value: format(from, formatsByTypes.SHORT_DATETIME_WITH_TIMEZONE),
                  icon: faCalendarClock,
                },
              ]
            : []),
          ...(to
            ? [
                {
                  name: "Rescheduled to",
                  value: format(to, formatsByTypes.SHORT_DATETIME_WITH_TIMEZONE),
                  icon: faCalendarDay,
                },
              ]
            : []),
        ],
      },
    ],
    ...(memo
      ? {
          menu: [
            {
              icon: <FontAwesomeIcon icon={faMemoCircleInfo} />,
              label: "Memo",
              command: () => onShowMemo(memo),
            },
          ],
          menuStyle: MenuStyles.ExternalAction,
        }
      : {}),
  }
}

type Props = {
  activityRecordsByMR?: Record<string, ActivityEventData[]>
}

export { ActivityHistory }
