import { type FieldProps, type FormikValues, useFormikContext } from "formik"
import { type FC, useEffect, useMemo } from "react"
import { classNames } from "primereact/utils"
import { RadioButton } from "primereact/radiobutton"
import type { Coding } from "fhir"

import { useOrganizationContext } from "organization"
import { BLOOD_DRAW_CODES, DRAW_BLOOD_MODES } from "data"

import { CheckBoxField } from "../CheckBoxField"
import { DateField } from "../DateField"
import { FormField, type FormFieldBaseProps } from "../FormField"

const BloodDrawField: FC<Props> = ({
  handleUpdateBDIO,
  handleUpdateSpecimenDate,
  label = "Draw blood in office",
  checkboxClassName,
  performerId,
  ...formFieldProps
}) => {
  const {
    values: { bloodDrawnInOffice, bloodDrawnMode },
    setFieldValue,
  } = useFormikContext<FormikValues & { bloodDrawnInOffice: boolean; bloodDrawnMode: string | undefined }>()

  const { performerLabsEnabled } = useOrganizationContext()

  const { isAmPerformer } = useMemo(() => {
    const amProvider = performerLabsEnabled.find(({ lab }) => lab.identifier?.some(({ value }) => value === "am-lab"))
    const isAmPerformer = performerId && amProvider?.ref?.id === performerId
    return { amProvider, isAmPerformer }
  }, [performerLabsEnabled, performerId])

  useEffect(() => {
    if (isAmPerformer && !bloodDrawnMode) {
      setFieldValue("bloodDrawnInOffice", true).finally(() => setFieldValue("bloodDrawnMode", "in-office"))
      handleUpdateBDIO?.(true)
    }
  }, [isAmPerformer, bloodDrawnMode])

  return (
    <>
      {isAmPerformer ? (
        <BloodDrawOptions
          horizontal={formFieldProps.horizontal}
          labelClassName={formFieldProps.labelClassName}
          options={DRAW_BLOOD_MODES}
          handleChange={(code) => setFieldValue("bloodDrawnInOffice", code === BLOOD_DRAW_CODES.IN_OFFICE)}
          className="flex-1 justify-center"
        />
      ) : (
        <CheckBoxField
          field="bloodDrawnInOffice"
          label={label}
          onCheck={handleUpdateBDIO}
          className={checkboxClassName}
          labelClassName="!text-sm !text-gray-500 !font-medium !leading-[1.0625rem]"
        />
      )}

      <DateField
        field="specimenDate"
        onChange={handleUpdateSpecimenDate}
        showTime
        disabled={!bloodDrawnInOffice}
        label="Specimen Collection Date"
        collectTimeZone
        validation={(value) => (!value && bloodDrawnInOffice ? "Specimen date is required" : undefined)}
        {...formFieldProps}
      />
    </>
  )
}

const BloodDrawOptions: FC<OptionProps> = ({
  options,
  horizontal,
  labelClassName,
  className,
  disabled,
  handleChange,
}) => {
  return (
    <FormField field="bloodDrawnMode" horizontal={horizontal} labelClassName={labelClassName} className={className}>
      {({ field: { name, value, onChange } }: FieldProps) => (
        <div className="flex w-full items-center space-x-1">
          {options.map((coding, index) => (
            <div key={index} className="flex items-center text-sm">
              <RadioButton
                inputId={`${coding.code}-${index}`}
                name={name}
                value={coding.code}
                checked={coding.code === value}
                onChange={(e) => {
                  onChange(e)
                  handleChange?.(e.value)
                }}
                disabled={disabled}
              />
              <label
                htmlFor={`${coding.code}-${index}`}
                className={classNames("mx-2 flex w-full cursor-pointer", {
                  "cursor-auto text-gray-500": disabled,
                })}
              >
                {coding.display}
              </label>
            </div>
          ))}
        </div>
      )}
    </FormField>
  )
}

type Props = {
  label?: string
  checkboxClassName?: string
  handleUpdateBDIO?(bdio: boolean): void
  handleUpdateSpecimenDate?(sDate: Date): void
  performerId?: string
} & Omit<FormFieldBaseProps, "validation" | "field">

type OptionProps = {
  horizontal?: boolean
  className?: string
  labelClassName?: string
  disabled?: boolean
  options: Coding[]
  handleChange?(value: string): void
}

export { BloodDrawField }
