import { type FC, useId } from "react"

import { DataContainerSlideoverForm, InfiniteScroll, ModulesId, SkeletonLoader, useCrudReducer } from "commons"
import { ReplaceFormProvider } from "commons/context"
import { useOpenEncounter } from "encounter"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

import type { Laboratory } from "../../laboratory/types"
import { useLabs, useUpdateLab } from "../hooks"
import { LabListItem } from "./LabListItem"
import { LabsForm } from "./LabsForm"
import { getInitialValues, sanitize, validationSchema } from "./validations"

const LabsContainer: FC = () => {
  const loaderKey = useId()
  const { patientId, patientRef } = usePatientContext()
  const { appSubModules } = useAppModuleContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { reset, add, edit, isNew, showSlide, initialValue } = useCrudReducer({
    defaultEntity: getInitialValues(patientRef, openEncounterRef),
  })

  const { labs, isLoading, hasNextPage, fetchNextPage } = useLabs(patientId)
  const { updateLab } = useUpdateLab(reset, isNew)

  const onSubmit = (lab: Laboratory) => {
    updateLab(sanitize(lab))
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  if (isLoading) return loader()

  const deactivate = (lab: Laboratory) => {
    lab.results?.forEach((obs) => {
      obs.status = "cancelled"
    })
    const sanitizedLab = sanitize({ ...lab, dr: { ...lab.dr, status: "cancelled" } })
    updateLab(sanitizedLab)
  }

  return (
    <ReplaceFormProvider>
      <DataContainerSlideoverForm
        hasData={!!labs?.length}
        showSlide={showSlide}
        formTitle="Lab"
        formInitialValue={initialValue}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        onCancel={reset}
        form={<LabsForm patient={patientRef} encounter={openEncounterRef} />}
        onButtonAddClick={add}
        iconDataNotFound={appSubModules["intake"][ModulesId.LABS].getIcon()}
      >
        <div className="h-full overflow-auto bg-white">
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
            <ul className="divide-y divide-gray-200">
              {labs?.map((lab, index) => (
                <LabListItem
                  key={lab.dr.id ?? index}
                  lab={lab}
                  onDelete={() => deactivate(lab)}
                  onEdit={() => edit(lab)}
                />
              ))}
            </ul>
          </InfiniteScroll>
        </div>
      </DataContainerSlideoverForm>
    </ReplaceFormProvider>
  )
}

export { LabsContainer }
