import { formatDate, parseISO } from "date-fns"
import { codeableConceptAsString } from "fhir"
import { TabPanel, TabView } from "primereact/tabview"
import { useSearchParams } from "react-router-dom"

import { Notes, NotesUseContext, SkeletonLoader } from "commons"
import { formatsByTypes } from "data"
import { usePatientContext } from "patients"
import { SYSTEM_VALUES } from "system-values"

import { usePatientProcedures } from "../hooks"
import { ItemDetails } from "./details/ItemDetails"
import { proceduresQueryKeys } from "../query-keys"

const ProcedureDetails = () => {
  const { patientId } = usePatientContext()
  const [searchParams] = useSearchParams()
  const procedureId = searchParams.get("procedureId") as string

  const { isLoading, proceduresData } = usePatientProcedures({ patientId, procedureId })

  const { procedure, performerName, configurationItem } = proceduresData[0] ?? {}

  const isMassage =
    procedure?.code?.coding?.find((cc) => cc.system === SYSTEM_VALUES.PROCEDURE_TYPE)?.code === "massage-procedure"
  const isBotox =
    procedure?.code?.coding?.find((cc) => cc.system === SYSTEM_VALUES.PROCEDURE_TYPE)?.code === "botox-procedure"

  return isLoading ? (
    <SkeletonLoader loaderType="two-lines" repeats={3} extraLine />
  ) : (
    <div className="@container flex flex-1 flex-col gap-2 overflow-y-scroll pb-6 pl-3 text-gray-600">
      <div className="flex flex-col gap-1">
        <div className="flex justify-between">
          <span title="Order number" className="text-lg font-medium text-primary">
            {codeableConceptAsString(procedure?.code)}
          </span>
        </div>
        <div className="flex justify-between p-3 text-sm">
          <div className="flex gap-1">
            <label className="text-gray-900">Performer:</label>
            <span className="text-gray-400">{performerName}</span>
          </div>
          {procedure?.performed?.dateTime && (
            <div className="flex gap-1">
              <label className="text-gray-900">Date:</label>
              <span className="text-gray-400">
                {formatDate(parseISO(procedure.performed.dateTime), formatsByTypes.LONG_DATE)}
              </span>
            </div>
          )}
          <div className="flex gap-1">
            <label className="text-gray-900">Status:</label>
            <span className="text-gray-400 capitalize">
              {procedure?.status === "revoked" ? "cancelled" : procedure?.status ?? "unspecified"}
            </span>
          </div>
        </div>
      </div>
      <TabView className="flex h-full flex-col overflow-y-scroll" panelContainerClassName="grow">
        <TabPanel
          header={<span className="text-sm">{isMassage ? "Massages" : "Medications"}</span>}
          visible={!!configurationItem?.length}
        >
          <ItemDetails items={configurationItem} isMassage={isMassage} isBotox={isBotox} />
        </TabPanel>
        <TabPanel header={<span className="text-sm">ICD-10 Codes</span>} visible={!!procedure?.reasonCode?.length}>
          <div className="flex flex-col divide-y divide-gray-200">
            {procedure?.reasonCode?.map((cc, index) => (
              <span key={index} className="py-3 text-sm font-medium text-gray-900">
                {cc.coding?.[0]?.code
                  ? `${codeableConceptAsString(cc)} - ${cc.coding?.[0]?.code}`
                  : codeableConceptAsString(cc)}
              </span>
            ))}
          </div>
        </TabPanel>
        {procedureId && (
          <TabPanel header={<span className="text-sm">Notes</span>}>
            <Notes
              notes={procedure.note}
              className="mt-3 w-full"
              editProps={{
                parentId: procedureId,
                context: NotesUseContext.PROCEDURE,
                notShowFullDataNotFound: false,
                queriesToInvalidate: proceduresQueryKeys.list(patientId, undefined, undefined, undefined, procedureId),
              }}
            />
          </TabPanel>
        )}
      </TabView>
    </div>
  )
}

export { ProcedureDetails }
