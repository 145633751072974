import { humanNameAsString } from "fhir"
import { Column } from "primereact/column"
import { type DataTableSelectionMultipleChangeEvent, DataTable } from "primereact/datatable"
import type { FC } from "react"

import { calculateAge, getStringAddressByType } from "utils"

import type { PersonOption } from "../types"

const CWPersonsTable: FC<Props> = ({ persons, onSelectionChange }) => {
  return (
    <DataTable
      value={persons}
      dataKey="resource.id"
      size="small"
      selectionMode="multiple"
      selection={persons.filter(({ selected }) => selected)}
      onSelectionChange={onSelectionChange}
    >
      <Column selectionMode="multiple" headerStyle={{ minWidth: "1rem", width: "40px" }} />
      <Column
        field="name"
        header="Name"
        body={({ name }) => (
          <p title="Name" className="mr-2 font-bold">
            {humanNameAsString(name?.[0])}
          </p>
        )}
        headerStyle={{ minWidth: "10rem" }}
      />
      <Column
        field="birthDate"
        header="Age"
        body={({ birthDate }) => {
          return (
            <p title="Name" className="mr-2 font-bold">
              {calculateAge(birthDate)}
            </p>
          )
        }}
        headerStyle={{ minWidth: "8rem" }}
      />
      <Column
        field="gender"
        header="Biological Sex"
        body={({ gender }) => (
          <p title="Name" className="mr-2 font-bold">
            {gender}
          </p>
        )}
        headerStyle={{ minWidth: "8rem" }}
      />
      <Column
        field="address"
        header="Address"
        body={({ address }) => {
          const homeAddress = getStringAddressByType(address)

          return (
            <p title="Name" className="mr-2 font-bold">
              {homeAddress.toLowerCase().includes("unspecified")
                ? getStringAddressByType({ address, addressUse: "temp" })
                : homeAddress}
            </p>
          )
        }}
        headerStyle={{ minWidth: "8rem" }}
      />
    </DataTable>
  )
}

type Props = {
  persons: PersonOption[]
  onSelectionChange: (e: DataTableSelectionMultipleChangeEvent<PersonOption[]>) => void
}

export { CWPersonsTable }
