import { $generateHtmlFromNodes } from "@lexical/html"
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin"
import { type InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { HeadingNode } from "@lexical/rich-text"
import { type EditorState, type LexicalEditor } from "lexical"
import { useRef } from "react"

import "./editor.css"
import EditorTheme from "./EditorTheme"
import { FloatingMenuPlugin } from "./plugins/FloatingMenuPlugin"
import { SnippetsTypeHeadPlugin } from "./plugins/SnippetsTypeHeadPlugin"
import { TemplatePlugin } from "./plugins/TemplatePlugin"

const BubbleEditor = ({ initialValue, onChange, readOnly, disabled, placeholder, autoFocus }: BubbleEditorProps) => {
  const editorConfig: InitialConfigType = {
    namespace: "Bubble Editor",
    onError(error: Error) {
      throw error
    },
    nodes: [HeadingNode],
    editable: !readOnly || !disabled,
    theme: EditorTheme,
  }

  // Track whether we're in the onChange handler to prevent cursor position reset
  const isUpdatingRef = useRef(false)

  const handleEditorChange = (editorState: EditorState, editor: LexicalEditor) => {
    if (onChange && !isUpdatingRef.current) {
      isUpdatingRef.current = true
      editorState.read(() => {
        const htmlString = $generateHtmlFromNodes(editor)
        onChange(htmlString)
      })
      setTimeout(() => {
        isUpdatingRef.current = false
      }, 0)
    }
  }

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container">
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder placeholder={placeholder} />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          {!!initialValue && <TemplatePlugin template={initialValue} />}
          <HistoryPlugin />
          {autoFocus && <AutoFocusPlugin />}
          <SnippetsTypeHeadPlugin />
          <FloatingMenuPlugin />
          <OnChangePlugin onChange={handleEditorChange} />
        </div>
      </div>
    </LexicalComposer>
  )
}

const Placeholder = ({ placeholder }: { placeholder?: string }) => {
  return <div className="editor-placeholder">{placeholder || "Enter # for snippets.."}</div>
}

type BubbleEditorProps = {
  initialValue?: string
  onChange?: (value: string) => void
  readOnly?: boolean
  disabled?: boolean
  placeholder?: string
  autoFocus?: boolean
}

export { BubbleEditor }
