import type { CarePlan, PlanDefinitionActionArrayActionArray } from "fhir"
import type { FC } from "react"

import { useChartContext } from "chart-view"
import { conditionModelBuilder } from "conditions"
import { useAppModuleContext } from "modules"

import { ModulesId } from "../../../../Module"
import type { QuestionnaireData } from "../../../../types"
import { type Intake, PLAN_ACTION_CODES } from "../../../types"
import { IntakeDataSection } from "./IntakeDataSection"
import { IntakeObservationsSection } from "./IntakeObservationsSection"
import { ManualAllergies } from "./ManualAllergies"
import { ManualConditions } from "./ManualConditions"
import { ManualMedStatements } from "./ManualMedStatements"

const CPIntakesDetails: FC<Props> = ({ carePlan, intakes, configureActions, questionnaireData }) => {
  const { isModuleActive, appSubModules } = useAppModuleContext()
  const { showSubModule } = useChartContext()

  const manualConditionsAct = configureActions[PLAN_ACTION_CODES.MANUAL_CONDITION]
  const manualAllergiesAct = configureActions[PLAN_ACTION_CODES.MANUAL_ALLERGY]
  const manualMedStatementsAct = configureActions[PLAN_ACTION_CODES.MANUAL_MED_STATEMENT]

  return (
    <div className="mx-auto my-6 flex flex-col gap-4">
      <div className="intakes-info flex flex-col space-y-4">
        {intakes?.map((intake) => (
          <section key={intake.questionnairesData.qResponse!.id} className="flex flex-col divide-y divide-gray-200">
            {!!intake.observations?.length && (
              <IntakeObservationsSection
                observations={intake.observations}
                elementDetail={
                  <span className="text-sm text-gray-400">{intake.questionnairesData.questionnaire.title}</span>
                }
              />
            )}

            {!!intake?.conditions?.length && (
              <IntakeDataSection
                title="Conditions"
                data={intake.conditions}
                modelBuilder={conditionModelBuilder}
                onLinkClicked={() => showSubModule({ subModule: appSubModules.intake[ModulesId.CONDITIONS] })}
                isLinkVisible={isModuleActive(ModulesId.INTAKE)}
                className="gap-4 pt-3"
              />
            )}
          </section>
        ))}
      </div>

      {!!manualAllergiesAct && (
        <ManualAllergies carePlan={carePlan} action={manualAllergiesAct} questionnaireData={questionnaireData} />
      )}
      {!!manualConditionsAct && (
        <ManualConditions carePlan={carePlan} action={manualConditionsAct} questionnaireData={questionnaireData} />
      )}
      {!!manualMedStatementsAct && (
        <ManualMedStatements
          carePlan={carePlan}
          action={manualMedStatementsAct}
          questionnaireData={questionnaireData}
        />
      )}
    </div>
  )
}

type Props = {
  carePlan: CarePlan
  intakes?: Intake[]
  configureActions: Record<string, PlanDefinitionActionArrayActionArray>
  questionnaireData?: QuestionnaireData[]
}

export { CPIntakesDetails }
