import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatDate } from "date-fns/format"
import { parseISO } from "date-fns/parseISO"
import { type Account, type Address, codeableConceptAsString, humanNameAsString, isOrganization, isPatient } from "fhir"
import pluralize from "pluralize"
import { type FC, useMemo } from "react"

import { useReaders } from "commons/readers"
import { creditCardTypes } from "data"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"
import { getMoneyCurrencyAlt, strCapitalize } from "utils"

import type { CustomInvoiceData } from "../types"
import { getSummaryParameter } from "../utils"
import { OrderSummary } from "./OrderSummary"

const InvoicePreviewSection: FC<
  Props & {
    invoiceData?: CustomInvoiceData
    readerAccount?: Account
  }
> = ({
  invoiceData,
  nutrasShippingAddress,
  showMedsAddress,
  readerAccount,
  showProductsSection = false,
  summaryAsCard = false,
}) => {
  const { currentOrganizationCCAccount, currentOrganization, currentOrganizationId } = useOrganizationContext()
  const { patient, preferedCreditCard } = usePatientContext()

  const { readers, locationsByReader } = useReaders(currentOrganizationId)
  const readerInfo = useMemo(() => {
    if (readerAccount) {
      const reader = readers?.find(({ id }) => readerAccount.subject?.[0]?.id === id)
      const locationStr = reader && locationsByReader?.[reader.id ?? ""]?.name
      const typeStr = codeableConceptAsString(readerAccount.type)

      return (
        <div className="flex flex-col">
          <p>{typeStr}</p>
          <p className="line-clamp-1 overflow-hidden break-words text-ellipsis">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
            {`${reader?.deviceName?.[0]?.name} | ${locationStr}`}
          </p>
        </div>
      )
    }
  }, [readerAccount, readers, locationsByReader])

  const { isPracticeInvoice } = useMemo(
    () => ({
      isPracticeInvoice: isOrganization(invoiceData?.invoice?.recipient),
      isPatientInvoice: isPatient(invoiceData?.invoice?.recipient),
    }),
    [invoiceData],
  )

  if (!invoiceData) return null

  const currency = getMoneyCurrencyAlt(invoiceData?.invoice?.totalNet?.currency)
  const summary = invoiceData?.summary
  const paymentCreditCard = isPracticeInvoice ? currentOrganizationCCAccount?.creditCard?.[0] : preferedCreditCard
  const hasMedsRequest = !!invoiceData.items?.nutraceutical?.length || !!invoiceData.items?.medication?.length
  const invoiceItems = Object.entries(invoiceData.items)
  const itemsSubtotal = invoiceData.itemsSubtotal + invoiceData.productFeesSubtotal + invoiceData.shippingMethodSubtotal

  const amendments = Object.entries(invoiceData.amendments)

  return (
    <div className="relative flex gap-8 px-3 first-of-type:border-t-0">
      <section className="flex-1 grow">
        <h3 className="inline-flex w-full items-center justify-between space-x-3 text-sm font-medium">
          <span className="text-gray-700">{isPracticeInvoice ? "Bill to Practice" : "Bill to Patient"}</span>{" "}
          <hr className="flex-1 border border-gray-200" />
        </h3>
        <div className="flex flex-col py-3 pl-2 text-sm">
          <div className="flex flex-col justify-between border-b border-gray-200">
            <div className="w-1/3">Information</div>
            <div className="w-full flex-1">
              <div className="flex justify-between border-b border-gray-200 pb-2">
                <div className="mr-2 font-light">Date</div>
                <div>
                  {invoiceData?.invoice?.date
                    ? formatDate(parseISO(invoiceData.invoice?.date), "MM/dd/yy")
                    : "Unspecified"}
                </div>
              </div>

              <div className="flex justify-between border-b border-gray-200 py-2">
                <div className="mr-2 font-light">Name</div>
                <div className="text-right">
                  <div>{isPracticeInvoice ? currentOrganization.name : humanNameAsString(patient.name?.[0])}</div>
                </div>
              </div>

              <AddressItem
                label="Shipping address"
                show={showMedsAddress && hasMedsRequest}
                address={nutrasShippingAddress}
              />

              <div className="flex justify-between py-2">
                <div className="mr-2 font-light text-nowrap">Payment Method</div>
                <div className="text-right">
                  {readerAccount ? (
                    readerInfo
                  ) : paymentCreditCard ? (
                    <>{`${
                      creditCardTypes.find((cc) => cc.code === paymentCreditCard.type)?.label?.toUpperCase() ??
                      "Unknown"
                    } | ${paymentCreditCard.last4Digits}`}</>
                  ) : (
                    <>N/A</>
                  )}
                </div>
              </div>
            </div>
          </div>

          {!!showProductsSection && !!invoiceItems.length && (
            <>
              <div className="grid w-full grid-cols-4 gap-3 pt-2">
                <span>Product Type</span>
                <span className="col-span-2">Description</span>
                <div className="col-span-1 flex justify-between">
                  <span>Qty</span>
                  <span>Price</span>
                </div>
              </div>
              {invoiceItems.map(([pType, items]) => (
                <div
                  key={pType}
                  className="grid grid-cols-4 items-baseline gap-3 border-b border-gray-200 py-2 text-sm"
                >
                  <div className="col-span-1 inline-block pt-1 font-light">
                    {pType === "medication" ? "Pharmaceuticals" : pluralize(strCapitalize(pType), 2)}
                  </div>
                  <div className="col-span-3 font-light">
                    {items.map(({ description, price, qty, productId }, index) => (
                      <div
                        className="border-b border-gray-200 py-2 last-of-type:border-b-0 last-of-type:pb-0"
                        key={`product_${index}`}
                      >
                        <div className="grid w-full grid-cols-3 items-center gap-3">
                          <span title="Product" className="col-span-2">
                            {description}
                          </span>
                          <div className="col-span-1 flex justify-between">
                            <span title="Quantity">x{qty ?? 1}</span>
                            <span title="Price" className="font-normal">
                              {`${price < 0 ? "-" : ""}${currency}${(price < 0 ? price * -1 : price).toFixed(2)}`}
                            </span>
                          </div>
                        </div>
                        {pType === "procedure" &&
                          invoiceData.productFees?.[productId as string]?.map(({ description, price }, index) => (
                            <div
                              key={`procedure_fee_${index}`}
                              className="grid grid-cols-3 items-center font-light"
                              title="Fee"
                            >
                              <span className="col-span-2">{description}</span>
                              <span className="inline-block text-right font-normal">{currency + price.toFixed(2)}</span>
                            </div>
                          ))}
                      </div>
                    ))}

                    {invoiceData.shippingMethods[pType]?.map(({ description, price }, index) => (
                      <div key={`shipping_${index}`} className="grid grid-cols-3 items-center py-2 last-of-type:pb-0">
                        <span className="col-span-2">{description}</span>
                        <span className="inline-block text-right font-normal">{currency + price.toFixed(2)}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </>
          )}

          {!!amendments.length && (
            <>
              <div className="w-1/3 pt-2">Amendments</div>
              <div className="grid w-full grid-cols-4 gap-3 pt-2">
                <span></span>
                <span className="col-span-2">Description</span>
                <div className="flex justify-end">
                  <span>Price</span>
                </div>
              </div>
              {amendments.map(([pType, items]) => (
                <div
                  key={pType}
                  className="grid grid-cols-4 items-baseline gap-3 border-b border-gray-200 py-2 text-sm"
                >
                  <div className="col-span-1 inline-block pt-1 font-light">
                    {pType === "medication" ? "Pharmaceuticals" : pluralize(strCapitalize(pType), 2)}
                  </div>
                  <div className="col-span-3 font-light">
                    {items.map(({ description, price }, index) => (
                      <div
                        className="border-b border-gray-200 py-2 last-of-type:border-b-0 last-of-type:pb-0"
                        key={`product_${index}`}
                      >
                        <div className="grid w-full grid-cols-3 gap-3">
                          <span title="Product" className="col-span-2">
                            {description}
                          </span>
                          <div className="col-span-1 flex justify-end">
                            <span title="Price" className="font-normal">
                              {`${price < 0 ? "-" : ""}${currency}${(price < 0 ? price * -1 : price).toFixed(2)}`}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </>
          )}

          {(!summary || !!invoiceData.discounts) && (
            <div className="flex justify-between border-b border-gray-200 py-2 text-sm">
              <span className="inline-block">Subtotal</span>
              <span className="inline-block text-right">{`${itemsSubtotal < 0 ? "-" : ""}${currency}${itemsSubtotal < 0 ? (itemsSubtotal * -1).toFixed(2) : itemsSubtotal.toFixed(2)}`}</span>
            </div>
          )}
          {!!invoiceData.discounts && (
            <div className="flex items-baseline justify-between border-b border-gray-200 py-2">
              <h4 className="inline-block w-1/3 pt-1 text-sm">Discounts</h4>
              {Object.entries(invoiceData.discounts).map(([pType, items], index) => (
                <div key={`discount_${index}`} className="flex w-full flex-1 justify-between text-sm font-light">
                  <span>{pType === "medication" ? "Pharmaceuticals" : pluralize(strCapitalize(pType), 2)}</span>
                  {items.map(({ price }, index) => (
                    <span key={`${pType}_${index}`} className="inline-block text-right font-normal">
                      {"-" +
                        currency +
                        (
                          (summary
                            ? (getSummaryParameter(summary, "discounts") as Record<string, number>)[pType] ?? price
                            : price) * -1
                        ).toFixed(2)}
                    </span>
                  ))}
                </div>
              ))}
            </div>
          )}
          {summary && !summaryAsCard && (
            <OrderSummary
              summary={summary}
              hasMedicationItems={hasMedsRequest}
              currency={currency}
              className="mt-3 py-2"
            />
          )}
        </div>
      </section>

      {summary && summaryAsCard && (
        <OrderSummary
          summary={summary}
          hasMedicationItems={hasMedsRequest}
          currency={currency}
          className="mb-4 h-fit min-w-48 border-collapse rounded-xl border border-gray-200 p-4 shadow-xs"
          titleClassName="font-semibold"
        />
      )}
    </div>
  )
}

const AddressItem = ({ label, address, show }: { label: string; show?: boolean; address?: Address }) => {
  if (!show) {
    return null
  }

  return (
    <div className="flex justify-between border-b border-gray-200 py-2">
      <div className="mr-2 font-light">{label}</div>
      <div className="text-right">
        {address ? (
          <div>
            <div>{address?.line?.[0]}</div>
            {address?.line?.[1] && <div>{address?.line?.[1]}</div>}
            <div>{`${address?.city}, ${address.state} ${address.postalCode}`}</div>
          </div>
        ) : (
          <div>No shipping address</div>
        )}
      </div>
    </div>
  )
}

type Props = {
  nutrasShippingAddress?: Address
  showMedsAddress?: boolean
  showProductsSection?: boolean
  summaryAsCard?: boolean
}

export { InvoicePreviewSection }
