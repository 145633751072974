import { faSearch, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { ResourceObject } from "fhir"
import { type FieldArrayRenderProps, type FormikValues, ErrorMessage, FieldArray } from "formik"
import pluralize from "pluralize"
import { classNames } from "primereact/utils"
import { Fragment, type ReactNode, useState } from "react"

import { AddFieldArrayItemButton } from "../components/Buttons"
import { ConfirmDialog } from "../components/ConfirmDialog"
import { type StackedListItemProps, StackedListItem } from "../components/StackedListItem"
import { MenuStyles } from "../types"

const GenericFieldArray = <T extends ResourceObject | FormikValues>({
  field,
  label,
  readOnly = false,
  addButttonDisabled,
  addButtonLabel,
  onAddButtonClick,
  emptyDataMessage,
  itemModelBuilder,
  confirmDeleteItemText,
  onRemoveItem,
  addButtonVisible = true,
  askDeleteConfirmation = true,
  children,
  className,
  fieldLabelVisible = !!label,
  itemsVisible = true,
  emptyMessageVisible = true,
  containerClassName,
  labelClassName = "text-sm",
  itemClassName,
  horizontal,
}: Props<T>) => {
  const [deleteIndex, setDeleteIndex] = useState<number>()

  const modelBuilder = (item: T, index: number, onDelete: (index: number) => void) => {
    const model = itemModelBuilder(item, index)
    model.menu = [
      ...(model.menu ? model.menu : []),
      ...(!readOnly && !model.readOnlyItem
        ? [
            {
              label: "Delete",
              icon: (
                <FontAwesomeIcon icon={faTrashCan} size="1x" className={classNames({ "mr-1": model.menu?.length })} />
              ),
              command: () => onDelete(index),
            },
          ]
        : []),
    ]
    if (model.menu.length === 1) model.menuStyle = MenuStyles.ActionItems
    return model
  }

  return (
    <FieldArray name={field}>
      {(props: FieldArrayRenderProps) => {
        const {
          remove,
          name,
          form: { getFieldMeta },
        } = props

        const fieldValue = getFieldMeta(field).value
        const fError = getFieldMeta(field).error
        const fTouched = getFieldMeta(field).touched

        return (
          <div className={classNames({ "horizontal inline-flex w-full justify-between": horizontal }, className)}>
            {fieldLabelVisible && (
              <label
                className={classNames(
                  "font-medium text-gray-700",
                  { "mt-2 mr-3 mb-0 py-4": horizontal },
                  labelClassName,
                )}
                htmlFor={field}
              >
                {label}
              </label>
            )}
            <div className="flex flex-1 flex-col">
              {!readOnly && (
                <>
                  {addButtonVisible && (
                    <AddFieldArrayItemButton
                      label={addButtonLabel}
                      disabled={addButttonDisabled}
                      className="p-4 px-0"
                      iconClassName="h-8 w-8"
                      onClick={onAddButtonClick}
                    />
                  )}

                  {askDeleteConfirmation && (
                    <ConfirmDialog
                      confirmText={
                        confirmDeleteItemText ??
                        `Are you sure you want to delete this ${pluralize((label ?? "item").toLowerCase(), 1)}?`
                      }
                      visible={deleteIndex !== undefined}
                      hideDialog={() => setDeleteIndex(undefined)}
                      actionName="Delete"
                      onConfirm={() => {
                        Array.isArray(fieldValue) &&
                          fieldValue[deleteIndex as number] &&
                          onRemoveItem?.(fieldValue[deleteIndex as number], props)
                        remove(deleteIndex as number)
                      }}
                    />
                  )}
                </>
              )}
              {children?.(props)}
              {itemsVisible && fieldValue && Array.isArray(fieldValue) && fieldValue.length > 0 ? (
                <ul className={classNames("@container divide-y divide-gray-200", containerClassName)}>
                  {fieldValue.map((item: T, index: number) => (
                    <Fragment key={`${item.id}_${index}`}>
                      <StackedListItem
                        modelData={modelBuilder(item, index, (i) =>
                          askDeleteConfirmation ? setDeleteIndex(i) : remove(i),
                        )}
                        className={classNames("py-4 !pl-0 first:!pt-0", itemClassName, {
                          "pb-0": Array.isArray(fError) && fError?.[index] !== undefined,
                        })}
                      />
                      {Array.isArray(fError) && fError?.[index] !== undefined && (
                        <ErrorMessage name={`${name}[${index}]`}>
                          {(msg) => typeof msg === "string" && <small className="p-error">{msg}</small>}
                        </ErrorMessage>
                      )}
                    </Fragment>
                  ))}
                </ul>
              ) : (
                emptyMessageVisible && (
                  <div
                    className={classNames("my-2 flex w-full flex-col items-center justify-center py-5", {
                      "rounded-md border border-red-600": fError && fTouched,
                    })}
                  >
                    <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
                    <p className="pt-1 text-xs text-slate-500">
                      {emptyDataMessage ?? `No ${pluralize((label ?? "item").toLowerCase(), 2)} added yet`}
                    </p>
                  </div>
                )
              )}
              <ErrorMessage name={name}>
                {(msg) =>
                  typeof msg === "string" && (
                    <small id={`errorMessage.${field}`} className="p-error">
                      {msg}
                    </small>
                  )
                }
              </ErrorMessage>
            </div>
          </div>
        )
      }}
    </FieldArray>
  )
}

type Props<T> = {
  field: string
  label?: string
  readOnly?: boolean
  addButttonDisabled?: boolean
  addButtonLabel?: string
  onAddButtonClick?(): void
  emptyDataMessage?: string
  itemModelBuilder(item: T, itemIndex: number): ListItemProps
  confirmDeleteItemText?: string
  children?(props: FieldArrayRenderProps): ReactNode
  onRemoveItem?(item: T, props: FieldArrayRenderProps): void
  addButtonVisible?: boolean
  className?: string
  askDeleteConfirmation?: boolean
  fieldLabelVisible?: boolean
  itemsVisible?: boolean
  emptyMessageVisible?: boolean
  containerClassName?: string
  itemClassName?: string
  labelClassName?: string
  horizontal?: boolean
}

type ListItemProps = StackedListItemProps & {
  readOnlyItem?: boolean
}

export { GenericFieldArray }
