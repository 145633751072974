import { type FC, useEffect, useMemo } from "react"

import { useAccountCreditCards } from "account"
import { SkeletonLoader } from "commons"
import { BILLING_TYPES_CODES, PAYMENT_METHODS } from "data"

import { useCPOERequestsContext } from "../hooks"
import { defaultCoveragesByType } from "../utils"
import { OrderCreditCardContainer } from "./OrderCreditCardContainer"
import { OrderReadersContainer } from "./OrderReadersContainer"

const OrdersPayment: FC = () => {
  const {
    activeRequestsInfo: {
      hasBillPatientLabRequest,
      hasBillToPracticeLabRequest,
      hasRXRequest,
      hasNutraRequest,
      hasProcedureRequest,
    },
    readerAccount,
    coverageByType,
    patientId,
    setReaderAccount,
    setCoverageByType,
  } = useCPOERequestsContext()
  const { defaultCreditCard, isLoading: isLoadingCC } = useAccountCreditCards(patientId)

  useEffect(() => {
    const validCoverages = { ...(coverageByType ?? {}) }
    if (!hasRXRequest) {
      delete validCoverages.rx
    }
    if (!hasNutraRequest) {
      delete validCoverages.nutraceutical
    }
    if (!hasNutraRequest || !hasRXRequest) setCoverageByType({ ...defaultCoveragesByType, ...validCoverages })
  }, [hasNutraRequest, hasRXRequest])

  const { showPatientCC, showPracticeCC } = useMemo(() => {
    const activeCoverages = coverageByType
      ? Object.entries(coverageByType)?.reduce((acc, [, coverage]) => {
          const typeCode = coverage?.type?.coding?.[0]?.code
          return typeCode ? [...acc, typeCode] : [...acc]
        }, [] as string[])
      : []

    return {
      showPatientCC:
        hasBillPatientLabRequest ||
        hasProcedureRequest ||
        ((hasNutraRequest || hasRXRequest) &&
          (!activeCoverages.length ||
            activeCoverages.includes(PAYMENT_METHODS.BILL_PATIENT) ||
            (hasNutraRequest && hasRXRequest && activeCoverages.length !== 2))),
      showPracticeCC:
        hasBillToPracticeLabRequest ||
        ((hasNutraRequest || hasRXRequest) && activeCoverages.includes(BILLING_TYPES_CODES.BILL_PRACTICE)),
    }
  }, [coverageByType, hasBillPatientLabRequest, hasBillToPracticeLabRequest, hasNutraRequest, hasRXRequest])

  if (isLoadingCC) return <SkeletonLoader repeats={4} loaderType="one-line" />

  return (
    <div className="mt-4 flex flex-col space-y-5 pr-4">
      {showPracticeCC && (
        <div className="flex flex-col space-y-3 pl-5">
          <h4 className="flex items-center justify-between text-sm font-medium text-gray-700">
            Practice Payment Methods <hr className="ml-3 flex-1 border" />
          </h4>
          <OrderCreditCardContainer isPracticeCC defaultCreditCard={readerAccount ? undefined : defaultCreditCard} />
        </div>
      )}
      {showPatientCC && (
        <div className="flex flex-col space-y-3 pl-5">
          <h4 className="flex items-center justify-between text-sm font-medium text-gray-700">
            Patient Payment Methods
            <hr className="ml-3 flex-1 border" />
          </h4>
          <OrderCreditCardContainer
            isPracticeCC={false}
            defaultCreditCard={readerAccount ? undefined : defaultCreditCard}
          />
          <OrderReadersContainer readerAccount={readerAccount} updateReader={setReaderAccount} />
        </div>
      )}
    </div>
  )
}

export { OrdersPayment }
