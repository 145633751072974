import type { Observation } from "fhir"
import { classNames } from "primereact/utils"

import { Badge } from "commons"

const VitalityDetails = ({ vitality }: Props) => (
  <div className="flex flex-col justify-center space-y-2">
    {vitality.map((v) => (
      <VitalityItem key={v.id} vitalityItem={v} />
    ))}
  </div>
)

const VitalityItem = ({ vitalityItem, onClick, className }: VitalityItemProps) => {
  const vitalityRisk = vitalityItem?.interpretation?.[0]?.text ?? "unspecified"
  return (
    <div
      className={classNames(
        "flex w-[35rem] justify-between self-center rounded-lg border border-gray-200 bg-white p-2",
        onClick ? "cursor-pointer" : "cursor-default",
        className,
      )}
      onClick={onClick}
    >
      <span className={classNames("w-52 font-medium")}>{vitalityItem.code?.text}</span>
      <div className="mr-2 text-right">
        <Badge
          text={`${vitalityRisk} risk`}
          colorStyle={vitalityRisk === "Low" ? "green" : vitalityRisk === "High" ? "red" : "yellow"}
        />
      </div>
    </div>
  )
}

type Props = {
  vitality: Observation[]
}

type VitalityItemProps = {
  className?: string
  vitalityItem: Observation
  onClick?(): void
}

export { VitalityDetails, VitalityItem }
