import {
  type CodeableConcept,
  type Coding,
  type ContactPoint,
  type DocumentReference,
  type Organization,
  type Patient,
  type PatientContactArray,
  type Practitioner,
  type Reference,
  asReference,
} from "fhir"
import * as Yup from "yup"
import { startOfDay } from "date-fns/startOfDay"

import { type PractitionerInfo, sanitizeAddress } from "commons"
import { ContactPointSystem, emptyAddress } from "data"
import { SYSTEM_VALUES } from "system-values"
import { getAddressSchema, getHomeAddressIndex, humanNameSchema, strCapitalize, telecomSchema } from "utils"

import type { DocumentFormData, PatientFormData } from "../types"

const getTelecomInitialValues = (telecom: Partial<ContactPoint>, type: "email" | "phone") => ({
  ...telecom,
  system: type === "email" ? ContactPointSystem.email : ContactPointSystem.phone,
  use: "home",
})

const INITIAL_VALUES: Partial<Patient> = {
  name: [{ use: "official", given: ["", ""], family: "", suffix: [""], prefix: [""] }],
  active: true,
  gender: "",
  birthDate: "",
  telecom: [getTelecomInitialValues({ value: "" }, "email"), getTelecomInitialValues({ value: "" }, "phone")],
  address: [emptyAddress],
  contact: [],
}

const getGeneralPractitioner = (patient: Partial<Patient>, practitionersInfo: PractitionerInfo[]) => {
  const existingPract = practitionersInfo.find(
    ({ practitioner, practitionerRole }) =>
      practitioner.id === patient.generalPractitioner?.[0].id && !!practitionerRole,
  )

  return existingPract ? asReference(existingPract.practitioner) : undefined
}

const initialValues = (
  patient: Partial<Patient> = INITIAL_VALUES,
  organization: Organization,
  practitionersInfo: PractitionerInfo[],
  consents: Coding[],
): PatientFormData => {
  const [name = { use: "official", given: ["", ""], family: "", suffix: [""], prefix: [""] }] = patient.name ?? []
  const address =
    getHomeAddressIndex(patient.address) !== -1 ? patient.address : [emptyAddress, ...(patient.address ?? [])]

  const generalPractitioner = getGeneralPractitioner(patient, practitionersInfo)

  return {
    patient: {
      ...patient,
      name: [name],
      address,
      managingOrganization: asReference(organization),
    },
    consents,
    invite: true,
    generalPractitioner: generalPractitioner,
  }
}

const sanitizePatientName = ({ ...patient }: Partial<Patient>) => {
  if (patient.name?.[0].given) {
    patient.name[0].given = patient.name[0].given.filter((value) => value && value !== "")
  } else if (patient.name?.[0].given?.length) {
    delete patient.name?.[0].given
  }

  if (patient.name?.[0].suffix) {
    patient.name[0].suffix = patient.name[0].suffix.filter((value) => value && value !== "")
  } else if (patient.name?.[0].suffix?.length) {
    delete patient.name?.[0].suffix
  }

  if (patient.name?.[0].prefix) {
    patient.name[0].prefix = patient.name[0].prefix.filter((value) => value && value !== "")
  } else if (patient.name?.[0].prefix?.length) {
    delete patient.name?.[0].prefix
  }

  return patient
}

const sanitize = ({ patient, generalPractitioner, ...rest }: PatientFormData) => {
  patient = sanitizePatientName(patient)
  patient = sanitizeAddress(patient) as Patient
  patient = { ...patient, telecom: sanitizeTelecom(patient.telecom), generalPractitioner: [generalPractitioner!] }

  if (!patient.contact?.length) delete patient.contact

  return { patient, ...rest }
}

const contactHumanNameSchema = Yup.object().shape({
  given: Yup.array()
    .of(
      Yup.string().test("test-first-name", "First name is required", (value, context) => {
        return context?.path === "name.given[0]" ? value !== undefined && value !== "" : true
      }),
    )
    .min(1, "Name is required"),
  family: Yup.string().required("Family name is required"),
})

const addressValidationSchema = (parentFullFieldName?: string) => getAddressSchema(parentFullFieldName)

const contactValidationSchema = (addressParentFullFieldName: string) =>
  Yup.object()
    .shape({
      name: contactHumanNameSchema,
      telecom: Yup.array(telecomSchema()),
      relationship: Yup.array(
        Yup.object().test(
          "empty-coding",
          "Relationship is required",
          (value: CodeableConcept) => value.coding !== undefined,
        ),
      ),
      gender: Yup.string().oneOf(["male", "female"], "Invalid value"),
      address: addressValidationSchema(addressParentFullFieldName),
    })
    .nullable()
    .optional()

const optionalTelecomSchema = Yup.object().shape({
  system: Yup.string()
    .oneOf(["phone", "fax", "email", "pager", "url", "sms", "other"], "Invalid value")
    .required("Specify telecom system"),
  use: Yup.string()
    .oneOf(["home", "work", "temp", "old", "mobile"], "Invalid value")
    .required("Specify this telecom usage"),
  value: Yup.string().when("system", (system, yup) => {
    if (system === "email" || system === "phone") return yup.required(`${strCapitalize(system)} is required`)
  }),
})

const emailSchema = Yup.object().shape({
  email: Yup.string().email("Valid email is required").required("Email is required"),
})

const patientValidationSchema = Yup.object().shape({
  name: Yup.array(humanNameSchema("patient")).min(1, "At least one name is required"),
  telecom: Yup.array(optionalTelecomSchema),
  birthDate: Yup.date()
    .max(startOfDay(new Date()), "This date is in the future. Please, provide a valid date")
    .required("Birthdate is required"),
  gender: Yup.string().required("Biological sex is required"),
})

const newPatientValidationSchema = (validateAddress?: boolean) =>
  Yup.object().shape({
    patient: validateAddress
      ? patientValidationSchema.concat(Yup.object({ address: Yup.array(getAddressSchema("patient.address[0]")) }))
      : patientValidationSchema,
  })

const CONTACT_INITIAL_VALUES = {
  relationship: [{ coding: undefined }],
  gender: "",
  address: emptyAddress,
  name: { use: "official", given: ["", ""], family: "", suffix: [""], prefix: [""] },
  telecom: [
    { system: ContactPointSystem.email, use: "home", value: "" },
    { system: ContactPointSystem.phone, use: "home", value: "" },
  ],
} as PatientContactArray

const sanitizeContact = ({ ...contact }: PatientContactArray) => {
  if (contact.name?.given) {
    contact.name.given = contact.name.given.filter((value) => value && value !== "")
  }

  if (contact.name?.suffix) {
    contact.name.suffix = contact.name.suffix.filter((value) => value && value !== "")
  }

  if (contact.name?.prefix) {
    contact.name.prefix = contact.name.prefix.filter((value) => value && value !== "")
  }

  if (contact.address?.type === "home") delete contact.address?.type

  if (!contact.address?.line?.[0]) delete contact.address

  if (contact.address?.line?.[0] && !contact.address?.use) contact.address.use = "home"

  return { ...contact, telecom: sanitizeTelecom(contact.telecom) }
}

const documentInitialValues = (patientRef: Reference, loggedinPract: Practitioner): DocumentFormData => {
  return {
    status: "current",
    type: {
      text: "Entered",
      coding: [
        {
          system: SYSTEM_VALUES.DOCUMENT_REFERENCE_TYPE,
          code: "entered",
          display: "Entered",
        },
      ],
    },
    content: undefined,
    subject: patientRef,
    author: [asReference(loggedinPract)],
    attachment: undefined,
    category: [{ coding: [{ code: undefined }] }],
  }
}

const documentValidationSchema = Yup.object().shape({
  attachment: Yup.object().required("Attachment is required"),
})

const sanitizeDocument = (documentData: DocumentFormData): DocumentReference => {
  const currentDate = new Date()

  documentData.date = currentDate

  const attachment = documentData.attachment

  if (attachment) {
    documentData.content = [{ attachment: { ...attachment, creation: currentDate.toISOString() } }]
  }

  delete documentData.attachment
  if (!documentData.category?.[0].coding?.[0]?.code) delete documentData.category

  return documentData as DocumentReference
}

const sanitizeTelecom = (telecom?: ContactPoint[]) => {
  return telecom?.reduce((acc, contact, index) => {
    if (contact.value) {
      const updatedContact = { ...getTelecomInitialValues(contact, index === 0 ? "email" : "phone") }
      return [...acc, updatedContact]
    }
    return acc
  }, Array<ContactPoint>())
}

export {
  addressValidationSchema,
  CONTACT_INITIAL_VALUES,
  contactValidationSchema,
  documentInitialValues,
  documentValidationSchema,
  emailSchema,
  getTelecomInitialValues,
  humanNameSchema,
  initialValues,
  newPatientValidationSchema,
  sanitize,
  sanitizeContact,
  sanitizeDocument,
  sanitizePatientName,
  sanitizeTelecom,
  patientValidationSchema,
}
