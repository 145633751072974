import { faTrashCan } from "@fortawesome/pro-regular-svg-icons"
import { format, parseISO } from "date-fns"
import { codeableConceptAsString } from "fhir"
import { type FieldArrayRenderProps, FieldArray, useFormikContext } from "formik"
import { useState } from "react"

import { AddFieldArrayItemButton, Button, CheckBoxField, DialogFormContainer, useCrudReducer } from "commons"
import { formatsByTypes } from "data"

import { DayHours } from "./DayHours"
import { SpecialHourForm } from "./SpecialHourForm"
import type { OperationHours, SpecialHoursData } from "./types"
import { getSpecialHourInitialValues, specialHourValidationSchema } from "./validations"

const SpecialHours = () => {
  const {
    values: { specialHours },
    setFieldValue,
  } = useFormikContext<OperationHours>()

  const [usedDays, setUsedDays] = useState<string[]>(specialHours.map((h) => h.date))
  const { showSlide, initialValue, add, reset } = useCrudReducer({ defaultEntity: getSpecialHourInitialValues() })

  return (
    <FieldArray name="specialHours">
      {({ name: fieldName, push, remove }: FieldArrayRenderProps) => {
        const removeHours = (daysIndex: number) => {
          setFieldValue(`${fieldName}[${daysIndex}].closed`, true)
          setFieldValue(`${fieldName}[${daysIndex}].hours`, [])
        }

        const onToggleClosed = (checked: boolean, daysIndex: number) =>
          setFieldValue(
            `${fieldName}[${daysIndex}].hours`,
            checked ? [] : [{ openingTime: "00:00:00", closingTime: "00:00:00" }],
          )

        const addSpecialHour = (data: SpecialHoursData) => {
          push(data)
          setUsedDays((days) => [...days, data.date])
          reset()
        }

        return (
          <div className="flex flex-1 flex-col divide-y divide-gray-200 *:pb-4">
            {specialHours.map((dayHours, daysIndex) => (
              <div key={daysIndex} className="flex justify-between pt-5 first:pt-0">
                {specialHours[daysIndex].hours.length ? (
                  <DayHours parentField={`${fieldName}[${daysIndex}]`} onRemoveDay={() => removeHours(daysIndex)} />
                ) : (
                  <CheckBoxField
                    label="Closed"
                    field={`${fieldName}[${daysIndex}].closed`}
                    onCheck={(checked) => onToggleClosed(checked, daysIndex)}
                  />
                )}
                <div className="flex items-center gap-6">
                  <div className="flex flex-col items-center justify-end text-sm text-gray-500">
                    <span className="font-medium">{dayHours.description}</span>
                    <div className="flex">
                      <span>{format(parseISO(dayHours.date), formatsByTypes.LONG_DATE)}</span>
                      {dayHours.frequency && (
                        <span className="ml-2 border-l border-gray-200 pl-2">
                          Repeat: {codeableConceptAsString(dayHours.frequency)}
                        </span>
                      )}
                    </div>
                  </div>
                  <Button
                    icon={faTrashCan}
                    title="Remove special hour"
                    buttonStyle="outlined"
                    className="h-10 w-10 justify-center"
                    iconClassName="h-4 w-4"
                    onClick={() => remove(daysIndex)}
                  />
                </div>
              </div>
            ))}
            <AddFieldArrayItemButton label="Add New Special Hour" onClick={add} />
            <DialogFormContainer
              title="New Special Hour"
              showForm={showSlide}
              initialValue={initialValue}
              validationSchema={specialHourValidationSchema}
              useFormik
              onCancel={reset}
              onSubmit={addSpecialHour}
            >
              <SpecialHourForm usedDays={usedDays} />
            </DialogFormContainer>
          </div>
        )
      }}
    </FieldArray>
  )
}

export { SpecialHours }
