import { codeableConceptAsString, type Observation, type Reference } from "fhir"
import { type FC } from "react"
import { format, parseISO, isValid } from "date-fns"
import { classNames } from "primereact/utils"
import type { QueryKey } from "@tanstack/react-query"

import { formatsByTypes } from "data"
import { LaboratoryValueItem } from "commons/labs"
import { Notes } from "commons"

const ObservationItem: FC<Props> = ({
  observation: { id, code, effective, value, note, encounter },
  queriesToInvalidate,
  encounter: openEncounter,
  className,
}) => {
  const showNotes = Boolean(note?.length && id && queriesToInvalidate)
  return (
    <>
      <div className={classNames("inline-flex w-full items-center justify-between", className)}>
        <span className="flex-1 truncate">{codeableConceptAsString(code)}</span>
        <span className="flex min-w-max items-center text-gray-400">
          {effective?.dateTime && isValid(effective.dateTime) && (
            <span>{format(parseISO(effective.dateTime), formatsByTypes.SHORT_DATETIME)}</span>
          )}
          {effective?.dateTime && value && <span className="mx-1 h-4 w-1 border-gray-200" />}
          {value?.string && <LaboratoryValueItem value={value?.string} className="items-center" />}
          {value?.Quantity && (
            <LaboratoryValueItem
              value={`${value.Quantity.value} ${value.Quantity.unit ?? ""}`}
              className="items-center"
            />
          )}
        </span>
      </div>
      {showNotes && (
        <Notes
          className="my-3 w-full"
          notes={note}
          editProps={
            id && queriesToInvalidate
              ? {
                  parentId: id,
                  encounter: encounter ?? openEncounter,
                  context: "Observation",
                  queriesToInvalidate,
                  notShowFullDataNotFound: true,
                }
              : undefined
          }
        />
      )}
    </>
  )
}

type Props = {
  className?: string
  observation: Observation
  queriesToInvalidate?: QueryKey
  encounter?: Reference
}

export { ObservationItem }
