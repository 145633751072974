import { isValid } from "date-fns/isValid"
import type { Invoice, Money, Reference } from "fhir"
import { format } from "date-fns/format"
import { classNames } from "primereact/utils"
import type { FC, PropsWithChildren } from "react"

import { formatsByTypes } from "data"
import { getMoneyCurrencyAlt } from "utils"
import { Badge, type BadgeProps } from "commons"

const OrderItem: FC<Props> = ({
  title,
  requester,
  invoice,
  date,
  status,
  customData,
  price,
  children,
  className,
  contentClassName,
  badge,
  seeOrderDetails,
  showOrderDetails = true,
}) => (
  <article className={classNames("flex flex-col gap-6", className)}>
    <div
      className={classNames("flex w-full items-center gap-2 text-gray-400", {
        "cursor-pointer hover:!text-primary": !!seeOrderDetails,
      })}
      onClick={seeOrderDetails}
    >
      <h5>{title}</h5>
      {!!title && <hr className="flex-1" />}
      {badge && <Badge {...badge} />}
    </div>
    <div className="grid w-full grid-cols-3 text-sm">
      <ul className="col-span-1 flex list-none flex-col gap-3 text-sm text-gray-400">
        {showOrderDetails && (
          <>
            {requester?.display && (
              <li>
                <strong className="font-semibold">Requester: </strong>
                {requester.display}
              </li>
            )}
            {invoice?.identifier?.[0]?.value && (
              <li>
                <strong className="font-semibold">Invoice: </strong>#{invoice.identifier[0].value}
              </li>
            )}
            {date && isValid(date) && (
              <li>
                <strong className="font-semibold">Date: </strong>
                {format(date, formatsByTypes.LONG_DATE)}
              </li>
            )}
            {status && (
              <li className="capitalize">
                <strong className="font-semibold">Status: </strong>
                {status}
              </li>
            )}
            {
              !!customData?.map(({ label, value }, index) => (
                <li key={index}>
                  <strong className="font-semibold">{label}: </strong>
                  {value}
                </li>
              ))
            }
            {price?.value && (
              <li>
                <strong className="font-semibold">Price: </strong>
                {getMoneyCurrencyAlt("USD")}
                {price.value.toFixed(2)}
              </li>
            )}
          </>
        )}
      </ul>
      <div className={classNames("col-span-2 flex flex-col", contentClassName)}>{children}</div>
    </div>
  </article>
)

type Props = {
  title: string
  requester?: Reference
  invoice?: Invoice
  status?: string
  date?: Date
  className?: string
  customData?: { label: string; value: string }[]
  contentClassName?: string
  price?: Money
  seeOrderDetails?(): void
  badge?: BadgeProps
  showOrderDetails?: boolean
} & PropsWithChildren

export { OrderItem }
