import type { CodeableConcept, Timing } from "fhir"

import { unitOfTime } from "data"
import { SYSTEM_VALUES } from "system-values"

import type { DispenseIntervalOption } from "./types"

const treatmentFrequencies = [
  { label: "Once / day", value: "oncePerDay" },
  { label: "Twice / day", value: "twicePerDay" },
  { label: "Three times / day", value: "threePerDay" },
  { label: "Four times / day", value: "fourPerDay" },
  { label: "Every morning", value: "everyMorning" },
  { label: "Every night", value: "everyNight" },
  { label: "Once / week", value: "oncePerWeek" },
  { label: "Twice / week", value: "twicePerWeek" },
  { label: "Three times / week", value: "threePerWeek" },
  { label: "Four times / week", value: "fourPerWeek" },
]

const oneTimeDispenseIntervalOption = {
  label: "One time",
  value: {
    value: 0,
    code: unitOfTime[5].code,
    system: unitOfTime[5].system,
    unit: unitOfTime[5].display.toLowerCase(),
  },
}

const onDemandDispenseIntervalOption = {
  label: "On-Demand",
  value: {
    value: 0,
    code: unitOfTime[5].code,
    system: unitOfTime[5].system,
    unit: unitOfTime[5].display.toLowerCase(),
  },
}

const commonsDispenseInterval: DispenseIntervalOption[] = [
  oneTimeDispenseIntervalOption,
  {
    label: "Monthly",
    value: {
      value: 1,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 2 months",
    value: {
      value: 2,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 3 months",
    value: {
      value: 3,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
]

const allDispenseInterval: DispenseIntervalOption[] = [
  ...commonsDispenseInterval,
  {
    label: "Every 4 months",
    value: {
      value: 4,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 5 months",
    value: {
      value: 5,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 6 months",
    value: {
      value: 6,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
]

enum MEDICATIONS_REGULATIONS_CODE {
  MAX_DISPENSE = "max-dispense",
  RESTRICTED_SHIPPING = "shipping-not-allowed",
  MAX_DAYS_SUPPLY = "max-days-supply",
}

const OVERNIGHT_FEE_CODES = ["rush-order", "2-day-shipping", "ups-2-day-free-shipping", "overnight-shipping"]

const dosageTimingRepeats = [
  {
    id: "oncePerDay",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 1,
      periodUnit: "d",
    },
  },
  {
    id: "twicePerDay",
    code: {
      coding: [
        {
          code: "BID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 2,
      period: 1,
      periodUnit: "d",
    },
  },
  {
    id: "threePerDay",
    code: {
      coding: [
        {
          code: "TID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 3,
      period: 1,
      periodUnit: "d",
    },
  },
  {
    id: "fourPerDay",
    code: {
      coding: [
        {
          code: "QID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 4,
      period: 1,
      periodUnit: "d",
    },
  },
  {
    id: "everyMorning",
    code: {
      coding: [
        {
          code: "AM",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 1,
      periodUnit: "d",
      when: ["MORN"],
    },
  },
  {
    id: "everyNight",
    code: {
      coding: [
        {
          code: "BED",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 1,
      periodUnit: "d",
      when: ["NIGHT"],
    },
  },
] as Timing[]

const DOSE_RANGE_REGEX = /\d\d?-?/g

const INJECTION_DRUG_CODES = ["home-kit", "injection"]

const DISPENSE_MEDICATIONS_TASK_CODE = {
  code: "dispense-medications",
  system: "http://chartedhealth.com/fhir/task-code",
  display: "Sent for Fulfillment",
}

const PHARMACY_LOCATION_CODE = {
  text: "Pharmacy Location",
  coding: [
    {
      code: "pharmacy-location",
      system: "http://chartedhealth.com/fhir/organization-type",
      display: "Pharmacy Location",
    },
  ],
}

export enum RefillModeCodes {
  AUTO_ORDER = "auto-order",
  ON_DEMAND = "on-demand",
  ONE_TIME = "one-time",
}

const REFILL_MODES: Record<RefillModeCodes, CodeableConcept> = {
  [RefillModeCodes.AUTO_ORDER]: {
    coding: [{ code: RefillModeCodes.AUTO_ORDER, system: SYSTEM_VALUES.MEDICATION_REFILL_MODE, display: "Auto Order" }],
  },
  [RefillModeCodes.ON_DEMAND]: {
    coding: [{ code: RefillModeCodes.ON_DEMAND, system: SYSTEM_VALUES.MEDICATION_REFILL_MODE, display: "On Demand" }],
  },
  [RefillModeCodes.ONE_TIME]: {
    coding: [{ code: RefillModeCodes.ONE_TIME, system: SYSTEM_VALUES.MEDICATION_REFILL_MODE, display: "One Time" }],
  },
}

export {
  INJECTION_DRUG_CODES,
  commonsDispenseInterval,
  allDispenseInterval,
  dosageTimingRepeats,
  DOSE_RANGE_REGEX,
  MEDICATIONS_REGULATIONS_CODE,
  OVERNIGHT_FEE_CODES,
  treatmentFrequencies,
  DISPENSE_MEDICATIONS_TASK_CODE,
  PHARMACY_LOCATION_CODE,
  REFILL_MODES,
  oneTimeDispenseIntervalOption,
  onDemandDispenseIntervalOption,
}
