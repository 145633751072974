import { Routes, Route } from "react-router-dom"

import { AdministrationView } from "administration"
import { AuditEventsView } from "audit-events"
import { CalendarView } from "calendar"
import { NotFoundView } from "errors"
import { Router as PatientRouter } from "patients"
import { PractitionerView } from "practitioner"
import { TaskView } from "tasks"
import { MessagesView } from "messages"
import { OrdersView } from "organization-orders"
import { useAppModuleContext } from "internals"

const Router: React.FC = () => {
  const { isModuleActive } = useAppModuleContext()

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <Routes>
        {isModuleActive("calendar") && <Route path="calendar" element={<CalendarView />} />}
        <Route path="patients/*" element={<PatientRouter />} />
        <Route path="practitioner-profile">
          <Route path=":practitionerId" element={<PractitionerView />} />
        </Route>
        <Route path="tasks" element={<TaskView />} />
        <Route path="events" element={<AuditEventsView />} />
        <Route path="administration" element={<AdministrationView />} />
        <Route path="messages" element={<MessagesView />} />
        <Route path="orders" element={<OrdersView />} />
        <Route path="*" element={<NotFoundView />} />
      </Routes>
    </div>
  )
}

export { Router }
