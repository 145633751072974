import { faCheck } from "@fortawesome/pro-regular-svg-icons"
import { format, isValid, parse, roundToNearestMinutes } from "date-fns"
import { getTimezoneOffset } from "date-fns-tz"
import { type FieldProps, useFormikContext } from "formik"
import { Calendar } from "primereact/calendar"
import type { FormEvent } from "primereact/ts-helpers"
import { classNames } from "primereact/utils"
import { type FC, useEffect, useRef } from "react"
import { formatsByTypes } from "data"

import { Button } from "../components/Buttons"
import type { FormatTypes } from "../types"
import { type FormFieldBaseProps, FormField } from "./FormField"

const DateField: FC<Props> = ({
  field,
  label,
  className,
  dateFormat,
  disabled,
  placeholder,
  showTime,
  timeOnly,
  view = "date",
  minDate,
  maxDate,
  selectionMode = "single",
  readOnlyInput,
  horizontal,
  inputClassName,
  onChange,
  validation,
  collectTimeZone,
  ...formFieldProps
}) => {
  const cal = useRef<Calendar>(null)
  const { getFieldMeta, setFieldValue } = useFormikContext()
  const initVal = getFieldMeta(field).initialValue as string | Date | Date[]
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const isSameTimeZone = (date: Date): boolean => {
    const dateTimezone = date.toLocaleString("en", { timeZoneName: "short" }).split(" ").pop()
    const userTimezoneStr = Intl.DateTimeFormat("en", { timeZoneName: "short" }).format(date).split(" ").pop()
    return dateTimezone === userTimezoneStr
  }
  const convertTimezone = (date: Date, { toUTC = false }: { toUTC?: boolean } = {}): Date => {
    if (!collectTimeZone || isSameTimeZone(date)) {
      return date
    }

    const tzOffset = getTimezoneOffset(userTimeZone, date)
    return new Date(date.getTime() + (toUTC ? -tzOffset : tzOffset))
  }

  const getParsedValue = (value: Date | Date[] | null | undefined, timeOnly: boolean = false) => {
    if (Array.isArray(value)) {
      return timeOnly
        ? [
            format(value[0], formatsByTypes.SHORT_TIME_WITH_SECONDS),
            format(value[1], formatsByTypes.SHORT_TIME_WITH_SECONDS),
          ]
        : value.map((date) => {
            if (!collectTimeZone) return date.toISOString()
            return format(convertTimezone(date, { toUTC: true }), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
          })
    } else if (value) {
      if (timeOnly) return format(value, formatsByTypes.SHORT_TIME_WITH_SECONDS)
      if (!collectTimeZone) return value.toISOString()
      return format(convertTimezone(value, { toUTC: true }), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
    }
    return null
  }

  const roundDate = (val: Date | string, timeOnly: boolean = false) => {
    if (timeOnly) {
      let timeString = "00:00:00"

      if (isValid(new Date(val)) || /\d{1,2}:\d{1,2}:\d{1,2}/.test(val as string)) {
        timeString = val as string
      }

      return roundToNearestMinutes(parse(timeString, formatsByTypes.SHORT_TIME_WITH_SECONDS, new Date()), {
        nearestTo: 15,
      })
    }

    let dateValue = new Date()
    if (isValid(new Date(val))) {
      dateValue = new Date(val)
    }

    return roundToNearestMinutes(dateValue, { nearestTo: 15 })
  }

  const parseDisplayValue = (value: string | Date): Date | null => {
    if (!value) return null
    if (value instanceof Date) {
      return collectTimeZone ? convertTimezone(value) : value
    }
    const parsedDate = new Date(value)
    return collectTimeZone ? convertTimezone(parsedDate) : parsedDate
  }

  useEffect(() => {
    if (initVal) {
      const initDate = Array.isArray(initVal)
        ? [roundDate(initVal[0], timeOnly), roundDate(initVal[1], timeOnly)]
        : roundDate(initVal, timeOnly)

      const parsedValue = getParsedValue(initDate, timeOnly)

      initVal !== parsedValue && !Array.isArray(parsedValue) && setFieldValue(field, parsedValue)
    } else cal.current?.updateViewDate(null, roundToNearestMinutes(new Date(), { nearestTo: 15 }))
  }, [])

  const handleChangeCalendar = (e: FormEvent<Date> | FormEvent<Date[]> | FormEvent<(Date | null)[]>) => {
    const { name, value } = e.target

    if (Array.isArray(value)) {
      setFieldValue(name, value)
    } else {
      setFieldValue(name, getParsedValue(value, timeOnly))
    }

    onChange?.(value ?? undefined)
  }

  return (
    <FormField
      field={field}
      validation={validation}
      label={label}
      className={className}
      horizontal={horizontal}
      {...formFieldProps}
    >
      {({ field: { name, value }, meta: { touched, error }, form: { setFieldValue } }: FieldProps) => {
        const footer = () => (
          <div className="flex justify-end border-t border-gray-200 pt-2">
            <Button
              label="Accept"
              icon={faCheck}
              size="sm"
              buttonStyle="default"
              onClick={async () => {
                const calDate = cal.current?.getCurrentDateTime()
                if (calDate) {
                  if (Array.isArray(calDate)) {
                    await setFieldValue(name, calDate)
                  } else {
                    await setFieldValue(name, getParsedValue(calDate, timeOnly))
                  }
                  onChange?.(calDate)
                }
                cal.current?.hide()
              }}
            />
          </div>
        )

        let displayValue = value
        if (typeof value === "string") {
          if (timeOnly) {
            const timePart = value.split("T")[1] ?? value
            const format = value.includes("Z")
              ? formatsByTypes.SHORT_TIME_WITH_SECONDS_AND_TIMEZONE
              : formatsByTypes.SHORT_TIME_WITH_SECONDS
            displayValue = parse(timePart, format, new Date())
          } else {
            displayValue = parseDisplayValue(value)
          }
        }

        return (
          <Calendar
            ref={cal}
            id={name}
            name={name}
            value={displayValue}
            onChange={handleChangeCalendar}
            minDate={minDate}
            maxDate={maxDate}
            showIcon
            showTime={showTime}
            timeOnly={timeOnly}
            stepMinute={15}
            dateFormat={dateFormat}
            disabled={disabled}
            selectionMode={selectionMode}
            hideOnRangeSelection
            view={view}
            placeholder={placeholder}
            readOnlyInput={readOnlyInput}
            panelClassName="small-calendar"
            className={classNames(
              "p-inputtext-sm",
              {
                "p-invalid": touched && error,
                horizontal: horizontal,
              },
              inputClassName,
            )}
            footerTemplate={showTime || timeOnly ? footer : undefined}
          />
        )
      }}
    </FormField>
  )
}

type Props = {
  field: string
  stringFormatType?: FormatTypes
  label?: string
  className?: string
  dateFormat?: string
  placeholder?: string
  showTime?: boolean
  view?: "date" | "month" | "year" | undefined
  timeOnly?: boolean
  disabled?: boolean
  minDate?: Date
  maxDate?: Date
  selectionMode?: "single" | "multiple" | "range"
  readOnlyInput?: boolean
  inputClassName?: string
  validation?(value: string): void
  onChange?(value?: Date | Date[] | (Date | null)[]): void
  collectTimeZone?: boolean
} & Omit<FormFieldBaseProps, "validation">

export { DateField }
