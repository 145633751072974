import { faEllipsisVertical, faExternalLink, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Menu } from "primereact/menu"
import type { MenuItem } from "primereact/menuitem"
import { classNames } from "primereact/utils"
import { type FC, useRef } from "react"

const CardListItem: FC<Props> = ({
  rowImg,
  contentHeader,
  contentSubheader,
  className,
  contentClassName = "py-3",
  actionsClassName,
  dropdownMenuItems,
  actionItem,
  removeActionItem,
  onContentCliked,
  isLoading = false,
  externalAction,
  disabled,
  lastElementClass = "last:mb-6 border-b border-gray-200",
}) => {
  const menu = useRef<Menu>(null)

  return (
    <div
      className={classNames(
        "flex items-center border-solid border-gray-300 first:mt-1",
        { "opacity-65": disabled },
        lastElementClass,
        className,
      )}
    >
      {rowImg}

      <div
        className={classNames("item-content flex w-full items-center", contentClassName)}
        onClick={!disabled ? onContentCliked : undefined}
      >
        <div className="flex w-full flex-1 flex-col space-y-1 text-sm">
          <div className="inline-flex items-center">{contentHeader}</div>
          {contentSubheader && <div className="inline-flex text-slate-400">{contentSubheader}</div>}
        </div>
      </div>

      {isLoading ? (
        <FontAwesomeIcon icon={faSpinner} spin className="mr-1 text-gray-400" size="lg" />
      ) : disabled ? (
        <></>
      ) : dropdownMenuItems?.length ? (
        <div
          className={classNames(
            "item-actions mr-2 flex h-6 w-6 cursor-pointer content-center items-center justify-center overflow-hidden rounded-full p-1 text-2xl font-bold text-gray-400 hover:bg-gray-100",
            actionsClassName,
          )}
          aria-controls="popup_menu"
          aria-haspopup
          onClick={(event) => menu.current && menu.current.toggle(event)}
        >
          <span className="inline-flex h-1/2 items-center justify-center">
            <FontAwesomeIcon icon={faEllipsisVertical} className="text-[1.2rem]" />
          </span>
          <Menu model={dropdownMenuItems} popup ref={menu} id="popup_menu" style={{ fontSize: "small" }} />
        </div>
      ) : externalAction ? (
        <span
          className="mr-2 inline-flex h-1/2 cursor-pointer items-center justify-center text-sm text-gray-400"
          title={externalAction}
          onClick={!disabled ? onContentCliked : undefined}
        >
          <FontAwesomeIcon icon={faExternalLink} />
        </span>
      ) : (
        actionItem && (
          <>
            <span
              title={actionItem.label}
              className={classNames(
                "item-actions mx-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-gray-400 hover:bg-gray-100",
                actionsClassName,
              )}
              onClick={(e) =>
                !actionItem.disabled && !disabled && actionItem.command?.({ originalEvent: e, item: actionItem })
              }
            >
              {actionItem.icon ?? actionItem.label}
            </span>
            {removeActionItem && (
              <span
                title={removeActionItem.label}
                className={classNames(
                  "item-actions mx-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-gray-400 hover:bg-gray-100",
                  actionsClassName,
                )}
                onClick={(e) =>
                  !removeActionItem.disabled &&
                  !disabled &&
                  removeActionItem.command?.({ originalEvent: e, item: removeActionItem })
                }
              >
                {removeActionItem.icon ?? removeActionItem.label}
              </span>
            )}
          </>
        )
      )}
    </div>
  )
}

type Props = {
  rowImg?: JSX.Element
  contentHeader?: JSX.Element
  contentSubheader?: JSX.Element
  className?: string
  contentClassName?: string
  actionsClassName?: string
  dropdownMenuItems?: MenuItem[]
  actionItem?: MenuItem
  removeActionItem?: MenuItem
  onContentCliked?: () => void
  isLoading?: boolean
  disabled?: boolean
  externalAction?: string
  lastElementClass?: string
}

export { CardListItem }
