import { forwardRef, useEffect, useState } from "react"
import type { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND, type TextFormatType } from "lexical"
import { $isHeadingNode } from "@lexical/rich-text"

import { IconButton } from "./IconButton"

const FloatingMenu = forwardRef<HTMLDivElement, FloatingMenuProps>(function FloatingMenu(props, ref) {
  const { editor, coords } = props

  const shouldShow = coords !== undefined

  const [state, setState] = useState<FloatingMenuState>({
    isBold: false,
    isItalic: false,
    isUnderline: false,
  })

  useEffect(() => {
    const unregisterListener = editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection()
        if (!$isRangeSelection(selection)) return

        const isWithinHeading = selection.getNodes().some((node) => {
          const parent = node.getParent()
          return parent ? $isHeadingNode(parent) : false
        })

        setState({
          isBold: selection.hasFormat("bold") || isWithinHeading,
          isItalic: selection.hasFormat("italic"),
          isUnderline: selection.hasFormat("underline"),
        })
      })
    })
    return unregisterListener
  }, [editor])

  const formatText = (formatType: TextFormatType) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType)
  }

  return (
    <div
      ref={ref}
      className="flex items-center justify-between gap-1 rounded-md border-[1px] border-slate-300 bg-slate-100 p-1"
      aria-hidden={!shouldShow}
      style={{
        position: "absolute",
        top: coords?.y,
        left: coords?.x,
        visibility: shouldShow ? "visible" : "hidden",
        opacity: shouldShow ? 1 : 0,
        zIndex: 9999,
      }}
    >
      <IconButton
        icon="bold"
        aria-label="Format text as bold"
        active={state.isBold}
        onClick={() => formatText("bold")}
      />
      <IconButton
        icon="italic"
        aria-label="Format text as italics"
        active={state.isItalic}
        onClick={() => formatText("italic")}
      />
      <IconButton
        icon="underline"
        aria-label="Format text to underlined"
        active={state.isUnderline}
        onClick={() => formatText("underline")}
      />
    </div>
  )
})

export type FloatingMenuCoords = { x: number; y: number } | undefined

type FloatingMenuState = {
  isBold: boolean
  isItalic: boolean
  isUnderline: boolean
}

type FloatingMenuProps = {
  editor: ReturnType<typeof useLexicalComposerContext>[0]
  coords: FloatingMenuCoords
}

export { FloatingMenu }
