import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Dialog } from "primereact/dialog"
import { useState } from "react"
import { max } from "date-fns"

const RefillOnDialog = ({
  initialDate = new Date(),
  isLoading,
  visible,
  hiddenWarning,
  acceptLabel = "Schedule",
  isRefillAllowed = true,
  refillNotAllowedReason = "Refill not allowed",
  onHide,
  onRefillOn,
}: Props) => {
  const [scheduleDate, setScheduleDate] = useState(initialDate)
  const dateValue = max([scheduleDate, initialDate])

  return (
    <Dialog
      closable
      header="Refill On"
      visible={visible}
      draggable={false}
      dismissableMask={true}
      style={{ width: "35vw", maxWidth: "434px" }}
      onHide={onHide}
      footer={
        <div className="mt-2">
          <Button label="Cancel" size="small" className="button-default" onClick={onHide} disabled={isLoading} />
          <Button
            label={acceptLabel}
            size="small"
            className="button-primary"
            loading={isLoading}
            onClick={() => {
              if (isRefillAllowed) {
                onRefillOn(dateValue.toISOString())
                return
              }
              onHide()
            }}
          />
        </div>
      }
    >
      {isRefillAllowed ? (
        <>
          <p className="text-gray-500 mb-2 font-normal">Schedule the day to receive the medication</p>
          {!hiddenWarning && (
            <p className="text-sm text-gray-400">*Notes: Only active and on-demand prescriptions can be scheduled</p>
          )}
          <div className="flex flex-col gap-2 mt-8">
            <label className="font-medium">Reception date</label>
            <Calendar
              className="w-full h-10"
              showIcon
              value={dateValue}
              minDate={initialDate}
              dateFormat={"M d, yy"}
              onChange={(e) => setScheduleDate(e.target.value as Date)}
            />
          </div>
        </>
      ) : (
        <p>{refillNotAllowedReason}</p>
      )}
    </Dialog>
  )
}

type Props = {
  title: string
  initialDate?: Date
  isLoading: boolean
  visible: boolean
  hiddenWarning?: boolean
  acceptLabel?: string
  isRefillAllowed?: boolean
  refillNotAllowedReason?: string
  onHide(): void
  onRefillOn(scheduleDate: string): void
}

export { RefillOnDialog }
