import type { ChargeItemDefinition } from "fhir"
import { Checkbox } from "primereact/checkbox"
import { RadioButton } from "primereact/radiobutton"
import { classNames } from "primereact/utils"

import { SYSTEM_VALUES } from "system-values"

import { useCPOERequestsContext } from "../hooks"
import { ACTION_GROUP_CODES, type ActionGroupCode } from "../types"

const OrderDiscountListItem = ({
  discount,
  selected,
  selectCid,
  sectionRequestsType,
  selectedRefill,
  selectRefillCid,
}: Props) => {
  const { isProcessingActions } = useCPOERequestsContext()
  const feeDisplay = discount.code?.coding?.find((coding) => coding.system === SYSTEM_VALUES.DISCOUNT)?.display

  return (
    <div
      className={classNames("my-4 flex w-full flex-col rounded-md border-primary px-4 py-2 text-sm", {
        border: selected,
      })}
    >
      <div className="flex w-full items-center">
        <RadioButton
          inputId={`discount${discount.id}`}
          name="discount"
          value={discount}
          checked={selected}
          onChange={selectCid}
          disabled={isProcessingActions}
        />
        <label htmlFor={`discount${discount.id}`} className="flex w-full cursor-pointer">
          <div className="flex flex-1 flex-col space-y-1 text-sm">
            <div className="mx-2 flex w-full items-center justify-between">
              <span title="Code" className="font-bold">
                {feeDisplay}
              </span>
            </div>
          </div>
        </label>
      </div>
      {selected && sectionRequestsType !== ACTION_GROUP_CODES.LAB && (
        <div className="mt-1 ml-6 flex items-center">
          <Checkbox
            inputId={`refillDiscount${discount.id}`}
            name="refillDiscount"
            value={discount}
            checked={selectedRefill}
            onChange={selectRefillCid}
            disabled={isProcessingActions}
          />
          <label htmlFor={`refillDiscount${discount.id}`} className="cursor-pointer text-sm">
            Apply to future refills?
          </label>
        </div>
      )}
    </div>
  )
}

type Props = {
  discount: ChargeItemDefinition
  selected: boolean
  selectCid(): void
  sectionRequestsType: ActionGroupCode
  selectedRefill: boolean
  selectRefillCid(): void
}

export { OrderDiscountListItem }
