import {
  endOfDay,
  endOfMonth,
  getHours,
  getMinutes,
  getSeconds,
  isSameMonth,
  parse,
  roundToNearestMinutes,
  set,
  startOfDay,
  startOfMonth,
} from "date-fns"
import {
  type Appointment,
  type AppointmentParticipantArray,
  type HealthcareService,
  type Reference,
  type Slot,
} from "fhir"
import type { FormikErrors } from "formik"

const getMonthDateBounds = ({
  month,
  year,
  ignoreCurrentDay,
}: {
  year: number
  month: number
  ignoreCurrentDay?: boolean
}) => {
  const currentDate = new Date()
  const targetDate = new Date(year, month, 1)

  const start = isSameMonth(currentDate, targetDate) && !ignoreCurrentDay ? currentDate : startOfMonth(targetDate)
  const end = endOfMonth(targetDate)

  return { start, end }
}

const getDayDateBounds = ({
  currentDayDate,
  healthcareService,
}: {
  currentDayDate?: Date
  healthcareService?: HealthcareService
}) => {
  if (!currentDayDate) return { startDate: undefined, endDate: undefined }

  const hsStartTime = healthcareService?.availableTime?.find(
    ({ availableStartTime }) => !!availableStartTime,
  )?.availableStartTime
  const hsEndTime = healthcareService?.availableTime?.find(
    ({ availableEndTime }) => !!availableEndTime,
  )?.availableEndTime

  let startDate = startOfDay(currentDayDate)
  let endDate = endOfDay(currentDayDate)

  if (hsStartTime) {
    const { hours, minutes, seconds } = getTimeParts(hsStartTime)
    startDate = set(startDate, { hours, minutes, seconds, milliseconds: 0 })
  }

  if (hsEndTime) {
    const { hours, minutes, seconds } = getTimeParts(hsEndTime)
    endDate = set(endDate, { hours, minutes, seconds, milliseconds: 0 })
  }

  return { startDate, endDate }
}

const getTimeParts = (time: string) => {
  const timeDate = parse(time, "HH:mm:ss", new Date(0))

  const hours = getHours(timeDate)
  const minutes = getMinutes(timeDate)
  const seconds = getSeconds(timeDate)

  return { hours, minutes, seconds }
}

const getSlotInitialValue = (start?: Date, end?: Date): Partial<Slot> | undefined =>
  start && end ? { start, end, resourceType: "Slot" } : undefined

const getAppointmentParticipanOptions = ({
  isEditing,
  isSameSlot,
  devicesRefs,
  practitionersRefs,
  roomsRefs,
  participants,
}: {
  isEditing?: boolean
  isSameSlot?: boolean
  practitionersRefs?: Reference[]
  roomsRefs?: Reference[]
  devicesRefs?: Reference[]
  participants: AppointmentParticipantArray[]
}) => {
  const practitionersRefsOptions =
    isEditing && isSameSlot
      ? [...(practitionersRefs ?? []), ...(participants[1].actor ? [participants[1].actor] : [])]
      : practitionersRefs
  const roomsRefsOptions =
    isEditing && isSameSlot
      ? [...(roomsRefs ?? []), ...(participants[3].actor ? [participants[3].actor] : [])]
      : roomsRefs
  const devicesRefsOptions =
    isEditing && isSameSlot
      ? [...(devicesRefs ?? []), ...(participants[4].actor ? [participants[4].actor] : [])]
      : devicesRefs

  return { practitionersRefsOptions, roomsRefsOptions, devicesRefsOptions }
}

const clearParticipantFieldWhenNotIncludedInOptions = ({
  fieldName,
  options,
  participant,
  setFieldValue,
}: {
  fieldName: string
  options: Reference[]
  participant: AppointmentParticipantArray
  setFieldValue: (
    field: string,
    value: Reference | undefined,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<Appointment>>
}) => {
  const isParticipantIncluded = options.some(({ id }) => id === participant?.actor?.id)
  if (options.length && !isParticipantIncluded) setFieldValue(fieldName, undefined)
}

const getQuestionnaireUrlFromCanonical = (canonical?: string) => canonical?.split("|")?.[0] ?? ""

const roundTime = (date?: Date) => {
  return roundToNearestMinutes(date ?? new Date(), {
    nearestTo: 15,
  })
}

export {
  clearParticipantFieldWhenNotIncludedInOptions,
  getAppointmentParticipanOptions,
  getDayDateBounds,
  getMonthDateBounds,
  getSlotInitialValue,
  getQuestionnaireUrlFromCanonical,
  roundTime,
}
