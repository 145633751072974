import { faCalendar, faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatDate } from "date-fns/format"
import type { Task } from "fhir"
import { classNames } from "primereact/utils"
import { type FC, useEffect, useMemo } from "react"

import { formatsByTypes } from "data"
import { useUpdateTask } from "tasks"

import { Accordion } from "../../../components/Accordion"
import type { StackedListItemProps } from "../../../components/StackedListItem"
import { StackedTimeLineCheckList } from "../../../components/StackedTimeLineCheckList"
import { useCarePlanDetails, usePlanContext } from "../../hooks"
import { plansQueryKeys } from "../../query-keys"
import type { CarePlanHistory } from "../../types"

const CPItem: FC<Props> = ({ cpItem, selected, onClick }) => {
  const initialCompletedReviews = useMemo(
    () => cpItem.reviewTasks?.filter(({ status }) => status === "completed").flatMap(({ id }) => id as string),
    [cpItem.reviewTasks],
  )
  const { patientId } = usePlanContext()
  const { candidateTask } = useCarePlanDetails(patientId, cpItem.carePlan.id)
  const isCandidate = candidateTask?.status === "completed"

  const { updateCompletedReviews } = usePlanContext()
  const { updateTask } = useUpdateTask({
    queryKeysToInvalidate: [plansQueryKeys.history(patientId, cpItem.carePlan.id)],
  })

  const header = (
    <div
      key={cpItem.carePlan.id}
      className={classNames(
        "mt-2 flex items-center space-x-2 rounded-sm border p-2 text-sm text-gray-500",
        selected ? "cursor-default border-primary" : "cursor-pointer",
      )}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faCalendar} />
      <span className="text-sm font-medium">
        {cpItem.appointment?.start
          ? formatDate(new Date(cpItem.appointment.start), formatsByTypes.FULL_DATETIME)
          : "No appointment established"}
      </span>
    </div>
  )

  useEffect(() => {
    // Avoid call update when it's not the active plan
    if (cpItem.carePlan.status === "active") updateCompletedReviews({ newCompleted: initialCompletedReviews ?? [] })
  }, [initialCompletedReviews])

  const handleCompleteReviewTask = (item: Task, checked: boolean) => {
    updateTask(
      { ...item, status: checked ? "completed" : "ready" },
      {
        onSuccess: () => {
          item.id && updateCompletedReviews({ newCompleted: item.id, toRemove: !checked })
        },
      },
    )
  }

  if (cpItem.reviewTasks?.length)
    return (
      <Accordion
        activeIndex={selected ? 0 : undefined}
        data={[cpItem]}
        headerTemplate={() => header}
        contentTemplate={(item) => (
          <div className="pb-2 pl-1">
            <StackedTimeLineCheckList
              initialCheckedIds={initialCompletedReviews}
              itemModel={(task) => checkTaskModel(task)}
              queryProps={{ data: item.reviewTasks, isFetching: false }}
              getItemId={({ id }) => id as string}
              itemTitle="review task"
              evaluateIsDisabled={() => isCandidate}
              onItemChecked={handleCompleteReviewTask}
            />
          </div>
        )}
        className="paddingless px-0"
        headerClassName="paddingless"
        expandIcon={<FontAwesomeIcon icon={faChevronDown} className="p-accordion-toggle-icon m-0 mt-2" />}
        collapseIcon={<FontAwesomeIcon icon={faChevronUp} className="p-accordion-toggle-icon m-0 mt-2" />}
      />
    )
  return header
}

const checkTaskModel = (task: Task): StackedListItemProps => {
  return { leftData: [{ lineItems: [{ name: "Description", value: task.description }] }] }
}

type Props = { cpItem: CarePlanHistory; selected?: boolean; onClick(): void }

export { CPItem }
