import { faPrint } from "@fortawesome/pro-solid-svg-icons"
import type { Encounter } from "fhir"
import { type FC } from "react"

import { Button, StackedListItem } from "commons"
import { useCPOEContext } from "orders"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { EncounterWrapUp } from "./details/EncounterWrapUp"
import { EncounterFinishButton } from "./EncounterFinishButton"
import { encounterModelBuilder } from "./encounterModelBuilder"
import { usePrintEncounter } from "../hooks"

const OpenEncounter: FC<Props> = ({ encounter, handleCloseModule }) => {
  const { currentOrganizationId } = useOrganizationContext()
  const { patientId, patientRef } = usePatientContext()
  const { printEncounter, isPrintingEncounter } = usePrintEncounter()
  const { ordersCount } = useCPOEContext()

  return (
    <>
      <div className="mb-4 flex w-full flex-row justify-between p-1">
        <StackedListItem
          modelData={encounterModelBuilder({ ...encounter, status: "" })}
          className="@container w-full py-0"
        />

        <Button
          label="Print"
          loading={isPrintingEncounter}
          icon={faPrint}
          buttonStyle="default"
          onClick={() => printEncounter(encounter.id as string)}
          className="mr-3 h-min w-fit"
        />
      </div>
      <EncounterWrapUp
        encounterId={encounter.id as string}
        patientId={patientId}
        patientRef={patientRef}
        organizationId={currentOrganizationId}
        className="grow overflow-y-auto"
      />

      <section className="flex h-fit w-full shrink-0 justify-end gap-3 border-t-2 border-gray-100 px-3 py-4">
        <Button label="Resume" buttonStyle="default" onClick={handleCloseModule} />
        <EncounterFinishButton
          encounter={encounter}
          patientId={patientId}
          patientRef={patientRef}
          showStopAction
          onEncounterFinished={handleCloseModule}
          showAssignCheckoutAction={ordersCount > 0}
        />
      </section>
    </>
  )
}

type Props = {
  encounter: Encounter
  handleCloseModule(): void
}

export { OpenEncounter }
