import { type Composition, type Reference } from "fhir"
import * as Yup from "yup"

import { CompositionSectionCode } from "../types"

const getAssessmentInitialValues = (
  practitionerRef: Reference,
  patientRef: Reference,
  encounter?: Reference,
  useTemplate?: boolean,
): Composition => ({
  author: [practitionerRef],
  subject: patientRef,
  resourceType: "Composition",
  encounter,
  date: new Date().toISOString(),
  status: "final",
  title: encounter?.display ?? "Assessment",
  type: {
    text: "Summary of episode note",
    coding: [
      {
        code: "34133-9",
        display: "Summary of episode note",
        system: "http://loinc.org",
      },
    ],
  },
  section: [
    {
      text: {
        status: "additional",
        div: useTemplate ? valueTemplate : "",
      },
      title: "Assessment",
      code: {
        text: "Assessment",
        coding: [
          {
            code: CompositionSectionCode.ASSESSMENT,
            display: "Assessment",
            system: "http://loinc.org",
          },
        ],
      },
    },
    {
      text: {
        status: "additional",
        div: useTemplate ? valueTemplate : "",
      },
      title: "Plan",
      code: {
        text: "Plan",
        coding: [
          {
            code: CompositionSectionCode.PLAN,
            display: "Plan",
            system: "http://loinc.org",
          },
        ],
      },
    },
  ],
})

const sanitize = ({ ...composition }: Composition) => {
  const currentDate = new Date().toISOString()

  composition.date = currentDate
  if (!composition.text?.div || composition.text.div === valueTemplate) delete composition.text

  return composition
}

const assessmentValidationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  section: Yup.array().of(
    Yup.object().shape({
      text: Yup.object().shape({
        div: Yup.string().required("Notes is required"),
      }),
    }),
  ),
})

const valueTemplate = "<h2>Assessment</h2><br/><br/><br/><br/><br/><br/><h2>Plan</h2><br/><br/><br/><br/><br/><br/>"

export {
  getAssessmentInitialValues,
  assessmentValidationSchema,
  sanitize,
  valueTemplate as Assessment_Default_Template,
}
