import { faInfoCircle, faTrashCan } from "@fortawesome/pro-regular-svg-icons"
import { faCalendar, faSyringe } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns"
import { codeableConceptAsString, isCarePlan } from "fhir"
import { Checkbox } from "primereact/checkbox"
import { OverlayPanel } from "primereact/overlaypanel"
import { classNames } from "primereact/utils"
import { type FC, useRef, useState } from "react"

import { Button, CardListItem } from "commons"
import { formatsByTypes } from "data"
import { formatDate, getMoneyCurrencyAlt } from "utils"

import type { CpoeRequest } from "../types"
import "./ProcedureOrderListItem.css"

const ProcedureOrderListItem: FC<Props> = ({ item, editable, onChange, onDelete, isDeleting, readonly }) => {
  const panelRef = useRef<OverlayPanel>(null)
  const [deletePressed, setDeletePressed] = useState(false)

  const isPartOfCP = item.procedureData?.procedure?.basedOn?.some(isCarePlan)

  const head = (
    <div className="flex w-full items-center justify-between">
      <div className="flex flex-1 items-center gap-2">
        {editable && !readonly && (
          <Checkbox
            name="status"
            checked={item.resource.code?.[0].coding?.[0].code === "activate"}
            onChange={(e) => onChange?.(e.checked ? "activate" : "draft", item.resource.resource?.id)}
          />
        )}
        <div className="flex flex-1 flex-col">
          <div className="line-clamp-2 flex font-semibold text-wrap text-ellipsis text-gray-600">
            {item.display}
            {!!item.procedureData?.medsData.length && (
              <span onClick={(e) => panelRef?.current?.toggle(e)} className="cursor-pointer">
                <FontAwesomeIcon icon={faInfoCircle} size="1x" className="ml-2" title="View medications" />
                <OverlayPanel ref={panelRef}>
                  {item.procedureData?.medsData.map((med, index) => (
                    <span key={med.id ?? `procedure_mr_${index}`} className="flex w-full">
                      {codeableConceptAsString(med.code)}
                    </span>
                  ))}
                </OverlayPanel>
              </span>
            )}
          </div>
          <div className="flex flex-wrap items-center space-x-1 text-slate-400">
            <span title="Date">
              <FontAwesomeIcon icon={faCalendar} size="1x" className="mr-1" />
              {formatDate(
                parseISO(item.procedureData?.procedure.performed?.dateTime as string),
                formatsByTypes.LONG_DATE,
              )}
            </span>
            <span title="Medications">
              <FontAwesomeIcon icon={faSyringe} size="1x" className="mx-1" />
              {item.procedureData?.medsData.length ?? 0}
            </span>
          </div>
        </div>
      </div>
      <div className="flex max-w-[50%] min-w-[35%] justify-end">
        <div className="inline-flex items-center text-right font-bold text-gray-600">
          {getMoneyCurrencyAlt(item?.unitPrice?.currency)}
          {(item.quantity * (item?.unitPrice?.value ?? 0)).toFixed(2)}
        </div>
        <div
          className={classNames("flex items-center justify-end py-1", { "ml-6 min-w-9 3xl:ml-12 xl:ml-9": !readonly })}
        >
          {!!onDelete && !readonly && !isPartOfCP && (
            <Button
              icon={faTrashCan}
              loading={isDeleting && deletePressed}
              buttonStyle="text"
              onClick={() => {
                setDeletePressed(true)
                onDelete(item.resource.resource?.id as string, item)
              }}
              title="Delete"
            />
          )}
        </div>
      </div>
    </div>
  )

  return <CardListItem className="procedure-item !border-none px-2" contentHeader={head} />
}

type Props = {
  item: CpoeRequest
  onChange?(value: string, id?: string): void
  editable: boolean
  onDelete?(itemId: string, item: CpoeRequest): void
  isDeleting?: boolean
  readonly?: boolean
}

export { ProcedureOrderListItem }
