import { faEdit, faLinkHorizontal, faSpinner } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Skeleton } from "primereact/skeleton"
import { classNames } from "primereact/utils"
import type { ReactNode } from "react"

const InfoRow = ({ className, title, content, editable, onEdit, showLink, loading, spinnerLoading }: Props) => (
  <div
    className={classNames(
      "flex w-full items-center border-b border-gray-200 py-5 text-sm text-gray-400 first:pt-0 last:border-0",
      className,
    )}
  >
    <p className="w-1/5 min-w-[5rem] font-semibold">{title}</p>
    <div className="flex w-5/6 justify-between overflow-hidden text-ellipsis whitespace-nowrap">
      {loading ? spinnerLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : <Skeleton /> : content}

      {showLink && (
        <div>
          <FontAwesomeIcon
            icon={loading ? faSpinner : faLinkHorizontal}
            spin={loading}
            className="ml-5 cursor-pointer"
          />
        </div>
      )}
      {editable && !loading && (
        <div className="w-1/5 pr-2 text-right">
          <span className="cursor-pointer rounded-full p-2 hover:bg-gray-100" title="Edit" onClick={onEdit}>
            <FontAwesomeIcon icon={faEdit} className="text-gray-600" />
          </span>
        </div>
      )}
    </div>
  </div>
)

type Props = {
  className?: string
  title: string
  content: string | ReactNode
  showLink?: boolean
  loading?: boolean
  spinnerLoading?: boolean
  editable?: boolean
  onEdit?(): void
}

export { InfoRow }
