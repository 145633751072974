import type { DatesSetArg, EventInput } from "@fullcalendar/core"
import { differenceInDays, endOfMonth, isFirstDayOfMonth, isSameDay, startOfMonth } from "date-fns"
import { humanNameAsString } from "fhir"

import { EventType, type CalendarAppointment } from "commons/types"
import { APPOINTMENT_TYPE_DEFAULT_COLOR, UNIT_DAYS } from "data"
import { SYSTEM_VALUES } from "system-values"
import { getAppointmentType, getCodingBySystem } from "utils"

const getEvents = (appointments: CalendarAppointment[]) =>
  appointments.reduce<EventInput[]>((events, calendarAppointment) => {
    const event = getAppointmentEventData(calendarAppointment)
    return [...events, event]
  }, [])

const getAppointmentEventData = (calendarAppointment: CalendarAppointment): EventInput => {
  const { appointment, patient, healthcareService, practitioner, location, devices } = calendarAppointment
  const color =
    getCodingBySystem(healthcareService?.characteristic, SYSTEM_VALUES.HEALTHCARESERVICE_CHARACTERISTIC_COLOR_SYSTEM)
      ?.code ?? APPOINTMENT_TYPE_DEFAULT_COLOR

  const event = {
    id: appointment.id,
    title: humanNameAsString(patient?.name?.[0]),
    start: new Date(appointment.start as Date),
    end: new Date(appointment.end as Date),
    status: appointment.status,
    borderColor: "#e5e7eb",
    backgroundColor: color,
    extendedProps: {
      id: appointment.id,
      color,
      status: appointment.status,
      patientId: patient?.id,
      appointment: appointment,
      type: healthcareService?.name ?? getAppointmentType(appointment),
      patient,
      practitioner,
      location,
      devices,
      healthcareService,
      eventType: EventType.APPT,
    },
  }

  return event
}

const getAppointmentsDateRangeFromDatesSet = (datesSet?: DatesSetArg) => {
  if (!datesSet) return undefined

  const { start, end } = datesSet

  const monthStartDate = startOfMonth(start)
  const monthEndDate = endOfMonth(end)

  return { start: monthStartDate, end: monthEndDate }
}

const getCalendarDateFromDatesSet = (datesSet?: DatesSetArg) => {
  if (!datesSet) return undefined

  const { start, end } = datesSet

  if (!isSameDay(start, end)) {
    if (isFirstDayOfMonth(start) || differenceInDays(end, start) <= UNIT_DAYS.WEEK) return start

    const newDate = new Date(start.getFullYear(), start.getMonth(), 1)
    newDate.setMonth(start.getMonth() + 1)
    return newDate
  }

  return start
}

export { getAppointmentsDateRangeFromDatesSet, getCalendarDateFromDatesSet, getEvents, getAppointmentEventData }
