import type { CarePlan, Condition, PlanDefinitionActionArrayActionArray } from "fhir"
import { useState } from "react"

import { PatientConditions, sanitize } from "conditions"
import { usePatientContext } from "patients"

import type { QuestionnaireData } from "../../../../types"
import { useAddCondition, useCPManualCondition } from "../../../hooks"
import { getActionQuestionnaireInfo } from "../../../utils"
import { SupportingInfo } from "./SupportingInfo"

const ManualConditions = ({ carePlan, action, questionnaireData }: Props) => {
  const { patientId } = usePatientContext()

  const [reset, setReset] = useState(false)

  const { conditions, isLoading } = useCPManualCondition(patientId, carePlan.id as string)
  const { addCondition } = useAddCondition(() => setReset(true))

  const onSubmit = (condition: Condition) =>
    addCondition({ patientId, planId: carePlan.id as string, condition: sanitize(condition) })

  const qInfo = getActionQuestionnaireInfo(action, questionnaireData)

  return (
    <PatientConditions
      queryData={{ conditions, isLoading }}
      title="Chief complaints"
      className="gap-4 border-t border-gray-200 pt-4 first:border-0 first:pt-0"
      onSubmit={onSubmit}
      forceReset={{ reset, setReset }}
      supportingInfo={qInfo && <SupportingInfo questionnaireInfo={qInfo} />}
    />
  )
}

type Props = {
  carePlan: CarePlan
  action: PlanDefinitionActionArrayActionArray
  questionnaireData?: QuestionnaireData[]
}

export { ManualConditions }
