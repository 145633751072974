import * as Yup from "yup"
import { isValid } from "date-fns/isValid"
import type { PractitionerRoleAvailableTimeArray } from "fhir"

import type { TimeOffHours } from "../../types"
import { parseTimeFrame } from "../../utils"
import { formatISO } from "date-fns"

export const INITIAL_VALUES: TimeOffHours = {
  description: "",
  allDay: true,
  during: { start: undefined, end: undefined },
  hours: [
    {
      availableStartTime: "08:00:00",
      availableEndTime: "20:00:00",
    },
  ],
}

export const notAvailableValidationSchema = Yup.object().shape({
  description: Yup.string().required("Description is required"),
  during: Yup.object({
    start: Yup.string().nullable().required("Begin is required"),
    end: Yup.string().nullable().required("End is required"),
  }).nullable(),
})

export const sanitize = (data: TimeOffHours) => {
  const utcTimes = data.hours.reduce((acc, period) => {
    const startTime = period.availableStartTime ? parseTimeFrame(period.availableStartTime) : undefined
    const endTime = period.availableEndTime ? parseTimeFrame(period.availableEndTime) : undefined
    const isValidPeriod = startTime && isValid(startTime) && endTime && isValid(endTime)

    if (!isValidPeriod) return acc

    const availableStartTime = formatISO(startTime, { representation: "time" })
    const availableEndTime = formatISO(endTime, { representation: "time" })

    return [...acc, { availableStartTime, availableEndTime }]
  }, Array<PractitionerRoleAvailableTimeArray>())

  return { ...data, hours: utcTimes }
}
