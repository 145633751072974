import { faArrowRotateLeft, faSignOut } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "primereact/button"
import type { FC } from "react"

import type { CustomError } from "commons"
import { ERROR_CAUSE } from "data"
import { getContactToSupportURL } from "errors/utils"

const AuthError: FC<Props> = ({ error, logout, shouldRetry }) => {
  const message = error.cause?.message ?? ERROR_CAUSE.UNAUTHORIZED
  const code = error.cause?.name ?? "Auth Error"

  const SUPPORT_EMAIL = window.VITE_APP_SUPPORT_EMAIL
  const handleRetry = () => {
    window.location.reload()
  }

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="text-center">
        <p className="p-error text-5xl">{code}</p>
        <h1 className="py-2 text-4xl font-bold">{error.message}</h1>
        <p className="pt-2 text-gray-400">{message}</p>
        <p className="pt-1 pb-6 text-gray-400">
          Please contact{" "}
          <a
            href={getContactToSupportURL(error.cause?.traceId as string)}
            className="cursor-pointer border-none font-semibold text-gray-500 hover:underline"
          >
            {SUPPORT_EMAIL}
          </a>{" "}
          for assistance.
        </p>
        <div className="flex items-center justify-center gap-4">
          {shouldRetry && (
            <Button onClick={handleRetry} className="p-button button-default">
              <span className="flex items-center">
                <FontAwesomeIcon icon={faArrowRotateLeft} className="mr-2" /> Retry
              </span>
            </Button>
          )}
          <Button onClick={logout} className="p-button button-primary">
            <span className="flex items-center">
              <FontAwesomeIcon icon={faSignOut} className="mr-2" /> Logout
            </span>
          </Button>
        </div>
      </div>
    </div>
  )
}

type Props = { error: CustomError; logout(): void; shouldRetry?: boolean }

export { AuthError }
