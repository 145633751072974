import type { Coding, Reference } from "fhir"
import { type FieldProps, type FormikValues, useFormikContext } from "formik"
import { type RadioButtonChangeEvent, RadioButton } from "primereact/radiobutton"
import { classNames } from "primereact/utils"
import { type FC, Fragment, useCallback, useEffect, useId } from "react"

import { BILLING_TYPES_CODES } from "data"
import { useOrganizationContext } from "organization"

import { type LaboratoryOrder, useInsuredPatientNoPhone, PatientPhoneRequiredMessage } from "../../labs"
import { FormField } from "../FormField"
import { InsuranceField } from "./InsuranceField"
import { usePatientContext } from "patients"

const BillingField: FC<Props> = ({
  billingLabel = "Bill to",
  showBillingLabel = true,
  insuranceLabel = "Insurance Plan",
  insuranceField = BILLING_TYPES_CODES.INSURANCE,
  insuranceValidation,
  onBillingChange,
  className,
  disabled,
  horizontal,
  labelClassName,
  radioButtonLabelClassName,
  hideInsuranceOption,
  hideNoInsuranceOptions,
}) => {
  const billingFieldId = useId()
  const { currentOrganizationBillingTypes } = useOrganizationContext()
  const {
    patient: { telecom },
  } = usePatientContext()
  const { setFieldValue } = useFormikContext<LaboratoryOrder>()

  const filteredBillingTypes = hideInsuranceOption
    ? currentOrganizationBillingTypes.filter(({ code }) => code !== BILLING_TYPES_CODES.INSURANCE)
    : hideNoInsuranceOptions
      ? currentOrganizationBillingTypes.filter(({ code }) => code === BILLING_TYPES_CODES.INSURANCE)
      : currentOrganizationBillingTypes

  const {
    values: { billingType },
  } = useFormikContext<FormikValues & { billingType: string | undefined }>()

  const { isInsuranceOrderPatientNoPhone } = useInsuredPatientNoPhone({ billingType, telecom })

  const defValidation = useCallback(
    (value: Reference[] | undefined) =>
      billingType === BILLING_TYPES_CODES.INSURANCE && (!value?.length || !value?.[0]?.id)
        ? `${insuranceLabel} is required`
        : isInsuranceOrderPatientNoPhone
          ? PatientPhoneRequiredMessage
          : undefined,
    [insuranceLabel, billingType],
  )

  const handleChange = (e: RadioButtonChangeEvent) => {
    if (e.value !== BILLING_TYPES_CODES.INSURANCE) setFieldValue(insuranceField, [])
    onBillingChange?.(e.value)
  }

  useEffect(() => {
    if (hideNoInsuranceOptions && !hideInsuranceOption) {
      setFieldValue("billingType", BILLING_TYPES_CODES.INSURANCE)
      onBillingChange?.(BILLING_TYPES_CODES.INSURANCE)
    } else if (hideInsuranceOption && billingType === BILLING_TYPES_CODES.INSURANCE) {
      setFieldValue("billingType", BILLING_TYPES_CODES.BILL_PATIENT)
      onBillingChange?.(BILLING_TYPES_CODES.BILL_PATIENT)
    }
  }, [hideNoInsuranceOptions, hideInsuranceOption])

  const renderBillingOption = (
    billingCode: Coding,
    name: string,
    value: string | undefined,
    onChange: (e: RadioButtonChangeEvent) => void,
  ) => (
    <>
      <RadioButton
        inputId={`${billingCode.code}-${billingFieldId}`}
        name={name}
        value={billingCode.code}
        checked={billingCode.code === value}
        onChange={(e) => {
          onChange(e)
          handleChange(e)
        }}
        disabled={disabled}
        radioGroup="billingType"
      />

      <label
        htmlFor={`${billingCode.code}-${billingFieldId}`}
        className={classNames(
          "mx-2 flex w-full cursor-pointer font-medium",
          {
            "cursor-auto text-gray-500": disabled,
          },
          radioButtonLabelClassName,
        )}
      >
        {billingCode.display}
      </label>
    </>
  )

  return (
    <>
      <FormField
        field="billingType"
        label={showBillingLabel ? billingLabel : undefined}
        horizontal={horizontal}
        labelClassName={labelClassName}
        className={className}
        containerClassName="grid grid-flow-row-dense grid-cols-2 gap-4"
      >
        {({ field: { name, value, onChange } }: FieldProps) => (
          <>
            {filteredBillingTypes.map((coding, index) => (
              <Fragment key={index}>
                {coding.code === BILLING_TYPES_CODES.INSURANCE ? (
                  <InsuranceField
                    field={insuranceField}
                    validation={insuranceValidation ?? defValidation}
                    horizontal={horizontal}
                    className={classNames(
                      "@container col-span-full flex items-center rounded-lg border p-3 text-sm",
                      coding.code === value ? "border-primary" : "border-gray-200",
                    )}
                    labelClassName={labelClassName}
                    setInsuranceBilling={(isInsurance) => {
                      if (isInsurance) {
                        setFieldValue("billingType", BILLING_TYPES_CODES.INSURANCE)
                        onBillingChange?.(BILLING_TYPES_CODES.INSURANCE)
                      } else {
                        setFieldValue("billingType", BILLING_TYPES_CODES.BILL_PATIENT)
                        onBillingChange?.(BILLING_TYPES_CODES.BILL_PATIENT)
                      }
                    }}
                  >
                    {renderBillingOption(coding, name, value, onChange)}
                  </InsuranceField>
                ) : (
                  <div
                    className={classNames(
                      "col-span-1 flex items-center rounded-lg border p-3 text-sm",
                      coding.code === value ? "border-primary" : "border-gray-200",
                    )}
                  >
                    {renderBillingOption(coding, name, value, onChange)}
                  </div>
                )}
              </Fragment>
            ))}
          </>
        )}
      </FormField>
    </>
  )
}

type Props = {
  billingLabel?: string
  insuranceLabel?: string
  insuranceField?: string
  horizontal?: boolean
  showBillingLabel?: boolean
  className?: string
  labelClassName?: string
  radioButtonLabelClassName?: string
  hideInsuranceOption?: boolean
  hideNoInsuranceOptions?: boolean
  hideInsuranceField?: boolean
  disabled?: boolean
  onBillingChange?(value: string): void
  insuranceValidation?(value: Reference[] | undefined): string
}

export { BillingField }
