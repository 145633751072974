import { type CheckboxChangeEvent, Checkbox } from "primereact/checkbox"
import { useState } from "react"

import { useOrganizationContext } from "organization"

import { type AdvanceFilter, LABS_SECTION_TYPE } from "../../types"

const LabsFilters = ({ activeTab, filters, onFilter }: Props) => {
  const { performerLabsEnabled } = useOrganizationContext()
  /*  const [selectedOrgs, setSelectedOrgs] = useState(initialValues?.orgs ?? []) */
  const [selectedPerformerLabs, setSelectedPerformerLabs] = useState(filters?.performerLabs ?? [])

  /* const onSelectedOrgsChange = (e: CheckboxChangeParams) => {
  const currentSelectedOrgs = [...selectedOrgs]
  if (e.checked) currentSelectedOrgs.push(e.value)
  else currentSelectedOrgs.splice(currentSelectedOrgs.indexOf(e.value), 1)

  setSelectedOrgs(currentSelectedOrgs)
}
*/
  const handleChangeFilters = (field: string, value: boolean | string[]) =>
    field === "orgs"
      ? onFilter({ orgs: value as string[] })
      : field === "performerLabs"
        ? onFilter({ performerLabs: value as string[] })
        : onFilter({ [field]: value })

  const onSelectedPerformerLabsChange = (e: CheckboxChangeEvent) => {
    const currentSelectedPerformerLabs = [...selectedPerformerLabs]
    if (e.checked) currentSelectedPerformerLabs.push(e.value)
    else currentSelectedPerformerLabs.splice(currentSelectedPerformerLabs.indexOf(e.value), 1)

    setSelectedPerformerLabs(currentSelectedPerformerLabs)
    handleChangeFilters("performerLabs", currentSelectedPerformerLabs)
  }

  const isComboFilter = activeTab === LABS_SECTION_TYPE.LABS_COMBOS_SECTION

  return (
    <>
      <div className="flex flex-col gap-3 pt-6 pb-6">
        <label className="text-sm text-gray-400">Laboratories</label>
        {filters.performerLabs &&
          performerLabsEnabled?.map(({ ref: item }) => (
            <div key={item.id} className="flex flex-row gap-4">
              <Checkbox
                onChange={(e) => {
                  onSelectedPerformerLabsChange(e)
                }}
                checked={selectedPerformerLabs?.includes(item.id!)}
                value={item.id}
              />
              <label className="p-checkbox-label">{item.display}</label>
            </div>
          ))}
      </div>
      {/* <div className="pt-6 pb-6 flex flex-col gap-3">
        <label className="text-sm text-gray-400">Organizations</label>
        {initialValues.orgs &&
          orgs?.map((item) => (
            <div key={item.code} className="flex flex-row gap-4">
              <Checkbox
                onChange={(e) => {
                  onSelectedOrgsChange(e)
                }}
                checked={selectedOrgs?.includes(item.code!)}
                value={item.code}
              />
              <label className="p-checkbox-label">{item.display}</label>
            </div>
          ))}
      </div> */}
      <div className="flex flex-row gap-4 pt-6">
        <Checkbox
          onChange={(e) => handleChangeFilters("archived", e.checked ?? false)}
          checked={filters.archived ?? false}
          value="Show Archived"
        />
        <label className="p-checkbox-label">Show Archived</label>
      </div>
      {isComboFilter && (
        <div className="flex flex-row gap-4 pt-6">
          <Checkbox
            onChange={(e) => handleChangeFilters("hidden", e.checked ?? false)}
            checked={filters.hidden ?? false}
            value="Show Hidden"
          />
          <label className="p-checkbox-label">Show Hidden</label>
        </div>
      )}
    </>
  )
}

type Props = {
  filters: AdvanceFilter
  activeTab: LABS_SECTION_TYPE
  onFilter: (filters: AdvanceFilter) => void
}

export { LabsFilters }
