import { useState } from "react"
import { classNames } from "primereact/utils"

import { usePatientContext } from "patients"
import { LoadingView } from "commons"

import { useDosespotUrl } from "../hooks"

const DosespotIframe = ({ practitionerRoleId }: IProps) => {
  const { patientId } = usePatientContext()
  const [isLoadingIframe, setIsloadingIframe] = useState(true)
  const { dosespotUrl, isLoading: isLoadingUrl } = useDosespotUrl(patientId, practitionerRoleId ?? "")
  const isLoading = isLoadingIframe || isLoadingUrl

  return (
    <div className="relative flex h-full w-full flex-col p-3">
      <div className="flex h-full w-full flex-col overflow-auto bg-white">
        {isLoading && <LoadingView />}
        <iframe
          title="Dosespot"
          src={dosespotUrl ?? ""}
          width="100%"
          height="100%"
          className={classNames("border-none", { hidden: isLoading })}
          onLoad={() => setIsloadingIframe(false)}
        />
      </div>
    </div>
  )
}

type IProps = {
  practitionerRoleId: string
}

export { DosespotIframe }
