import {
  type ChargeItemDefinition,
  type CodeableConcept,
  type Dosage,
  type Duration,
  type MedicationDispense,
  type MedicationKnowledge,
  type MedicationKnowledgeAdministrationGuidelinesArray,
  type MedicationRequestDispenseRequest,
  type MedicationRequestMedication,
  type Patient,
  type PractitionerRole,
  type Quantity,
  type Reference,
  type Task,
  type Timing,
  asReference,
  codeableConceptAsString,
  isDevice,
  isPatient,
  isPractitioner,
  type Parameters,
} from "fhir"

import { ADMINISTRATION_GUIDELINE_DOSAGE_TYPE, formatsByTypes, MED_FEE_TYPE, mrCategoryCodes, unitOfTime } from "data"
import { SYSTEM_VALUES } from "system-values"
import {
  getBillToPatientFeePrice,
  getCommonCode,
  isRefrigeratedMedicationKnowledge,
  medicationKnowledgeRegulations,
} from "utils"

import { format, parseISO } from "date-fns"
import type { PractitionerInfo } from "../types"
import {
  commonsDispenseInterval,
  DISPENSE_MEDICATIONS_TASK_CODE,
  dosageTimingRepeats,
  DOSE_RANGE_REGEX,
  INJECTION_DRUG_CODES,
  REFILL_MODES,
  treatmentFrequencies,
} from "./data"
import type { ActivityEventAgent, AgentData, MedicationRequestInfo, RefillOnDemandValidationResponse } from "./types"

const getMRInitialValues = (
  medicationKnowledge: MedicationKnowledge,
  dispenseQuantity: number,
  patient: Patient,
  loggedInPractitionerRole: PractitionerRole,
  practitionersInfo: PractitionerInfo[],
  catalogAuthor: Reference,
  encounter?: Reference | null,
  requesterPractitionerRole?: Reference,
): MedicationRequestInfo => {
  const mkDefaultDosages = medicationKnowledge.administrationGuidelines?.[0]?.dosage?.reduce((prev, dosageArray) => {
    return [...prev, ...dosageArray.dosage]
  }, [] as Dosage[])

  const defaultQuantity = {
    value: dispenseQuantity,
    code: medicationKnowledge.packaging?.type?.coding?.[0].code,
    unit: medicationKnowledge.packaging?.type?.coding?.[0].display,
    system: SYSTEM_VALUES.MK_PACKAGE_TYPE,
  } as Quantity
  const currentDate = new Date().toISOString()

  const mrCategory = [{ coding: [mrCategoryCodes.nutraceutical], text: mrCategoryCodes.nutraceutical.display }]

  const isMKRefrigerated = isRefrigeratedMedicationKnowledge(medicationKnowledge)
  const regulations = medicationKnowledgeRegulations(medicationKnowledge)

  if (isMKRefrigerated) {
    mrCategory.push({ coding: [mrCategoryCodes.refrigerated], text: mrCategoryCodes.refrigerated.display })
  }

  if (regulations?.length) {
    mrCategory.push(
      ...regulations.map((code) => ({
        coding: [code],
        text: code.display ?? code.code,
      })),
    )
  }

  const patientGpRole =
    requesterPractitionerRole ??
    practitionersInfo.find(
      ({ practitioner, practitionerRole }) =>
        practitioner.id === patient.generalPractitioner?.[0]?.id && !!practitionerRole,
    )?.practitionerRole

  const requester = patientGpRole ? asReference(patientGpRole) : undefined

  return {
    medication: { CodeableConcept: medicationKnowledge.code },
    status: "draft",
    intent: "order",
    category: mrCategory,
    authoredOn: currentDate,
    subject: asReference(patient),
    encounter: encounter ?? undefined,
    requester,
    recorder: asReference(loggedInPractitionerRole),
    performer: asReference(patient),
    dosageInstruction: mkDefaultDosages,
    dispenseRequest: {
      initialFill: {
        quantity: defaultQuantity,
        duration: commonsDispenseInterval[1].value,
        isDfo: false,
      },
      nextRefillDate: currentDate,
      numberOfRepeatsAllowed: 0,
      dispenseInterval: commonsDispenseInterval[1].value,
      expectedSupplyDuration: commonsDispenseInterval[1].value,
      quantity: defaultQuantity,
      performer: catalogAuthor,
    },
    doseQuantity: "1",
    medicationUnit: medicationKnowledge.amount?.unit,
    treatmentFrequency: treatmentFrequencies[0].value,
    unitsByRecipient: medicationKnowledge.amount?.value,
    resourceType: "MedicationRequest",
  }
}

const getMedCommonCode = ({
  medication,
  medicationKnowledge,
}: {
  medicationKnowledge?: MedicationKnowledge
  medication?: MedicationRequestMedication
}) => getCommonCode({ codes: medication?.CodeableConcept?.coding ?? medicationKnowledge?.code?.coding })

const getAdministrationGuideline = (
  med?: { code?: CodeableConcept },
  medRecommendedDosage?: Record<string, Dosage[]>,
) => {
  const medCode = getCommonCode({ codes: med?.code?.coding })
  const dosage = medRecommendedDosage?.[medCode]

  return dosage
    ? ({
        dosage: [{ dosage, type: ADMINISTRATION_GUIDELINE_DOSAGE_TYPE }],
      } as MedicationKnowledgeAdministrationGuidelinesArray)
    : undefined
}

const getMKDisplayText = (e?: MedicationKnowledge) => {
  const textCode = codeableConceptAsString(e?.code)
  const packagingText =
    !!e?.packaging?.type?.coding?.[0]?.display &&
    !!e?.packaging?.quantity?.value &&
    `${e?.packaging?.type?.coding?.[0]?.display}`
  const strength = e?.ingredient?.length === 1 && e?.ingredient[0]?.strength?.numerator?.unit
  const doseForm = e?.doseForm?.coding?.[0]?.display

  const displayArray = [textCode, strength, doseForm, packagingText].filter(Boolean)

  return `${displayArray.join(" - ")}`
}

const sanitizeMR = ({ ...medicationReq }: MedicationRequestInfo, mrFromMK?: boolean) => {
  const currentDate = new Date().toISOString()
  if (!medicationReq.authoredOn) medicationReq.authoredOn = currentDate
  if (!medicationReq.dispenseRequest?.nextRefillDate)
    medicationReq.dispenseRequest = { ...medicationReq.dispenseRequest, ...{ nextRefillDate: currentDate } }

  const quantity = medicationReq.dispenseRequest?.initialFill?.quantity as Quantity
  const duration = medicationReq.dispenseRequest?.initialFill?.duration as Duration
  // TODO: commented until we support this
  // const interval = medicationReq.dispenseRequest.dispenseInterval?.value

  const repeats = medicationReq.dispenseRequest?.numberOfRepeatsAllowed

  medicationReq.dispenseRequest = {
    ...medicationReq.dispenseRequest,
    quantity,
    ...(repeats !== undefined ? { numberOfRepeatsAllowed: repeats } : undefined),
    expectedSupplyDuration: duration,
    validityPeriod: {
      start: medicationReq.dispenseRequest?.validityPeriod?.start ?? new Date().toISOString(),
      // TODO: commented until we support this
      // ...(!interval
      //   ? {
      //       end: add(
      //         medicationReq.dispenseRequest?.validityPeriod?.start
      //           ? new Date(medicationReq.dispenseRequest.validityPeriod.start)
      //           : new Date(),
      //         { [`${duration.unit ?? "second"}s`]: (duration.value ?? 0) * ((repeats ?? 0) + 1) },
      //       ).toISOString(),
      //     }
      //   : undefined),
    },
    refillMode:
      medicationReq.dispenseRequest.dispenseInterval?.value === 0
        ? REFILL_MODES["one-time"]
        : REFILL_MODES["auto-order"],
  }

  const frequency = getTimingFrequency(medicationReq.treatmentFrequency)

  if ((mrFromMK && !medicationReq.dosageInstruction?.length) || !mrFromMK) {
    medicationReq.dosageInstruction = [
      {
        timing: { ...frequency },
        text: `Take ${medicationReq.doseQuantity} ${medicationReq.medicationUnit?.toLowerCase()} ${treatmentFrequencies
          .find((f) => f.value === medicationReq.treatmentFrequency)
          ?.label?.toLowerCase()}`,
      },
      ...(medicationReq.dosageInstruction?.slice(1) ?? []),
    ]

    const matches = medicationReq.doseQuantity?.match(DOSE_RANGE_REGEX)
    if (matches?.length === 2) {
      medicationReq.dosageInstruction[0].doseAndRate = [
        {
          dose: {
            Range: {
              low: { value: Number.parseInt(matches[0].replace("-", "")), unit: medicationReq.medicationUnit },
              high: { value: Number.parseInt(matches[1]), unit: medicationReq.medicationUnit },
            },
          },
        },
      ]
    } else {
      medicationReq.dosageInstruction[0].doseAndRate = [
        {
          dose: {
            Quantity: { value: Number.parseInt(medicationReq.doseQuantity ?? "1"), unit: medicationReq.medicationUnit },
          },
        },
      ]
    }
  }

  if (!medicationReq.encounter) delete medicationReq.encounter
  if (!medicationReq.recorder) delete medicationReq.recorder
  if (!medicationReq.requester) delete medicationReq.requester
  if (!medicationReq.performer) delete medicationReq.performer
  if (!medicationReq.note?.[0].text) delete medicationReq.note

  delete medicationReq.unitsByRecipient
  delete medicationReq.medicationUnit
  delete medicationReq.treatmentFrequency
  delete medicationReq.doseQuantity

  return medicationReq
}

const getTimingFrequency = (frequencyKeyValue?: string) => {
  const selectedfrequency = dosageTimingRepeats.find((option) => option.id === frequencyKeyValue)

  const frequency = { code: selectedfrequency?.code, repeat: selectedfrequency?.repeat }

  const defaultTimingFreq = {
    code: { coding: [{ code: "QD", system: SYSTEM_VALUES.V3_GTSABB }] },
    repeat: { periodUnit: unitOfTime[3].code, period: 0, frequency: 0 },
  } as Timing

  return frequency ?? defaultTimingFreq
}

// TODO: Check this code back with meds team to improve implementation
const hasInvalidMedicationDispense = (medicationDispenses?: MedicationDispense[]) =>
  Boolean(
    medicationDispenses?.some((md) => md.statusReason?.CodeableConcept?.coding?.[0]?.code === "Invalid Submission"),
  )

const getFeeType = (cids: ChargeItemDefinition[]) =>
  cids?.some(
    (cid) =>
      cid?.useContext?.find((context) => context?.code?.code === "bill-patient-fee-type")?.value?.CodeableConcept
        ?.coding?.[0]?.code === "by-frequency",
  )
    ? MED_FEE_TYPE.ByFrequency
    : MED_FEE_TYPE.Fixed

const getMedFee = (cids: ChargeItemDefinition[]) => {
  const uniqueFees = new Map<string, { value: number; duration?: Quantity }>()

  for (const cid of cids || []) {
    const fee = getBillToPatientFeePrice(cid?.propertyGroup?.[0]?.priceComponent)?.amount?.value

    if (!fee) continue

    const isByFrequency = getFeeType([cid]) === MED_FEE_TYPE.ByFrequency
    let key = `${fee}`
    const feeObject: { value: number; duration?: Quantity } = { value: fee }

    if (isByFrequency) {
      const applicableDurationExpression = cid?.propertyGroup?.[0]?.applicability?.find(
        (a) => a.description === "frequency",
      )?.expression

      if (!applicableDurationExpression) continue

      const [durationValue, durationUnit] = applicableDurationExpression.split(" ")
      const duration = {
        value: Number.parseInt(durationValue),
        unit: durationUnit,
        code: unitOfTime.find((u) => u.display.toLowerCase() === durationUnit.toLowerCase())?.code,
      } as Quantity

      if (duration) {
        key += `-${duration.value}-${duration.unit}`
        feeObject.duration = duration
      }
    }

    if (!uniqueFees.has(key)) {
      uniqueFees.set(key, feeObject)
    }
  }

  const result = Array.from(uniqueFees.values())
  return result.length ? result : undefined
}

const getActivityEventParticipants = (agent: ActivityEventAgent[] | undefined) =>
  agent?.reduce<AgentData[]>((agents, agent) => {
    let result: AgentData | undefined

    if (agent.who?.type === "Client") {
      result = { name: agent.who.display as string, type: "Client", requestor: agent.requestor ?? false }
    } else if (isPatient(agent.who) || isPractitioner(agent.who)) {
      result = {
        name: agent?.who?.display as string,
        type: agent.who?.type ?? agent.who?.resourceType ?? "Unspecified",
        requestor: agent.requestor ?? false,
      }
    } else if (isDevice(agent.who)) {
      result = {
        name: agent.who.display as string,
        type: codeableConceptAsString(agent.type, ""),
        requestor: agent.requestor ?? false,
      }
    }

    if (result) {
      if (!result.name) result.name = agent.name ?? (agent.who?.display as string)
      return [...agents, result]
    }
    return agents
  }, [])

const isInjectableMK = (mk?: MedicationKnowledge) =>
  mk?.doseForm?.coding?.some(({ code }) => INJECTION_DRUG_CODES.includes(code as string)) ?? false

const isDispenseTask = (task?: Task) =>
  task?.code?.coding?.some(
    ({ code, system }) =>
      code === DISPENSE_MEDICATIONS_TASK_CODE.code && system === DISPENSE_MEDICATIONS_TASK_CODE.system,
  ) ?? false

const getMRDispenseInfo = (
  status: string,
  dispenseRequest?: MedicationRequestDispenseRequest,
  dispenses?: MedicationDispense[],
) => {
  const refills = dispenseRequest?.numberOfRepeatsAllowed ?? 0
  const dispensed = dispenses?.filter(({ status }) => ["completed", "in-progress"].includes(status)).length ?? 0
  const refillsLeft =
    ["completed", "stopped"].includes(status) || !refills
      ? 0
      : refills - (dispensed > 1 ? dispensed - 1 /* -1 initial fill */ : 0)

  const startDispense =
    dispenseRequest?.validityPeriod?.start &&
    format(parseISO(dispenseRequest?.validityPeriod?.start), formatsByTypes.SHORT_MONTH_YEAR)
  const isDfo = (dispenseRequest?.initialFill?.isDfo && dispensed <= 1) ?? false
  const nextRefill = ["completed", "stopped"].includes(status) ? undefined : dispenseRequest?.nextRefillDate

  return { refills, dispensed, startDispense, isDfo, nextRefill, refillsLeft }
}

const validationInitialValues: RefillOnDemandValidationResponse = {
  isValid: true,
  earliestRefillDate: "",
  failureReason: "",
}

export const getOnDemandValidationResponseFromParameters = (parameters: Parameters): RefillOnDemandValidationResponse =>
  parameters?.parameter?.reduce((acc, { name, value }) => {
    switch (name) {
      case "is-valid":
        return { ...acc, isValid: value?.boolean }
      case "reason":
        return { ...acc, failureReason: value?.string }
      case "earliest-refill-date":
        return { ...acc, earliestRefillDate: value?.dateTime }
      default:
        return acc
    }
  }, validationInitialValues) ?? validationInitialValues

export {
  getActivityEventParticipants,
  getAdministrationGuideline,
  getFeeType,
  getMedCommonCode,
  getMedFee,
  getMKDisplayText,
  getMRDispenseInfo,
  getMRInitialValues,
  hasInvalidMedicationDispense,
  isDispenseTask,
  isInjectableMK,
  sanitizeMR,
}
