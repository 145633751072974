import { faPenField } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type FormikValues, useFormikContext } from "formik"

import { Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { type ReactNode, useEffect, useState } from "react"

import { DismissButton } from "../components/Buttons"

const DissmisableFieldList = <T extends FormikValues>({
  label,
  containerClassName,
  fieldDefinitions,
  addFieldLabel = "Add item",
  removeFieldLabel = "Remove item",
  emptyFieldsMessage = "No fields added",
  fieldContainerClassName,
  dismissButtonClassName,
  className,
  fieldIsInitialActive,
  onFieldStatusChanged,
  children,
}: DissmisableFieldListProps<T>) => {
  const [activeFields, setActiveFields] = useState<Array<DissmisableFieldProps<T>>>([])
  const { initialValues, setFieldTouched, isSubmitting } = useFormikContext<FormikValues>()

  useEffect(() => {
    setActiveFields(fieldDefinitions.filter((fd) => fieldIsInitialActive(fd)))
  }, [initialValues])

  useEffect(() => {
    isSubmitting && activeFields.forEach(({ field: fieldName }) => setFieldTouched(fieldName))
  }, [isSubmitting])

  const availableFields = fieldDefinitions.filter((fd) => activeFields.every((af) => af.field !== fd.field))

  const addField = (fieldData: DissmisableFieldProps<T>) => {
    setActiveFields([fieldData, ...activeFields])
    onFieldStatusChanged?.(fieldData, true)
  }

  const removeField = (fieldData: DissmisableFieldProps<T>) => {
    setActiveFields(activeFields.filter((af) => af.field !== fieldData.field))
    onFieldStatusChanged?.(fieldData, false)
  }

  return (
    <div className={classNames("flex flex-col space-y-3", className)}>
      <div className="flex flex-1 justify-between">
        <div className="font-medium text-gray-900">{label}</div>
        {!!availableFields.length && (
          <Dropdown
            optionLabel="label"
            options={availableFields}
            onChange={(e) => addField(e.value)}
            filter
            filterBy="label"
            showFilterClear
            resetFilterOnHide
            className="p-inputtext-sm no-focusable small-trigger trigger-dashed flex-row-reverse items-center border-0 px-1 py-0 text-sm font-medium"
            placeholder={addFieldLabel}
            dropdownIcon="pi pi-plus"
            panelClassName=""
          />
        )}
      </div>
      <div className={containerClassName}>
        {!activeFields.length ? (
          <div className="flex flex-1 items-center justify-center space-x-1 py-3 text-slate-500">
            <FontAwesomeIcon icon={faPenField} size="1x" />
            <span className="text-sm">{emptyFieldsMessage}</span>
          </div>
        ) : (
          activeFields.map((item, index) => (
            <div
              key={`${item.field}${index}`}
              className={classNames("flex items-center space-x-2", fieldContainerClassName)}
            >
              {children(item)}
              <DismissButton
                label={removeFieldLabel}
                className={dismissButtonClassName}
                onClick={() => removeField(item)}
              />
            </div>
          ))
        )}
      </div>
    </div>
  )
}

type DissmisableFieldProps<T> = {
  label: string
  field: string
  itemData?: T
}

type DissmisableFieldListProps<T> = {
  label: string
  className?: string
  containerClassName?: string
  addFieldLabel?: string
  removeFieldLabel?: string
  fieldDefinitions: DissmisableFieldProps<T>[]
  fieldContainerClassName?: string
  dismissButtonClassName?: string
  emptyFieldsMessage?: string
  fieldIsInitialActive(item: DissmisableFieldProps<T>): boolean
  onFieldStatusChanged?(item: DissmisableFieldProps<T>, enabled: boolean): void
  children(itemField: DissmisableFieldProps<T>): ReactNode
}

export { DissmisableFieldList }
