import type { AccountBETACreditCardArray } from "fhir"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarCheck, faUser } from "@fortawesome/pro-light-svg-icons"
import { faPencil, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { classNames } from "primereact/utils"

import { formatCreditCardNumber } from "utils"
import { CardListItem } from "commons"
import visa from "assets/visasm.png"
import masterCard from "assets/mastercard.png"
import americanExpress from "assets/amex.png"
import discover from "assets/discover.png"
import dinersClub from "assets/dinersclub.png"
import jcb from "assets/jcb.png"
import genericCard from "assets/gcard.png"

const OrderCreditCardListItem = ({
  creditCard,
  selected = false,
  onSelect,
  onEdit,
  onDelete,
  index,
  isLoading,
  disabled,
}: Props) => {
  const cardImg: Record<cardType, string> = {
    AE: americanExpress,
    MC: masterCard,
    V: visa,
    D: discover,
    DC: dinersClub,
    JCB: jcb,
  }

  const dropdownItems = [
    {
      label: "Edit",
      icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />,
      command: () => onEdit(index),
    },
    {
      label: "Delete",
      icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
      command: () => onDelete(index),
    },
  ]

  const rowImg = (
    <img
      src={cardImg[creditCard.type as cardType] ?? genericCard}
      alt="creditCard.type"
      className="mr-2 h-10 rounded-lg"
      title="Type"
    />
  )
  const head = (
    <div className="inline-flex items-center">
      <div className="mr-2 text-sm font-medium" title="Number">
        {formatCreditCardNumber(creditCard.last4Digits as string, creditCard.type)}
      </div>
    </div>
  )
  const details = (
    <div className="inline-flex items-center text-sm">
      <span title="Holder name">
        <FontAwesomeIcon icon={faUser} size="xs" className="mr-1" />
        {creditCard.cardHolderName ?? "Unspecified"}
      </span>
      <span className="mx-1">|</span>
      <span title="Expiration date">
        <FontAwesomeIcon icon={faCalendarCheck} size="xs" className="mx-1" />
        {`${creditCard.expirationMonth?.padStart(2, "0")}-${creditCard.expirationYear}`}{" "}
      </span>
    </div>
  )
  return (
    <CardListItem
      rowImg={rowImg}
      contentHeader={head}
      contentSubheader={details}
      className={classNames(
        "min-w-80 rounded-lg border border-gray-200 bg-white shadow-xs transition-all ease-in-out hover:shadow-md focus:outline-hidden",
        {
          "border-transparent ring-2 ring-primary": selected,
        },
        { "cursor-pointer": !disabled },
      )}
      dropdownMenuItems={dropdownItems}
      disabled={disabled}
      onContentCliked={() => onSelect(creditCard)}
      isLoading={isLoading}
    />
  )
}

type cardType = "AE" | "MC" | "V" | "D" | "DC" | "JCB"

type Props = {
  creditCard: AccountBETACreditCardArray
  selected: boolean
  index: number
  onSelect(creditCard: AccountBETACreditCardArray): void
  onEdit(index: number): void
  onDelete(index: number): void
  isLoading: boolean
  disabled?: boolean
}
export { OrderCreditCardListItem }
