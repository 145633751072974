import { useCallback, type FC } from "react"
import { Tooltip } from "primereact/tooltip"

import { EventType, type CalendarEventDetails } from "commons/types"
import { AppointmentDetails } from "calendar"

import { SlotDetails } from "./SlotDetails"

const EventDetailsContainer: FC<Props> = ({ calendarEvent, onUnbook, onReleaseSlot }) => {
  const renderDetail = useCallback(() => {
    switch (calendarEvent.extraInfo.eventType) {
      case EventType.APPT:
        return <AppointmentDetails calendarEvent={calendarEvent} onUnbook={onUnbook} />
      case EventType.SLOT:
        return <SlotDetails calendarEvent={calendarEvent} onRelease={onReleaseSlot} />
    }
  }, [calendarEvent])

  return (
    <Tooltip
      target={`#appt_${calendarEvent.event.id}`}
      pt={{
        arrow: { className: "hidden" },
        text: { className: "bg-white !p-0 pb-1 border border-gray-200 shadow-md min-w-56" },
      }}
      position="mouse"
      autoHide={false}
      showDelay={100}
    >
      {renderDetail()}
    </Tooltip>
  )
}

type Props = {
  calendarEvent: CalendarEventDetails
  onReleaseSlot?: () => void
  onUnbook?: () => void
}

export { EventDetailsContainer }
