import type { FC } from "react"

import { SnippetsNoteContainer } from "administration"
import { asReference } from "fhir"
import { useLoginContext } from "security"

import { ProfileSection } from "../data"

const SnippetsNoteInformation: FC = () => {
  const { loggedInPractitioner } = useLoginContext()
  return (
    <div
      className="profile-card-section scroll-mt-4 rounded-lg border p-4 pb-0 shadow-xs"
      id={ProfileSection.SNIPPETS_NOTE}
    >
      <h3 className="mb-2 flex text-lg font-semibold text-gray-900">Snippets note</h3>
      <SnippetsNoteContainer author={asReference(loggedInPractitioner)} />
    </div>
  )
}

export { SnippetsNoteInformation }
