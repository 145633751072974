import { classNames } from "primereact/utils"
import { useState } from "react"

import { LoadingView } from "commons"
import { useOrganizationContext } from "organization"

import { useSettingsDefinition } from "../hooks"
import type { SettingDefinitionItem } from "../types"
import { SettingDetails } from "./SettingDetails"

const SettingsView = () => {
  const { currentOrganizationId } = useOrganizationContext()
  const { settingsDefinition, isLoading } = useSettingsDefinition(currentOrganizationId)
  const [selectedDefinition, setSelectedDefinition] = useState<SettingDefinitionItem>(settingsDefinition[0])

  return (
    <>
      {isLoading ? (
        <LoadingView />
      ) : (
        <div className="flex h-full w-full flex-col pt-2">
          <div className="mb-6 w-full shrink-0 pr-32 pl-6 text-sm">
            <ul className="flex flex-row border-b border-gray-200">
              {settingsDefinition.map((setting, index) => (
                <MenuItem
                  key={setting.label}
                  settingDefinitionItem={setting}
                  selected={setting.settingDefinitionId === selectedDefinition.settingDefinitionId}
                  onClick={setSelectedDefinition}
                  className={index === 0 ? "pl-0" : ""}
                />
              ))}
            </ul>
          </div>
          <div className="grow overflow-y-auto pl-1">
            <SettingDetails
              settingDefinitionId={selectedDefinition.settingDefinitionId}
              label={selectedDefinition.label}
            />
          </div>
        </div>
      )}
    </>
  )
}

const MenuItem = ({ settingDefinitionItem, selected, onClick, className }: MenuItemProps) => (
  <li
    className={classNames(
      "cursor-pointer border-b-2 border-gray-200 p-3 text-gray-500 hover:bg-blue-100/10 hover:text-primary",
      {
        "border-primary bg-blue-100/10 text-primary": selected,
        "border-transparent": !selected,
      },
      className,
    )}
    onClick={() => onClick(settingDefinitionItem)}
  >
    {settingDefinitionItem.label}
  </li>
)

type MenuItemProps = {
  settingDefinitionItem: SettingDefinitionItem
  selected?: boolean
  onClick(group: { label: string; settingDefinitionId: string }): void
  className: string
}

export { SettingsView }
