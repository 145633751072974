import type { PropsWithChildren } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"

const DetailsContainer = ({ children, onClose, title }: PropsWithChildren<Props>) => {
  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <div className="flex justify-between px-4 py-6 sm:px-6">
        <h2 className="text-lg leading-6 font-semibold text-gray-900">{title ?? "Details"}</h2>
        <span className="cursor-pointer rounded-full px-2 hover:bg-gray-100" title="Close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>

      <div className="space-y-6 px-4 pb-6 sm:px-6">{children}</div>
    </div>
  )
}

type Props = {
  onClose(): void
  title?: string
}

export { DetailsContainer }
