import type { Address, ChargeItemDefinition, Coverage } from "fhir"
import { Formik } from "formik"
import { classNames } from "primereact/utils"
import { useState } from "react"

import { AddFieldArrayItemButton, BillingField, Button } from "commons"
import { BILLING_TYPES_CODES } from "data"

import { useCPOERequestsContext } from "../hooks"
import { type ActionGroupCode, type CpoeRequest, ACTION_GROUP_CODES } from "../types"
import { CheckoutItemsSection } from "./CheckoutItemsSection"
import { LaboratoryOrderListItem } from "./LaboratoryOrderListItem"
import { OrderDiscountListItem } from "./OrderDiscountListItem"
import { OrderFeeListItem } from "./OrderFeeListItem"
import { OrdersShippingAddress } from "./OrdersShippingAddress"

const CheckoutSection = ({
  shippingMethods,
  discounts,
  coverage,
  handleSelectCoverage,
  requests,
  sectionRequestsType,
  selectedShippingMethod,
  handleSelectShippingMethod,
  selectedDiscount,
  handleSelectDiscount,
  totalItemsCost,
  nutrasShippingAddress,
  className,
  selectedRefillDiscount,
  handleSelectRefillDiscount,
}: Props) => {
  const {
    isProcessingActions,
    productPrices,
    activeRequestsInfo: { hasOnlyDFONutras, hasDropShipNutras },
  } = useCPOERequestsContext()
  const [showDiscount, setShowDiscount] = useState(!!selectedDiscount)
  const [showPharmaShippingMethod, setShowPharmaShippingMethod] = useState(!!selectedShippingMethod)

  const showBillingTypes = [ACTION_GROUP_CODES.NUTRA, ACTION_GROUP_CODES.PHARMA, ACTION_GROUP_CODES.LAB].includes(
    sectionRequestsType as ACTION_GROUP_CODES,
  )
  const showShippingMethods =
    [ACTION_GROUP_CODES.NUTRA, ACTION_GROUP_CODES.PHARMA].includes(sectionRequestsType as ACTION_GROUP_CODES) &&
    sectionRequestsType === ACTION_GROUP_CODES.NUTRA
      ? !hasOnlyDFONutras
      : true

  const initialBillingType = (coverage?.type?.coding?.[0].code ??
    BILLING_TYPES_CODES.BILL_PATIENT) as BILLING_TYPES_CODES
  const filteredRequests = requests.filter((req) => req.type === sectionRequestsType)
  const showShippingAddress = sectionRequestsType === ACTION_GROUP_CODES.NUTRA && !!hasDropShipNutras
  const isPharmaSection = sectionRequestsType === ACTION_GROUP_CODES.PHARMA
  const allowRemoveShippingMethod = showShippingMethods && selectedShippingMethod && isPharmaSection

  if (!filteredRequests.length) return null

  return (
    <div className={classNames("pl-3", className)}>
      <div className="space-y-6">
        {sectionRequestsType !== ACTION_GROUP_CODES.LAB && (
          <CheckoutItemsSection
            readonly
            requests={filteredRequests}
            sectionRequestsType={sectionRequestsType}
            billingType={initialBillingType}
            productPrices={productPrices}
          />
        )}
        {(!!shippingMethods?.length || !!discounts?.length || showBillingTypes || showShippingAddress) && (
          <>
            {showShippingAddress && (
              <OrdersShippingAddress
                className="pl-4"
                showMedsAddress={sectionRequestsType === ACTION_GROUP_CODES.NUTRA}
                nutrasShippingAddress={nutrasShippingAddress}
              />
            )}
            {showShippingMethods && (
              <div className="flex flex-col pl-4">
                <div className="flex h-8 items-center justify-between">
                  <h4 className="inline-flex w-full items-center justify-between text-sm font-medium">
                    {sectionRequestsType === ACTION_GROUP_CODES.PHARMA ? "Additional Services" : "Shipping methods"}
                    <hr className="ml-3 flex-1 border border-gray-200" />
                  </h4>
                  {allowRemoveShippingMethod && (
                    <Button
                      buttonStyle="text"
                      label="Remove"
                      onClick={() => {
                        if (selectedShippingMethod) {
                          handleSelectShippingMethod?.(selectedShippingMethod, sectionRequestsType)
                          setShowPharmaShippingMethod(false)
                        }
                      }}
                    />
                  )}
                </div>
                {shippingMethods?.length ? (
                  isPharmaSection && !showPharmaShippingMethod ? (
                    <AddFieldArrayItemButton label="Add service" onClick={() => setShowPharmaShippingMethod(true)} />
                  ) : (
                    <div className="my-3 pl-1">
                      {shippingMethods.map((cid, index) => (
                        <OrderFeeListItem
                          key={cid.id ?? index}
                          cid={cid}
                          selectCid={() => handleSelectShippingMethod?.(cid, sectionRequestsType)}
                          selected={selectedShippingMethod?.id === cid.id}
                          totalItemsCost={totalItemsCost ?? 0}
                        />
                      ))}
                    </div>
                  )
                ) : (
                  <p className="text-sm">
                    {sectionRequestsType === ACTION_GROUP_CODES.PHARMA
                      ? "No additional services"
                      : "No shipping methods"}
                  </p>
                )}
              </div>
            )}
            {!!discounts?.length && (
              <div className="flex flex-col pl-4">
                <div className="flex h-8 items-center justify-between">
                  <h4 className="inline-flex w-full items-center justify-between text-sm font-medium">
                    Discounts
                    <hr className="ml-3 flex-1 border border-gray-200" />
                  </h4>
                  {showDiscount && (
                    <Button
                      buttonStyle="text"
                      label="Remove"
                      onClick={() => {
                        selectedDiscount && handleSelectDiscount?.(selectedDiscount, sectionRequestsType)
                        setShowDiscount(false)
                      }}
                    />
                  )}
                </div>

                <div className="pl-1">
                  {showDiscount ? (
                    discounts.map((cid, index) => (
                      <OrderDiscountListItem
                        key={cid.id ?? index}
                        discount={cid}
                        selectCid={() => handleSelectDiscount?.(cid, sectionRequestsType)}
                        selected={selectedDiscount?.id === cid.id}
                        sectionRequestsType={sectionRequestsType}
                        selectedRefill={selectedRefillDiscount?.id === cid.id}
                        selectRefillCid={() => handleSelectRefillDiscount?.(cid, sectionRequestsType)}
                      />
                    ))
                  ) : (
                    <AddFieldArrayItemButton label="Add discount" onClick={() => setShowDiscount(true)} />
                  )}
                </div>
              </div>
            )}
            {showBillingTypes && (
              <div className="flex flex-col divide-y divide-gray-200 pl-4">
                {sectionRequestsType === ACTION_GROUP_CODES.LAB ? (
                  <>
                    {filteredRequests
                      .toSorted((a) => (a.laboratoryData?.billingType === BILLING_TYPES_CODES.INSURANCE ? 1 : -1))
                      .map((item, index) => (
                        <Formik
                          key={item.resource.resource?.id ?? index}
                          initialValues={{ billingType: item?.laboratoryData?.billingType, insurance: undefined }}
                          onSubmit={() => {}}
                        >
                          <div className="flex flex-1 flex-col">
                            <LaboratoryOrderListItem labRequest={item} editable={false} readonly />
                            {item?.laboratoryData?.billingType !== BILLING_TYPES_CODES.INSURANCE && (
                              <BillingField
                                hideInsuranceField
                                hideInsuranceOption
                                onBillingChange={(billing) => {
                                  handleSelectCoverage?.(billing, sectionRequestsType, item)
                                }}
                                disabled={isProcessingActions}
                              />
                            )}
                          </div>
                        </Formik>
                      ))}
                  </>
                ) : (
                  <Formik
                    key={sectionRequestsType}
                    initialValues={{
                      billingType:
                        initialBillingType !== BILLING_TYPES_CODES.INSURANCE ? initialBillingType : undefined,
                      insurance: undefined,
                    }}
                    onSubmit={() => {}}
                  >
                    <BillingField
                      hideInsuranceOption
                      onBillingChange={(billing) => {
                        handleSelectCoverage?.(billing, sectionRequestsType)
                      }}
                      disabled={isProcessingActions}
                    />
                  </Formik>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

type Props = {
  label?: string
  shippingMethods?: ChargeItemDefinition[]
  discounts?: ChargeItemDefinition[]
  coverage?: Coverage
  handleSelectCoverage?(billingType: string, type?: ActionGroupCode, item?: CpoeRequest): void
  requests: CpoeRequest[]
  sectionRequestsType: ActionGroupCode
  selectedShippingMethod?: ChargeItemDefinition
  handleSelectShippingMethod?(cid: ChargeItemDefinition, type: ActionGroupCode): void
  selectedDiscount?: ChargeItemDefinition
  selectedRefillDiscount?: ChargeItemDefinition
  handleSelectDiscount?(cid: ChargeItemDefinition, type: ActionGroupCode): void
  handleSelectRefillDiscount?(cid: ChargeItemDefinition, type: ActionGroupCode): void
  totalItemsCost?: number
  className?: string
  nutrasShippingAddress?: Address
}

export { CheckoutSection }
