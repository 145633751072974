import { useIsMutating } from "@tanstack/react-query"
import { type Patient, asReference, getFirstEmailNoFallback } from "fhir"
import { type FormikHelpers, type FormikProps, Form, Formik } from "formik"
import { useRef, type FC } from "react"

import { useChartContext } from "chart-view"
import { Button, EmailField, PhoneField, useUpdateUserEmail } from "commons"

import { useInvitePatient, usePatchPatient } from "../../hooks"
import { sanitizeTelecom } from "../validations"
import { PatientInviteContainer } from "../PatientInviteContainer"

const TelecomForm: FC<Props> = ({ patient, hasB2CUser }) => {
  const initialValues: Partial<Patient> = { telecom: patient.telecom }

  const blockByInvite = useRef(false)

  const isCheckingEmail = useIsMutating({ mutationKey: ["check-email-exists"], status: "pending" })

  const { navigateToOriginModule } = useChartContext()
  const { invitePatient, isInviting } = useInvitePatient(() => (blockByInvite.current = false))
  const { patchPatient, isPatching } = usePatchPatient(navigateToOriginModule)
  const { updateEmail, isPending } = useUpdateUserEmail(patient.id, (_, { email, resourceId }) => {
    if (blockByInvite.current) invitePatient({ patientId: resourceId, patientNameOrEmail: email })
  })

  const handleSubmit = (telecomData: Partial<Patient>, fomikHelpers: FormikHelpers<Partial<Patient>>) => {
    if (
      !!telecomData.telecom?.[0]?.value &&
      JSON.stringify(telecomData.telecom?.[0] ?? {}) !== JSON.stringify(patient.telecom?.[0] ?? {})
    ) {
      updateEmail({ email: telecomData.telecom?.[0]?.value, resourceId: patient.id as string, resourceType: "Patient" })
    }
    if (JSON.stringify(telecomData.telecom?.[1] ?? {}) !== JSON.stringify(patient.telecom?.[1] ?? {}))
      patchPatient({
        patientId: patient.id as string,
        patientData: { telecom: sanitizeTelecom(telecomData.telecom), meta: patient.meta },
      })
    fomikHelpers.setSubmitting(false)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      {({
        isSubmitting,
        isValid,
        values: { telecom },
        initialValues: { telecom: initialTelecom },
        getFieldMeta,
        submitForm,
      }: FormikProps<Partial<Patient>>) => (
        <Form className="flex h-full flex-col divide-y divide-gray-200 overflow-hidden">
          <section className="grid h-full grid-cols-2 gap-x-3 overflow-hidden pb-6 @xl:grid-cols-3">
            <fieldset className="col-span-1 gap-y-6 px-4 @xl:col-span-2">
              <EmailField
                field="telecom[0].value"
                label="Email"
                validateRules
                initialValue={initialValues.telecom?.[0].value}
                validateDuplicate
                subject={asReference(patient)}
                disabled={blockByInvite.current}
              />
              <PhoneField
                unmask
                field="telecom[1].value"
                className="mt-2"
                label="Phone"
                validation={(value) => (!value ? "Phone is required" : undefined)}
                disabled={blockByInvite.current}
              />
            </fieldset>
            <PatientInviteContainer
              patient={patient}
              hasB2CUser={hasB2CUser}
              emailUpdates={{
                initialEmail: getFirstEmailNoFallback(initialTelecom),
                newEmail: getFirstEmailNoFallback(telecom),
                isUpdating: isSubmitting,
                updateEmailAndInvite: async (callback) => {
                  await submitForm()
                  if (isValid) {
                    blockByInvite.current = true
                    callback?.()
                  }
                },
              }}
              invitePatient={invitePatient}
              isInviting={isInviting}
              disabled={!!getFieldMeta("telecom[0].value").error}
            />
          </section>

          <div className="flex flex-shrink-0 justify-end gap-3 px-4 py-4">
            <Button
              type="submit"
              label="Update"
              size="lg"
              loading={isPatching || isPending || isSubmitting}
              disabled={!!isCheckingEmail || blockByInvite.current || isInviting}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

type Props = {
  patient: Patient
  hasB2CUser?: boolean
}

export { TelecomForm }
