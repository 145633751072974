import {
  InputField,
  AutocompleteCodeableConceptField,
  EmailField,
  AddressField,
  PhoneField,
  GenderField,
  ValueSetIds,
} from "commons"

const RelatedPersonForm = () => (
  <>
    <fieldset className="p-fluid relative grid grid-cols-2 gap-4">
      <legend>Basic Information</legend>

      <InputField field="name[0].given[0]" label="First Name" />
      <InputField field="name[0].family" label="Last Name" />

      <GenderField field="gender" label="Biological Sex" className="col-span-2" />
      <AutocompleteCodeableConceptField
        field="relationship[0]"
        label="Relationship"
        valueSetId={ValueSetIds.RELATIONSSHIP_TYPE}
        className="col-span-2"
      />

      <EmailField field="telecom[0].value" label="Email" className="col-span-2" />
      <PhoneField field="telecom[1].value" label="Phone" className="col-span-2" />
    </fieldset>

    <fieldset className="p-fluid relative grid grid-cols-2 gap-4">
      <legend>Address</legend>
      <AddressField parentFieldName="address[0]" showTypeUseField={false} />
    </fieldset>
  </>
)

export { RelatedPersonForm }
