import { faBarcode, faBuilding, faQuestionCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { codeableConceptAsString } from "fhir"
import { classNames } from "primereact/utils"

import { CardListItem } from "commons"
import type { LaboratoryOrderPanel } from "commons/labs"
import { getCommonCode, getMoneyCurrencyAlt } from "utils"
import { defaultEditRemoveMenu } from "utils-components"

const LaboratoryOrderPanelItem = ({
  panel,
  isReadonly,
  onDelete,
  parentComboTitle,
  showQrs = false,
  showPrice = true,
  className = "mr-2",
  lastElementClass,
  isInsurance,
}: Props) => {
  const menuItems = defaultEditRemoveMenu(undefined, onDelete)

  const chLabIdentifier = getCommonCode({ codes: panel.profile.code?.coding })
  const performerDisplay = panel.profile.performer?.[0]?.display

  const head = (
    <span className={classNames("flex w-full items-center justify-between", className)} title="Code">
      <span className="flex flex-col">
        <span className="break-words whitespace-normal">{codeableConceptAsString(panel?.profile?.code)}</span>
        <div className="flex space-x-1">
          {performerDisplay && (
            <span title="Performer" className="text-sm text-gray-400">
              <FontAwesomeIcon icon={faBuilding} size="xs" className="mr-1" />
              {performerDisplay}
            </span>
          )}
          {chLabIdentifier && (
            <span title="Lab Identifier" className="text-sm text-gray-400">
              <FontAwesomeIcon icon={faBarcode} size="xs" className="mr-1" />
              {chLabIdentifier}
            </span>
          )}
        </div>

        {showQrs &&
          panel?.questionnairesResponses &&
          panel?.questionnairesResponses.length > 0 &&
          panel?.questionnairesResponses?.map((qr) => (
            <span className="text-gray-400" key={qr.id}>
              <FontAwesomeIcon icon={faQuestionCircle} className="mr-1" size="xs" />
              <span className="mr-1">{qr.item?.[0].text}:</span>
              <span>{qr.item?.[0].answer?.[0].value?.string}</span>
            </span>
          ))}
      </span>
      {parentComboTitle ? (
        <span title="Combo" className="text-xs text-gray-400">
          ({parentComboTitle})
        </span>
      ) : (
        showPrice && (
          <span title="Price" className={classNames("text-sm", { "line-through": isInsurance })}>
            {panel.price ? (
              `${getMoneyCurrencyAlt(panel.price?.currency)}${panel.price?.value?.toFixed(2)}`
            ) : (
              <span className="text-gray-400">No price</span>
            )}
          </span>
        )
      )}
    </span>
  )

  return (
    <CardListItem
      contentHeader={head}
      actionItem={isReadonly ? undefined : menuItems[0]}
      lastElementClass={lastElementClass}
      actionsClassName="text-sm"
    />
  )
}

type Props = {
  panel: LaboratoryOrderPanel
  isReadonly?: boolean
  onDelete?(): void
  showQrs?: boolean
  parentComboTitle?: string
  showPrice?: boolean
  className?: string
  lastElementClass?: string
  isInsurance?: boolean
}

export { LaboratoryOrderPanelItem }
