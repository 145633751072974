import { type FC } from "react"
import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"

import { StackedListContainer } from "commons"
import { familyHistoryModelBuilder } from "family-history"

import { WrapUpSection } from "./WrapUpSection"
import type { IntakeData } from "../../types"

const FamilyHistorySection: FC<Props> = ({ familyHistory, total, icon, hideSpacer }) => (
  <WrapUpSection
    subSectionTitle="Family History"
    isEmpty={!total}
    icon={icon}
    emptyMesage="No history registered during this encounter"
    className="flex w-full flex-row text-sm"
    inlineContent={!hideSpacer}
  >
    <StackedListContainer
      data={familyHistory ?? []}
      itemModelBuilder={(item) => ({ ...familyHistoryModelBuilder(item), badge: undefined })}
      className="w-full"
      itemsClassName="!pl-0 py-4"
    />
  </WrapUpSection>
)

type Props = {
  total?: number
  icon: IconDefinition
  hideSpacer?: boolean
} & Pick<IntakeData, "familyHistory">

export { FamilyHistorySection }
