import { classNames } from "primereact/utils"
import type { ComponentProps } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBold, faItalic, faUnderline } from "@fortawesome/pro-solid-svg-icons"

const IconButton = ({ icon, active, className, ...props }: IconButtonProps) => {
  if (!icon) return null

  return (
    <button
      {...props}
      className={classNames(
        `rounded-sm px-1 py-[1px] ${active ? "text-slate-900" : ""} ${
          props.disabled
            ? `bg-slate-50 text-slate-400`
            : active
              ? `cursor-pointer bg-slate-100 text-slate-900 hover:bg-slate-200 hover:text-slate-900`
              : `cursor-pointer bg-slate-100 text-slate-500 hover:bg-slate-200 hover:text-slate-900`
        }`,
        className,
      )}
    >
      <FontAwesomeIcon icon={IconLibrary[icon]} />
    </button>
  )
}

const IconLibrary: Record<IconType, typeof faBold> = {
  bold: faBold,
  italic: faItalic,
  underline: faUnderline,
}

type IconType = "bold" | "italic" | "underline"

type IconButtonProps = {
  active?: boolean
  icon?: IconType
} & ComponentProps<"button">

export { IconButton }
