import { PaymentView } from "@chartedhealth/payment-components"
import { type FC, Suspense, useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { LoadingView } from "commons"

const PaymentDetailsContainer: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { setSearchData } = useChartContext()

  const paymentId = searchParams.get("paymentId")

  useEffect(() => {
    setSearchData({
      showSearch: false,
    })
  }, [])

  const showInvoiceDetails = (invoiceId: string) => {
    searchParams.delete("paymentId")
    searchParams.set("invoiceId", invoiceId)

    setSearchParams(searchParams)
  }

  if (!paymentId) {
    return null
  }

  return (
    <div className="relative flex h-screen w-full flex-col overflow-auto bg-white p-3">
      <Suspense fallback={<LoadingView />}>
        <PaymentView paymentId={paymentId} showInvoiceDetails={showInvoiceDetails} />
      </Suspense>
    </div>
  )
}

export { PaymentDetailsContainer }
