import { useMutation } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess, openLinkInNewTabV2 } from "utils"
import { registerErrorTrace } from "logger"
import type { CustomError } from "commons"
import { useOrganizationContext } from "organization"

const useExportPatients = (onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const { currentOrganizationId } = useOrganizationContext()

  const exportFn = async () => {
    const response = await operationRequest({
      endpoint: "Organization",
      method: "GET",
      operation: "export-patients-csv",
      id: currentOrganizationId,
      headers: { Accept: "text/csv" },
      rawResponse: true, // use raw response
    })

    const blob = await (response as Response).blob()
    const url = window.URL.createObjectURL(blob)

    openLinkInNewTabV2({ download: `${currentOrganizationId}-patients.csv`, url })
  }

  const { mutate: exportPatients, isPending: isExporting } = useMutation({
    mutationFn: exportFn,
    onSuccess: async () => {
      displayNotificationSuccess(`Patients exported successfully`)
    },
    onSettled,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, { patientId: context }))
    },
  })

  return { exportPatients, isExporting }
}

export { useExportPatients }
