import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type FC, useEffect, useMemo, useState } from "react"
import type { Practitioner } from "fhir"

import { GroupedList, SearchWithFilters, StackedListItem } from "commons"
import { useOrganizationContext } from "organization"

import { getPractitionerModel } from "./getPractitionerModel"
import { practitionerMatchesQuery, getGroupedPractitioners } from "../../../utils"

const PractitionersList: FC<Props> = ({ onPractitionerSelected }) => {
  const { organizationPractitionersInfo: practitioners } = useOrganizationContext()

  const [currentPractitioners, setCurrentPractitioners] = useState(practitioners)
  const [filters, setFilters] = useState<{ searchText?: string }>({
    searchText: "",
  })

  const search = (searchText?: string) => {
    setFilters({ searchText })
    if (!searchText) setCurrentPractitioners(practitioners)
    else {
      setCurrentPractitioners(
        practitioners.filter(({ practitioner }) => practitionerMatchesQuery(practitioner, searchText)),
      )
    }
  }

  useEffect(() => {
    search(filters?.searchText)
  }, [practitioners])

  const practitionerGroups = useMemo(() => getGroupedPractitioners(currentPractitioners), [currentPractitioners])

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <div className="mb-4 flex h-12 flex-col justify-start gap-1">
        <h3 className="text-lg">Practitioners Calendar</h3>
      </div>
      <div className="mb-3 flex flex-col gap-1 p-2">
        <SearchWithFilters onTextFilter={search} isLoading={false} showClearButton />
        <p className="mt-1 text-sm text-gray-500">Showing {practitioners.length} active practitioners</p>
      </div>
      <div className="flex w-full grow overflow-y-auto">
        <GroupedList
          className="grow"
          groups={practitionerGroups}
          renderItem={(practitionerInfo) => (
            <StackedListItem
              modelData={getPractitionerModel({
                practitionerInfo,
                onClick: () => {
                  onPractitionerSelected(practitionerInfo.practitioner)
                },
              })}
            />
          )}
          renderEmptyState={() => (
            <div className="m-auto flex flex-col items-center pt-10">
              <FontAwesomeIcon icon={faSearch} size="2x" className="text-slate-500" />
              <p className="pt-3 text-slate-400">No results found, please change filters and try again</p>
            </div>
          )}
        />
      </div>
    </div>
  )
}

type Props = {
  onPractitionerSelected(pract: Practitioner): void
}

export { PractitionersList }
